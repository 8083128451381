import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { uploadImageObject } from "../productgroups/utils";
import { ProductForm } from "./_form";

export const ProductCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("product.create")) return null;

  const transform = async (data: any) => {
    data.images = await Promise.all(data.images.map(uploadImageObject));
    return data;
  };

  return (
    <Create redirect="show" transform={transform}>
      <ProductForm />
    </Create>
  );
};
