import { ModalAction } from "@/components/common/ModalAction";
import { Box, Typography } from "@mui/material";
import {
  ArrayInput,
  Button,
  Form,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  maxValue,
  minValue,
  useNotify,
  useRecordContext,
  useRefresh,
  useSimpleFormIteratorItem,
} from "react-admin";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import { AxiosError, TPurchase, TSubPurchase, getSeeriApi } from "@/api";
import Refresh from "@mui/icons-material/Refresh";
import { FC, useState } from "react";
import { useAdmin } from "@/hooks/admin";

export const SubpurchaseReturnProductsAction: FC<{ purchase: TPurchase }> = ({
  purchase,
}) => {
  const subpurchase = useRecordContext<TSubPurchase>();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const { isAdmin } = useAdmin();

  const candidateProducts = (purchase?.products ?? [])
    .filter(
      (p: any) =>
        subpurchase.id === p.subPurchaseId &&
        (p.quantityReturned ?? 0) < p.quantity
    )
    .map((p: any) => ({
      id: p.id,
      name: p.name,
      quantityReturned: 0,
      maxQuantity: p.quantity - (p.quantityReturned ?? 0),
      quantity: p.quantity,
    }));

  const formId = "purchase-return-products-" + purchase?.id;

  const handleSubmit = async (values: any, onClose: Function) => {
    if (loading) return;
    setLoading(true);

    try {
      const productsReturned = values.productsReturned
        .filter((p: any) => p.quantity > 0)
        .map((p: any) => ({
          id: p.id,
          quantityReturned: p.quantityReturned,
          quantity: p.quantity,
        }));
      await getSeeriApi().post("/api/purchases/returnProducts", {
        purchaseId: purchase.id,
        productsReturned,
      });
      notify("Se procesaron los productos con éxito", { type: "success" });
      refresh();
      onClose();
    } catch (error) {
      console.log(error);
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  return (
    <Box>
      <ModalAction
        buttonMode="icon"
        buttonIcon={<AssignmentReturnIcon />}
        buttonDisabled={!isAdmin && subpurchase.deliveryStatus !== "DELIVERED"}
        dialogTitle="Devolver productos"
        dialogMaxWidth="sm"
        dialogContent={(onClose) => (
          <Form
            id={formId}
            record={{ productsReturned: candidateProducts }}
            onSubmit={(values) => handleSubmit(values, onClose)}
          >
            {candidateProducts.length > 0 ? (
              <ArrayInput
                label="Productos a devolver"
                source="productsReturned"
                fullWidth
              >
                <SimpleFormIterator
                  disableAdd
                  disableRemove
                  disableReordering
                  disableClear
                  inline
                  fullWidth
                >
                  <SelectInput
                    label="Producto"
                    source="id"
                    optionValue="id"
                    optionText="name"
                    choices={candidateProducts}
                    disabled
                    sx={{ flex: "1" }}
                  />
                  <ProductQuantity />
                </SimpleFormIterator>
              </ArrayInput>
            ) : (
              <Typography>No hay productos para devolver</Typography>
            )}
          </Form>
        )}
        dialogActions={
          candidateProducts.length > 0 && (
            <Box>
              <Button
                label="Devolver"
                variant="contained"
                type="submit"
                size="medium"
                form={formId}
                disabled={loading}
                startIcon={
                  loading && (
                    <Refresh className={loading ? "rotate-icon" : ""} />
                  )
                }
              />
            </Box>
          )
        }
      />
    </Box>
  );
};

const ProductQuantity = () => {
  const { index } = useSimpleFormIteratorItem();
  const { productsReturned } = useRecordContext();

  return (
    <NumberInput
      label="Cantidad a devolver"
      source={"productsReturned." + index + ".quantityReturned"}
      validate={[minValue(0), maxValue(productsReturned[index].maxQuantity)]}
      sx={{ flex: "1" }}
    />
  );
};
