import { FC } from "react";
import { TPurchase } from "@/api";
import { Chip, Typography, Stack } from "@mui/material";

import { formatCurrency } from "../../utils/currency";
import { deliveryStatusRendering } from "../../data/purchases";
import {
  formatStatusColor,
  groupTotalByStatus,
  groupTotalBySubpurchaseStatus,
} from "../../hooks/purchase";

type Props = {
  purchase: TPurchase;
};

export const PricingByStatus: FC<Props> = ({ purchase }) => {
  const totalByPendingProducts = groupTotalByStatus(purchase.products);
  const totalBySubpurchaseStatus = groupTotalBySubpurchaseStatus(
    purchase.subPurchases
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h6" fontWeight="bold">
        Total según estado de envío
      </Typography>
      {Object.keys(totalByPendingProducts).map((code) => (
        <PriceLabel
          key={code}
          code={code}
          price={totalByPendingProducts[code]}
        />
      ))}
      {Object.keys(totalBySubpurchaseStatus).map((code) => (
        <PriceLabel
          key={code}
          code={code}
          price={totalBySubpurchaseStatus[code]}
        />
      ))}
    </Stack>
  );
};

const PriceLabel = ({ code, price }: { code: string; price: number }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Chip
        label={deliveryStatusRendering.find((s) => s.code === code)?.name}
        size="small"
        variant="outlined"
        color={formatStatusColor(code)}
      />
      <Typography variant="h6">{formatCurrency(price)}</Typography>
    </Stack>
  );
};
