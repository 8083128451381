import { TCategory, TCategoryTree, getSeeriApi } from "@/api";
import { FC } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
  AutocompleteInput,
  AutocompleteInputProps,
} from "react-admin";
import useSWR from "swr";

type Props = {
  maxDeepLevel?: number;
};

type CategoryTree = TCategory & { subCategories: CategoryTree[] };
type TChoice = { id: string; name: string };

export const useCategoriesChoices = ({ maxDeepLevel = Infinity }: Props) => {
  const { data: categories } = useSWR("category-tree", () =>
    getSeeriApi()
      .get<CategoryTree[]>("/api/categories/v2", {
        params: { fullTree: "true" },
      })
      .then((res) => res.data)
  );

  if (!categories) return [];

  function getChoices(
    categories: TCategoryTree[],
    parentName: string,
    level: number
  ): TChoice[] {
    const choices = [];
    for (const category of categories) {
      let name = category.name;
      if (parentName) name = parentName + " - " + name;
      choices.push({ id: category.id, name });
      if (category.subCategories && maxDeepLevel - 1 > level) {
        const subChoices = getChoices(category.subCategories, name, level + 1);
        choices.push(...subChoices);
      }
    }
    return choices;
  }

  const choices = getChoices(categories, "", 0);

  return choices;
};

export const CategoryAutocompleteInput: FC<AutocompleteInputProps & Props> = ({
  maxDeepLevel = Infinity,
  ...props
}) => {
  const categories = useCategoriesChoices({ maxDeepLevel });
  return <AutocompleteInput {...props} choices={categories} fullWidth />;
};

export const CategoryAutocompleteArrayInput: FC<
  AutocompleteArrayInputProps & Props
> = ({ maxDeepLevel = Infinity, ...props }) => {
  const categories = useCategoriesChoices({ maxDeepLevel });
  return <AutocompleteArrayInput {...props} choices={categories} fullWidth />;
};
