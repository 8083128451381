import { FC } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { PRODUCT_GROUPS } from "../../config/resources";
import { ProductInputs, uniqueOptionCombination } from "./inputs";
import { formatOptions } from "../productgroups/inputs/options";
import { TProduct } from "@/api";

const FormToolbar = () => {
  const isEdit = !!useRecordContext();
  return (
    <Toolbar>
      <SaveButton alwaysEnable={isEdit} />
    </Toolbar>
  );
};

export const ProductForm: FC = () => {
  const product = useRecordContext<TProduct>();

  return (
    <SimpleForm
      mode="onBlur"
      record={product}
      toolbar={<FormToolbar />}
      warnWhenUnsavedChanges
    >
      <FormContent />
    </SimpleForm>
  );
};

const FormContent = () => {
  const productGroupId = useWatch({ name: "groupProductId" });
  const productGroup = useProductGroup(productGroupId);

  return (
    <>
      <ProductGroupInput />
      <ProductInputs
        groupDeps={{
          ...productGroup,
          options: formatOptions(productGroup?.options),
        }}
      />
    </>
  );
};

const ProductGroupInput = () => {
  const currentProduct = useRecordContext();
  const productOptions = useWatch({ name: "productOptions" });
  const productGroupId = useWatch({ name: "groupProductId" });
  const productGroup = useProductGroup(productGroupId);

  return (
    <ReferenceInput
      source="groupProductId"
      reference={PRODUCT_GROUPS}
      perPage={50}
    >
      <AutocompleteInput
        source="id"
        optionText="name"
        filterToQuery={(searchText: string) => ({ name: searchText })}
        fullWidth
        validate={[
          () =>
            uniqueOptionCombination(
              productGroup?.products.filter(
                (p: TProduct) => p.id !== currentProduct?.id
              ) ?? [],
              {
                productOptions,
              }
            ),
          ...(currentProduct ? [] : [required()]),
        ]}
        disabled={!!currentProduct}
      />
    </ReferenceInput>
  );
};

const useProductGroup = (productGroupId: string) => {
  const { data: productGroup } = useGetOne(
    PRODUCT_GROUPS,
    { id: productGroupId },
    { enabled: !!productGroupId }
  );
  return productGroup;
};
