import { ModalAction } from "@/components/common/ModalAction";
import AddIcon from "@mui/icons-material/Add";
import { SellerInvoiceAddressCreate } from "./create";
import { FC } from "react";

export const CreateSellerInvoiceAddressAction: FC<{
  sellerId: string;
  disabled?: boolean;
}> = ({ sellerId, disabled = false }) => {
  return (
    <ModalAction
      dialogTitle="Crear dirección de facturación"
      buttonText="Crear"
      buttonIcon={<AddIcon />}
      buttonDisabled={disabled}
      dialogContent={(onClose) => (
        <SellerInvoiceAddressCreate sellerId={sellerId} onDone={onClose} />
      )}
      dialogContentSx={{ p: 0 }}
    />
  );
};
