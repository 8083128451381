import { FC, useEffect } from "react";
import { required, TextInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { generateSlug } from "../../utils/slug";

type SlugInputProps = {
  source: string;
  stringSource: string;
};

export const SlugInput: FC<SlugInputProps> = ({ source, stringSource }) => {
  const stringValue = useWatch({ name: stringSource });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (stringValue) {
      setValue(source, generateSlug(stringValue));
    } else {
      setValue(source, "");
    }
  }, [stringValue]);

  return (
    <TextInput
      source={source}
      validate={required()}
      helperText="Se muestra en la url de la página web"
      fullWidth
    />
  );
};
