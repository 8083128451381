import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { uploadImageObject } from "../productgroups/utils";
import { ProductForm } from "./_form";

export const ProductEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("product.update")) return null;

  const transform = async (data: any) => {
    if (data.optionsToBeDeleted?.length > 0) {
      data.optionsToBeDeleted.forEach((optionId: string) => {
        delete data.productOptions[optionId];
      });
      delete data.optionsToBeDeleted;
    }

    data.images = await Promise.all(data.images.map(uploadImageObject));
    return data;
  };

  return (
    <Edit mutationMode="pessimistic" redirect="show" transform={transform}>
      <ProductForm />
    </Edit>
  );
};
