import { CancelRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { AxiosError, revertCoinsTransaction } from "@/api";
import { FC, useState } from "react";
import { Button, useNotify, useRecordContext, useRefresh } from "react-admin";
import { useAdmin } from "../../../hooks/admin";

export const CoinsCancelTransaction: FC<{ purchaseId: string }> = ({
  purchaseId,
}) => {
  const notify = useNotify();
  const [sending, setSending] = useState(false);
  const [open, setOpen] = useState(false);
  const { admin } = useAdmin();
  const refresh = useRefresh();

  const record = useRecordContext();
  const sellerId = record!.id as string;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!admin) return null;

  return (
    <div>
      <Button label="Revertir" onClick={handleClickOpen}>
        <CancelRounded />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>¿Desea revertir la transacción?</DialogTitle>
        <DialogActions>
          <Button label="Cerrar" onClick={handleClose} />
          <Button
            label="Revertir"
            disabled={sending}
            onClick={async (e) => {
              e.stopPropagation();
              setSending(true);
              try {
                await revertCoinsTransaction(sellerId, purchaseId, {
                  createdBy: admin.id,
                });
                refresh();
                notify("La transacción de revirtió satisfactoriamente");
              } catch (error) {
                console.log({ error });
                if (error instanceof AxiosError) {
                  notify(error.response?.data?.message ?? error.message, {
                    type: "error",
                  });
                } else {
                  notify(
                    "No se puede realizar la reversión de la transacción",
                    { type: "error" }
                  );
                }
              }
              setSending(false);
              setOpen(false);
            }}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
