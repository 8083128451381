import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { AdminForm } from "./_form";

export const AdminCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("admin.create")) return null;

  return (
    <Create redirect="show">
      <AdminForm />
    </Create>
  );
};
