import { z } from "zod";
import { QuoteStatus } from "./constants";

export const getRules = (q: any = {}) => {
  const Status = QuoteStatus.enum;

  return {
    edit: z.enum([Status.PENDING, Status.QUOTED]).safeParse(q.status).success,
    quoted: z.enum([Status.PENDING, Status.QUOTED]).safeParse(q.status).success,
    progress: z.enum([Status.QUOTED]).safeParse(q.status).success,
    paid: z.enum([Status.PAYMENT_IN_PROGRESS]).safeParse(q.status).success,
    delivered: z.enum([Status.PAID]).safeParse(q.status).success,
  };
};
