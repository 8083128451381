export const type = [
  { id: "SUPPLEMENTATION", name: "Suplementacion deportiva" },
  { id: "NATULRAL_FOOD_STORE", name: "Tienda naturista" },
  { id: "HEALTHY_MARKET", name: "Mercado saludable " },
  { id: "GYM", name: "Gym" },
  { id: "PERSONAL_TRAINER", name: "Instructor personal" },
  { id: "MIXED_STORE", name: "Tienda mixta" },
];

export const userTypes = [
  { id: "SELLER", name: "Vendedor" },
  { id: "STORE", name: "Tienda" },
];

export const validationStatuses = [
  { name: "No válido", code: "NOT_VALID" },
  { name: "Válido", code: "VALIDATED" },
  { name: "En proceso", code: "IN_PROCESS" },
];
