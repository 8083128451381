import { AxiosError, getSeeriApi } from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import { useState } from "react";
import {
  Button,
  FileField,
  FileInput,
  Form,
  required,
  useNotify,
} from "react-admin";
import { Refresh } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { mdiFileCertificate } from "@mdi/js";

export const SubpurchasePaymentsCSVAction = () => {
  const notify = useNotify();
  const [uploading, setUploading] = useState(false);

  const onSubmit = async (values: any, onClose: Function) => {
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append("file", values.file.rawFile);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      await getSeeriApi().post(
        "/api/coordinadora/logistic/payments",
        formData,
        config
      );
      notify("Carga exitosa", { type: "success" });
      onClose();
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else if (error.response?.status === 400) {
          notify("Error al cargar. Por favor, revisa tu documento.", {
            type: "error",
          });
        } else if (error.response?.status === 500) {
          notify("Error de servidor. Vuelva a intentarlo en unos minutos.", {
            type: "error",
          });
        } else {
          notify(error.message, { type: "error" });
        }
      } else {
        notify("Ocurrió un error de conexión con el servidor", {
          type: "error",
        });
      }
    }
    setUploading(false);
  };

  const formId = "subpurchase-payments-csv";

  return (
    <ModalAction
      buttonVariant="text"
      buttonText="Cargar CSV"
      buttonIcon={
        <SvgIcon>
          <path d={mdiFileCertificate} />
        </SvgIcon>
      }
      dialogTitle="Cargar archivo CSV"
      dialogContent={(onClose) => (
        <Form id={formId} onSubmit={(values) => onSubmit(values, onClose)}>
          <FileInput
            source="file"
            label="Archivo CSV"
            accept="text/csv"
            fullWidth
            validate={[
              required(),
              (value) => {
                if (value?.rawFile.size > 1_500_000)
                  return "El archivo supera los 1.5MB";
              },
            ]}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </Form>
      )}
      dialogActions={
        <Button
          label="Guardar"
          type="submit"
          size="medium"
          form={formId}
          disabled={uploading}
          startIcon={
            uploading && <Refresh className={uploading ? "rotate-icon" : ""} />
          }
        />
      }
    />
  );
};
