import {
  ArrayField,
  DateField,
  ReferenceField,
  SaveButton,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { ADMINS, PURCHASES } from "../../../config/resources";
import { useCallback, useState } from "react";
import { AxiosError, addPurchaseComment } from "@/api";
import { useAdmin } from "../../../hooks/admin";

const PurchaseCommentsToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const PurchaseComments = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { hasPermission, admin } = useAdmin();
  const record = useRecordContext();

  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const { comment } = values;
        await addPurchaseComment(record.id as string, {
          createdBy: admin.id,
          comment,
          fullName: admin.firstName + " " + admin.lastName,
        });
        refresh();
        notify("Se guardó el comentario del pedido exitosamente", {
          type: "success",
        });
        setModalOpen(false);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se guardó el comentario del pedido", { type: "error" });
        }
        return;
      }
    },
    [record, admin]
  );

  if (!record) return null;

  return (
    <div style={{ width: "fit-content", height: "100%" }}>
      {record.comments?.length > 0 ? (
        <>
          <Typography fontWeight={"bold"}>Ultimo comentario</Typography>

          <Typography fontSize="small">{record.comments[0].message}</Typography>
        </>
      ) : (
        <div>Aún no hay comentarios</div>
      )}
      <Button
        variant="text"
        onClick={() => setModalOpen(true)}
        style={{ width: "100%" }}
      >
        {record.comments?.length > 0
          ? `Ver comentarios (${record.comments?.length})`
          : "Crear comentario"}
      </Button>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogContent>
          {hasPermission("purchase.add.comment") && (
            <SimpleForm
              record={record}
              resource={PURCHASES}
              toolbar={<PurchaseCommentsToolbar />}
              onSubmit={onSubmit}
            >
              <TextInput
                source="comment"
                label="Comentario"
                multiline
                minRows={2}
                fullWidth
              />
            </SimpleForm>
          )}
          <ArrayField source="comments">
            <SimpleList
              primaryText={<TextField source="message" fontSize="small" />}
              secondaryText={
                <ReferenceField source="createdBy" reference={ADMINS}>
                  <TextField source="firstName" />
                </ReferenceField>
              }
              tertiaryText={() => <DateField source="createdAt" showTime />}
              linkType={false}
              rowStyle={(_, index) => ({
                borderTop: index > 0 ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
                padding: "8px",
              })}
            />
          </ArrayField>
        </DialogContent>
      </Dialog>
    </div>
  );
};
