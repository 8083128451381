import { FC } from "react";
import { mdiAlertCircle } from "@mdi/js";
import { useRecordContext } from "react-admin";
import { Box, Divider, Stack, SvgIcon, Typography } from "@mui/material";
import { formatCurrency } from "../../utils/currency";

type Props = {
  ignoreStatus?: boolean;
};

export const ProductOptionRenderer: FC<Props> = ({ ignoreStatus }) => {
  const p = useRecordContext();

  const { supplierName, supplierSku, wholesalePrice, availableStock } =
    p.suppliers[0] ?? {};

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        width="100%"
        sx={{ border: "20 solid gray" }}
        paddingBottom={1}
      >
        <Stack direction="row" spacing={2} width="100%">
          <div>
            {p.images?.length ? (
              <img
                src={p?.images[0]?.src}
                width={40}
                height={40}
                className="object-cover bg-gray-400"
              />
            ) : (
              <div style={{ width: 40, height: 40, backgroundColor: "#ddd" }} />
            )}
          </div>
          <div>
            <Typography variant="subtitle2" lineHeight={1}>
              {p?.name}
            </Typography>
            <Typography
              variant="body2"
              display="block"
              lineHeight={1.8}
              color="gray"
            >
              SKU: {supplierSku?.slice(0, -13)}
            </Typography>
            <Typography
              variant="body2"
              display="block"
              color="gray"
              lineHeight={1}
            >
              Proveedor: {supplierName ?? "Error"}
            </Typography>
            {!ignoreStatus && p?.status === false && (
              <Stack direction="row" color="red" alignItems="center">
                <SvgIcon fontSize="inherit">
                  <path d={mdiAlertCircle} />
                </SvgIcon>
                <Typography variant="subtitle2" display="block">
                  El producto está desactivado
                </Typography>
              </Stack>
            )}
          </div>
        </Stack>
        <Box textAlign="right">
          <Typography variant="subtitle2">
            {formatCurrency(wholesalePrice ?? 0)}
          </Typography>
          <Typography variant="body2" whiteSpace="nowrap" color="gray">
            Stock: {availableStock ?? "-"}
          </Typography>
        </Box>
      </Stack>
      <Divider />
    </Box>
  );
};
