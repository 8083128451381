import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CouponForm } from "./_form";

export const CouponCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("coupon.create")) return null;

  const transform = (formData: any) => {
    const endData = {
      ...formData,
      maximumUsesByUser: formData.data.maximumUsesByUser,
    };

    return endData;
  };

  return (
    <Create redirect="show" transform={transform}>
      <CouponForm />
    </Create>
  );
};
