import { getClientsBySellerId } from "@/api";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  number,
  RadioButtonGroupInput,
  required,
  SelectInput,
  TextInput,
  useGetList,
  useUpdate,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import useSWR from "swr";
import { Alert, Grid } from "@mui/material";

import { DocumentTypeInput } from "../../shared/documen-type";
import { CityInput, CountryInput, StateInput } from "../../shared/location";
import { getClientsEnabled } from "../../../config/remote";
import { SELLER_ADDRESSES } from "../../../config/resources";
import { country } from "@/config/country";

export const ClientOption = () => {
  const sellerId = useWatch({ name: "sellerId" });
  const clientTarget = useWatch({ name: "clientTarget" });
  const { setValue } = useFormContext();

  return (
    <div>
      {getClientsEnabled() && (
        <RadioButtonGroupInput
          source="clientTarget"
          label="¿A quién va dirigida la venta?"
          choices={[
            { id: "own", name: "Mismo vendedor" },
            { id: "existing", name: "Cliente existinge" },
            { id: "new", name: "Nuevo cliente" },
          ]}
          validate={required()}
          onChange={(e) => setValue("isForSeller", e.target.value === "own")}
        />
      )}
      <div>
        {clientTarget === "own" ? (
          sellerId ? (
            <AdressesDropdown />
          ) : (
            <Alert severity="warning">
              Elige un vendedor para ver sus direcciones
            </Alert>
          )
        ) : clientTarget === "new" ? (
          <ClientForm />
        ) : clientTarget === "existing" ? (
          <ClientsDropdown />
        ) : null}
      </div>
    </div>
  );
};

const AdressesDropdown = () => {
  const { setValue, trigger } = useFormContext();
  const sellerId = useWatch({ name: "sellerId" });
  const addressId = useWatch({ name: "addressId" });

  const { data: addresses, isLoading } = useGetList(
    SELLER_ADDRESSES,
    { meta: { dependentId: sellerId } },
    {
      onSuccess: ({ data }) => {
        if (!data.some((a) => a.id === addressId)) {
          const main = data.find((a) => a.isMain)?.id || "";
          setValue("addressId", main);
          trigger("addressId");
        }
      },
    }
  );

  const [update, { isLoading: isUpdating }] = useUpdate();

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await update(
        SELLER_ADDRESSES,
        {
          id: event.target.value,
          data: { isMain: true },
          meta: { dependentId: sellerId },
        },
        {
          mutationMode: "pessimistic",
        }
      );
    },
    [sellerId]
  );

  return (
    <RadioButtonGroupInput
      source="addressId"
      label="Dirección"
      defaultValue={""}
      optionText={(a) => `${a?.addressLine1} - ${a?.city} - ${a?.state}`}
      choices={addresses || []}
      disabled={isLoading || isUpdating}
      onChange={handleChange}
      row={false}
      validate={[required()]}
    />
  );
};

const ClientsDropdown = () => {
  const sellerId = useWatch({ name: "sellerId" });
  const { setValue } = useFormContext();

  const { data } = useSWR(sellerId ? [sellerId, "clients"] : null, ([id]) =>
    getClientsBySellerId(id)
  );

  useEffect(() => {
    setValue("clientId", "");
  }, [sellerId]);

  return (
    <SelectInput
      source="clientId"
      label="Cliente"
      optionText={(c) => `${c.firstName} ${c.lastName}`}
      choices={data || []}
      disabled={!sellerId}
      validate={required()}
    />
  );
};

const ClientForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextInput source="client.firstName" validate={required()} fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextInput source="client.lastName" validate={required()} fullWidth />
      </Grid>
      <Grid item xs={6}>
        <DocumentTypeInput
          source="client.documentType"
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput source="client.documentId" validate={required()} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="client.displayName"
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput source="client.email" validate={required()} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <TextInput source="client.phone" validate={required()} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <CountryInput
          source="client.location.country"
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <StateInput
          source="client.location.state"
          countrySource="client.location.country"
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <CityInput
          source="client.location.city"
          stateSource="client.location.state"
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="client.location.postalCode"
          validate={[number()].concat(
            country.code === "co" ? [] : [required()]
          )}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="client.location.addressLine1"
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput source="client.location.addressLine2" fullWidth />
      </Grid>
    </Grid>
  );
};
