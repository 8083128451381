import { read, utils } from "xlsx";
import { productGroupHeaders, productHeaders } from "../headers";
import { difference, isEqual } from "lodash";
import { Swal } from "@/core/swal";

export const readTemplate = async (file: File) => {
  const groupKeys = Object.keys(productGroupHeaders);
  const variantKeys = Object.keys(productHeaders);
  const data = await file.arrayBuffer();
  const workbook = read(data);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  let aoa = utils.sheet_to_json<unknown[]>(worksheet, {
    header: 1,
    blankrows: false,
  });

  // Check headers
  const receivedHeaders = aoa[1];
  const expectedHeaders = [
    ...Object.values(productGroupHeaders),
    ...Object.values(productHeaders),
  ];
  if (!isEqual(receivedHeaders, expectedHeaders)) {
    const diff = difference(expectedHeaders, receivedHeaders);
    Swal.fire({
      title: "Las cabeceras del archivo ingresado son incompatibles",
      icon: "error",
      html: (
        <>
          <p>Las cabeceras compatibles son:</p>
          <p>
            {expectedHeaders.map((h, i, a) => (
              <span>
                {diff.includes(h) ? <b>{h}</b> : <>{h}</>}
                {i !== a.length - 1 && ", "}
              </span>
            ))}
          </p>
          <p>Puedes descargar la plantilla actualizada.</p>
        </>
      ),
    });
    return null;
  }

  // Build raw groups
  aoa = aoa.slice(2);
  const rawGroups: any[] = [];
  for (const row of aoa) {
    const excelGroup = row
      .slice(0, groupKeys.length)
      .reduce<Record<string, unknown>>((obj, value, i) => {
        obj[groupKeys[i]] = value;
        return obj;
      }, {});
    const excelVariant = row
      .slice(groupKeys.length, groupKeys.length + variantKeys.length)
      .reduce<Record<string, unknown>>((obj, value, i) => {
        obj[variantKeys[i]] = value;
        return obj;
      }, {});
    if (excelGroup.name) {
      rawGroups.push({ ...excelGroup, products: [excelVariant] });
    } else {
      rawGroups.at(-1)?.products.push(excelVariant);
    }
  }

  return rawGroups;
};
