import {
  BooleanInput,
  Button,
  SaveButton,
  required,
  SimpleForm,
  TextInput,
  Toolbar,
  useDelete,
  useRecordContext,
  number,
  SelectInput,
} from "react-admin";
import { Grid } from "@mui/material";
import { CityInput, CountryInput, StateInput } from "../../shared/location";
import type { FC } from "react";
import { SELLER_ADDRESSES } from "../../../config/resources";
import ActionDelete from "@mui/icons-material/Delete";
import { country, phonePrefixChoices } from "@/config/country";

const CustomDeleteButton: FC<{ sellerId: string }> = ({ sellerId }) => {
  const record = useRecordContext();
  const [deleteOne, { isLoading }] = useDelete();

  const handleClick = () => {
    const answer = window.confirm("¿Seguro que desea eliminar esta dirección?");
    if (!answer) return;
    deleteOne(
      SELLER_ADDRESSES,
      { id: record.id, meta: { dependentId: sellerId } },
      { mutationMode: "pessimistic" }
    );
  };

  return (
    <Button
      onClick={handleClick}
      label="Eliminar"
      color="secondary"
      startIcon={<ActionDelete />}
      alignIcon="left"
      disabled={isLoading}
    />
  );
};

const EditToolbar: FC<{ sellerId: string }> = ({ sellerId }) => {
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      {/* 
          Use this when the bug is fixed:
          https://github.com/marmelab/react-admin/issues/8016
      */}
      {/* <DeleteWithConfirmButton
        redirect={false}
        mutationOptions={{ meta: { dependentId: sellerId } }}
      /> */}
      <CustomDeleteButton sellerId={sellerId} />
    </Toolbar>
  );
};

export const SellerAddressForm: FC<{ sellerId: string }> = ({ sellerId }) => {
  const record = useRecordContext();
  return (
    <SimpleForm
      toolbar={record ? <EditToolbar sellerId={sellerId} /> : undefined}
      defaultValues={{ phonePrefix: country.phoneCode }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CountryInput source="country" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={6}>
          <StateInput
            source="state"
            countrySource="country"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CityInput
            source="city"
            stateSource="state"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            source="postalCode"
            validate={[number()].concat(
              country.code === "co" ? [] : [required()]
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="addressLine1" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="addressLine2" fullWidth />
        </Grid>

        <Grid item xs={6}>
          <SelectInput
            source="phonePrefix"
            label="Prefijo país"
            choices={phonePrefixChoices}
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            source="phone"
            label="Teléfono"
            validate={[number("Solo números")]}
            inputMode="tel"
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <BooleanInput source="isMain" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
