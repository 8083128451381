import {
  type AxiosError,
  type TSupplier,
  getAccountGroups,
  getAccountGroupsSupplier,
} from "@/api";
import { useEffect, useState } from "react";
import {
  BooleanField,
  EditButton,
  EmailField,
  FileField,
  FunctionField,
  Labeled,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useUpdate,
  useNotify,
  WithRecord,
  ArrayField,
  Datagrid,
} from "react-admin";
import { paymentMethodDeals } from "../../../data/suppliers";
import { useAdmin } from "../../../hooks/admin";
import { SupplierAddressList } from "../addresses/list";
import { useParams } from "react-router-dom";
import { getNTName } from "../constants";
import { SUPPLIERS } from "../../../config/resources";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Refresh, ToggleOn, ToggleOffOutlined } from "@mui/icons-material";
import { SupplierDiscounts } from "../discounts/discounts";
import { SupplierProductsMarketplaceOrStatus } from "./actions/products-marketplace";
import { SupplierUpdatePasswordAction } from "./actions/update-password";
import { SupplierCreateSubdomainModalAction } from "./actions/create-subdomain";
import { SupplierSellersList } from "./sellers";
import { SupplierProductsList } from "./products";
import { SupplierProductsBulkUpdateAction } from "./actions/bulk-update-products";
import { SupplierPaymentsTabContent } from "./tab-payments";
import { country } from "@/config/country";
import { ModalImageField } from "@/components/elements/ModalImageField";
import { SupplierSubscriptionModalAction } from "./actions/update-subscription";

const SupplierShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("supplier.action.subscription") && (
        <SupplierSubscriptionModalAction />
      )}
      {hasPermission("supplier.action.subdomain") && (
        <SupplierCreateSubdomainModalAction />
      )}
      {hasPermission("supplier.action.password") && (
        <SupplierUpdatePasswordAction />
      )}

      {hasPermission("supplier.update") && <EditButton />}
    </TopToolbar>
  );
};

export const SupplierShow = () => {
  const { hasPermission } = useAdmin();
  const { id } = useParams();

  if (!hasPermission("supplier.view")) return null;

  return (
    <Show actions={<SupplierShowActions />}>
      <TabbedShowLayout>
        <Tab label="Principal">
          <TextField source="id" />
          <FunctionField
            label="Logo"
            render={() => (
              <ModalImageField source="logo" maxHeight={80} maxWidth={80} />
            )}
          />
          <TextField source="name" />
          <TextField source="publicName" emptyText="-" />
          <TextField source="companyId" />
          <EmailField source="email" />
          <EmailField source="communicationChannel" emptyText="-" />
          <BooleanField source="multiSupplierProducts" />
          <BooleanField source="status" />
          <ManualInvoiceSwitch />
          <CanUseLogisticIntegrationSwitch />
          <OwnUponDeliverySwitch />
          <ShowPricingInfoOnInvoiceSwitch />
          <ShowClientInfoOnNotOwnedSalesSwitch />
          <ProductsAdministrationSwitch />
          <ProductsQuantitySwitch />
          <TextField source="contactName" />
          <TextField source="socialReason" />
          <TextField source="phonePrefix" emptyText="-" />
          <TextField source="phone" />
          <FunctionField
            source="paymentMethodDeal"
            render={(supplier: TSupplier) =>
              paymentMethodDeals.find(
                (pmd) => pmd.code === supplier.paymentMethodDeal
              )?.name || "-"
            }
          />

          <FileField
            source="digitalDocument.src"
            title="digitalDocument.src"
            target="_blank"
          />

          {country.code === "co" && <SupplierAccountGroupField />}
        </Tab>
        <Tab label="Negociación">
          <FunctionField
            source="negotiationType"
            render={(supplier: TSupplier) =>
              getNTName(supplier.negotiationType)
            }
          />
          <WithRecord
            render={(supplier: TSupplier) =>
              (supplier.negotiationType === "FIXED_MARGIN" ||
                supplier.negotiationType === "VARIABLE_MARGIN") && (
                <Labeled>
                  <ArrayField source="supplierTakeRates">
                    <Datagrid
                      bulkActionButtons={false}
                      sx={{ maxWidth: { lg: "30%" } }}
                    >
                      <NumberField source="discount" />
                      <NumberField source="takeRate" />
                    </Datagrid>
                  </ArrayField>
                </Labeled>
              )
            }
          />
          <WithRecord
            render={(supplier: TSupplier) =>
              supplier.negotiationType === "PRICE_LIST" && (
                <Labeled>
                  <NumberField
                    source="priceListIncreasePercentage"
                    emptyText="-"
                  />
                </Labeled>
              )
            }
          />
          <NumberField source="paymentGatewayServiceTakeRate" emptyText="-" />
          <NumberField source="logisticServiceTakeRate" emptyText="-" />
        </Tab>
        {/* This tab is nro 2 in the url, it is used by <SupplierPaymentShow /> */}
        <Tab label="Pagos y transacciones">
          <SupplierPaymentsTabContent />
        </Tab>
        <Tab label="Descuentos escalonados">
          <WithRecord
            render={(record: TSupplier) => (
              <Stack direction={"column"} spacing={4}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography>Proveedor</Typography>
                    <SupplierDiscounts
                      applier="SUPPLIER"
                      supplierId={record.id}
                    />
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardContent>
                    <Typography>Marcas</Typography>
                    <SupplierDiscounts applier="BRAND" supplierId={record.id} />
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardContent>
                    <Typography>Productos</Typography>
                    <SupplierDiscounts
                      applier="PRODUCT"
                      supplierId={record.id}
                    />
                  </CardContent>
                </Card>
              </Stack>
            )}
          />
        </Tab>
        <Tab label="Productos">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            {hasPermission("supplier.action.productsMarketplace") && (
              <>
                <SupplierProductsMarketplaceOrStatus
                  field="isMarketplace"
                  mode="enable"
                />
                <SupplierProductsMarketplaceOrStatus
                  field="isMarketplace"
                  mode="disable"
                />
              </>
            )}
            {hasPermission("supplier.action.disableProducts") && (
              <>
                <SupplierProductsMarketplaceOrStatus
                  field="status"
                  mode="enable"
                />
                <SupplierProductsMarketplaceOrStatus
                  field="status"
                  mode="disable"
                />
              </>
            )}
            {hasPermission("supplier.products.bulkUpdate") && (
              <>
                <SupplierProductsBulkUpdateAction />

                <WithRecord
                  render={(supplier: TSupplier) =>
                    supplier.productsAdministration && (
                      <Typography color="brown" fontSize="14px">
                        El proveedor administra sus productos
                      </Typography>
                    )
                  }
                />
              </>
            )}
          </Box>
          <SupplierProductsList />
        </Tab>
        <Tab label="Almacenes">
          <SupplierAddressList supplierId={id!} />
        </Tab>
        <Tab label="Vendedores">
          <SupplierSellersList supplierId={id!} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const SupplierAccountGroupField = () => {
  const [accountGroup, setAccountGroup] = useState<any>({
    id: null,
    name: null,
    active: false,
  });
  const supplier = useRecordContext<TSupplier>();

  useEffect(() => {
    if (country.code === "co") {
      getAccountGroupsSupplier().then(async (groups) => {
        const group = groups.find((g) => g.supplierId === supplier.id);
        await getAccountGroups().then((accountGroups) =>
          setAccountGroup(
            accountGroups.find((ag) => ag.id === group?.siigoAccountGroupId)
          )
        );
      });
    }
  }, []);

  return (
    <Labeled label="Grupo de cuenta">
      <FunctionField render={() => accountGroup?.name || "-"} />
    </Labeled>
  );
};

const ProductsQuantitySwitch = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate(
    SUPPLIERS,
    {
      id: record?.id,
      data: {
        ...record,
        canChangeProductQuantity: !record.canChangeProductQuantity,
      },
      previousData: record,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );
  return (
    <Labeled label={"El proveedor puede modificar las unidades de sus pedidos"}>
      <IconButton
        color="primary"
        title={record.canChangeProductQuantity ? "Desactivar" : "Activar"}
        onClick={(e) => {
          e.stopPropagation();
          update();
        }}
        disabled={isLoading}
        style={{ width: "3rem" }}
      >
        {isLoading ? (
          <Refresh className={isLoading ? "rotate-icon" : ""} />
        ) : record.canChangeProductQuantity ? (
          <ToggleOn fontSize="large" />
        ) : (
          <ToggleOffOutlined fontSize="large" />
        )}
      </IconButton>
    </Labeled>
  );
};

const ManualInvoiceSwitch = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate(
    SUPPLIERS,
    {
      id: record?.id,
      data: { ...record, manualInvoice: !record.manualInvoice },
      previousData: record,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );
  return (
    <Labeled label={"El proveedor factura sus pedidos"}>
      <IconButton
        color="primary"
        title={record.manualInvoice ? "Desactivar" : "Activar"}
        onClick={(e) => {
          e.stopPropagation();
          update();
        }}
        disabled={isLoading}
        style={{ width: "3rem" }}
      >
        {isLoading ? (
          <Refresh className={isLoading ? "rotate-icon" : ""} />
        ) : record.manualInvoice ? (
          <ToggleOn fontSize="large" />
        ) : (
          <ToggleOffOutlined fontSize="large" />
        )}
      </IconButton>
    </Labeled>
  );
};

const CanUseLogisticIntegrationSwitch = () => {
  const supplier = useRecordContext<TSupplier>();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate(
    SUPPLIERS,
    {
      id: supplier?.id,
      data: {
        ...supplier,
        canUseLogisticIntegration: !supplier.canUseLogisticIntegration,
      },
      previousData: supplier,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );
  return (
    <Labeled
      label={"El proveedor puede usar la integración logística de Seeri"}
    >
      <IconButton
        color="primary"
        title={supplier.canUseLogisticIntegration ? "Desactivar" : "Activar"}
        onClick={(e) => {
          e.stopPropagation();
          update();
        }}
        disabled={isLoading}
        style={{ width: "3rem" }}
      >
        {isLoading ? (
          <Refresh className={isLoading ? "rotate-icon" : ""} />
        ) : supplier.canUseLogisticIntegration ? (
          <ToggleOn fontSize="large" />
        ) : (
          <ToggleOffOutlined fontSize="large" />
        )}
      </IconButton>
    </Labeled>
  );
};

const OwnUponDeliverySwitch = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate(
    SUPPLIERS,
    {
      id: record?.id,
      data: { ...record, ownUponDelivery: !record.ownUponDelivery },
      previousData: record,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );
  return (
    <Labeled
      label={
        "El proveedor puede usar logística propia en pedidos contra entrega"
      }
    >
      <IconButton
        color="primary"
        title={record.ownUponDelivery ? "Desactivar" : "Activar"}
        onClick={(e) => {
          e.stopPropagation();
          update();
        }}
        disabled={isLoading}
        style={{ width: "3rem" }}
      >
        {isLoading ? (
          <Refresh className={isLoading ? "rotate-icon" : ""} />
        ) : record.ownUponDelivery ? (
          <ToggleOn fontSize="large" />
        ) : (
          <ToggleOffOutlined fontSize="large" />
        )}
      </IconButton>
    </Labeled>
  );
};

const ShowPricingInfoOnInvoiceSwitch = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate(
    SUPPLIERS,
    {
      id: record?.id,
      data: {
        ...record,
        showPricingInfoOnInvoice: !record.showPricingInfoOnInvoice,
      },
      previousData: record,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );
  return (
    <Labeled label={"Mostrar precios en remisión de proveedor"}>
      <IconButton
        color="primary"
        title={record.showPricingInfoOnInvoice ? "Desactivar" : "Activar"}
        onClick={(e) => {
          e.stopPropagation();
          update();
        }}
        disabled={isLoading}
        style={{ width: "3rem" }}
      >
        {isLoading ? (
          <Refresh className={isLoading ? "rotate-icon" : ""} />
        ) : record.showPricingInfoOnInvoice ? (
          <ToggleOn fontSize="large" />
        ) : (
          <ToggleOffOutlined fontSize="large" />
        )}
      </IconButton>
    </Labeled>
  );
};

const ShowClientInfoOnNotOwnedSalesSwitch = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate(
    SUPPLIERS,
    {
      id: record?.id,
      data: {
        ...record,
        showClientInfoOnInvoice: !record.showClientInfoOnInvoice,
      },
      previousData: record,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );
  return (
    <Labeled label={"Mostrar información del cliente en pedidos no propios"}>
      <IconButton
        color="primary"
        title={record.showClientInfoOnInvoice ? "Desactivar" : "Activar"}
        onClick={(e) => {
          e.stopPropagation();
          update();
        }}
        disabled={isLoading}
        style={{ width: "3rem" }}
      >
        {isLoading ? (
          <Refresh className={isLoading ? "rotate-icon" : ""} />
        ) : record.showClientInfoOnInvoice ? (
          <ToggleOn fontSize="large" />
        ) : (
          <ToggleOffOutlined fontSize="large" />
        )}
      </IconButton>
    </Labeled>
  );
};

const ProductsAdministrationSwitch = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate(
    SUPPLIERS,
    {
      id: record?.id,
      data: {
        ...record,
        productsAdministration: !record.productsAdministration,
      },
      previousData: record,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );
  return (
    <Labeled label={"El proveedor administra sus productos"}>
      <IconButton
        color="primary"
        title={record.productsAdministration ? "Desactivar" : "Activar"}
        onClick={(e) => {
          e.stopPropagation();
          update();
        }}
        disabled={isLoading}
        style={{ width: "3rem" }}
      >
        {isLoading ? (
          <Refresh className={isLoading ? "rotate-icon" : ""} />
        ) : record.productsAdministration ? (
          <ToggleOn fontSize="large" />
        ) : (
          <ToggleOffOutlined fontSize="large" />
        )}
      </IconButton>
    </Labeled>
  );
};
