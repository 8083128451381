import { AxiosError, TPurchase, getSeeriApi } from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import { KamInput } from "@/resources/shared/KamInput";
import { useState } from "react";
import {
  Button,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Refresh } from "@mui/icons-material";

export const PurchaseChangeKam = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const purchase = useRecordContext<TPurchase>();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any, onClose: Function) => {
    if (loading) return;
    setLoading(true);
    try {
      await getSeeriApi().put(`/api/purchases/${purchase.id}/kam`, values);
      const msg = "El kam del pedido fue actualizado correctamente.";
      notify(msg, { type: "success" });
      refresh();
      onClose();
    } catch (error) {
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  const formId = "form-purchase-change-kam";

  return (
    <ModalAction
      buttonText="Cambiar"
      buttonVariant="outlined"
      dialogTitle="Cambiar KAM"
      dialogMaxWidth="sm"
      dialogContent={(onClose) => (
        <Form
          id={formId}
          record={{ kam: purchase.kam }}
          onSubmit={(values) => onSubmit(values, onClose)}
        >
          <KamInput />
        </Form>
      )}
      dialogActions={
        <Button
          type="submit"
          variant="contained"
          size="medium"
          label="Guardar"
          form={formId}
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
    />
  );
};
