import {
  BooleanField,
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { CATEGORIES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";

const CategoryFilter = [<TextInput source="name" alwaysOn />];

const CategoryActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("category.create") && <CreateButton />}
      {hasPermission("category.export") && <ExportButton />}
    </TopToolbar>
  );
};

export const CategoryList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("category.list")) return null;

  return (
    <List
      perPage={100}
      filters={CategoryFilter}
      sort={{ field: "name", order: "ASC" }}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("category.create")}
      actions={<CategoryActions />}
    >
      <Datagrid
        rowClick={hasPermission("category.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <FunctionField
          source="image"
          label="Imagen"
          sortable={false}
          render={(record: any) =>
            record.image ? (
              <img
                src={record.image.src}
                width={80}
                height={80}
                style={{ objectFit: "contain" }}
              />
            ) : (
              <div style={{ width: 80, height: 80, backgroundColor: "#ddd" }} />
            )
          }
        />
        <TextField source="name" />
        <TextField source="code" />

        <ReferenceField
          source="parentCategoryId"
          reference={CATEGORIES}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="status" emptyText="-" />
      </Datagrid>
    </List>
  );
};
