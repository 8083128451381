import {
  Button,
  Toolbar,
  TextInput,
  useNotify,
  SimpleForm,
  useRefresh,
  SaveButton,
  useRecordContext,
  SelectInput,
  required,
  useGetOne,
} from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useCallback, useState } from "react";
import {
  AxiosError,
  getSubpurchaseByTrackingCode,
  TPurchase,
  TSubPurchase,
  updateTrackingCodeBySupurchaseId,
} from "@/api";
import { ListAlt } from "@mui/icons-material";

import { useAdmin } from "../../../../hooks/admin";
import { PURCHASES } from "../../../../config/resources";
import { useDeliveryProviders } from "../../../../hooks/purchase";

export const SubpurchaseTrackingEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const SubpurchaseTrackingButton = () => {
  const { hasPermission } = useAdmin();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const subpurchase = useRecordContext<TSubPurchase>();
  const trackingCode = subpurchase?.trackingCode;
  const deliveryProviders = useDeliveryProviders();
  const { data: purchase } = useGetOne<TPurchase>(PURCHASES, {
    id: subpurchase.purchaseId,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(
    async (values: any) => {
      const { trackingCode, deliveryProvider } = values;
      const { content = [] } = await getSubpurchaseByTrackingCode(trackingCode);
      const mappedContent = content.filter(
        (order) => order.trackingCode === trackingCode
      );
      if (mappedContent?.length) {
        notify(
          "El código de seguimiento ya está siendo usado en el subpedido " +
            mappedContent[0].subPurchaseNumber,
          { type: "error" }
        );
        return;
      }

      try {
        await updateTrackingCodeBySupurchaseId(
          subpurchase.id as string,
          trackingCode,
          deliveryProvider
        );
        notify("El subpedido se actualizó exitosamente", { type: "success" });
        refresh();
        setOpen(false);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }
        return;
      } finally {
        setOpen(false);
      }
    },
    [trackingCode]
  );

  return (
    <>
      <Tooltip title="Cambiar guía de subpedido">
        <div>
          <IconButton
            color="primary"
            onClick={handleClickOpen}
            disabled={
              !hasPermission("subpurchase.update.trackingCode") ||
              ["CANCELLED"].includes(subpurchase.deliveryStatus)
            }
          >
            <ListAlt />
          </IconButton>
        </div>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>Cambiar guía de subpedido</DialogTitle>
        <DialogContent>
          <SimpleForm
            mode="onBlur"
            record={subpurchase}
            toolbar={<SubpurchaseTrackingEditToolbar />}
            resource={PURCHASES}
            onSubmit={onSubmit}
          >
            <SelectInput
              source="deliveryProvider"
              fullWidth
              choices={deliveryProviders}
              validate={required()}
              defaultValue="SERVIENTREGA"
              optionValue="code"
            />
            <TextInput
              source="trackingCode"
              fullWidth
              validate={[required()]}
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button label="Cerrar" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};
