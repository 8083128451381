import { Children, FC, ReactNode, cloneElement, isValidElement } from "react";
import { useResourceContext } from "react-admin";

export const TranslatedInputs: FC<{ children: ReactNode }> = ({ children }) => {
  const resource = useResourceContext();
  return (
    <>
      {Children.map(children, (input) =>
        input && isValidElement(input)
          ? cloneElement(input, { ...input.props, resource })
          : null
      )}
    </>
  );
};
