import { ModalAction } from "@/components/common/ModalAction";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import { AxiosError, TSupplier, getSeeriApi } from "@/api";
import { useCallback, useState } from "react";
import {
  AutocompleteInput,
  Button,
  Form,
  required,
  useNotify,
  useRecordContext,
} from "react-admin";
import { Refresh } from "@mui/icons-material";
import { useSubscription } from "@/hooks/subscriptions";

export const SupplierSubscriptionModalAction = () => {
  const supplier = useRecordContext<TSupplier>();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const { currentPackage, packages, reloadSubscription } = useSubscription(
    supplier?.id
  );

  const onSubmit = useCallback(
    async (values: any, onClose: Function) => {
      setLoading(true);
      try {
        await getSeeriApi().post("/api/subscriptions/webhook/supplier/create", {
          supplierId: supplier.id,
          code: values.code,
          type: "MONTHLY",
        });

        notify("El plan fue asignado correctamente", {
          type: "success",
        });

        reloadSubscription();
        onClose();
      } catch (error) {
        let errorMessage;

        if (error instanceof AxiosError) {
          errorMessage = error.response?.data?.message;
        }

        notify(errorMessage || "Error de servidor.", {
          type: "warning",
        });
      }

      setLoading(false);
    },
    [supplier]
  );

  const formId = "supplier-subscription-form";

  if (!currentPackage) return null;

  return (
    <ModalAction
      buttonText="Suscripción"
      buttonIcon={<AccountBalanceWallet />}
      buttonVariant="text"
      dialogMaxWidth="sm"
      dialogTitle="Asignar paquete"
      dialogContent={(onClose) => (
        <Form
          id={formId}
          onSubmit={(values) => onSubmit(values, onClose)}
          record={{ code: currentPackage?.code }}
        >
          <AutocompleteInput
            label="Paquete"
            source="code"
            optionValue="code"
            optionText="name"
            choices={packages}
            fullWidth
            validate={required()}
          />
          <AutocompleteInput
            label="Periodo"
            source="type"
            optionValue="code"
            optionText="name"
            choices={[
              { code: "MONTHLY", name: "Mensual" },
              { code: "BI_YEARLY", name: "Semestral" },
              { code: "YEARLY", name: "Anual" },
            ]}
            fullWidth
            validate={required()}
          />
        </Form>
      )}
      dialogActions={
        <Button
          label="Asignar"
          type="submit"
          size="medium"
          variant="contained"
          form={formId}
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
    />
  );
};
