import { ShoppingCart } from "@mui/icons-material";
import { Alert, Box, Grid } from "@mui/material";
import {
  addPurchaseComment,
  AxiosError,
  TPurchase,
  TPurchaseProduct,
  updatePurchaseProducts,
} from "@/api";
import { useCallback, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Button,
  ReferenceInput,
  required,
  SimpleFormIterator,
  useNotify,
  useRecordContext,
  useRefresh,
  BooleanInput,
  TextInput,
  FunctionField,
  Labeled,
  Form,
  FormDataConsumer,
} from "react-admin";
import { PRODUCTS, PURCHASES } from "../../../../../config/resources";
import { useAdmin } from "../../../../../hooks/admin";
import { ProductOptionRenderer } from "../../../../shared/product";
import { groupInvoicesBySuppurchase } from "../../../../../hooks/purchase";
import {
  ProductItemQuantityInput,
  ProductItemSupplierInput,
} from "../../../post/products";
import { ModalAction } from "@/components/common/ModalAction";
import { Refresh } from "@mui/icons-material";

const PurchaseProductArrayInputs = (props: any) => {
  const { subpurchaseInvoices, getSource, scopedFormData } = props;

  const { hasPermission } = useAdmin();
  const purchase = useRecordContext<TPurchase>();

  const currentProduct = scopedFormData;
  const productRecord: TPurchaseProduct | undefined = purchase.products.find(
    (p: TPurchaseProduct) => p.id === currentProduct.id
  );

  return (
    <>
      {subpurchaseInvoices[currentProduct.subPurchaseId] > 0 && (
        <Alert severity="warning">
          El subpedido al que pertenece este producto tiene factura, modificarlo
          implica la generación de una nota de crédito, por favor contacte a
          finanzas.
        </Alert>
      )}

      <Box sx={{ padding: "8px 0px" }}>
        <Grid container spacing={"16px"}>
          <Grid item xs={12} md={6}>
            {productRecord && currentProduct.name ? (
              <>
                <TextInput source={getSource("id")} sx={{ display: "none" }} />
                <Box mt={1}>
                  <Labeled label="Nombre del producto">
                    <FunctionField
                      render={() => (
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <img
                            src={productRecord.images?.[0]?.src}
                            width="60"
                            height="60"
                          />
                          <p>{productRecord.name}</p>
                        </Box>
                      )}
                    />
                  </Labeled>
                </Box>
              </>
            ) : (
              <ReferenceInput
                reference={PRODUCTS}
                source={getSource("id")}
                sort={{ field: "name", order: "ASC" }}
                perPage={50}
              >
                <AutocompleteInput
                  label="Nombre del producto"
                  validate={required()}
                  filterToQuery={(searchText: string) => ({
                    name: searchText,
                  })}
                  matchSuggestion={(_: string, choice: any) => true}
                  optionText={<ProductOptionRenderer />}
                  inputText={(p: any) => p?.name}
                  shouldRenderSuggestions={(v: string) => v?.trim().length >= 1}
                  noOptionsText="No hay resultados"
                  optionValue="id"
                  fullWidth
                  disabled={!!currentProduct.wholesalePrice}
                />
              </ReferenceInput>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <ProductItemSupplierInput
              disabled={
                hasPermission("purchase.update.products.always")
                  ? false
                  : !!productRecord?.subPurchaseId
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ProductItemQuantityInput
              disabled={
                hasPermission("purchase.update.products.always")
                  ? false
                  : // Se deshabilita si tiene subpedido y este es distinto a pendiente
                    currentProduct?.subPurchaseId &&
                    purchase.subPurchases.find(
                      (s) => s.id === currentProduct.subPurchaseId
                    )!.supplierStatus !== "PENDING"
              }
              current={productRecord?.quantity}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const PurchaseProductsArrayInput = () => {
  const { hasPermission } = useAdmin();
  const purchase = useRecordContext<TPurchase>();
  const subpurchaseInvoices = groupInvoicesBySuppurchase(purchase.subPurchases);
  const { isAdmin } = useAdmin();
  return (
    <ArrayInput source="products" label="Productos">
      <SimpleFormIterator
        disableClear
        disableRemove={(product) =>
          isAdmin || hasPermission("purchase.update.products.remove")
            ? false
            : // Se deshabilita si tiene subpedido y este es distinto a pendiente
              product?.subPurchaseId &&
              purchase.subPurchases.find((s) => s.id === product.subPurchaseId)!
                .supplierStatus !== "PENDING"
        }
        disableReordering
        fullWidth
      >
        <FormDataConsumer>
          {({ getSource, scopedFormData }) => (
            <PurchaseProductArrayInputs
              subpurchaseInvoices={subpurchaseInvoices}
              getSource={getSource}
              scopedFormData={scopedFormData}
            />
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const PurchaseProductsAction = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { admin, hasPermission, isAdmin } = useAdmin();
  const purchase = useRecordContext<TPurchase>();
  const [loading, setLoading] = useState(false);
  const isPaid = purchase?.paymentStatus === "PAID";
  const hasSubpurchases = purchase?.subPurchases.length > 0;

  const onSubmit = useCallback(
    async (values: any, onClose: Function) => {
      setLoading(true);
      try {
        const { products, recalculate, actionComment: comment } = values;
        const productList = products.map((p: any) => ({
          id: p.id,
          quantity: p.quantity,
          wholesalePrice: p.wholesalePrice,
          supplierId: p.supplierId,
          unitPrice: p.unitPrice,
        }));
        await updatePurchaseProducts(purchase.id, {
          products: productList,
          recalculate,
        });
        await addPurchaseComment(purchase.id as string, {
          createdBy: admin.id,
          comment,
          fullName: admin.firstName + " " + admin.lastName,
        });
        notify("El pedido se actualizó exitosamente", { type: "success" });
        refresh();
        onClose();
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }
        return;
      }
      setLoading(false);
    },
    [purchase]
  );

  if (!purchase) return null;

  const formId = "form-purchase-update-products";

  return (
    <ModalAction
      buttonMode="icon"
      buttonIcon={<ShoppingCart />}
      buttonDisabled={
        (!isAdmin && purchase.paymentStatus === "PAID") ||
        purchase.status === "CANCELLED" ||
        purchase.status === "REJECTED" ||
        !hasPermission("purchase.update.products")
      }
      dialogTitle="Actualizar productos"
      dialogContent={(onClose) => (
        <Form
          id={formId}
          record={purchase}
          resource={PURCHASES}
          onSubmit={(values) => onSubmit(values, onClose)}
        >
          <BooleanInput
            source="recalculate"
            defaultValue={!(isPaid || hasSubpurchases)}
            disabled={!hasPermission("purchase.update.products.recalculate")}
          />
          <PurchaseProductsArrayInput />
          <TextInput
            source="actionComment"
            label="Comentario"
            validate={[required()]}
            fullWidth
            multiline
          />
        </Form>
      )}
      dialogActions={
        <Button
          type="submit"
          variant="contained"
          size="medium"
          label="Guardar"
          form={formId}
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
    />
  );
};
