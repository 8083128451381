export const fixApiDateStr = (dateStr: string) => {
  if (dateStr && typeof dateStr === "string") {
    // Caso: Llegan malformados con .Z al final
    if (dateStr.endsWith(".Z")) {
      return dateStr.replace(".Z", "Z")
    }

    // Caso: si no tiene Z al final, se la agregamos
    if (!dateStr.endsWith("Z")) {
      return dateStr + "Z"
    }
  };

  return dateStr;
}
