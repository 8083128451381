import { FC, ReactElement } from "react";
import { useWatch } from "react-hook-form";

type Props = {
  dependent: string;
  render: (dependent: string) => ReactElement | null;
};

export const DependentFormContent: FC<Props> = ({ dependent, render }) => {
  const value = useWatch({ name: dependent, defaultValue: "" });
  return render(value) || null;
};
