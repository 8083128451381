import { isProd } from "../constants/env";
import { primaryApp } from "../core/firebase";
import {
	fetchAndActivate,
	getRemoteConfig,
	getValue,
} from "firebase/remote-config";
import type { TAppCountry } from "./country";

export const remoteConfig = getRemoteConfig(primaryApp);

remoteConfig.settings.minimumFetchIntervalMillis =
	(isProd ? 1 : 1 / 6) * 3600000;

export const getRCBrowser = async () => {
	const remoteConfig = getRemoteConfig(primaryApp);
	remoteConfig.settings.minimumFetchIntervalMillis = isProd
		? 6 * 60 * 60 * 1000
		: 10 * 60 * 1000;
	await fetchAndActivate(remoteConfig);
	return remoteConfig;
};

export const getBaseURL = async (country: TAppCountry) => {
	const remoteConfig = await getRCBrowser();

	const microservicesHost = getValue(
		remoteConfig,
		"microservices_host",
	).asString();
	const values = JSON.parse(microservicesHost || "{}");

	return values[country.microserviceHost] || values.co;
};

export const getApplyCouponEnabled = () => {
	return getValue(remoteConfig, "apply_coupon_enabled").asBoolean();
};

export const getClientsEnabled = () => {
	return getValue(remoteConfig, "clients_enabled").asBoolean();
};
