import { PRODUCTS } from "@/config/resources";
import { useAdmin } from "@/hooks/admin";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ListBase,
  NumberField,
  TextField,
  useRecordContext,
} from "react-admin";
import { CustomPagination } from "@/resources/shared/pagination";
import { ModalImageField } from "@/components/elements/ModalImageField";
import { TProduct, TSupplier } from "@/api";

export const SupplierProductsList = () => {
  const { hasPermission } = useAdmin();
  const supplier = useRecordContext<TSupplier>();

  return (
    <ListBase
      resource={PRODUCTS}
      filter={{ supplierId: supplier.id }}
      disableSyncWithLocation
      perPage={20}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={hasPermission("product.view") ? "show" : false}
      >
        <FunctionField
          label="Foto"
          render={() => (
            <ModalImageField
              source="images.0.src"
              maxHeight={80}
              maxWidth={80}
            />
          )}
        />
        <TextField source="name" />
        <FunctionField
          label="Stock disponible"
          render={(product: TProduct) => {
            const productSupplier = product.suppliers.find(
              (s) => s.supplierId === supplier.id
            );

            return (
              <NumberField source="availableStock" record={productSupplier} />
            );
          }}
        />
        {supplier.negotiationType === "PRICE_LIST" && (
          <FunctionField
            label="Precio compra a distribuidor"
            render={(product: TProduct) => {
              const productSupplier = product.suppliers.find(
                (s) => s.supplierId === supplier.id
              );

              return (
                <NumberField source="supplierPrice" record={productSupplier} />
              );
            }}
          />
        )}
        <FunctionField
          label="Precio al por mayor"
          render={(product: TProduct) => {
            const productSupplier = product.suppliers.find(
              (s) => s.supplierId === supplier.id
            );

            return (
              <NumberField source="wholesalePrice" record={productSupplier} />
            );
          }}
        />
        <FunctionField
          label="Marketplace"
          render={(product: TProduct) => {
            const productSupplier = product.suppliers.find(
              (s) => s.supplierId === supplier.id
            );

            return (
              <BooleanField
                source="isMarketplace"
                emptyText="-"
                record={productSupplier}
              />
            );
          }}
        />
        <FunctionField
          label="Estado"
          render={(product: TProduct) => {
            const productSupplier = product.suppliers.find(
              (s) => s.supplierId === supplier.id
            );

            return (
              <BooleanField
                source="status"
                emptyText="-"
                record={productSupplier}
              />
            );
          }}
        />
      </Datagrid>
      <CustomPagination />
    </ListBase>
  );
};
