import { Button, Grid } from "@mui/material";
import { AxiosError } from "axios";
import { useState } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Edit,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
} from "react-admin";
import { ADMINROLES, ADMINS } from "../config/resources";
import { adminsRef } from "../core/firebase";
import { useAdmin } from "../hooks/admin";
import { sendPasswordFirebaseAuth } from "../providers/extensions/auth";
import { query, where, getDocs } from "firebase/firestore";
import { countries } from "@/config/country";

const ProfileEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const validateUserForm = async (values: any) => {
  const errors: any = {};
  let q;

  if (values.email) {
    const queryConstraints = [];
    queryConstraints.push(where("email", "==", values.email));
    if (values.id) queryConstraints.push(where("id", "!=", values.id));
    q = query(adminsRef, ...queryConstraints);
    getDocs(q).then((result) => {
      if (result.docs.length > 0) {
        errors.email = "El correo ya existe";
      }
    });
  }

  return errors;
};

export const ProfileEdit = () => {
  const notify = useNotify();
  const [sending, setSending] = useState(false);

  const { admin, hasPermission } = useAdmin();

  if (!admin) return null;

  const canUpdateUser = hasPermission("admin.update");

  return (
    <Edit
      id={admin.id}
      resource={ADMINS}
      title="Mi Perfil"
      mutationMode="pessimistic"
      redirect={"/"}
    >
      <SimpleForm
        mode="onBlur"
        toolbar={<ProfileEditToolbar />}
        validate={validateUserForm}
      >
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextInput source="id" disabled fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="email"
              validate={required()}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="firstName" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="lastName" validate={required()} fullWidth />
          </Grid>
          {canUpdateUser && (
            <>
              <Grid item xs={4}>
                <ReferenceInput
                  source="roleId"
                  reference={ADMINROLES}
                  sort={{ field: "name", order: "ASC" }}
                  perPage={100}
                >
                  <AutocompleteInput
                    label="Rol"
                    optionText="name"
                    validate={required()}
                    filterToQuery={(searchText: string) => ({
                      name: searchText,
                    })}
                    fullWidth
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={4}>
                <AutocompleteArrayInput
                  label="País(es)"
                  source="countries"
                  choices={countries}
                  validate={required()}
                  optionValue="code"
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="secondary"
                  title="Restablecer contraseña"
                  variant="outlined"
                  disabled={sending}
                  onClick={async (e) => {
                    e.stopPropagation();
                    setSending(true);
                    try {
                      await sendPasswordFirebaseAuth(admin.email);
                      notify(
                        "Se ha enviado el correo para restablecer la contraseña"
                      );
                    } catch (error) {
                      console.log({ error });
                      if (error instanceof AxiosError) {
                        notify(error.response?.data?.message ?? error.message, {
                          type: "error",
                        });
                      } else {
                        notify(
                          "No se pudo restablecer, inténtelo nuevamente en unos minutos",
                          { type: "error" }
                        );
                      }
                    }
                    setSending(false);
                  }}
                >
                  Restablecer contraseña
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
