import { getSeeriApi } from "@/api";
import useSWR from "swr";

export const useSupplierBrands = (supplierId: string) => {
  return useSWR({ supplierId, key: "brands" }, () =>
    getSeeriApi()
      .get(`/api/products/supplier/${supplierId}/brands`)
      .then((res) => res.data)
  );
};
