import { Box, Dialog, DialogContent } from "@mui/material";
import { marked } from "marked";
import { useState } from "react";
import changelog from "../../CHANGELOG.md?raw";
import packageJson from "../../package.json";
import { isProd } from "../constants/env";
import { useAdmin } from "../hooks/admin";

console.log("Version: ", packageJson.version);

export const Version = () => {
  const { admin } = useAdmin();

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div>
      {!isProd && (
        <Box
          sx={{
            color: "white",
            background: "red",
            position: "fixed",
            bottom: 64,
            left: { xs: "auto", md: 12 },
            right: { xs: 12, md: "auto" },
            zIndex: 1,
            fontSize: 18,
            fontWeight: 600,
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          PRUEBAS
        </Box>
      )}
      <Box
        sx={{
          position: "fixed",
          bottom: 24,
          left: { xs: "auto", md: 12 },
          right: { xs: 12, md: "auto" },
          zIndex: 1,
        }}
      >
        <button
          onClick={() => admin && setModalOpen(true)}
          style={{
            color: "white",
            background: "gray",
            fontSize: 18,
            fontWeight: 600,
            padding: "4px 8px",
            borderRadius: 4,
            cursor: admin ? "pointer" : "default",
            border: "0",
          }}
        >
          Versión: {packageJson.version}
        </button>
      </Box>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: marked(changelog, { headerIds: false }),
            }}
          ></div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
