import { TPurchase } from "@/api";
import { ADMINS } from "@/config/resources";
import { useAdmin } from "@/hooks/admin";
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import {
  DateField,
  FunctionField,
  Labeled,
  ReferenceField,
  TextField,
} from "react-admin";
import { PurchaseChangeKam } from "./actions/change-kam";

export const PurchaseInfoTabContent = () => {
  const { hasPermission } = useAdmin();

  return (
    <Grid container spacing={"16px"}>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography
            marginBottom={"8px"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            Cliente
          </Typography>
          <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
            <Card variant="outlined">
              <CardContent>
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                    gap: "1rem",
                  }}
                >
                  <Labeled source="client.status">
                    <TextField source="client.status" />
                  </Labeled>
                  <Labeled source="client.id">
                    <TextField source="client.id" />
                  </Labeled>
                  <Labeled source="client.documentType">
                    <TextField source="client.documentType" />
                  </Labeled>
                  <Labeled source="client.documentId">
                    <TextField source="client.documentId" />
                  </Labeled>
                </Stack>
              </CardContent>
            </Card>
            <Card variant="outlined">
              <CardContent>
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                    gap: "1rem",
                  }}
                >
                  <Labeled source="client.displayName">
                    <TextField source="client.displayName" />
                  </Labeled>
                  <Labeled source="client.firstName">
                    <TextField source="client.firstName" />
                  </Labeled>
                  <Labeled source="client.lastName">
                    <TextField source="client.lastName" emptyText="-" />
                  </Labeled>
                  <Labeled source="client.email">
                    <TextField source="client.email" />
                  </Labeled>
                  <Labeled source="client.phone">
                    <TextField source="client.phone" />
                  </Labeled>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box marginBottom={"16px"}>
          <Typography
            marginBottom={"8px"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            Ubicación
          </Typography>

          <Card variant="outlined">
            <CardContent>
              <Stack
                direction="row"
                sx={{
                  flexWrap: "wrap",
                  gap: "1rem",
                }}
              >
                <Labeled source="client.location.country">
                  <TextField source="client.location.country" />
                </Labeled>
                <Labeled source="client.location.state">
                  <TextField source="client.location.state" />
                </Labeled>
                <Labeled source="client.location.city">
                  <TextField source="client.location.city" />
                </Labeled>
                <Labeled source="client.location.postalCode">
                  <TextField source="client.location.postalCode" />
                </Labeled>
                <Labeled source="client.location.addressLine1">
                  <TextField source="client.location.addressLine1" />
                </Labeled>
                <Labeled source="client.location.addressLine2">
                  <TextField source="client.location.addressLine2" />
                </Labeled>
              </Stack>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Typography
            marginBottom={"8px"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            Miscelánea
          </Typography>

          <Card variant="outlined">
            <CardContent>
              <Stack
                direction="row"
                sx={{
                  flexWrap: "wrap",
                  gap: "1rem",
                }}
              >
                <Labeled source="origin.name">
                  <TextField source="origin.name" />
                </Labeled>
                <Labeled source="expiresAt">
                  <DateField source="expiresAt" />
                </Labeled>
                <Labeled source="updatedAt">
                  <DateField source="updatedAt" />
                </Labeled>
                <Labeled source="createdBy">
                  <ReferenceField
                    source="createdBy"
                    reference={ADMINS}
                    link={hasPermission("admin.view") ? "show" : false}
                    emptyText="-"
                  >
                    <TextField source="email" emptyText="-" />
                  </ReferenceField>
                </Labeled>
                <Labeled source="kam">
                  <FunctionField
                    source="kam"
                    render={(purchase: TPurchase) => (
                      <Box>
                        <div>
                          {purchase.kam}{" "}
                          {hasPermission("purchase.action.changeKam") && (
                            <PurchaseChangeKam />
                          )}
                        </div>
                      </Box>
                    )}
                  />
                </Labeled>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};
