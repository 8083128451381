import {
  EditButton,
  FileField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRedirect,
  useStore,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { LECTURES, RESOURCES } from "../../config/resources";
import { useEffect } from "react";

const ResourceShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("resource.update") && <EditButton />}
    </TopToolbar>
  );
};

export const ResourceShow = () => {
  const { hasPermission } = useAdmin();
  const redirect = useRedirect();

  const [lectureId] = useStore("currentLectureId");

  useEffect(() => {
    if (!lectureId) {
      redirect("/" + LECTURES);
    }
  }, [lectureId]);

  if (!hasPermission("resource.view")) return null;

  if (!lectureId) return null;

  return (
    <Show
      resource={RESOURCES}
      queryOptions={{
        meta: {
          dependentId: lectureId,
        },
      }}
      actions={<ResourceShowActions />}
    >
      <TabbedShowLayout>
        <Tab label="Principal">
          <TextField source="id" />
          <TextField source="name" />
          <FileField source="url" title="url" target="_blank" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
