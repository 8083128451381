import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CourseForm } from "./_form";

export const CourseEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("course.update")) return null;

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <CourseForm isEdit />
    </Edit>
  );
};
