import { Box, SxProps, Theme } from "@mui/material";
import { Children, FC, ReactNode, isValidElement } from "react";
import { Labeled, useResourceContext } from "react-admin";

export const TranslatedFields: FC<{
  sx?: SxProps<Theme> | undefined;
  children: ReactNode;
}> = ({ sx, children }) => {
  const resource = useResourceContext();
  return (
    <Box sx={sx}>
      {Children.map(children, (field) =>
        field && isValidElement(field) ? (
          <Labeled key={field.props.source} resource={resource}>
            {field}
          </Labeled>
        ) : null
      )}
    </Box>
  );
};
