import { TProductGroup } from "@/api";
import { difference } from "lodash";
import * as yup from "yup";
import { listify } from "../../../../utils/listify";
import { parseProductOptionsToEntries } from "./parse";

export const validateProductOptions =
  (productGroup: TProductGroup) =>
  (value = "", context: yup.TestContext) => {
    const options = parseProductOptionsToEntries(value);

    let messages = [];
    if (productGroup.options)
      for (const [option, value] of options) {
        if (
          productGroup.options[option] &&
          !productGroup.options[option].includes(value)
        ) {
          messages.push(
            "El valor " +
              value +
              " de la opción " +
              option +
              " es inválido. Se esperaba: " +
              listify(productGroup.options[option], "or")
          );
        }
      }

    const invalid = difference(
      options.map(([option]) => option),
      Object.keys(productGroup.options || {})
    );
    if (invalid.length) {
      messages.push("Las opciones inválidas son: " + listify(invalid, null));
    }

    const pending = difference(
      Object.keys(productGroup.options || {}),
      options.map(([option]) => option)
    );
    if (pending.length) {
      messages.push("Las opciones pendientes son: " + listify(pending, null));
    }

    return messages.length
      ? context.createError({ message: messages.join("\n") })
      : true;
  };
