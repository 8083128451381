import { FC } from "react";
import { CreateBase, useNotify, useRefresh } from "react-admin";
import { SELLER_ADDRESSES } from "../../../config/resources";
import { SellerAddressForm } from "./_form";

type Props = {
  sellerId: string;
  onDone: () => void;
};

export const SellerAddressCreate: FC<Props> = ({ sellerId, onDone }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <CreateBase
      resource={SELLER_ADDRESSES}
      mutationOptions={{
        meta: {
          dependentId: sellerId,
        },
        onSuccess: () => {
          notify("Dirección creada");
          refresh();
          onDone();
        },
      }}
      redirect={false}
    >
      <SellerAddressForm sellerId={sellerId} />
    </CreateBase>
  );
};
