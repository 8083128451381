import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import {
  Button,
  FileField,
  FileInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useCallback, useState } from "react";
import { SUPPLIER_PURCHASES } from "../../../config/resources";
import {
  AxiosError,
  getSubpurchaseByTrackingCode,
  updateTrackingCodeSupplierOrder,
} from "@/api";
import { getMD5Checksum } from "../../../utils/md5-checksum";
import { uploadFile } from "../../../utils/file-upload";
import { buckets } from "../../../core/buckets";

export const ApproveSupplierPurchase = () => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const trackingCode = record?.trackingCode;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadGuide = async (trackingFile: any) => {
    const rawTrackingFile = trackingFile?.rawFile;
    const hash = await getMD5Checksum(rawTrackingFile);
    const url = await uploadFile(
      buckets.purchases,
      "SupplierGuide",
      hash,
      rawTrackingFile
    );

    return url;
  };

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const { trackingCode, trackingFile, id: orderId } = values;

        const { content = [] } = await getSubpurchaseByTrackingCode(
          trackingCode
        );
        const mappedContent = content.filter(
          (order) => order.trackingCode === trackingCode
        );
        if (mappedContent?.length) {
          notify(
            "El código de seguimiento ya está siendo usado en el subpedido " +
              mappedContent[0].subPurchaseNumber,
            { type: "error" }
          );
          return;
        }

        const url = await uploadGuide(trackingFile);

        await updateTrackingCodeSupplierOrder(orderId, trackingCode, url);
        notify("El pedido se actualizó exitosamente", { type: "success" });
        refresh();
        setOpen(false);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }
        setOpen(false);
      }
    },
    [trackingCode]
  );

  if (
    !["IN_PROCESS", "PENDING", "REMISSION_GENERATED"].includes(
      record?.supplierStatus?.toUpperCase()
    )
  ) {
    return null;
  }

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <Tooltip title="Generar guía">
          <div>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              size="small"
              label="Generar guía"
            />
          </div>
        </Tooltip>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>Confirmación de despacho</DialogTitle>
        <DialogContent>
          <SimpleForm
            mode="onBlur"
            toolbar={<ApproveSupplierPurchaseToolbar />}
            record={record}
            resource={SUPPLIER_PURCHASES}
            onSubmit={onSubmit}
          >
            <TextInput
              validate={[required()]}
              source="trackingCode"
              fullWidth
              defaultValue={""}
            />
            <FileInput
              accept="application/pdf"
              source="trackingFile"
              fullWidth
              validate={[required()]}
              label="Guía (.pdf)"
            >
              <FileField source="src" title="title" target="_blank" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button label="Cerrar" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ApproveSupplierPurchaseToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
