import { TProduct, TPurchaseProduct, TSubPurchase, TTicketDetail } from "@/api";
import { PRODUCTS, SUPPLIERS } from "@/config/resources";
import { useAdmin } from "@/hooks/admin";
import { TicketUpdateProductQuantityAction } from "@/resources/tickets/actions/update-product-quantity";
import { formatCurrency } from "@/utils/currency";
import { Box, Theme, useMediaQuery } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Link,
  NumberField,
  RaRecord,
  ReferenceField,
  SimpleList,
  TextField,
  useRecordContext,
} from "react-admin";

type TProductsTable =
  | {
      source: string;
      mode: "subpurchase" | "pending" | "deleted";
    }
  | {
      source: string;
      mode: "ticket";
      ticket: TTicketDetail;
    };

export const ReusableProductsTable = (props: TProductsTable) => {
  const { hasPermission } = useAdmin();
  const { mode, source } = props;
  const record = useRecordContext();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const sum = record.products.reduce(
    (previousValue: number, currentValue: TPurchaseProduct) =>
      previousValue + (currentValue?.subTotalWithDiscount ?? 0),
    0
  );

  const isSubpurchase = mode === "subpurchase";
  const isTicket = mode === "ticket";

  const totalReturnedAmount =
    isSubpurchase || isTicket
      ? (record as TSubPurchase).products.reduce(
          (amount, p) => amount + (p.returnedAmount ?? 0),
          0
        )
      : 0;

  return (
    <>
      <ArrayField source={source}>
        {isSmall ? (
          <SimpleList
            resource={PRODUCTS}
            primaryText={(record) => `${record.name} `}
            secondaryText={(record) => `Cantidad: ${record.quantity}`}
            tertiaryText={(record) =>
              formatCurrency(record.subTotalWithDiscount)
            }
          />
        ) : (
          <Datagrid
            width="100%"
            resource={PRODUCTS}
            rowClick={undefined}
            bulkActionButtons={false}
          >
            <FunctionField
              source="images"
              render={(record: TProduct) =>
                record.images ? (
                  <Link to={`/${PRODUCTS}/${record.id}/show`}>
                    <img src={record.images[0]?.src} width={50} height={50} />
                  </Link>
                ) : null
              }
            />
            <FunctionField
              source="name"
              render={(record: RaRecord) => (
                <>
                  <Link to={`/${PRODUCTS}/${record.id}/show`}>
                    <TextField source="name" />
                  </Link>
                  <Box sx={{ fontSize: "0.7rem" }}>
                    SKU: <TextField source="sku" sx={{ fontSize: "0.7rem" }} />
                  </Box>
                </>
              )}
            />

            <ReferenceField
              label="Distribuidor"
              source="supplierId"
              reference={SUPPLIERS}
              link={hasPermission("supplier.view") ? "show" : false}
            >
              <TextField source="name" />
            </ReferenceField>
            {hasPermission("product.show.tab.finance") && (
              <NumberField source="supplierPrice" />
            )}

            <NumberField source="wholesalePrice" />
            <FunctionField
              source="quantity"
              render={(record: any) => (
                <>
                  <NumberField source="quantity" />
                  {(record?.quantityReturned || record?.returnedQuantity) >
                    0 && (
                    <Box fontSize={"12px"} whiteSpace="nowrap" color="red">
                      Devueltos:{" "}
                      {record.quantityReturned || record?.returnedQuantity}
                      {isTicket &&
                        hasPermission(
                          "ticket.update.productQuantityReturned"
                        ) && (
                          <TicketUpdateProductQuantityAction
                            product={record}
                            ticket={props.ticket}
                          />
                        )}
                    </Box>
                  )}
                </>
              )}
            />

            <NumberField source="subtotal" />
            <FunctionField
              source="subTotalWithDiscount"
              render={(record: any) => (
                <>
                  <NumberField source="subTotalWithDiscount" />
                  {(!!record?.amountReturned || !!record?.returnedAmount) && (
                    <Box fontSize={"12px"} whiteSpace="nowrap" color="red">
                      Devuelto:{" "}
                      {!!record?.amountReturned && (
                        <NumberField
                          source="amountReturned"
                          sx={{ fontSize: "inherit" }}
                        />
                      )}
                      {!!record?.returnedAmount && (
                        <NumberField
                          source="returnedAmount"
                          sx={{ fontSize: "inherit" }}
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
            />
          </Datagrid>
        )}
      </ArrayField>
      <Box justifyContent="end" sx={{ p: 1, display: "flex" }}>
        Total{isSubpurchase && " subpedido"}: {formatCurrency(sum)}
      </Box>
      {isSubpurchase && Boolean(record.shipping) && (
        <Box justifyContent="end" sx={{ p: 1, display: "flex" }}>
          Envío: {formatCurrency(record.shipping)}
        </Box>
      )}
      {isSubpurchase && Boolean(record.shipping) && (
        <Box justifyContent="end" sx={{ p: 1, display: "flex" }}>
          Total más envío: {formatCurrency(sum + (record.shipping ?? 0))}
        </Box>
      )}
      {isSubpurchase && Boolean(record.partialMethodAmount) && (
        <Box
          justifyContent="end"
          sx={{ p: 1, display: "flex" }}
          color="status.danger"
        >
          Pago parcial: {formatCurrency(record.partialMethodAmount)}
        </Box>
      )}
      {(isSubpurchase || isTicket) && Boolean(totalReturnedAmount) && (
        <Box justifyContent="end" sx={{ p: 1, display: "flex" }} color="red">
          Valor total devuelto: {formatCurrency(totalReturnedAmount)}
        </Box>
      )}
      {isSubpurchase && Boolean(record.partialMethodAmount) && (
        <Box justifyContent="end" sx={{ p: 1, display: "flex" }}>
          Total a cobrar:{" "}
          {formatCurrency(
            sum - record.partialMethodAmount + (record.shipping ?? 0)
          )}
        </Box>
      )}
    </>
  );
};
