import {
  List,
  Datagrid,
  TextField,
  TextInput,
  SelectInput,
  FunctionField,
  DateField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { PURCHASES, SELLERS } from "../../config/resources";
import {
  ticketTypes,
  ticketReasons,
  ticketStatuses,
} from "../../data/purchases";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";
import { Tooltip } from "@mui/material";

const TicketFilter = [
  <SelectInput
    source="status"
    choices={ticketStatuses}
    optionValue="code"
    alwaysOn
  />,
  <ReferenceInput source="userId" reference={SELLERS} alwaysOn>
    <AutocompleteInput
      label="Vendedor"
      filterToQuery={(searchText: string) => ({ email: searchText })}
      optionText="email"
    />
  </ReferenceInput>,
  <TextInput source="purchaseNumber" alwaysOn />,
  <SelectInput
    source="type"
    choices={ticketTypes}
    optionValue="code"
    alwaysOn
  />,
  <SelectInput
    source="reason"
    choices={ticketReasons}
    optionValue="code"
    alwaysOn
  />,
  <TextInput source="number" label="No. Ticket" alwaysOn />,
];

export const TicketActions = () => {
  return (
    <TopToolbar>
      <Tooltip title="Crear ticket de devolución">
        <div>
          <CreateButton />
        </div>
      </Tooltip>
    </TopToolbar>
  );
};

export const TicketList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("ticket.list")) return null;

  return (
    <List
      filters={TicketFilter}
      perPage={20}
      sort={{ field: "createdAt", order: "DESC" }}
      pagination={<CustomPagination />}
      exporter={false}
      actions={<TicketActions />}
    >
      <Datagrid
        rowClick={hasPermission("ticket.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <TextField source="number" sortable={false} />
        <DateField source="createdAt" showTime />
        <ReferenceField
          source="purchaseNumber"
          reference={PURCHASES}
          link={hasPermission("purchase.view") ? "show" : false}
        >
          <TextField source="purchaseNumber" />
        </ReferenceField>
        <FunctionField
          source="reason"
          sortable={false}
          render={(record: any) => record.reason?.name}
        />
        <FunctionField
          source="type"
          sortable={false}
          render={(record: any) => record.type?.name}
        />
        <FunctionField
          source="status"
          render={(record: any) =>
            ticketStatuses.find((s) => s.code === record.status)?.name
          }
        />
      </Datagrid>
    </List>
  );
};
