import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { SupplierForm } from "./_form";
import { buckets } from "@/core/buckets";
import { uploadFile } from "@/utils/file-upload";
import { nanoid } from "nanoid";

export const SupplierEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("supplier.update")) return null;

  const transform = async (data: any) => {
    if (data.logo?.rawFile) {
      data.logo = await uploadFile(
        buckets.banners,
        "SupplierLogos",
        nanoid(),
        data.logo.rawFile
      );
    }

    return data;
  };

  return (
    <Edit mutationMode="pessimistic" redirect="show" transform={transform}>
      <SupplierForm isEdit />
    </Edit>
  );
};
