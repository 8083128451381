import { ModalAction } from "@/components/common/ModalAction";
import { SellerForm } from "@/resources/sellers/_form";
import AddBusiness from "@mui/icons-material/AddBusiness";
import { AxiosError, TSupplier, createSeller } from "@/api";
import { useCallback } from "react";
import {
  RecordContextProvider,
  useNotify,
  useRecordContext,
} from "react-admin";

export const SupplierCreateSellerModalAction = () => {
  const supplier = useRecordContext<TSupplier>();
  const notify = useNotify();

  const onSubmit = useCallback(
    async (values: any, onClose: Function) => {
      const payload = { ...values, supplierId: supplier.id };
      try {
        await createSeller(payload);
        notify("El vendedor fue creado correctamente.", {
          type: "success",
        });
        onClose();
      } catch (error: any) {
        let errorMessage;
        if (error.code === "auth/email-already-in-use") {
          errorMessage = "El correo ingresado ya existe";
        }
        if (error.code === "auth/weak-password") {
          errorMessage =
            "La contraseña es insegura, por favor ingresa números y caracteres";
        }
        if (error.code === "auth/invalid-email") {
          errorMessage = "El correo es inválido";
        }

        if (error instanceof AxiosError) {
          errorMessage = error.response?.data?.message;
        }

        notify(errorMessage || "Error al crear el vendedor.", {
          type: "warning",
        });
      }
    },
    [supplier]
  );

  return (
    <ModalAction
      buttonText="Crear vendedor"
      buttonIcon={<AddBusiness />}
      buttonVariant="text"
      dialogTitle="Crear vendedor"
      dialogContent={(onClose) => (
        <RecordContextProvider value={undefined}>
          <SellerForm
            onSubmit={(values) => onSubmit(values, onClose)}
            isEmbed
          />
        </RecordContextProvider>
      )}
    />
  );
};
