import { TPurchaseProduct, TTicketDetail, getSeeriApi } from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import { Edit, Refresh } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useId } from "react";
import {
  Button,
  Form,
  NumberInput,
  minValue,
  required,
  useNotify,
  useRefresh,
} from "react-admin";
import { FieldValues } from "react-hook-form";
import useSWRMutation from "swr/mutation";

export const TicketUpdateProductQuantityAction = ({
  ticket,
  product,
}: {
  ticket: TTicketDetail;
  product: TPurchaseProduct;
}) => {
  const id = useId();
  const refresh = useRefresh();
  const notify = useNotify();

  const { trigger, isMutating } = useSWRMutation(
    id,
    (_, { arg }: { arg: FieldValues }) =>
      getSeeriApi().put(`/api/tickets/${ticket.id}/products`, [
        {
          id: product.id,
          quantityReturned: arg.quantityReturned,
        },
      ]),
    {
      onSuccess: () => {
        refresh();
        notify("Cantidad editada", { type: "success" });
      },
      onError: () => {
        notify("No se pudo editar la cantidad", { type: "error" });
      },
    }
  );

  return (
    <ModalAction
      buttonMode="icon"
      buttonIcon={<Edit />}
      dialogTitle="Editar cantidad"
      dialogMaxWidth="xs"
      dialogContent={(onClose) => (
        <Form
          id={id}
          onSubmit={(values) => trigger(values).then(onClose)}
          record={ticket?.products?.find((p) => p.id)}
        >
          <Typography>{product.name}</Typography>
          <NumberInput
            label="Cantidad devuelta"
            source="quantityReturned"
            validate={[required(), minValue(1)]}
          />
        </Form>
      )}
      dialogActions={
        <Box>
          <Button
            label="Guardar"
            type="submit"
            size="medium"
            form={id}
            disabled={isMutating}
            startIcon={
              isMutating && (
                <Refresh className={isMutating ? "rotate-icon" : ""} />
              )
            }
          />
        </Box>
      }
    />
  );
};
