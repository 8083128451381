import {
  Button,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import { QUOTES } from "../../../config/resources";
import { QuoteStatus, quoteStatusNames } from "../constants";
import { AxiosError } from "axios";
import { Refresh, Check } from "@mui/icons-material";
import { getRules } from "../rules";
import { ModalAction } from "../../../components/common/ModalAction";
import { Typography } from "@mui/material";

export const QuoteDeliveredAction = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const quote = useRecordContext();
  const [update, { isLoading }] = useUpdate();

  if (!quote) return null;

  const setDelivered = async (onClose: () => void) => {
    try {
      await update(QUOTES, {
        id: quote.id,
        data: { status: QuoteStatus.enum.DELIVERED },
        previousData: quote,
      });
      const msg = "La cotización fue actualizada correctamente";
      notify(msg, { type: "success" });
      refresh();
      onClose();
    } catch (error) {
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
  };

  const targetStatusName = quoteStatusNames[QuoteStatus.enum.DELIVERED];

  return (
    <ModalAction
      buttonText="Enviado"
      buttonDisabled={!getRules(quote).delivered}
      buttonIcon={<Check />}
      dialogMaxWidth="xs"
      dialogTitle={targetStatusName}
      dialogContent={
        <Typography>
          La cotización se marcará como <b>{targetStatusName}</b>
        </Typography>
      }
      disableClose={isLoading}
      dialogActions={(onClose) => (
        <Button
          variant="contained"
          size="medium"
          label="Confirmar"
          onClick={() => setDelivered(onClose)}
          disabled={isLoading}
          startIcon={
            isLoading && <Refresh className={isLoading ? "rotate-icon" : ""} />
          }
        />
      )}
    />
  );
};
