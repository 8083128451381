export const permissions = [
  {
    id: "admin.create",
    description: "ADMINISTRADORES: Crear",
    name: "admin.create",
  },
  {
    id: "admin.delete",
    description: "ADMINISTRADORES: Eliminar",
    name: "admin.delete",
  },
  {
    id: "admin.export",
    description: "ADMINISTRADORES: Exportar",
    name: "admin.export",
  },
  {
    id: "admin.list",
    description: "ADMINISTRADORES: Listar",
    name: "admin.list",
  },
  {
    id: "admin.recoverPassword",
    description: "ADMINISTRADORES: Recuperar contraseña",
    name: "admin.recoverPassword",
  },
  {
    id: "admin.search",
    description: "ADMINISTRADORES: Buscar",
    name: "admin.search",
  },
  {
    id: "admin.update",
    description: "ADMINISTRADORES: Actualizar",
    name: "admin.update",
  },
  {
    id: "admin.update.country",
    description: "ADMINISTRADORES: Actualizar país",
    name: "admin.update.country",
  },
  {
    id: "admin.update.email",
    description: "ADMINISTRADORES: Actualizar correo",
    name: "admin.update.email",
  },
  {
    id: "admin.update.name",
    description: "ADMINISTRADORES: Actualizar nombre",
    name: "admin.update.name",
  },
  {
    id: "admin.update.role",
    description: "ADMINISTRADORES: Actualizar rol",
    name: "admin.update.role",
  },
  {
    id: "admin.update.status",
    description: "ADMINISTRADORES: Actualizar estado",
    name: "admin.update.status",
  },
  {
    id: "admin.view",
    description: "ADMINISTRADORES: Ver",
    name: "admin.view",
  },
  {
    id: "adminrole.create",
    description: "ROL DE ADMINISTRADORES: Crear",
    name: "adminrole.create",
  },
  {
    id: "adminrole.delete",
    description: "ROL DE ADMINISTRADORES: Eliminar",
    name: "adminrole.delete",
  },
  {
    id: "adminrole.list",
    description: "ROL DE ADMINISTRADORES: Listar",
    name: "adminrole.list",
  },
  {
    id: "adminrole.update",
    description: "ROL DE ADMINISTRADORES: Actualizar",
    name: "adminrole.update",
  },
  {
    id: "adminrole.view",
    description: "ROL DE ADMINISTRADORES: Ver",
    name: "adminrole.view",
  },
  {
    id: "banner.create",
    description: "Crear banner",
    name: "banner.create",
  },
  {
    id: "banner.delete",
    description: "Eliminar banner",
    name: "banner.delete",
  },
  {
    id: "banner.list",
    description: "Listar banners",
    name: "banner.list",
  },
  {
    id: "banner.update",
    description: "Actualizar banner",
    name: "banner.update",
  },
  {
    id: "banner.view",
    description: "Ver detalle de banner",
    name: "banner.view",
  },
  {
    id: "brand.add.logo",
    description: "MARCAS: Agregar logo",
    name: "brand.add.logo",
  },
  {
    id: "brand.add.manual",
    description: "MARCAS: Agregar manual",
    name: "brand.add.manual",
  },
  {
    id: "brand.create",
    description: "MARCAS: Crear",
    name: "brand.create",
  },
  {
    id: "brand.delete",
    description: "MARCAS: Eliminar",
    name: "brand.delete",
  },
  {
    id: "brand.export",
    description: "MARCAS: Exportar",
    name: "brand.export",
  },
  {
    id: "brand.list",
    description: "MARCAS: Listar",
    name: "brand.list",
  },
  {
    id: "brand.search",
    description: "MARCAS: Buscar",
    name: "brand.search",
  },
  {
    id: "brand.update",
    description: "MARCAS: Actualizar",
    name: "brand.update",
  },
  {
    id: "brand.update.bannerDesktop",
    description: "Marcas: Actualizar imagen del banner (Desktop)",
    name: "brand.update.bannerDesktop",
  },
  {
    id: "brand.update.bannerMobile",
    description: "Marcas: Actualizar imagen del banner (Mobile)",
    name: "brand.update.bannerMobile",
  },
  {
    id: "brand.update.name",
    description: "Marcas: Actualizar nombre",
    name: "brand.update.name",
  },
  {
    id: "brand.update.status",
    description: "MARCAS: Actualizar estado",
    name: "brand.update.status",
  },
  {
    id: "brand.update.supplier",
    description: "MARCAS: Actualizar proveedor",
    name: "brand.update.supplier",
  },
  {
    id: "brand.view",
    description: "MARCAS: Ver",
    name: "brand.view",
  },
  {
    id: "category.create",
    description: "CATEGORÍAS: Crear",
    name: "category.create",
  },
  {
    id: "category.delete",
    description: "CATEGORÍAS: Eliminar",
    name: "category.delete",
  },
  {
    id: "category.export",
    description: "CATEGORÍAS: Exportar",
    name: "category.export",
  },
  {
    id: "category.list",
    description: "CATEGORÍAS: Listar",
    name: "category.list",
  },
  {
    id: "category.update.status",
    description: "CATEGORÍAS: Prender o apagar categoría en la edición.",
    name: "category.update.status",
  },
  {
    id: "category.update",
    description: "CATEGORÍAS: Actualizar",
    name: "category.update",
  },
  {
    id: "category.update.bannerDesktop",
    description: "Categorías: Actualizar imagen del banner (Desktop)",
    name: "category.update.bannerDesktop",
  },
  {
    id: "category.update.bannerMobile",
    description: "Categorías: Actualizar imagen del banner (Mobile)",
    name: "category.update.bannerMobile",
  },
  {
    id: "category.update.image",
    description: "Categorías: Actualizar imagen",
    name: "category.update.image",
  },
  {
    id: "category.update.name",
    description: "CATEGORÍAS: Actualizar nombre",
    name: "category.update.name",
  },
  {
    id: "category.view",
    description: "CATEGORÍAS: Ver",
    name: "category.view",
  },
  {
    id: "coupon.create",
    description: "Cupones: Crear",
    name: "coupon.create",
  },
  {
    id: "coupon.delete",
    description: "Cupones: Eliminar",
    name: "coupon.delete",
  },
  {
    id: "coupon.list",
    description: "Listar cupones",
    name: "coupon.list",
  },
  {
    id: "coupon.update",
    description: "Cupones: Actualizar",
    name: "coupon.update",
  },
  {
    id: "coupon.view",
    description: "Vista de Cupón",
    name: "coupon.view",
  },
  {
    id: "course.create",
    description: "Cursos: Crear",
    name: "course.create",
  },
  {
    id: "course.delete",
    description: "Cursos: Eliminar",
    name: "course.delete",
  },
  {
    id: "course.list",
    description: "Cursos: Listar",
    name: "course.list",
  },
  {
    id: "course.update",
    description: "Cursos: Actualizar",
    name: "course.update",
  },
  {
    id: "course.view",
    description: "Cursos: Ver",
    name: "course.view",
  },
  {
    id: "dashboard.charts",
    description: "DASHBOARD: Visualizar",
    name: "dashboard.charts",
  },
  {
    id: "document.list",
    description: "DOCUMENTOS: Listar",
    name: "document.list",
  },
  {
    id: "lecture.create",
    description: "Clase: Crear",
    name: "lecture.create",
  },
  {
    id: "lecture.delete",
    description: "Clase: Eliminar",
    name: "lecture.delete",
  },
  {
    id: "lecture.list",
    description: "Clase: Listar",
    name: "lecture.list",
  },
  {
    id: "lecture.update",
    description: "Clase: Actualizar",
    name: "lecture.update",
  },
  {
    id: "lecture.view",
    description: "Clase: Ver",
    name: "lecture.view",
  },
  {
    id: "marketing.list",
    description: "MARKETING: Listar",
    name: "marketing.list",
  },
  {
    id: "offert.create",
    description: "Ofertas: Crear",
    name: "offert.create",
  },
  {
    id: "offert.list",
    description: "Ofertas: listar",
    name: "offert.list",
  },
  {
    id: "offert.update",
    description: "Ofertas: Actualizar",
    name: "offert.update",
  },
  {
    id: "offert.view",
    description: "Ofertas: ver",
    name: "offert.view",
  },
  {
    id: "permission.create",
    description: "PERMISOS: Crear",
    name: "permission.create",
  },
  {
    id: "permission.delete",
    description: "PERMISOS: Eliminar",
    name: "permission.delete",
  },
  {
    id: "permission.export",
    description: "PERMISOS: Exportar",
    name: "permission.export",
  },
  {
    id: "permission.list",
    description: "PERMISOS: Listar",
    name: "permission.list",
  },
  {
    id: "permission.update",
    description: "PERMISOS: Actualizar",
    name: "permission.update",
  },
  {
    id: "permission.view",
    description: "PERMISOS: Ver",
    name: "permission.view",
  },
  {
    id: "procurement.cancel.orders",
    description: "ADQUISICIONES: Cancelar órdenes",
    name: "procurement.cancel.orders",
  },
  {
    id: "procurement.create",
    description: "ADQUISICIONES: Crear",
    name: "procurement.create",
  },
  {
    id: "procurement.list",
    description: "ADQUISICIONES: Listar",
    name: "procurement.list",
  },
  {
    id: "procurement.send",
    description: "ADQUISICIONES: Enviar",
    name: "procurement.send",
  },
  {
    id: "procurement.view",
    description: "ADQUISICIONES: Ver",
    name: "procurement.view",
  },
  {
    id: "product.add.images",
    description: "PRODUCTOS: Agregar imágenes",
    name: "product.add.images",
  },
  {
    id: "product.add.keywords",
    description: "PRODUCTOS: Agregar keywords",
    name: "product.add.keywords",
  },
  {
    id: "product.create",
    description: "PRODUCTOS: Crear",
    name: "product.create",
  },
  {
    id: "product.delete",
    description: "PRODUCTOS: Eliminar",
    name: "product.delete",
  },
  {
    id: "product.export",
    description: "PRODUCTOS: Exportar",
    name: "product.export",
  },
  {
    id: "product.list",
    description: "PRODUCTOS: Listar",
    name: "product.list",
  },
  {
    id: "product.search",
    description: "PRODUCTOS: Buscar",
    name: "product.search",
  },
  {
    id: "product.show.tab.finance",
    description: "PRODUCTOS: Mostrar Tab Finanzas",
    name: "product.show.tab.finance",
  },
  {
    id: "product.supplier.create",
    description: "Producto -> Distribuidor: Crear asociación",
    name: "product.supplier.create",
  },
  {
    id: "product.supplier.delete",
    description: "Producto -> Distribuidor: Eliminar asociación",
    name: "product.supplier.delete",
  },
  {
    id: "product.supplier.disableInStockOut",
    description:
      "Producto -> Distribuidor -> Formulario: Campo de deshabilitar en stock agotado",
    name: "product.supplier.disableInStockOut",
  },
  {
    id: "product.supplier.edit",
    description: "Producto -> Distribuidor: Editar",
    name: "product.supplier.edit",
  },
  {
    id: "product.supplier.stock",
    description: "Producto -> Distribuidor: Aprovisionar stock",
    name: "product.supplier.stock",
  },
  {
    id: "product.update",
    description: "PRODUCTOS: Actualizar",
    name: "product.update",
  },
  {
    id: "product.update.location",
    description: "PRODUCTOS: Actualizar ubicación",
    name: "product.update.location",
  },
  {
    id: "product.update.status",
    description: "PRODUCTOS: Actualizar estado",
    name: "product.update.status",
  },
  {
    id: "product.update.visibleCategories",
    description: "PRODUCTOS: Actualizar categorías visibles",
    name: "product.update.visibleCategories",
  },
  {
    id: "product.view",
    description: "PRODUCTOS: Ver",
    name: "product.view",
  },
  {
    id: "productGroup.create",
    description: "Grupo de Productos: Crear",
    name: "productGroup.create",
  },
  {
    id: "productGroup.delete",
    description: "Grupo de Productos: Eliminar",
    name: "productGroup.delete",
  },
  {
    id: "productGroup.list",
    description: "Grupo de Productos: Listar",
    name: "productGroup.list",
  },
  {
    id: "productGroup.update",
    description: "Grupo de Productos: Actualizar",
    name: "productGroup.update",
  },
  {
    id: "productGroup.view",
    description: "Grupo de Productos: Ver",
    name: "productGroup.view",
  },
  {
    id: "purchase.action.revive",
    description: "Pedido: Revivir",
    name: "purchase.action.revive",
  },
  {
    id: "purchase.action.changeKam",
    description: "Pedidos: Cambiar KAM de un pedido",
    name: "purchase.action.changeKam",
  },
  {
    id: "purchase.add.comment",
    description: "Pedidos: Agregar comentario",
    name: "purchase.add.comment",
  },
  {
    id: "purchase.add.credits",
    description: "PEDIDOS: Agregar créditos",
    name: "purchase.add.credits",
  },
  {
    id: "purchase.batchsyncstock",
    description: "Proceso de batch para pedidos quietos por stock",
    name: "purchase.batchsyncstock",
  },
  {
    id: "purchase.cancel",
    description: "PEDIDOS: Cancelar",
    name: "purchase.cancel",
  },
  {
    id: "purchase.client.location.update",
    description: "PEDIDOS: Actualizar ubicación de cliente",
    name: "purchase.client.location.update",
  },
  {
    id: "purchase.create",
    description: "PEDIDOS: Crear",
    name: "purchase.create",
  },
  {
    id: "purchase.create.coupon",
    description: "Pedidos: Usar cupón al crear pedido",
    name: "purchase.create.coupon",
  },
  {
    id: "purchase.create.shipping",
    description: "Pedidos: Modificar costo de envío",
    name: "purchase.create.shipping",
  },
  {
    id: "purchase.create.uponDeliverySeeri",
    description: "Pedidos: Poder elegir Contraentrega Seeri al crear pedido",
    name: "purchase.create.uponDeliverySeeri",
  },
  {
    id: "purchase.create.mandatoryDiscount",
    description:
      "Pedidos: Poder ingresar el precio unitario.",
    name: "purchase.create.mandatoryDiscount",
  },
  {
    id: "purchase.delete.products",
    description: "PEDIDOS: Eliminar productos",
    name: "purchase.delete.products",
  },
  {
    id: "purchase.deliveryStatus.rollback",
    description: "PEDIDOS: Rollback de estado de envío",
    name: "purchase.deliveryStatus.rollback",
  },
  {
    id: "purchase.export",
    description: "PEDIDOS: Exportar",
    name: "purchase.export",
  },
  {
    id: "purchase.import",
    description: "Pedidos: Botón importar en el listado de pedidos",
    name: "purchase.import",
  },
  {
    id: "purchase.list",
    description: "PEDIDOS: Listar",
    name: "purchase.list",
  },
  {
    id: "purchase.paymentStatus",
    description: "PEDIDOS: Actualizar estado de pago",
    name: "purchase.paymentStatus",
  },
  {
    id: "purchase.quoter",
    description: "PEDIDOS: Crear cotizaciones",
    name: "purchase.quoter",
  },
  {
    id: "purchase.refund",
    description: "PEDIDOS: Devolución de dinero",
    name: "purchase.refund",
  },
  {
    id: "purchase.retry",
    description: "PEDIDOS: Reintentar envío",
    name: "purchase.retry",
  },
  {
    id: "purchase.retry.forced",
    description: "PEDIDOS: Reintentar envío de manera forzada",
    name: "purchase.retry.forced",
  },
  {
    id: "purchase.search",
    description: "PEDIDOS: Buscar",
    name: "purchase.search",
  },
  {
    id: "purchase.update",
    description: "PEDIDOS: Actualizar",
    name: "purchase.update",
  },
  {
    id: "purchase.update.clientLocation",
    description: "Pedidos: Actualizar ubicación de cliente",
    name: "purchase.update.clientLocation",
  },
  {
    id: "purchase.update.deliverOn",
    description: "Pedidos: Actualizar fecha programada de envío",
    name: "purchase.update.deliverOn",
  },
  {
    id: "purchase.update.deliveryStatus",
    description: "Pedidos: Actualizar estado de envío",
    name: "purchase.update.deliveryStatus",
  },
  {
    id: "purchase.update.paymentMethod",
    description: "PEDIDOS: Actualizar método de pago",
    name: "purchase.update.paymentMethod",
  },
  {
    id: "purchase.update.paymentStatus",
    description: "Pedidos: Actualizar estado de pago",
    name: "purchase.update.paymentStatus",
  },
  {
    id: "purchase.update.priority",
    description: "PEDIDOS: Actualizar prioridad del pedido",
    name: "purchase.update.priority",
  },
  {
    id: "purchase.update.products",
    description: "Pedidos: Actualizar productos",
    name: "purchase.update.products",
  },
  {
    id: "purchase.update.products.always",
    description: "PEDIDOS: Actualizar productos sin condición",
    name: "purchase.update.products.always",
  },
  {
    id: "purchase.update.products.recalculate",
    description:
      "PEDIDOS: Poder cambiar el campo Recalcular al editar productos",
    name: "purchase.update.products.recalculate",
  },
  {
    id: "purchase.update.products.remove",
    description:
      "PEDIDOS: Poder remover sin restricción productos de un pedido.",
    name: "purchase.update.products.remove",
  },
  {
    id: "purchase.update.status",
    description: "PEDIDOS: Actualizar estado",
    name: "purchase.update.status",
  },
  {
    id: "purchase.update.trackingCode",
    description: "PEDIDOS: Actualizar código de seguimiento",
    name: "purchase.update.trackingCode",
  },
  {
    id: "purchase.update.vouchers",
    description: "Pedidos: Actualizar voucher",
    name: "purchase.update.vouchers",
  },
  {
    id: "purchase.view",
    description: "PEDIDOS: Ver",
    name: "purchase.view",
  },
  {
    id: "purchase.view.aside",
    description: "Pedidos: Ver sección lateral",
    name: "purchase.view.aside",
  },
  {
    id: "purchase.view.vouchers",
    description: "Pedidos: Ver voucher de pedido",
    name: "purchase.view.vouchers",
  },
  {
    id: "quotes.create",
    description: "Cotizaciones: Crear",
    name: "quotes.create",
  },
  {
    id: "quotes.edit",
    description: "Cotizaciones: Editar",
    name: "quotes.edit",
  },
  {
    id: "quotes.list",
    description: "Cotizaciones: Listar",
    name: "quotes.list",
  },
  {
    id: "quotes.show",
    description: "Cotizaciones: Ver",
    name: "quotes.show",
  },
  {
    id: "resource.create",
    description: "Recurso de clase: Crear",
    name: "resource.create",
  },
  {
    id: "resource.delete",
    description: "Recurso de clase: Eliminar",
    name: "resource.delete",
  },
  {
    id: "resource.list",
    description: "Recurso de clase: Listar",
    name: "resource.list",
  },
  {
    id: "resource.update",
    description: "Recurso de clase: Actualizar",
    name: "resource.update",
  },
  {
    id: "resource.view",
    description: "Recurso de clase: Ver",
    name: "resource.view",
  },
  {
    id: "seller.add.credits",
    description: "VENDEDORES: Agregar créditos",
    name: "seller.add.credits",
  },
  {
    id: "seller.addDocument",
    description: "VENDEDORES: Agregar documento de identidad",
    name: "seller.addDocument",
  },
  {
    id: "seller.coins.bulkcharge",
    description: "VENDEDORES: Carga masiva de Coins",
    name: "seller.coins.bulkcharge",
  },
  {
    id: "seller.enable.otp",
    description: "VENDEDORES: Modificar OTP para vendedores",
    name: "seller.enable.otp",
  },
  {
    id: "seller.coins.charge",
    description: "VENDEDORES: Recargar Coins",
    name: "seller.coins.charge",
  },
  {
    id: "seller.coins.revert",
    description: "VENDEDORES: Revertir Coins",
    name: "seller.coins.revert",
  },
  {
    id: "seller.coins.show",
    description: "VENDEDORES: Ver Coins",
    name: "seller.coins.show",
  },
  {
    id: "seller.create",
    description: "Vendedores: Crear",
    name: "seller.create",
  },
  {
    id: "seller.export",
    description: "VENDEDORES: Exportar",
    name: "seller.export",
  },
  {
    id: "seller.form.manualInvoice",
    description: "Vendedores: Activar factura manual en el formulario",
    name: "seller.form.manualInvoice",
  },
  {
    id: "seller.form.otpFree",
    description: "Vendedores: Formulario libre de verificación por OTP",
    name: "seller.form.otpFree",
  },
  {
    id: "seller.list",
    description: "VENDEDORES: Listar",
    name: "seller.list",
  },
  {
    id: "seller.maxCreditCapacity",
    description: "Vendedores: Cambiar Capacidad Máxima de Crédito",
    name: "seller.maxCreditCapacity",
  },
  {
    id: "seller.search",
    description: "VENDEDORES: Buscar",
    name: "seller.search",
  },
  {
    id: "seller.show.backAccount",
    description: "VENDEDORES: Mostrar información bancaria",
    name: "seller.show.backAccount",
  },
  {
    id: "seller.show.clients",
    description: "VENDEDORES: Mostrar clientes",
    name: "seller.show.clients",
  },
  {
    id: "seller.show.email",
    description: "VENDEDORES: Mostrar correo",
    name: "seller.show.email",
  },
  {
    id: "seller.show.phone",
    description: "VENDEDORES: Mostrar teléfono",
    name: "seller.show.phone",
  },
  {
    id: "seller.show.transactions",
    description: "VENDEDORES: Mostrar transacciones",
    name: "seller.show.transactions",
  },
  {
    id: "seller.update",
    description: "VENDEDORES: Actualizar",
    name: "seller.update",
  },
  {
    id: "seller.delete",
    description: "VENDEDORES: Eliminar",
    name: "seller.delete",
  },
  {
    id: "seller.update.kam",
    description: "Vendedores: Actualizar KAM",
    name: "seller.update.kam",
  },
  {
    id: "seller.update.status",
    description: "VENDEDORES: Actualizar estado",
    name: "seller.update.status",
  },
  {
    id: "seller.update.tab.bankAccount",
    description: "VENDEDORES: Actualizar Tab Información Bancaria",
    name: "seller.update.tab.bankAccount",
  },
  {
    id: "seller.update.tab.location",
    description: "VENDEDORES: Actualizar Tab Ubicación",
    name: "seller.update.tab.location",
  },
  {
    id: "seller.update.tab.principal",
    description: "VENDEDORES: Actualizar Tab Principal",
    name: "seller.update.tab.principal",
  },
  {
    id: "seller.update.tab.sales",
    description: "VENDEDORES: Actualizar Tab Ventas",
    name: "seller.update.tab.sales",
  },
  {
    id: "seller.validate.backAccount",
    description: "VENDEDORES: Validar Cuenta Bancaria",
    name: "seller.validate.backAccount",
  },
  {
    id: "seller.validate.files",
    description: "Vendedores: Validar documentos",
    name: "seller.validate.files",
  },
  {
    id: "seller.view",
    description: "VENDEDORES: Ver",
    name: "seller.view",
  },
  {
    id: "sellerrole.create",
    description: "ROL DE VENDEDORES: Crear",
    name: "sellerrole.create",
  },
  {
    id: "sellerrole.delete",
    description: "ROL DE VENDEDORES: Eliminar",
    name: "sellerrole.delete",
  },
  {
    id: "sellerrole.list",
    description: "ROL DE VENDEDORES: Listar",
    name: "sellerrole.list",
  },
  {
    id: "sellerrole.update",
    description: "ROL DE VENDEDORES: Actualizar",
    name: "sellerrole.update",
  },
  {
    id: "sellerrole.view",
    description: "ROL DE VENDEDORES: Ver",
    name: "sellerrole.view",
  },
  {
    id: "subpurchase.action.delete",
    description: "Subpedido: Eliminar pedido",
    name: "subpurchase.action.delete",
  },
  {
    id: "subpurchase.action.generateInvoice",
    description: "Subpedido: Generar factura",
    name: "subpurchase.action.generateInvoice",
  },
  {
    id: "subpurchase.action.generateInvoiceWhenDelivered",
    description: "Subpedido: Generar factura solo cuando está entregado",
    name: "subpurchase.action.generateInvoiceWhenDelivered",
  },
  {
    id: "subpurchase.action.payments",
    description: "Subpedido: Cargar CSV de pagos",
    name: "subpurchase.action.payments",
  },
  {
    id: "subpurchase.action.voucher",
    description: "Subpedido: Cargar comprobante de pago",
    name: "subpurchase.action.voucher",
  },
  {
    id: "subpurchase.action.voucher.review",
    description: "Subpedido: Aprobar comprobante de pago",
    name: "subpurchase.action.voucher.review",
  },
  {
    id: "subpurchase.action.voucher.delete",
    description: "Subpedido: Eliminar comprobante de pago",
    name: "subpurchase.action.voucher.delete",
  },
  {
    id: "subpurchase.fromSupplier.updateDelivery",
    description:
      "Subpedido: Poder actualizar envío de subpedidos del portal de proveedores",
    name: "subpurchase.fromSupplier.updateDelivery",
  },
  {
    id: "subpurchase.invoices",
    description: "SUBPEDIDO: Visualizar y descargar facturas",
    name: "subpurchase.invoices",
  },
  {
    id: "subpurchase.update.deliveryCost",
    description: "Subpedidos: Actualizar costo de envío",
    name: "subpurchase.update.deliveryCost",
  },
  {
    id: "subpurchase.update.deliveryStatus",
    description: "SUBPEDIDOS: Cambiar estado de envío",
    name: "subpurchase.update.deliveryStatus",
  },
  {
    id: "subpurchase.update.paymentMethod",
    description: "Subpedidos: Cambiar método de pago de subpedido",
    name: "subpurchase.update.paymentMethod",
  },
  {
    id: "subpurchase.update.paymentStatus",
    description: "SUBPEDIDOS: Actualizar estado de pago",
    name: "subpurchase.update.paymentStatus",
  },
  {
    id: "subpurchase.update.trackingCode",
    description: "SUBPEDIDO: Actualizar guía",
    name: "subpurchase.update.trackingCode",
  },
  {
    id: "subpurchase.view.deliveryCost",
    description: "Subpedidos: Ver costo de envío de subpedido",
    name: "subpurchase.view.deliveryCost",
  },
  {
    id: "supplier.action.createSeller",
    description: "Proveedor: Crear vendedor al proveedor",
    name: "supplier.action.createSeller",
  },
  {
    id: "supplier.action.disableProducts",
    description: "Proveedor: Desactivar todos los productos",
    name: "supplier.action.disableProducts",
  },
  {
    id: "supplier.action.productsMarketplace",
    description:
      "Proveedor: Activar o desactivar Marketplace en todos los productos",
    name: "supplier.action.productsMarketplace",
  },
  {
    id: "supplier.action.password",
    description: "Proveedor: Cambiar contraseña",
    name: "supplier.action.password",
  },
  {
    id: "supplier.action.subdomain",
    description: "Proveedor: Crear subdominio",
    name: "supplier.action.subdomain",
  },
  {
    id: "supplier.create",
    description: "PROVEEDORES: Crear",
    name: "supplier.create",
  },
  {
    id: "supplier.delete",
    description: "PROVEEDORES: Eliminar",
    name: "supplier.delete",
  },
  {
    id: "supplier.export",
    description: "PROVEEDORES: Exportar",
    name: "supplier.export",
  },
  {
    id: "supplier.list",
    description: "PROVEEDORES: Listar",
    name: "supplier.list",
  },
  {
    id: "supplier.products.bulkUpdate",
    description:
      "Proveedores: Actualización masiva de productos desde el detalle de proveedor",
    name: "supplier.products.bulkUpdate",
  },
  {
    id: "supplier.update",
    description: "PROVEEDORES: Actualizar",
    name: "supplier.update",
  },
  {
    id: "supplier.view",
    description: "PROVEEDORES: Ver",
    name: "supplier.view",
  },
  {
    id: "supplier.action.subscription",
    description: "PROVEEDORES: Actualizar suscripción",
    name: "supplier.action.subscription",
  },
  {
    id: "ticket.create",
    description: "TICKETS: Crear",
    name: "ticket.create",
  },
  {
    id: "ticket.list",
    description: "TICKETS: Listar",
    name: "ticket.list",
  },
  {
    id: "ticket.update",
    description: "TICKET: Actualizar",
    name: "ticket.update",
  },
  {
    id: "ticket.update.productQuantityReturned",
    description: "TICKET: Actualizar cantidad devuelta de un producto",
    name: "ticket.update.productQuantityReturned",
  },
  {
    id: "ticket.view",
    description: "TICKETS: VER",
    name: "ticket.view",
  },
  {
    id: "voucher.create",
    description: "Vouchers: Crear comprobante de pago",
    name: "voucher.create",
  },
  {
    id: "voucher.delete",
    description: "Vouchers: Eliminar comprobante de pago",
    name: "voucher.delete",
  },
  {
    id: "voucher.delete.approved",
    description: "Vouchers: Eliminar vouchers aprobados",
    name: "voucher.delete.approved",
  },
  {
    id: "voucher.list",
    description: "Vouchers: Listado de comprobantes de pago",
    name: "voucher.list",
  },
  {
    id: "voucher.update.status",
    description: "Actualizar estado de comprobante de pago",
    name: "voucher.update.status",
  },
  {
    id: "voucher.view",
    description: "Vouchers: Detalle de comprobante de pago",
    name: "voucher.view",
  },
  {
    id: "supplier-permission.list",
    description: "Permisos de proveedores: Listado de permisos de proveedores",
    name: "supplier-permission.list",
  },
  {
    id: "supplier-permission.show",
    description: "Permisos de proveedores: Detalle de permiso de proveedores",
    name: "supplier-permission.show",
  },
  {
    id: "supplier-permission.create",
    description: "Permisos de proveedores: Crear permisos de proveedores",
    name: "supplier-permission.create",
  },
  {
    id: "supplier-permission.edit",
    description: "Permisos de proveedores: Editar permisos de proveedores",
    name: "supplier-permission.edit",
  },
] as const;

const ids = permissions.map((p) => p.id);

export type TPermission = (typeof permissions)[number];
export type TPermissionId = (typeof ids)[number];
