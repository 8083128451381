import {
  Button,
  Form,
  SelectInput,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Refresh, Payments } from "@mui/icons-material";
import { useState } from "react";
import { ModalAction } from "@/components/common/ModalAction";
import { AxiosError, TSubPurchase, getSeeriApi } from "@/api";
import { usePaymentMethods } from "@/hooks/payment-methods";
import { useAdmin } from "@/hooks/admin";
import { PURCHASES } from "@/config/resources";
import { i18nProvider } from "@/providers/i18n";

export const SubpurchasePaymentMethodAction = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const subpurchase = useRecordContext<TSubPurchase>();
  const [loading, setLoading] = useState(false);
  const paymentMethods = usePaymentMethods();
  const { hasPermission } = useAdmin();

  if (!subpurchase || !paymentMethods.length) return null;

  const updatePaymentMethod = async (values: any, onClose: () => void) => {
    setLoading(true);
    try {
      await getSeeriApi().put(
        "/api/sub-purchases/payment-method/" + subpurchase.id,
        values
      );
      const msg = "El subpedido fue actualizado correctamente.";
      notify(msg, { type: "success" });
      refresh();
      onClose();
    } catch (error) {
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  const formId = "form-subpurchase-payment-method";

  return (
    <ModalAction
      buttonMode="icon"
      buttonDisabled={!hasPermission("subpurchase.update.paymentMethod")}
      buttonIcon={<Payments />}
      dialogMaxWidth="sm"
      dialogTitle="Cambiar método de pago de subpedido"
      dialogContent={(onClose) => (
        <>
          <Form
            record={{ paymentMethod: subpurchase.paymentMethod }}
            onSubmit={(values) => updatePaymentMethod(values, onClose)}
            id={formId}
          >
            <SelectInput
              source="paymentMethod"
              choices={paymentMethods.map((pm) => ({
                code: pm.code,
                name: i18nProvider.translate(
                  `resources.${PURCHASES}.fields.paymentMethods.${pm.code}`
                ),
              }))}
              optionValue="code"
              validate={required()}
              fullWidth
            />
          </Form>
        </>
      )}
      dialogActions={
        <Button
          type="submit"
          variant="contained"
          size="medium"
          label="Guardar"
          form={formId}
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
      disableClose={loading}
    />
  );
};
