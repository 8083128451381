import { useRecordContext, Labeled, TextField } from "react-admin";

export const LocationProductField = () => {
  const record = useRecordContext();

  if (record?.locationType === "SEERI_STORAGE") return null;

  return (
    <>
      <div>
        <Labeled label="País">
          <TextField source="location.country" />
        </Labeled>
      </div>
      <div>
        <Labeled label="Estado">
          <TextField source="location.state" />
        </Labeled>
      </div>
      <div>
        <Labeled label="Ciudad">
          <TextField source="location.city" />
        </Labeled>
      </div>
      <div>
        <Labeled label="Código postal">
          <TextField source="location.postalCode" />
        </Labeled>
      </div>
      <div>
        <Labeled label="Línea de dirección 1">
          <TextField source="location.addressLine1" />
        </Labeled>
      </div>
      <div>
        <Labeled label="Línea de dirección 2">
          <TextField source="location.addressLine2" />
        </Labeled>
      </div>
    </>
  );
};
