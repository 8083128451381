import { FC } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  FileField,
  FileInput,
  FormTab,
  maxLength,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { SUPPLIERS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { SlugInput } from "../shared/slug";
import { Grid } from "@mui/material";
import { OptimizedImageInput } from "../../components/elements/OptimizedImageInput";

const BrandEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const BrandForm: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const { hasPermission } = useAdmin();

  return (
    <TabbedForm
      mode="onBlur"
      toolbar={<BrandEditToolbar />}
      warnWhenUnsavedChanges
      defaultValues={{ priority: 0 }}
    >
      <FormTab label="Principal">
        <Grid container spacing={4}>
          {isEdit && (
            <Grid item xs={4}>
              <TextInput source="id" disabled fullWidth />
            </Grid>
          )}
          <Grid item xs={4}>
            <TextInput
              source="name"
              disabled={!hasPermission("brand.update.name") && isEdit}
              validate={required()}
              fullWidth
            />
          </Grid>
          {!isEdit && (
            <Grid item xs={4}>
              <SlugInput source="slug" stringSource="name" />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput
              source="description"
              validate={[maxLength(150)]}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <BooleanInput source="featured" defaultValue={false} />
          </Grid>
          <Grid item xs={6}>
            <BooleanInput
              source="status"
              defaultValue={true}
              disabled={!hasPermission("brand.update.status") && isEdit}
            />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  );
};
