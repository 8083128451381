import { getPaymentMethods } from "@/api";
import useSWR from "swr";

export const usePaymentMethods = () => {
  const { data: paymentMethods = [] } = useSWR(
    "paymentMethods",
    getPaymentMethods
  );
  return paymentMethods;
};
