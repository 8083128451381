import { z } from "zod";

export const ExcelProductGroupBase = z.object({
  name: z.string().trim(),
  brandName: z.string().trim(),
  supplierName: z.string().trim(),
  categoryName: z.string().trim(),
  subCategoryName: z.string().trim(),
  taxesType: z.string().default(""),
});

export type ExcelProductGroupBase = z.infer<typeof ExcelProductGroupBase>;

export const ExcelVariant = z.object({
  productOptions: z
    .string()
    .regex(
      /^[\w\/\. ]+:[\w\/\. ]+(;[\w\/\. ]+:[\w\/\. ]+)*;?$/,
      "Debe cumplir con el formato 'op1:val1;op2:val2;'"
    ),
  retailPrice: z.number().min(0).optional().default(0),
  description: z.string().trim(),
  longDescription: z.string().trim(),
  image: z
    .string()
    .regex(/.+\.(jpe?g|png)$/, "Debe terminar en .jpg, .jpeg o .png")
    .optional(),
  height: z.number().min(0),
  width: z.number().min(0),
  length: z.number().min(0),
  weight: z.number().min(0),
  barCode: z.coerce.string(),
  locationType: z.enum(["SEERI_STORAGE", "SUPPLIER_STORAGE"]),

  // Product supplier
  supplierSku: z.coerce.string().trim(),
  price: z.number().min(0),
  securityStock: z.number().int().min(0),
  status: z.boolean(),
  marketplace: z.boolean(),
});

export type ExcelVariant = z.infer<typeof ExcelVariant>;

export const ExcelProductGroup = ExcelProductGroupBase.extend({
  products: ExcelVariant.array(),
}).strict();

export type ExcelProductGroup = z.infer<typeof ExcelProductGroup>;
