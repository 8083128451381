import { nanoid } from "nanoid";
import { DataProvider, HttpError } from "react-admin";
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  RAFirebaseOptions,
} from "react-admin-firebase";
import { ADMINS, SELLERS, ADMINROLES, PERMISSIONS } from "../config/resources";
import { config } from "../core/firebase";
import { seeriDataProvider } from "./data/provider";
import { AxiosError, getSeeriApi } from "@/api";
import { permissions } from "@/resources/permissions/data";

const options: RAFirebaseOptions = {
  disableMeta: true,
  useFileNamesInStorage: true, // Important because of a bug
};

const firebaseDataProvider = FirebaseDataProvider(config, options);

export const dataProvider: DataProvider = {
  ...firebaseDataProvider,
  async getList(resource, params) {
    if (resource === PERMISSIONS) {
      return {
        data: permissions as any,
        total: permissions.length,
      };
    }
    if (resource === ADMINS || resource === ADMINROLES) {
      return firebaseDataProvider.getList(resource, params);
    }
    return seeriDataProvider.getList(resource, params);
  },
  async getOne(resource, params) {
    if (resource === PERMISSIONS) {
      const data = permissions.find((p) => p.id === params.id) as any;
      return { data };
    }
    if (resource === ADMINS || resource === ADMINROLES) {
      return firebaseDataProvider.getOne(resource, params);
    }
    return seeriDataProvider.getOne(resource, params);
  },
  async getMany(resource, params) {
    if (resource === PERMISSIONS) {
      return {
        data: params.ids
          .map((id) => permissions.find((p) => p.id === id))
          .filter((_) => _) as any,
      };
    }
    if (resource === ADMINS || resource === ADMINROLES) {
      return firebaseDataProvider.getMany(resource, params);
    }
    return seeriDataProvider.getMany(resource, params);
  },
  async create(resource, params) {
    if (resource === PERMISSIONS) {
      throw new Error("No se pueden crear permisos de esta manera");
    }
    switch (resource) {
      case ADMINS:
        try {
          const user = await getSeeriApi()
            .post("/api/users", {
              email: params.data.email,
              password: nanoid(),
              phone: params.data.phonePrefix + params.data.phone,
              role: "ADMIN",
            })
            .then((res) => res.data);
          params.data.id = user.authId;
        } catch (error) {
          const message =
            (error instanceof AxiosError &&
              (error.response?.data.message ||
                error.response?.data.errorMessage ||
                error.message)) ||
            "Error desconocido al crear administrador";
          return Promise.reject(
            new HttpError(message, 400, { message, error })
          );
        }
        break;
      case SELLERS:
        params.data.password = nanoid();
        break;
    }

    if (resource === ADMINS || resource === ADMINROLES) {
      return firebaseDataProvider.create(resource, params);
    }
    return seeriDataProvider.create(resource, params);
  },
  async update(resource, params) {
    if (resource === PERMISSIONS) {
      throw new Error("No se pueden actualizar permisos de esta manera");
    }
    if (resource === ADMINS || resource === ADMINROLES) {
      return firebaseDataProvider.update(resource, params);
    }
    return seeriDataProvider.update(resource, params);
  },
  async delete(resource, params) {
    if (resource === PERMISSIONS) {
      throw new Error("No se pueden eliminar permisos de esta manera");
    }
    if (resource === ADMINS || resource === ADMINROLES) {
      return firebaseDataProvider.delete(resource, params);
    }
    return seeriDataProvider.delete(resource, params);
  },
};

export const authProvider = FirebaseAuthProvider(config, options);
