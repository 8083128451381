import { useState, MouseEvent } from "react";
import { useRecordContext } from "react-admin";
import { Box, IconButton, Menu, MenuList, Tooltip } from "@mui/material";
import { Download } from "@mui/icons-material";
import { ExportXLSXMenuItem } from "./export-xlsx";
import { ExportPDFMenuItem } from "./export-pdf";

export const RemissionExportButton = () => {
  const record = useRecordContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Tooltip title="Descargar remisión">
        <div>
          <IconButton color="primary" onClick={handleClick}>
            <Download />
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuList>
          <Box sx={{ px: 2, pb: 1, textTransform: "uppercase" }}>
            <strong>Descargar remisión</strong>
          </Box>
          <ExportPDFMenuItem record={record} setState={setAnchorEl} />
          <ExportXLSXMenuItem record={record} setState={setAnchorEl} />
        </MenuList>
      </Menu>
    </>
  );
};
