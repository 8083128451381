import { RecordContextProvider } from "ra-core";
import { ArrayField, Datagrid, FunctionField, NumberField } from "react-admin";
import { FC } from "react";
import { DeleteLimitButton } from "./delete";
import { useLimits } from "./use";

type Props = {
  applier: "CATEGORY" | "SUPPLIER" | "BRAND";
  type: "LIMIT" | "DISCOUNT";
};

export const LimitsList: FC<Props> = ({ applier, type }) => {
  const { limits } = useLimits(applier, type);

  return (
    <RecordContextProvider value={{ limits }}>
      <ArrayField source="limits">
        <Datagrid bulkActionButtons={false} sx={{ maxWidth: "800px" }}>
          <FunctionField
            source="valueType"
            label="Tipo de valor"
            render={(limit: any) => getValueTypeName(limit.valueType)}
          />
          <NumberField source="value" label="Valor" />
          {type === "DISCOUNT" && (
            <NumberField source="percentage" label="Porcentaje" />
          )}
          <FunctionField
            render={(limit: any) => (
              <DeleteLimitButton
                limitId={limit.id}
                applier={applier}
                type={type}
              />
            )}
          />
        </Datagrid>
      </ArrayField>
    </RecordContextProvider>
  );
};

function getValueTypeName(name: string) {
  const names: Record<string, string> = {
    AMOUNT: "Monto",
    UNITS: "Unidades",
  };
  return names[name] || "Otro";
}
