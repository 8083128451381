import {
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  FunctionField,
  List,
  TextField,
  TopToolbar,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { SUPPLIER_PERMISSIONS } from "@/config/resources";

const PermissionActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("supplier-permission.create") && <CreateButton />}
    </TopToolbar>
  );
};

export const SupplierPermissionList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("supplier-permission.list")) return null;

  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      perPage={20}
      actions={<PermissionActions />}
    >
      <Datagrid
        rowClick={
          hasPermission("supplier-permission.show") ? "show" : undefined
        }
        bulkActionButtons={false}
      >
        <TextField source="name" />
        <TextField source="key" />
        <TextField source="description" />
        {hasPermission("supplier-permission.create") && (
          <FunctionField
            label="Acciones"
            render={(record: any) => (
              <DeleteWithConfirmButton
                redirect={false}
                resource={SUPPLIER_PERMISSIONS}
                id={record.id}
              />
            )}
          />
        )}
      </Datagrid>
    </List>
  );
};
