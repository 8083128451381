import { Dialog, DialogActions, DialogTitle, Link } from "@mui/material";
import { AxiosError, getSeeriApi } from "@/api";
import { FC, useEffect, useState } from "react";
import {
  BooleanInput,
  Button,
  FunctionField,
  Labeled,
  minValue,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  useNotify,
  useRecordContext,
} from "react-admin";
import { DependentFormContent } from "../../../components/DependentFormContent";

export const SellerPaymentLink: FC<{ sellerId: string }> = ({ sellerId }) => {
  const [open, setOpen] = useState(false);
  const { debt } = useRecordContext();
  const notify = useNotify();
  const [paymentLink, setPaymentLink] = useState<string>("");

  useEffect(() => {
    setPaymentLink("");
  }, [open]);

  const onSubmit = async (values: any) => {
    const { amount, amountless } = values;
    try {
      const data = await getSeeriApi()
        .post(
          `/api/payments/seller/${sellerId}/payment-link`,
          amountless ? undefined : { amount }
        )
        .then((res) => res.data);
      setPaymentLink(data.paymentLink);
    } catch (error) {
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo generar el enlace de pago", { type: "error" });
      }
    }
  };

  return (
    <>
      <Button
        label="Pagar"
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{ marginLeft: "1rem" }}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Generar enlace de pago</DialogTitle>
        {open && (
          <>
            <SimpleForm
              onSubmit={onSubmit}
              toolbar={false}
              record={{ amount: debt < 0 ? -debt : null, paymentLink }}
            >
              <DependentFormContent
                dependent="amountless"
                render={(amountless) => (
                  <NumberInput
                    source="amount"
                    validate={!amountless ? [required(), minValue(1)] : []}
                    fullWidth
                    disabled={!!amountless}
                  />
                )}
              />
              <BooleanInput label="Sin monto" source="amountless" />
              <SaveButton
                label="Generar"
                fullWidth
                sx={{ marginBottom: "1rem" }}
                alwaysEnable
              />
              <Labeled>
                <FunctionField
                  label="Enlace de pago generado"
                  source="paymentLink"
                  render={(record: any) =>
                    record.paymentLink ? (
                      <Link href={record.paymentLink} target="_blank">
                        {record.paymentLink}
                      </Link>
                    ) : (
                      "-"
                    )
                  }
                />
              </Labeled>
            </SimpleForm>
          </>
        )}
        <DialogActions>
          <Button label="Cerrar" onClick={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};
