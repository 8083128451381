import { nanoid } from "nanoid";
import { PRODUCT_GROUPS } from "../../config/resources";
import { buckets } from "../../core/buckets";
import { uploadFile } from "../../utils/file-upload";
import { getBrands, getCategories, getSuppliers } from "@/api";

export const uploadImageObject = (image: any) =>
  image.rawFile
    ? uploadFile(
        buckets.products,
        PRODUCT_GROUPS,
        nanoid(),
        image.rawFile
      ).then((url) => ({ src: url }))
    : image;

export const getBrandByName = (brandName: string) =>
  getBrands({
    search: `name:${brandName}`,
    sort: "name,asc",
    page: 0,
    size: 1,
  }).then((result) => {
    const brand = result.content[0];
    if (!brand) throw new Error("Marca no encontrada");
    return brand;
  });

export const getSupplierByName = (supplierName: string) =>
  getSuppliers({
    search: `name:${supplierName}`,
    sort: "name,asc",
    page: 0,
    size: 1,
  }).then((result) => {
    const brand = result.content[0];
    if (!brand) throw new Error("Proveedor no encontrado");
    return brand;
  });

export const getCategoryByName = (categoryName: string) =>
  getCategories({
    search: `name:${categoryName}`,
    sort: "name,asc",
    page: 0,
    size: 1,
  }).then((result) => {
    const category = result.content[0];
    if (!category) throw new Error("Categoría no encontrada");
    return category;
  });
