import { TProduct } from "@/api";
import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { parseOptions, TOption } from "./inputs/options";
import { uploadImageObject } from "./utils";
import { ProductGroupForm } from "./_form";

export const ProductGroupCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("productGroup.create")) return null;

  const transform = async (data: any) => {
    data.options = parseOptions(data.options);

    await Promise.all([
      Promise.all(data.images.map(uploadImageObject)).then((images) => {
        data.images = images;
      }),
      Promise.all(
        data.products.map((product: any) => {
          return Promise.all(product.images.map(uploadImageObject)).then(
            (images) => {
              product.images = images;
              return product;
            }
          );
        })
      ).then((products) => {
        data.products = products;
      }),
    ]);

    return data;
  };

  return (
    <Create redirect="show" transform={transform}>
      <ProductGroupForm />
    </Create>
  );
};
