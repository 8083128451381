import { FC, useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  maxValue,
  minValue,
  number,
  NumberInput,
  ReferenceArrayInput,
  required,
  SaveButton,
  AutocompleteInput,
  SimpleForm,
  TextInput,
  TextInputProps,
  Toolbar,
  ReferenceInput,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { getCouponTypes } from "@/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addMinutes } from "date-fns/esm";
import { useFormContext } from "react-hook-form";

import { ISODateInput } from "../../components/elements/ISODateInput";
import { PRODUCTS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { integer } from "../../utils/input-validators";
import { ProductOptionRenderer } from "../shared/product";

const CouponEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

type TCouponInput = TextInputProps & {
  label: string;
  couponType: string;
  source: string;
  defaultValue: any;
  service: any;
  optionText: string;
  type: string;
  choices: [];
  fullWidth?: boolean;
};

const CouponInput: FC<TCouponInput> = ({
  label,
  couponType,
  source,
  defaultValue,
  service,
  optionText,
  type,
  choices,
  fullWidth,
}) => {
  if (service) {
    service = service.toLowerCase();
  }

  if (service) {
    return (
      <ReferenceArrayInput
        source={"data." + source}
        reference={service}
        perPage={50}
      >
        {service === PRODUCTS ? (
          <AutocompleteArrayInput
            label={label}
            filterToQuery={(searchText: string) => ({
              [optionText]: searchText,
            })}
            matchSuggestion={() => true}
            optionText={<ProductOptionRenderer />}
            inputText={(p: any) => p?.name}
            shouldRenderSuggestions={(v: string) => v?.trim().length >= 1}
            noOptionsText="No hay resultados"
            optionValue="id"
            defaultValue={defaultValue || []}
            fullWidth={fullWidth}
          />
        ) : (
          <AutocompleteArrayInput
            label={label}
            filterToQuery={(searchText: string) => ({
              [optionText]: searchText,
            })}
            optionText={optionText}
            defaultValue={defaultValue || []}
            fullWidth={fullWidth}
          />
        )}
      </ReferenceArrayInput>
    );
  } else {
    if (type === "string-array") {
      return (
        <AutocompleteArrayInput
          source={"data." + source}
          label={label}
          choices={choices}
          fullWidth={fullWidth}
        />
      );
    }
    if (type === "integer" || type === "double") {
      let min;
      let max;
      let validation;
      let helperText = "";
      switch (source) {
        case "minValue":
          min = 0;
          validation = [number(), integer(), minValue(min)];
          break;
        case "value":
          if (couponType === "DISCOUNT_PERCENTAGE") {
            min = 1;
            max = 100;
            validation = [number(), integer(), minValue(min), maxValue(max)];
          } else if (couponType === "DISCOUNT_AMOUNT") {
            min = 1;
            validation = [number(), minValue(min)];
          }
          break;
        case "maxDiscount":
          min = 0;
          helperText = "El valor 0 se tomará como máximo no definido";
          validation = [number(), minValue(min)];
          break;
      }

      return (
        <NumberInput
          source={"data." + source}
          label={label}
          defaultValue={defaultValue}
          min={min}
          max={max}
          helperText={helperText}
          validate={validation}
          fullWidth={fullWidth}
        />
      );
    }

    if (type === "string" && source === "productId") {
      return (
        <ReferenceInput
          reference={PRODUCTS}
          source="data.productId"
          sort={{ field: "name", order: "ASC" }}
          perPage={50}
        >
          <AutocompleteInput
            label="Nombre del producto"
            validate={[required()]}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            matchSuggestion={() => true}
            optionText={<ProductOptionRenderer />}
            inputText={(p: any) => p?.name + " " + p?.sku}
            shouldRenderSuggestions={(v: string) => v?.trim().length >= 1}
            noOptionsText="No hay resultados"
            optionValue="id"
            fullWidth
          />
        </ReferenceInput>
      );
    }
  }
  return null;
};

const couponDefaultValues = (adminId: string) => ({
  code: "",
  type: "",
  data: {
    sellersIds: [],
    paymentMethods: [],
    productsCategories: [],
    productsSubCategories: [],
    productsBrands: [],
    productsIds: [],
    productId: "",
    maximumNumberUses: 1,
    value: 0,
    minValue: 0,
    maxDiscount: 0,
    maximumUsesByUser: 0,
  },
  createdBy: adminId,
  maximumUsesByUser: 0,
});

type TCouponForm = {
  isEdit?: boolean;
};

const schemaCouponForm = yup.object().shape({
  beginsAt: yup.date().required(),
  endsAt: yup
    .date()
    .required()
    .when("beginsAt", (beginsAt, yup) =>
      beginsAt
        ? yup.min(
            addMinutes(beginsAt, 1),
            "La fecha de vigencia final debe ser mayor a la de vigencia inicial."
          )
        : yup.min(new Date())
    ),
  code: yup.string().required(),
  type: yup.string().required(),
});

export const CouponForm: FC<TCouponForm> = ({ isEdit }) => {
  const { admin } = useAdmin();

  if (!admin) return null;

  return (
    <SimpleForm
      mode="onBlur"
      toolbar={<CouponEditToolbar />}
      defaultValues={couponDefaultValues(admin.id)}
      resolver={yupResolver(schemaCouponForm)}
      warnWhenUnsavedChanges
    >
      <CouponFormContent isEdit={isEdit} />
    </SimpleForm>
  );
};

export const CouponFormContent: FC<TCouponForm> = ({ isEdit }) => {
  const [couponTypes, setCouponTypes] = useState<any>([]);
  const couponType = useWatch({ name: "type" });
  const { trigger, formState, setValue } = useFormContext();

  const type = couponTypes?.find((ct: any) => ct.id === couponType);

  const fields =
    type?.fields.filter((f: any) => f.field !== "maximumUsesByUser") || [];

  useEffect(() => {
    getCouponTypes().then(setCouponTypes);
  }, []);

  useEffect(() => {
    const valueData = (formState as any)?.defaultValues?.data;
    const maximumUsesByUser = (formState as any)?.defaultValues
      ?.maximumUsesByUser;
    if (maximumUsesByUser !== valueData?.maximumUsesByUser) {
      setValue("data", {
        ...(valueData ?? {}),
        maximumUsesByUser: maximumUsesByUser ?? 0,
      });
    }
  }, [formState.defaultValues]);

  return (
    <Grid container spacing={4}>
      {isEdit && (
        <Grid item xs={4}>
          <TextInput source="id" disabled fullWidth />
        </Grid>
      )}
      <Grid item xs={4}>
        <TextInput
          source="code"
          disabled={isEdit}
          format={(value: string) => value?.replace(/\s/g, "").toUpperCase()}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <ISODateInput
          source="beginsAt"
          time
          onChange={() => trigger("endsAt")}
        />
      </Grid>
      <Grid item xs={4}>
        <ISODateInput source="endsAt" time />
      </Grid>
      <Grid item xs={4}>
        <AutocompleteInput
          source="type"
          choices={couponTypes.map((ct: any) => ({
            id: ct.id,
            name: ct.name,
          }))}
          fullWidth
          disabled={!couponTypes}
        />
      </Grid>

      {fields?.map((f: any) => (
        <Grid item xs={4} key={f.field}>
          <CouponInput
            key={f.field}
            couponType={type.id}
            label={f.name}
            source={f.field}
            defaultValue={f.defaultValue}
            service={f.service}
            optionText={f.optionText}
            type={f.type}
            choices={f.choices}
            fullWidth
          />
        </Grid>
      ))}

      <Grid item xs={4}>
        <CouponInput
          couponType={type?.id ?? ""}
          label="Máximo de usos del cupón por usuario"
          source="maximumUsesByUser"
          defaultValue={null}
          service={null}
          optionText=""
          type="integer"
          choices={[]}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <BooleanInput source="firstSale" defaultValue={false} />
      </Grid>
      <Grid item xs={4}>
        <BooleanInput source="status" defaultValue={false} />
      </Grid>
    </Grid>
  );
};
