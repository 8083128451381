import { Tooltip } from "@mui/material";
import { useState } from "react";
import { Button, useRecordContext } from "react-admin";
import { format } from "date-fns";
import jsPDF from "jspdf";
import { TSubPurchase, generateRemission, getPurchaseById } from "@/api";
import { supplierStatus } from "@/data/purchases";
import autoTable from "jspdf-autotable";
import { usePurchaseProducts } from "@/hooks/purchase";
import { formatCurrency } from "@/utils/currency";
import useSWR from "swr";

export const DownloadSupplierPurchase = () => {
  const record = useRecordContext<TSubPurchase>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: purchaseDetail } = useSWR(
    record?.purchaseId ? record.purchaseId : null,
    getPurchaseById
  );

  const orderProducts = usePurchaseProducts(
    record?.products ?? [],
    record?.purchaseId ?? ""
  );
  const totalQuantity =
    record?.products?.reduce((p: number, c: any) => p + c?.quantity ?? 0, 0) ||
    0;

  const DETAILS_HEADERS = [
    { header: "Pedido", dataKey: "field" },
    { header: record?.subPurchaseNumber, dataKey: "value" },
  ];

  const PRODUCTS_HEADERS = [
    { header: "Cantidad", dataKey: "quantity" },
    { header: "Precio unit.", dataKey: "pvp" },
    { header: "Producto", dataKey: "name" },
    { header: "Total", dataKey: "subtotal" },
    { header: "Descuento", dataKey: "totalDiscount" },
    { header: "Total a pagar", dataKey: "finalPrice" },
  ];

  const mapFields = () => {
    return [
      {
        field: "Estado",
        value: record?.supplierStatus
          ? supplierStatus.find((s) => s.code === record.supplierStatus)
              ?.name ?? "-"
          : "-",
      },
      {
        field: "Fecha",
        value: record?.createdAt
          ? format(new Date(record?.createdAt), "dd-MM-yyyy")
          : "-",
      },
      {
        field: "Cantidad de productos",
        value: totalQuantity,
      },
      ...(purchaseDetail
        ? [
            {
              field: "Nombre del Cliente",
              value: `${purchaseDetail.client?.firstName} ${purchaseDetail.client?.lastName}`,
            },
            {
              field: "Correo electrónico",
              value: purchaseDetail.client?.email ?? "-",
            },
            {
              field: "Dirección",
              value: `${purchaseDetail.client?.location?.addressLine1} ${purchaseDetail.client?.location?.addressLine2}
  ${purchaseDetail.client?.location?.city}, ${purchaseDetail.client?.location?.state}, ${purchaseDetail.client?.location?.country}  - CP ${purchaseDetail.client?.location?.postalCode}`,
            },
            {
              field: "Teléfono",
              value: `${purchaseDetail.client?.phonePrefix} ${purchaseDetail.client?.phone}`,
            },
          ]
        : []),
    ];
  };

  const mapProducts = async () => {
    if (orderProducts.length) {
      return Promise.all(
        orderProducts.map(async (product: any) => {
          const subtotal =
            (product.wholesalePrice || 0) * (product?.quantity || 0);

          const finalPrice =
            product?.couponType === "DISCOUNT_PERCENTAGE"
              ? (product.subTotalWithDiscount || 0) -
                (product.discountByCoupon || 0)
              : product.subTotalWithDiscount || 0;

          const totalDiscount = subtotal - finalPrice;

          return {
            name: product?.name || "-",
            quantity: product?.quantity || 0,
            pvp: formatCurrency(product?.wholesalePrice),
            subtotal: formatCurrency(subtotal),
            totalDiscount: formatCurrency(totalDiscount),
            finalPrice: formatCurrency(finalPrice),
          };
        })
      );
    }

    return [];
  };

  const downloadPurchase = async () => {
    setIsLoading(true);
    const doc = new jsPDF("landscape");
    if (record?.supplierStatus === "PENDING") {
      await generateRemission(record?.id ?? "");
    }

    const bodyFields = mapFields();

    autoTable(doc, {
      body: bodyFields,
      columns: DETAILS_HEADERS,
      columnStyles: {
        0: { cellWidth: 50 },
      },
    });

    const bodyProducts = await mapProducts();

    autoTable(doc, {
      body: bodyProducts,
      columns: PRODUCTS_HEADERS,
      styles: {
        minCellHeight: 15,
        fontSize: 8,
      },
      columnStyles: {
        2: { cellWidth: 30, fontSize: 8 },
      },
    });

    autoTable(doc, {
      ...(purchaseDetail
        ? {
            columns: [{ header: "Total" }, { header: "" }],
          }
        : {}),
      body: [
        ["Subtotal", purchaseDetail!.subTotalWithProductDiscounts],
        ["Envio", purchaseDetail!.shipping],
        ["Total descuento", purchaseDetail!.totalDiscount],
        ["Total a pagar", purchaseDetail!.total + purchaseDetail!.shipping],
      ],
      headStyles: {
        halign: "right",
      },
      styles: {
        halign: "right",
      },
    });

    doc.save(`orden_${record?.subPurchaseNumber}.pdf`);
    setIsLoading(false);
  };

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <Tooltip title="Generar remisión">
          <div>
            <Button
              disabled={isLoading}
              onClick={downloadPurchase}
              variant="contained"
              size="small"
              label="Generar remisión"
            />
          </div>
        </Tooltip>
      </div>
    </>
  );
};
