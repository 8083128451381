import { FC, useEffect } from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  Toolbar,
  minValue,
  required,
  useGetMany,
  useGetOne,
} from "react-admin";
import { PRODUCTS, PURCHASES, SUPPLIERS } from "../../config/resources";
import { ProductOptionRenderer } from "../shared/product";
import { useWatch } from "react-hook-form";
import { TProduct, TPurchase } from "@/api";
import { useFormContext } from "react-hook-form";

export const QuoteForm: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  return (
    <SimpleForm
      mode="onChange"
      warnWhenUnsavedChanges
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <FormContent isEdit={isEdit} />
    </SimpleForm>
  );
};

const FormContent: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const { setValue, register } = useFormContext();

  return (
    <>
      <ReferenceInput
        source="supplierId"
        reference={SUPPLIERS}
        enableGetChoices={({ name }) => name && name.length >= 2}
      >
        <AutocompleteInput
          filterToQuery={(searchText: string) => ({ name: searchText })}
          onChange={() => setValue("products", [])}
          isLoading={true}
          optionValue="id"
          optionText="name"
          noOptionsText="No hay resultados"
          validate={[required()]}
          disabled={isEdit}
          fullWidth
        />
      </ReferenceInput>

      <ArrayInput source="products" validate={required()}>
        <SimpleFormIterator
          fullWidth
          disableClear
          disableReordering
          getItemLabel={(index) => `#${index + 1}`}
          sx={{
            ".RaSimpleFormIterator-form": {
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: 4,
            },
          }}
        >
          <QuoteProductProductIdInput />
          <QuoteProductPurchasesInput />
          <QuoteProductStock />
          <QuoteProductTotalNeededInput />
          <QuoteProductMinRequired />
          <QuoteProductQuantity />
          <FormDataConsumer>
            {({ getSource }) => (
              <input
                {...register(getSource?.("supplierPrice")!, {
                  value: 0,
                  valueAsNumber: true,
                })}
                style={{ display: "none" }}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

const QuoteProductProductIdInput: FC<{
  source?: "string";
  disabled?: boolean;
}> = ({ source }) => {
  const supplierId = useWatch({ name: "supplierId" });
  const { setValue } = useFormContext();

  return (
    <ReferenceInput
      source={source + ".productId"}
      reference={PRODUCTS}
      filter={{ supplierId, locationType: "SEERI_STORAGE" }}
    >
      <AutocompleteInput
        fullWidth
        label="Nombre del producto"
        validate={[required()]}
        filterToQuery={(searchText: string) => ({ name: searchText })}
        matchSuggestion={() => true}
        optionText={<ProductOptionRenderer />}
        inputText={(p?: TProduct) => p?.name + " " + p?.sku}
        noOptionsText="No hay resultados"
        optionValue="id"
        onChange={() => setValue(source + ".purchases", [])}
      />
    </ReferenceInput>
  );
};

const QuoteProductPurchasesInput: FC<{
  source?: "string";
  disabled?: boolean;
}> = ({ source }) => {
  const productId = useWatch({ name: source + ".productId" });

  return (
    <ReferenceArrayInput
      source={source + ".purchases"}
      reference={PURCHASES}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={10}
      filter={{ includeSubPurchases: false }}
      validate={required()}
    >
      <AutocompleteArrayInput
        label="Número(s) de pedido"
        filterToQuery={(purchaseNumber: string) => ({ purchaseNumber })}
        matchSuggestion={(_, purchase: TPurchase) =>
          purchase.products.some((p) => p.id === productId)
        }
        getOptionDisabled={(purchase: TPurchase) =>
          purchase.products.every(
            (p) => p.id !== productId || p.subPurchaseId || (p as any).quoteId
          )
        }
        shouldRenderSuggestions={(v: string) => v?.length >= 10}
        optionText="purchaseNumber"
        noOptionsText="No hay resultados"
        optionValue="id"
        disabled={!productId}
      />
    </ReferenceArrayInput>
  );
};

const QuoteProductStock: FC<{
  source?: "string";
  disabled?: boolean;
}> = ({ source }) => {
  const { setValue } = useFormContext();
  const productId = useWatch({ name: source + ".productId" });
  const { data: product } = useGetOne(
    PRODUCTS,
    { id: productId },
    { enabled: !!productId }
  );

  useEffect(() => {
    if (product) {
      setValue(
        source + ".stock",
        Math.max(product.totalStock - product.reservedStock || 0, 0)
      );
    }
  }, [product]);

  return (
    <NumberInput
      label="Stock disponible"
      source={source + ".stock"}
      defaultValue={0}
      fullWidth
      inputProps={{ readOnly: true }}
    />
  );
};

const QuoteProductTotalNeededInput: FC<{
  source?: "string";
  disabled?: boolean;
}> = ({ source }) => {
  const { setValue } = useFormContext();
  const purchasesIds = useWatch({ name: source + ".purchases" });
  const productId = useWatch({ name: source + ".productId" });
  const { data: purchases } = useGetMany<TPurchase>(
    PURCHASES,
    {
      ids: purchasesIds ?? [],
    },
    { enabled: !!purchasesIds && !!purchasesIds.length }
  );

  useEffect(() => {
    if (purchases) {
      const needed = purchases.reduce((sum, purchase) => {
        const product = purchase.products.find((p) => p.id === productId);
        return sum + (product?.quantity ?? 0);
      }, 0);
      setValue(source + ".totalNeeded", needed);
    }
  }, [purchases]);

  return (
    <NumberInput
      label="Necesario para los pedidos"
      source={source + ".totalNeeded"}
      defaultValue={0}
      fullWidth
      inputProps={{ readOnly: true }}
    />
  );
};

const QuoteProductMinRequired: FC<{
  source?: "string";
  disabled?: boolean;
}> = ({ source }) => {
  const { setValue } = useFormContext();
  const totalNeeded = useWatch({ name: source + ".totalNeeded" });
  const stock = useWatch({ name: source + ".stock" });

  useEffect(() => {
    const minRequired = Math.max(totalNeeded - stock, 0);
    setValue(source + ".minRequired", minRequired);
  }, [totalNeeded, stock]);

  return (
    <NumberInput
      label="Mínimo requerido"
      source={source + ".minRequired"}
      defaultValue={0}
      fullWidth
      inputProps={{ readOnly: true }}
    />
  );
};

const QuoteProductQuantity: FC<{
  source?: "string";
  disabled?: boolean;
}> = ({ source }) => {
  const minRequired = useWatch({ name: source + ".minRequired" });

  return (
    <NumberInput
      label="Cantidad a cotizar"
      source={source + ".quantity"}
      validate={[
        required(),
        minValue(minRequired, "Debe ser como mínimo, el mínimo requerido"),
      ]}
      fullWidth
    />
  );
};
