import { FC, useState } from "react";
import {
  BooleanInput,
  Button,
  FunctionField,
  ImageInput,
  Labeled,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
  useNotify,
  useRefresh,
} from "react-admin";
import { useWatch } from "react-hook-form";
import {
  Alert,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import { Add, Search, Restore, Remove } from "@mui/icons-material";
import { useAtom } from "jotai";

import { ticketRefundTypes, ticketStatuses } from "../../data/purchases";
import { PURCHASES, SELLERS } from "../../config/resources";
import { Evidences } from "./show";
import { ImageField } from "react-admin";
import {
  hasReasonsAtom,
  useCreateTicketsForm,
} from "./hooks/useCreateTicketsForm";
import { updateTicket } from "@/api";

const TicketToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const ticketDefaultValue = () => ({
  reply: "",
  requestPickup: false,
});

export const TicketForm: FC = () => {
  return (
    <>
      <div>
        <Grid container spacing={2} padding={5}>
          <Grid item xs={6} color="secondary">
            <Card variant="outlined" sx={{ width: "100%" }}>
              <CardContent>
                <Stack direction="row" sx={{ flexWrap: "wrap", gap: "1rem" }}>
                  <Labeled source="type">
                    <FunctionField
                      source="type"
                      sortable={false}
                      render={(record: any) => record.type.name}
                    />
                  </Labeled>
                  <Labeled source="reason">
                    <FunctionField
                      source="reason"
                      sortable={false}
                      render={(record: any) => record.reason.name}
                    />
                  </Labeled>
                  <Labeled source="number">
                    <TextField source="number" />
                  </Labeled>
                  <Labeled source="createdAt">
                    <TextField source="createdAt" />
                  </Labeled>
                </Stack>
              </CardContent>
            </Card>
            <br />
            <Card variant="outlined" sx={{ width: "100%" }}>
              <CardContent>
                <Stack direction="row" sx={{ flexWrap: "wrap", gap: "1rem" }}>
                  <Labeled source="userId">
                    <ReferenceField
                      source="userId"
                      reference={SELLERS}
                      link={"show"}
                    >
                      <TextField source="email" />
                    </ReferenceField>
                  </Labeled>
                  <Labeled source="purchaseNumber">
                    <ReferenceField
                      source="purchaseNumber"
                      reference={PURCHASES}
                      link={"show"}
                    >
                      <TextField source="purchaseNumber" />
                    </ReferenceField>
                  </Labeled>
                  <Labeled source="history">
                    <TextField source="history" />
                  </Labeled>
                </Stack>
              </CardContent>
            </Card>
            <br />
            <Card variant="outlined" sx={{ width: "100%" }}>
              <CardContent>
                <Stack direction="row" sx={{ flexWrap: "wrap", gap: "1rem" }}>
                  <Labeled source="comment">
                    <TextField source="comment" />
                  </Labeled>
                  <Labeled source="evidences">
                    <FunctionField
                      source="evidences"
                      render={(record: any) => (
                        <Evidences evidences={record.evidences} />
                      )}
                    />
                  </Labeled>
                </Stack>
                <Labeled source="refundType">
                  <FunctionField
                    source="refundType"
                    render={(record: any) =>
                      ticketRefundTypes.find(
                        (s) => s.code === record.refundType
                      )?.name
                    }
                  />
                </Labeled>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} color="secondary">
            <SimpleForm
              toolbar={<TicketToolbar />}
              defaultValues={ticketDefaultValue}
            >
              <Stack spacing={2} width="100%">
                <TextInput source="number" disabled fullWidth />
                <TextInput
                  source="reply"
                  label="Respuesta Seeri"
                  fullWidth
                  multiline
                  validate={[required()]}
                />
                <SelectInput
                  source="status"
                  choices={ticketStatuses}
                  optionValue="code"
                  validate={required()}
                  fullWidth
                />
                <ResponseToggle />
                <ManualRefundAlert />
              </Stack>
            </SimpleForm>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const ResponseToggle: FC = () => {
  const record = useRecordContext();
  const status = useWatch({ name: "status" });

  if (
    status !== "APPROVED" ||
    ["ORDER_WITHOUT_ARRIVING", "INCOMPLETE_ORDER"].includes(record.reason.code)
  )
    return null;
  return (
    <>
      <BooleanInput source="requestPickup" label="Solicitar recogida" />
    </>
  );
};

const ManualRefundAlert: FC = () => {
  const record = useRecordContext();
  const status = useWatch({ name: "status" });

  if (status !== "APPROVED" || record.refundType !== "MANUAL") return null;
  return (
    <Alert severity="warning">
      El usuario ha elegido reembolso <strong>Manual</strong>. La transacción
      debe ser realizada antes de aprobar esta solicitud.
    </Alert>
  );
};

export const CreateTicketFormInputs: FC = () => {
  const {
    showProducts,
    showQuestions,
    isDisabledFileInput,
    hasReasons,
    reasonList,
    productList,
    purchaseId,
    isAvailableToEvidence,
    errors,
    refundTypeList,
    onRemoveImage,
    onUploadEvidence,
    getPlaceholderFile,
    resetForm,
    setValueProduct,
    searchPurchaseReasons,
  } = useCreateTicketsForm();

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={8}>
          <TextInput
            source="purchaseId"
            label="Id Pedido"
            disabled={hasReasons}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={4} justifyContent="flex-end">
          <Button
            label="Buscar"
            disabled={hasReasons || !purchaseId}
            startIcon={<Search />}
            onClick={searchPurchaseReasons}
            color="primary"
          />
          {hasReasons && (
            <Button
              label="Limpiar"
              startIcon={<Restore />}
              onClick={resetForm}
              color="secondary"
            />
          )}
        </Grid>
      </Grid>
      {hasReasons && (
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={8}>
            <h3>Selecciona una opción</h3>
            <SelectInput
              source="reason"
              fullWidth
              validate={[required()]}
              choices={reasonList}
            />
          </Grid>
          {showProducts && (
            <Grid item sm={12}>
              <h3>¿Cuál o cuáles productos llegaron defectuosos?</h3>
              {errors["products"] && (
                <div
                  style={{
                    color: "#f44336",
                    paddingBottom: 16,
                    marginTop: -10,
                  }}
                >
                  Seleccione al menos 1 producto
                </div>
              )}
              {productList.map((product) => (
                <Grid
                  key={`key_${product.id}`}
                  container
                  spacing={1}
                  sx={{ borderBottom: "1px solid grey" }}
                >
                  <Grid item sm={8}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      sx={{ padding: 0 }}
                    >
                      <span style={{ fontSize: 18, fontWeight: 500 }}>
                        - {product.name}
                      </span>
                      <span style={{ marginLeft: 16 }}>
                        cantidad: {product.quantity}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} sx={{ padding: 0 }}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Button
                        startIcon={<Remove />}
                        onClick={() =>
                          setValueProduct({
                            name: `product:${product.id}`,
                            result: -1,
                            max: product.quantity,
                          })
                        }
                      />
                      <NumberInput
                        source={`product:${product.id}`}
                        name={`product:${product.id}`}
                        label="#"
                        min={0}
                        validate={[required()]}
                        max={product.quantity}
                        defaultValue={0}
                        sx={{ width: 80 }}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setValueProduct({
                            name,
                            value,
                            max: product.quantity,
                          });
                        }}
                      />
                      <Button
                        startIcon={<Add />}
                        onClick={() =>
                          setValueProduct({
                            name: `product:${product.id}`,
                            result: 1,
                            max: product.quantity,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          {showQuestions && (
            <>
              <Grid item sm={12}>
                <h3>Cuéntanos, ¿Qué ocurrió?</h3>
                <TextInput
                  source="comment"
                  label="Comentario"
                  validate={[required()]}
                  multiline
                  minRows={3}
                  fullWidth
                />
              </Grid>
              {isAvailableToEvidence && (
                <Grid item sm={12}>
                  <h3>Evidencia</h3>
                  <ImageInput
                    source="images"
                    multiple
                    placeholder={getPlaceholderFile()}
                    options={{ disabled: isDisabledFileInput }}
                    onChange={(listFiles) => {
                      onUploadEvidence([...listFiles].pop());
                      const itemExist = listFiles.filter(
                        (fileItem: any) =>
                          (fileItem?.title ?? "") === listFiles.at(-1).name
                      );
                      if (itemExist.length) {
                        listFiles.pop();
                      }
                    }}
                    onRemove={(file: any) => onRemoveImage(file.rawFile)}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Grid>
              )}
              <Grid item sm={12}>
                <h3>¿Cómo se prefiere el reembolso?</h3>
                <RadioButtonGroupInput
                  source="refundType"
                  validate={[required()]}
                  choices={refundTypeList}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

type ModalEditRefundTypeProps = {
  isOpen: boolean;
  handleClose: () => void;
  choiseList: { id: string; name: string }[];
};

export const ModalEditRefundType: React.FC<ModalEditRefundTypeProps> = ({
  isOpen,
  handleClose,
  choiseList,
}) => {
  const [isSending, setIsSending] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = ({ id, newRefundType, refundType }: any) => {
    if (newRefundType === refundType) {
      handleClose();
      return;
    }

    setIsSending(true);
    updateTicket(id, { refundType: newRefundType })
      .then(() => {
        setIsSending(false);
        handleClose();
        refresh();
        notify("El ticket se actualizó satisfactoriamente", {
          type: "success",
        });
      })
      .catch((error) => {
        notify(error.message, { type: "error" });
        setIsSending(false);
      });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>¿Cómo se prefiere el reembolso?</DialogTitle>
      <SimpleForm onSubmit={handleSubmit} toolbar={false}>
        <DialogContent>
          <Grid item sm={12}>
            <RadioButtonGroupInput
              source="newRefundType"
              validate={[required()]}
              choices={choiseList}
            />
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <Button label="Cerrar" onClick={handleClose} />
          <Button
            label="Actualizar tipo reembolso"
            disabled={isSending}
            type="submit"
          />
        </DialogActions>
      </SimpleForm>
    </Dialog>
  );
};

export const CreateTicketForm = () => {
  const [hasReasons] = useAtom(hasReasonsAtom);

  return (
    <SimpleForm toolbar={hasReasons ? undefined : false}>
      <CreateTicketFormInputs />
    </SimpleForm>
  );
};
