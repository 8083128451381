import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { SupplierPermissionForm } from "./_form";

export const SupplierPermissionCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("supplier-permission.create")) return null;

  return (
    <Create redirect="show">
      <SupplierPermissionForm />
    </Create>
  );
};
