import { FC, useState } from "react";
import {
  BooleanField,
  Datagrid,
  TextField,
  ListBase,
  Button,
  useRecordContext,
} from "react-admin";
import { SELLER_ADDRESSES } from "../../../config/resources";
import { CustomPagination } from "../../shared/pagination";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Dialog, DialogContent } from "@mui/material";
import { SellerAddressCreate } from "./create";
import { SellerAddressEdit } from "./edit";

type Props = {
  sellerId: string;
};

const AddressDialog: FC<{ mode: "create" | "edit"; sellerId: string }> = ({
  mode,
  sellerId,
}) => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          {mode === "create" ? (
            <SellerAddressCreate
              sellerId={sellerId}
              onDone={() => setOpen(false)}
            />
          ) : (
            <SellerAddressEdit
              sellerId={sellerId}
              id={record.id as string}
              onDone={() => setOpen(false)}
            />
          )}
        </DialogContent>
      </Dialog>
      <Button
        label={mode === "create" ? "Crear" : "Editar"}
        startIcon={mode === "create" ? <AddIcon /> : <EditIcon />}
        onClick={() => setOpen(true)}
      />
    </>
  );
};

export const SellerAddressList: FC<Props> = ({ sellerId }) => {
  return (
    <ListBase
      resource={SELLER_ADDRESSES}
      queryOptions={{ meta: { dependentId: sellerId } }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", my: "8px" }}>
        <AddressDialog mode="create" sellerId={sellerId} />
      </Box>
      <Datagrid bulkActionButtons={false}>
        <TextField source="country" />
        <TextField source="state" />
        <TextField source="city" />
        <TextField source="addressLine1" sortable={false} />
        <TextField source="addressLine2" sortable={false} />
        <TextField source="postalCode" sortable={false} />
        <BooleanField source="isMain" sortable={false} />
        <AddressDialog mode="edit" sellerId={sellerId} />
      </Datagrid>
      <CustomPagination />
    </ListBase>
  );
};
