import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CouponForm } from "./_form";

export const CouponEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("coupon.update")) return null;

  const transform = (dataEdited: any) => {
    return {
      ...dataEdited,
      maximumUsesByUser: dataEdited.data.maximumUsesByUser,
    };
  };

  return (
    <Edit mutationMode="pessimistic" redirect="show" transform={transform}>
      <CouponForm isEdit />
    </Edit>
  );
};
