import { FC } from "react";
import { EditBase, useNotify, useRefresh } from "react-admin";
import { SELLER_ADDRESSES } from "../../../config/resources";
import { SellerAddressForm } from "./_form";

type Props = {
  id: string;
  sellerId: string;
  onDone: () => void;
};

export const SellerAddressEdit: FC<Props> = ({ sellerId, id, onDone }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <EditBase
      id={id}
      resource={SELLER_ADDRESSES}
      mutationMode="pessimistic"
      mutationOptions={{
        meta: {
          dependentId: sellerId,
        },
        onSuccess: () => {
          notify("Dirección actualizada");
          refresh();
          onDone();
        },
      }}
      queryOptions={{
        meta: {
          dependentId: sellerId,
        },
      }}
      redirect={false}
    >
      <SellerAddressForm sellerId={sellerId} />
    </EditBase>
  );
};
