import { mdiUngroup } from "@mdi/js";
import { Box, Dialog, DialogTitle, SvgIcon } from "@mui/material";
import { AxiosError, TProduct, updateProductV2ProductGroup } from "@/api";
import { useState } from "react";
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { PRODUCT_GROUPS } from "../../../config/resources";

export const ChangeGroupButton = () => {
  const product = useRecordContext<TProduct>();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values: any) => {
    try {
      const { groupProductId } = values;
      await updateProductV2ProductGroup(product.id, groupProductId);
      notify("El producto se actualizó exitosamente", { type: "success" });
      refresh();
      setOpen(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo actualizar", { type: "error" });
      }
    }
  };

  return (
    <>
      <Button
        startIcon={
          <SvgIcon>
            <path d={mdiUngroup} />
          </SvgIcon>
        }
        label="Cambiar grupo"
        onClick={() => setOpen(true)}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Cambiar grupo</DialogTitle>
        {open && (
          <SimpleForm
            mode="onBlur"
            onSubmit={onSubmit}
            toolbar={
              <Toolbar>
                <SaveButton />
                <Box ml={"auto"}>
                  <Button label="Cerrar" onClick={() => setOpen(false)} />
                </Box>
              </Toolbar>
            }
          >
            <ProductGroupInput />
          </SimpleForm>
        )}
      </Dialog>
    </>
  );
};

const ProductGroupInput = ({ disabled = false }) => {
  const product = useRecordContext() as TProduct;

  return (
    <ReferenceInput
      source="groupProductId"
      reference={PRODUCT_GROUPS}
      filter={{ brandId: product.brandId }}
    >
      <AutocompleteInput
        helperText="Solo se muestran grupos de productos de la misma marca."
        source="id"
        optionText="name"
        filterToQuery={(searchText: string) => ({
          name: searchText,
        })}
        fullWidth
        validate={required()}
        disabled={disabled}
      />
    </ReferenceInput>
  );
};
