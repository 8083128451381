import { useWatch } from "react-hook-form";
import { TOption } from "./options";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import {
  ArrayInput,
  Button,
  FormDataConsumer,
  SimpleFormIterator,
  useSimpleFormIteratorItem,
} from "react-admin";
import { Grid } from "@mui/material";
import { ProductInputs, uniqueOptionCombination } from "../../products/inputs";

export const ProductsInput = () => {
  const type = useWatch({ name: "type" });
  const supplierId = useWatch({ name: "supplierId" });
  const categoryId = useWatch({ name: "categoryId" });
  let options = (useWatch({ name: "options" }) as TOption[]) || [];

  // Unique named options
  options = options?.filter(
    (option, index) =>
      option.name && options.findIndex((o) => o.name === option.name) === index
  );

  return (
    <ArrayInput
      source="products"
      label="Productos (variantes)"
      validate={[(values) => uniqueOptionCombination(values)]}
      defaultValue={[]}
    >
      <SimpleFormIterator
        fullWidth
        disableClear
        addButton={
          <Button
            label="Adicionar producto"
            startIcon={<AddIcon fontSize="small" />}
          />
        }
      >
        <FormDataConsumer>
          {({ getSource }) => {
            const { index } = useSimpleFormIteratorItem();

            return (
              <>
                <Grid item xs={12}>
                  <h2>Producto {index + 1}</h2>
                </Grid>
                <ProductInputs
                  getSource={getSource}
                  groupDeps={{ options, type, supplierId, categoryId }}
                />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
