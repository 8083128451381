import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { AxiosError, approveSupplierGroupV2 } from "@/api";
import { useCallback, useState } from "react";
import { useNotify, useRecordContext, useRefresh } from "react-admin";

export const ApproveButton = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const productId = record?.id;
  const groupProductId = record?.groupProductId;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      await approveSupplierGroupV2(groupProductId, {
        variants: [
          {
            approved: true,
            id: productId,
          },
        ],
      });

      notify("El producto se aprobó exitosamente", { type: "success" });
      refresh();
      setOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo aprobar", { type: "error" });
      }
    }
  }, [productId]);

  if ("IN_REVIEW" !== record?.approvedStatus) {
    return null;
  }

  return (
    <>
      <div>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Aprobar producto
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>
          Aprobando, vas a publicar el siguiente producto: {record.name}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cerrar
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={isLoading}>
            Aprobar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
