import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { BrandForm } from "./_form";

export const BrandEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("brand.update")) return null;

  return (
    <Edit
      mutationMode="pessimistic"
      redirect="show"
      transform={(data: any) => {
        data.name = data.name.trim();
        return data;
      }}
    >
      <BrandForm isEdit />
    </Edit>
  );
};
