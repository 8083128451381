import { datadogRum } from "@datadog/browser-rum";
import packageJson from "../../package.json";

const applicationId = import.meta.env.VITE_APP_DATADOG_APP_ID;
const clientToken = import.meta.env.VITE_APP_DATADOG_CLIENT_TOKEN;
const env = import.meta.env.VITE_APP_ENV;

if (applicationId) {
  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.com",
    service: "seeri-backoffice",
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    version: packageJson.version,
  });

  datadogRum.onReady(() => {
    datadogRum.startSessionReplayRecording();
    datadogRum.startView();
  });
}
