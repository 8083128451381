import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { PRODUCTS, PURCHASES } from "../../config/resources";
import { getRules } from "./rules";
import { QuoteQuotedAction } from "./actions/quote-quoted";
import { ModalImageField } from "../../components/elements/ModalImageField";
import { statusChoices } from "./constants";
import { useAdmin } from "../../hooks/admin";
import { QuotePaymentInProgressAction } from "./actions/quote-payment-progress";
import { QuotePaidAction } from "./actions/quote-paid";
import { QuoteDeliveredAction } from "./actions/quote-delivered";
import { OpenInNew } from "@mui/icons-material";
import { Link } from "@mui/material";

const QuoteActions = () => {
  const { hasPermission } = useAdmin();
  const quote = useRecordContext();
  const rules = getRules(quote);

  return (
    <TopToolbar>
      {hasPermission("quotes.edit") && <EditButton disabled={!rules.edit} />}
      <QuoteQuotedAction />
      <QuotePaymentInProgressAction />
      <QuotePaidAction />
      <QuoteDeliveredAction />
    </TopToolbar>
  );
};

export const QuoteShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("quotes.show")) return null;

  return (
    <Show actions={<QuoteActions />}>
      <SimpleShowLayout
        sx={{
          ".RaSimpleShowLayout-stack": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: 2,
          },
          ".ra-field-products": {
            gridColumn: "1 / -1",
          },
        }}
      >
        <TextField source="quoteNumber" />
        <ReferenceField source="supplierId" reference="suppliers">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="status"
          render={(quote: any) => (
            <>
              {statusChoices.map((choice, i) => (
                <ChipField
                  key={choice.id}
                  source="name"
                  record={choice}
                  variant={choice.id === quote.status ? "filled" : "outlined"}
                  color="info"
                />
              ))}
            </>
          )}
        />
        <NumberField source="price" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <FunctionField
          source="quoteFileUrl"
          render={(quote: any) => (
            <Link
              href={quote.quoteFileUrl}
              target="_blank"
              display="flex"
              alignItems="center"
              gap="2px"
            >
              Abrir archivo <OpenInNew />
            </Link>
          )}
        />
        <ModalImageField
          source="paymentFileUrl"
          maxHeight={150}
          emptyContent={<>-</>}
        />
        <ArrayField source="products" cellClassName="hola-mundo">
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="productId" reference={PRODUCTS} link="show">
              <TextField source="name" />
            </ReferenceField>
            <NumberField source="supplierPrice" />
            <ReferenceArrayField source="purchases" reference={PURCHASES}>
              <SingleFieldList linkType="show">
                <ChipField source="purchaseNumber" />
              </SingleFieldList>
            </ReferenceArrayField>
            <NumberField source="totalNeeded" />
            <NumberField source="quantity" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
