import * as XLSX from "xlsx";
import { productGroupHeaders, productHeaders } from "../headers";
import {
  ExcelProductGroup,
  ExcelProductGroupBase,
  ExcelVariant,
} from "../schemas/excel";
import packageJson from "@/../package.json";
import { isProd } from "@/constants/env";

export const downloadTemplate = () => {
  const headersValues = [
    ...Object.values(productGroupHeaders),
    ...Object.values(productHeaders),
  ];

  const colsQty = headersValues.length;
  const variantsColIndex = Object.values(productGroupHeaders).length;

  const mainHeader = Array.from({ length: colsQty });
  mainHeader[0] = "Grupos";
  mainHeader[variantsColIndex] = "Variantes";

  const aoa = [mainHeader, headersValues, ...aoaSamples];

  const worksheet = XLSX.utils.aoa_to_sheet(aoa);
  if (!worksheet["!merges"]) worksheet["!merges"] = [];
  worksheet["!merges"]?.push({
    s: { c: 0, r: 0 },
    e: { c: variantsColIndex - 1, r: 0 },
  });
  worksheet["!merges"]?.push({
    s: { c: variantsColIndex, r: 0 },
    e: { c: colsQty - 1, r: 0 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    "Creación de grupos + variantes"
  );

  const devSuffix = isProd ? "" : "-develop";
  XLSX.writeFile(
    workbook,
    `plantilla-grupos-${packageJson.version}${devSuffix}.xlsx`
  );
};

const samples: ExcelProductGroup[] = [
  {
    name: "Prueba iphone 14",
    brandName: "BOSS",
    supplierName: "Zall",
    categoryName: "Marcas Exclusivas",
    subCategoryName: "6-21",
    taxesType: "IVA",
    products: [
      {
        productOptions: "Color:Rosado;Almacenamiento:1GB",
        retailPrice: 4400,
        description: "test",
        longDescription: "test test test",
        image: "prueba11.jpg",
        height: 20,
        width: 20,
        length: 20,
        weight: 1,
        barCode: "barras123",
        locationType: "SUPPLIER_STORAGE",
        supplierSku: "abc1231",
        price: 2200,
        securityStock: 10,
        status: true,
        marketplace: false,
      },
      {
        productOptions: "Color:Rosado;Almacenamiento:8GB",
        retailPrice: 4400,
        description: "test",
        longDescription: "test test test",
        image: "prueba12.jpg",
        height: 20,
        width: 20,
        length: 20,
        weight: 1,
        barCode: "barras124",
        locationType: "SUPPLIER_STORAGE",
        supplierSku: "abc1232",
        price: 2200,
        securityStock: 10,
        status: true,
        marketplace: false,
      },
      {
        productOptions: "Color:Gris;Almacenamiento:8GB",
        retailPrice: 4400,
        description: "test",
        longDescription: "test test test",
        image: "prueba13.jpg",
        height: 20,
        width: 20,
        length: 20,
        weight: 1,
        barCode: "barras125",
        locationType: "SEERI_STORAGE",
        supplierSku: "abc1233",
        price: 2200,
        securityStock: 10,
        status: true,
        marketplace: false,
      },
    ],
  },
  {
    name: "Prueba iphone 13 pro",
    brandName: "BOSS",
    supplierName: "Zall",
    categoryName: "Marcas Exclusivas",
    subCategoryName: "6-21",
    taxesType: "IVA_0",
    products: [
      {
        productOptions: "Color:Dorado;Memoria:6GB;",
        retailPrice: 4400,
        description: "test",
        longDescription: "test test test",
        image: "prueba2.jpg",
        height: 20,
        width: 20,
        length: 20,
        weight: 1,
        barCode: "barras126",
        locationType: "SEERI_STORAGE",
        supplierSku: "abc1234",
        price: 2200,
        securityStock: 10,
        status: true,
        marketplace: true,
      },
    ],
  },
  {
    name: "Prueba iphone XR",
    brandName: "BOSS",
    supplierName: "Zall",
    categoryName: "Marcas Exclusivas",
    subCategoryName: "6-21",
    taxesType: "",
    products: [
      {
        productOptions: "Color:Verde;Almacenamiento:32GB",
        retailPrice: 4400,
        description: "test",
        longDescription: "test test test",
        image: "prueba31.jpg",
        height: 20,
        width: 20,
        length: 20,
        weight: 2,
        barCode: "barras127",
        locationType: "SUPPLIER_STORAGE",
        supplierSku: "abc1235",
        price: 1500,
        securityStock: 10,
        status: false,
        marketplace: true,
      },
    ],
  },
  {
    name: "Prueba Macbook",
    brandName: "BOSS",
    supplierName: "Zall",
    categoryName: "Marcas Exclusivas",
    subCategoryName: "6-21",
    taxesType: "IVA",
    products: [
      {
        productOptions: "Color:Gris;Memoria:4GB",
        retailPrice: 4400,
        description: "test",
        longDescription: "test test test",
        image: "prueba41.jpg",
        height: 20,
        width: 20,
        length: 20,
        weight: 3,
        barCode: "barras128",
        locationType: "SEERI_STORAGE",
        supplierSku: "abc1236",
        price: 1800,
        securityStock: 10,
        status: true,
        marketplace: false,
      },
      {
        productOptions: "Color:Gris;Memoria:6GB",
        retailPrice: 4400,
        description: "test",
        longDescription: "test test test",
        image: "prueba42.jpg",
        height: 20,
        width: 20,
        length: 20,
        weight: 3,
        barCode: "barras129",
        locationType: "SEERI_STORAGE",
        supplierSku: "abc1237",
        price: 1800,
        securityStock: 10,
        status: true,
        marketplace: false,
      },
    ],
  },
];

const groupKeys = Object.keys(ExcelProductGroupBase.shape);
const variantKeys = Object.keys(ExcelVariant.shape);

const aoaSamples = ExcelProductGroup.array()
  .parse(samples)
  .reduce<unknown[][]>((arr, group) => {
    const { products, ...onlyGroup } = group as Record<string, unknown>;
    const onlyGroupPart = groupKeys.map((k) => onlyGroup[k]);
    const onlyGroupPartEmpty = groupKeys.map((_) => null);

    for (let i = 0; i < group.products.length; i++) {
      const product = group.products[i] as Record<string, unknown>;
      let row: unknown[] = [];
      row = row.concat(i === 0 ? onlyGroupPart : onlyGroupPartEmpty);
      row = row.concat(variantKeys.map((k) => product[k]));
      arr.push(row);
    }

    return arr;
  }, []);
