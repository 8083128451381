import { SELLERS } from "@/config/resources";
import { useAdmin } from "@/hooks/admin";
import { Box } from "@mui/material";
import {
  Datagrid,
  EmailField,
  ListBase,
  SelectField,
  TextField,
} from "react-admin";
import { SupplierCreateSellerModalAction } from "./actions/create-seller";
import { CustomPagination } from "@/resources/shared/pagination";
import { userTypes } from "../constants";

export const SupplierSellersList = ({ supplierId }: { supplierId: string }) => {
  const { hasPermission } = useAdmin();
  return (
    <ListBase
      resource={SELLERS}
      filter={{ supplierId }}
      disableSyncWithLocation
    >
      {hasPermission("supplier.action.createSeller") && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", my: "8px" }}>
          <SupplierCreateSellerModalAction />
        </Box>
      )}
      <Datagrid
        bulkActionButtons={false}
        rowClick={hasPermission("seller.view") ? "show" : false}
      >
        <EmailField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phone" />
        <SelectField source="userType" choices={userTypes} />
        <TextField source="kam" />
      </Datagrid>
      <CustomPagination />
    </ListBase>
  );
};
