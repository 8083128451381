import { FC } from "react";
import { mdiTagArrowDownOutline } from "@mdi/js";
import { TPrePurchase, TPurchase } from "@/api";
import { Divider, Chip, Typography, Stack, SvgIcon } from "@mui/material";

import { formatCurrency } from "../../utils/currency";

type Props = {
  purchase: TPrePurchase | TPurchase;
};

export const Pricing: FC<Props> = ({ purchase }) => {
  const subTotal = purchase?.subtotal ?? 0;

  const paymentFee = purchase?.paymentFee ?? 0;

  const subTotalWithDiscounts = purchase?.subTotalWithDiscounts;
  const shipping = purchase?.shipping;
  const pendingToPay = purchase?.pendingToPay ?? purchase?.total;
  const partialMethodAmount = purchase?.partialMethodAmount;

  const discountByCoupon = purchase?.discountByCoupon ?? 0;
  const discountByOffer = purchase?.discountByOffer ?? 0;
  const discountByProducts = purchase?.discountByProducts ?? 0;

  return (
    <Stack direction="column" spacing={1}>
      <Divider>
        <Chip label="Resumen de compra" />
      </Divider>
      {Boolean(subTotal) && <PriceLabel name="Subtotal" price={subTotal} />}
      {Boolean(discountByProducts) && (
        <PriceLabel
          name="Descuento productos"
          price={discountByProducts}
          negative
        />
      )}
      <PriceLabel
        name="Descuento por oferta"
        price={discountByOffer}
        negative
      />
      <PriceLabel
        name={`Descuento cupón ${purchase?.couponInfo?.code}`}
        price={discountByCoupon}
        negative
      />
      <PriceLabel
        name="Método de pago parcial"
        price={partialMethodAmount}
        negative
      />

      {subTotal != subTotalWithDiscounts && <Divider />}
      {subTotal != subTotalWithDiscounts && (
        <PriceLabel
          name="Subtotal con descuentos"
          price={subTotalWithDiscounts}
        />
      )}
      <PriceLabel name="Recargo pasarela de pago" price={paymentFee} />
      <PriceLabel
        name={`Envío ${
          purchase?.couponInfo?.type === "FREE_SHIPPING"
            ? `(cupón: ${purchase.couponInfo.code})`
            : ""
        }`}
        price={shipping}
        showZero
      />
      <Divider />
      <PriceLabel name="Total" price={pendingToPay} bold />
    </Stack>
  );
};

const PriceLabel = ({
  name,
  bold,
  price,
  negative,
  showZero,
  secondary,
}: {
  name: string;
  price: number;
  bold?: boolean;
  showZero?: boolean;
  negative?: boolean;
  secondary?: boolean;
}) => {
  if (price === 0 && !showZero) return null;

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Typography
        {...(bold ? { variant: "h6", fontWeight: "bold" } : {})}
        {...(secondary
          ? { color: "gray", fontSize: "small", paddingLeft: "5px" }
          : {})}
      >
        {name}
      </Typography>
      <Stack direction="row" alignItems="center">
        {negative && (
          <SvgIcon fontSize="inherit">
            <path d={mdiTagArrowDownOutline} />
          </SvgIcon>
        )}
        <Typography
          {...(bold ? { variant: "h6", fontWeight: "bold" } : {})}
          {...(negative ? { color: "status.danger" } : {})}
          {...(secondary ? { color: "gray", fontSize: "small" } : {})}
        >
          {formatCurrency(price)}
        </Typography>
      </Stack>
    </Stack>
  );
};
