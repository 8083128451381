import { Alert, Box, Grid, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { useNotify, useRecordContext } from "ra-core";
import {
  Button,
  SelectInput,
  required,
  TextInput,
  IconButtonWithTooltip,
} from "react-admin";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { getSeeriApi, sendOtp, TSeller, verifyOtp } from "@/api";
import Countdown from "react-countdown";
import { addMinutes } from "date-fns";

type Props = {
  channel?: "whatsapp" | "sms";
};

export const SellerOTP = ({ channel }: Props) => {
  const notify = useNotify();
  const { setValue, unregister, getFieldState } = useFormContext();
  const seller = useRecordContext<TSeller>();

  const phonePrefix = useWatch({ name: "phonePrefix" });
  const phone = useWatch({ name: "phone" });
  const phoneState = getFieldState("phone");
  const otpChannel = channel ?? useWatch({ name: "otpChannel" });
  const otpCode = useWatch({ name: "otpCode" });

  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState<Date | null>(null);
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState("");

  const requiresOTP = seller ? seller.phone !== phone : true;

  const reset = () => {
    setValue("otpCode", "");
    setSent(null);
    setVerified("");
  };

  // Reset all when phone changes
  useEffect(reset, [phone]);

  if (!requiresOTP) {
    return (
      <Alert severity="info" sx={{ width: "100%" }}>
        No requiere del flujo OTP
      </Alert>
    );
  }

  const send = async () => {
    try {
      setSending(true);
      if (otpChannel === "whatsapp") {
        await getSeeriApi().post("/api/sellers/otp", {
          phone: phone,
          prefix: phonePrefix,
        });
      } else {
        await sendOtp({
          channel: otpChannel,
          to: phonePrefix + phone,
        });
      }
      setSent(new Date());
    } catch (error) {
      notify("Error al enviar el código, vuelva a intentarlo.", {
        type: "error",
      });
    } finally {
      setSending(false);
    }
  };

  const verify = async () => {
    try {
      setVerifying(true);
      let ok = false;
      if (otpChannel === "whatsapp") {
        const { data } = await getSeeriApi().post("/api/sellers/otp/validate", {
          phone: phone,
          prefix: phonePrefix,
          code: otpCode,
        });
        ok = data.validated;
      } else {
        const result = await verifyOtp({
          code: otpCode,
          to: phonePrefix + phone,
        });
        ok = result.status !== "pending";
      }

      if (!ok) {
        notify("No se ha podido verificar el número", {
          type: "error",
        });
      } else {
        setVerified(phone);
        unregister("otpChannel");
        unregister("otpCode");
      }
    } catch (error) {
      notify("No se ha podido verificar el número", {
        type: "error",
      });
    } finally {
      setVerifying(false);
    }
  };

  return (
    <Grid container>
      <Grid item md={8}>
        {!verified && (
          <>
            <Typography mb={2}>
              Proceso para verificar el número: <b>{phone}</b>{" "}
              <IconButtonWithTooltip
                label="Reiniciar proceso"
                onClick={reset}
                disabled={sending || verifying}
              >
                <Refresh />
              </IconButtonWithTooltip>
            </Typography>
            <Typography mt={3} fontWeight="bold">
              Paso 1
            </Typography>
            {!channel && (
              <SelectInput
                source="otpChannel"
                label="Elige un método de verificación"
                choices={["whatsapp", "sms"].map((id) => ({ id, name: id }))}
                validate={[required()]}
                disabled={!!sent || sending}
                fullWidth
              />
            )}
            <Box margin={"16px 0"}>
              <Button
                label="Solicitar código"
                onClick={send}
                disabled={
                  !phone ||
                  phoneState.invalid ||
                  !phonePrefix ||
                  !!sent ||
                  !otpChannel ||
                  sending
                }
                variant="outlined"
                size="medium"
              />
              {!phonePrefix && (
                <Typography color={"red"} fontSize={"12px"} margin={"8px 0"}>
                  El prefijo de celular es requerido
                </Typography>
              )}
              {(!phone || phoneState.invalid) && (
                <Typography color={"red"} fontSize={"12px"} margin={"8px 0"}>
                  El número de celular ingresado es inválido
                </Typography>
              )}
            </Box>
            <Typography mt={3} fontWeight="bold">
              Paso 2
            </Typography>
            {sent ? (
              <p>
                El código expira en:{" "}
                <Countdown
                  date={addMinutes(sent, 2)}
                  onComplete={reset}
                  daysInHours
                />
              </p>
            ) : (
              <p>Recibirás un código por {otpChannel}</p>
            )}
            <TextInput
              source="otpCode"
              label={"Ingresa el código recibido por " + otpChannel}
              validate={[
                required(),
                (value) =>
                  !value ? undefined : "Falta verificar. Click en verificar.",
              ]}
              disabled={!sent || verifying}
              fullWidth
            />
            <Button
              label="Verificar código"
              onClick={verify}
              disabled={!sent || verifying || !otpCode}
              variant="outlined"
              size="medium"
            />
          </>
        )}
        {verified && (
          <Alert severity="success" sx={{ width: "100%" }}>
            Se ha verificado el número <strong>{verified}</strong>. Si lo
            modifica, tendrá que verificarlo nuevamente.
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};
