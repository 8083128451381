import {
  BRANDS,
  CATEGORIES,
  PRODUCTS,
  SUPPLIERS,
} from "../../config/resources";

export const statusChoices = [
  { id: "ACTIVE", name: "Activo" },
  { id: "DISABLED", name: "Deshabilitado" },
];

export const redirectToTypeChoices = [
  { id: "NONE", name: "Sin enlace" },
  { id: "EXTERNAL", name: "Link Externo" },
  { id: "INTERNAL", name: "Vista Interna" },
];

export const typeChoices = [
  { id: "HOME", name: "Home" },
  { id: "CATEGORY", name: "Categorías" },
  { id: "SUB_CATEGORY", name: "Subcategorías" },
  { id: "BRAND", name: "Marcas" },
  { id: "SUPPLIER", name: "Proveedores" },
  { id: "PRODUCT", name: "Productos" },
];

export const formatChoices = [
  { id: "IMAGE", name: "Imagen" },
  { id: "TEXT", name: "Texto" },
];

export const typesResources = {
  CATEGORY: CATEGORIES,
  SUB_CATEGORY: CATEGORIES,
  BRAND: BRANDS,
  SUPPLIER: SUPPLIERS,
  PRODUCT: PRODUCTS,
};

export type TBannerType = "HOME" | keyof typeof typesResources;
