export function getLimitSourceName(limitSource: string) {
  const names: Record<string, string> = {
    BRAND: "Marca",
    SUPPLIER: "Proveedor",
    CATEGORY: "Categoría",
    PRODUCT: "Producto",
    NONE: "Ninguno",
  };
  return names[limitSource] || "Desconocido";
}
