import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListFilterContext,
  useListPaginationContext,
} from "react-admin";
import { PURCHASES, SUPPLIERS } from "../../config/resources";
import { deliveryStatusRendering, supplierStatus } from "../../data/purchases";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";
import { SubpurchasePaymentsCSVAction } from "../purchases/show/actions/subpurchase-upload-csv";
import { usePaymentMethods } from "@/hooks/payment-methods";
import { useDeliveryProviders } from "@/hooks/purchase";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { i18nProvider } from "@/providers/i18n";

export const SupplierPurchaseList = () => {
  const { hasPermission } = useAdmin();
  const paymentMethods = usePaymentMethods();
  const deliveryProviders = useDeliveryProviders();
  const customUrl = (_: any, __: any, record: any) => {
    const id = record?.id;

    return hasPermission("purchase.view") && id ? `${id}/show` : "";
  };

  if (!hasPermission("purchase.list")) return null;

  return (
    <>
      <List
        filters={[
          <ReferenceInput source="supplierId" reference={SUPPLIERS} alwaysOn>
            <AutocompleteInput
              label="Proveedor"
              filterToQuery={(searchText: string) => ({ name: searchText })}
              optionText="name"
            />
          </ReferenceInput>,
          <SelectInput
            source="supplierStatus"
            choices={supplierStatus}
            optionValue="code"
            alwaysOn
          />,
          <TextInput source="subPurchaseNumber" alwaysOn />,
          <SelectInput
            source="deliveryStatus"
            choices={deliveryStatusRendering}
            optionText="name"
            optionValue="code"
            alwaysOn
          />,
          <SelectInput
            source="paymentMethod"
            choices={paymentMethods.map((pm) => ({
              code: pm.code,
              name: i18nProvider.translate(
                `resources.${PURCHASES}.fields.paymentMethods.${pm.code}`
              ),
            }))}
            optionText="name"
            optionValue="code"
            alwaysOn
          />,
          <SelectInput
            source="deliveryProvider"
            choices={deliveryProviders}
            optionText="name"
            optionValue="code"
            alwaysOn
          />,
        ]}
        perPage={20}
        sort={{ field: "createdAt", order: "DESC" }}
        pagination={<SubpurchaseListPagination />}
        exporter={false}
        actions={
          <TopToolbar>
            {hasPermission("subpurchase.action.payments") && (
              <>
                <SubpurchasePaymentsCSVAction />
              </>
            )}
          </TopToolbar>
        }
      >
        <Datagrid rowClick={customUrl} bulkActionButtons={false}>
          <FunctionField
            source="subPurchaseNumber"
            sortable={false}
            render={(record: any) =>
              record.purchaseId ? (
                <Link
                  to={`/${PURCHASES}/${record.purchaseId}/show`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {record.subPurchaseNumber}
                </Link>
              ) : (
                record.subPurchaseNumber
              )
            }
          />
          <TextField source="priority" />
          <DateField source="createdAt" showTime />
          <DateField
            source="approvedAt"
            label="Aprobado el"
            showTime
            sortable={false}
            emptyText="Sin fecha de aprobación"
          />
          <ReferenceField
            source="supplierId"
            label="ID de Empresa"
            reference={SUPPLIERS}
            link={false}
          >
            <TextField
              label="ID de Empresa"
              source="companyId"
              sortable={false}
            />
          </ReferenceField>
          <ReferenceField
            source="supplierId"
            reference={SUPPLIERS}
            label={"Proveedor"}
            link={"show"}
          >
            <TextField label="Proveedor" source="name" sortable={false} />
          </ReferenceField>
          <FunctionField
            source="paymentMethod"
            render={(record: any) =>
              i18nProvider.translate(
                `resources.${PURCHASES}.fields.paymentMethods.${record.paymentMethod}`
              )
            }
          />
          <FunctionField
            source="deliveryStatus"
            render={(record: any) =>
              deliveryStatusRendering.find(
                (s) => s.code === record.deliveryStatus
              )?.name
            }
          />
          <FunctionField
            source="supplierStatus"
            render={(record: any) =>
              supplierStatus.find((s) => s.code === record.supplierStatus)?.name
            }
          />
        </Datagrid>
      </List>
    </>
  );
};

const SubpurchaseListPagination = () => {
  const { filterValues } = useListFilterContext();
  const { setPerPage } = useListPaginationContext();
  const DEFAULT_PER_PAGE = 5;

  useEffect(() => {
    if (Object.keys(filterValues).length === 0) {
      setPerPage(DEFAULT_PER_PAGE);
    }
  }, [filterValues]);

  if (Object.keys(filterValues).length > 0) {
    return <CustomPagination />;
  }

  return (
    <Box padding={"16px"} textAlign={"center"}>
      Se muestran solo {DEFAULT_PER_PAGE} filas. <b>Usa los filtros</b> para
      encontrar el recurso que buscas.
    </Box>
  );
};
