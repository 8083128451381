import {
  EditButton,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";

const PermissionShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("supplier-permission.edit") && <EditButton />}
    </TopToolbar>
  );
};

export const SupplierPermissionShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("supplier-permission.show")) return null;

  return (
    <Show actions={<PermissionShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="key" />
        <TextField source="description" />
      </SimpleShowLayout>
    </Show>
  );
};
