import { FC } from "react";
import {
  FileField,
  FileInput,
  FormTab,
  required,
  SaveButton,
  TabbedForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { Grid } from "@mui/material";

const ResourceEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

type TResourceForm = {
  isEdit?: boolean;
};

export const ResourceForm: FC<TResourceForm> = ({ isEdit }) => {
  return (
    <TabbedForm toolbar={<ResourceEditToolbar />}>
      <FormTab label="Principal">
        <Grid container spacing={6}>
          {isEdit && (
            <Grid item xs={6}>
              <TextInput source="id" disabled fullWidth />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextInput source="name" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <FileInput
              source="url"
              accept="application/pdf"
              maxSize={5000000}
              helperText="Archivo de menos de 5MB. Si el archivo no carga es porque supera el peso de 5MB."
              format={(v: any) => {
                if (v?.rawFile) {
                  const { rawFile } = v;
                  const blob = rawFile.slice(0, rawFile.size, rawFile.type);
                  v.rawFile = new File(
                    [blob],
                    Date.now() + "." + rawFile.type?.split("/")[1],
                    { type: blob.type }
                  );
                }
                return v;
              }}
              validate={required()}
            >
              <FileField title="title" target="_blank" />
            </FileInput>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  );
};
