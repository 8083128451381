import { getTickets } from "@/api";
import useSWR from "swr";

export const useTicketHistory = (sellerId: string) => {
  const { data, error, mutate } = useSWR(
    sellerId
      ? {
          search: `userId:${sellerId}`,
          sort: "createdAt,desc",
          page: 0,
          size: 5,
        }
      : null,
    getTickets
  );
  return {
    userTickets: data,
    userTicketsIsLoading: !error && !data,
    userTicketsIsError: error,
    mutate,
  };
};
