import { useState } from "react";
import {
  Button,
  Form,
  ImageField,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import { buckets } from "../../../core/buckets";
import { QUOTES } from "../../../config/resources";
import { nanoid } from "nanoid";
import { uploadFile } from "../../../utils/file-upload";
import { QuoteStatus, quoteStatusNames } from "../constants";
import { AxiosError } from "axios";
import { Refresh, PriceCheck } from "@mui/icons-material";
import { getRules } from "../rules";
import { ModalAction } from "../../../components/common/ModalAction";
import { Typography } from "@mui/material";
import { OptimizedImageInput } from "../../../components/elements/OptimizedImageInput";

export const QuotePaidAction = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const quote = useRecordContext();
  const [loading, setLoading] = useState(false);
  const [update] = useUpdate();

  if (!quote) return null;

  const setPaid = async (values: any, onClose: () => void) => {
    if (!values.paymentFileUrl.rawFile) return;
    setLoading(true);
    try {
      const paymentFileUrl = await uploadFile(
        buckets.quotes,
        QUOTES,
        nanoid(),
        values.paymentFileUrl.rawFile
      );
      await update(QUOTES, {
        id: quote.id,
        data: { paymentFileUrl, status: QuoteStatus.enum.PAID },
        previousData: quote,
      });
      const msg = "La cotización fue actualizada correctamente";
      notify(msg, { type: "success" });
      refresh();
      onClose();
    } catch (error) {
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  const formId = "form-quote-paid";

  return (
    <ModalAction
      buttonText="Pagado"
      buttonDisabled={!getRules(quote).paid}
      buttonIcon={<PriceCheck />}
      dialogTitle="Cargar comprobante de pago"
      dialogContent={(onClose) => (
        <>
          <Form
            record={{ paymentFileUrl: { src: quote.paymentFileUrl } }}
            onSubmit={(values) => setPaid(values, onClose)}
            id={formId}
          >
            <OptimizedImageInput
              source="paymentFileUrl"
              validate={[required()]}
            >
              <ImageField source="src" />
            </OptimizedImageInput>
          </Form>
          <Typography>
            La cotización se marcará como{" "}
            <b>{quoteStatusNames[QuoteStatus.enum.PAID]}</b>
          </Typography>
        </>
      )}
      dialogActions={
        <Button
          type="submit"
          variant="contained"
          size="medium"
          label="Guardar"
          form={formId}
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
      disableClose={loading}
    />
  );
};
