import { SELLER_BILLING_ADDRESSES } from "@/config/resources";
import { CreateSellerInvoiceAddressAction } from "@/resources/sellers/billing-addresses/create-modal";
import { ShowSellerInvoiceAddressAction } from "@/resources/sellers/billing-addresses/show-modal";
import { Alert, Box, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import {
  RadioButtonGroupInput,
  ResourceContextProvider,
  required,
  useGetList,
} from "react-admin";
import { useWatch } from "react-hook-form";

export const BillingAdressDropdown = () => {
  const sellerId = useWatch({ name: "sellerId" });
  const [sameAsDelivery, setSameAsDelivery] = useState(true);

  const { data: billingAddresses, isLoading } = useGetList(
    SELLER_BILLING_ADDRESSES,
    { filter: { sellerId } },
    { enabled: !!sellerId }
  );

  if (!sellerId)
    return <Alert severity="warning">Selecciona un vendedor</Alert>;

  return (
    <Box display="grid">
      <FormControlLabel
        label="Usar la dirección de envío"
        control={
          <Checkbox
            checked={sameAsDelivery}
            onChange={(_, checked) => setSameAsDelivery(checked)}
          />
        }
      />
      <ResourceContextProvider value={SELLER_BILLING_ADDRESSES}>
        <>
          <RadioButtonGroupInput
            source="invoiceThirdPartyId"
            label="Dirección de facturación"
            optionText={(a) => (
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box>
                  {a?.documentType} {a?.documentId} - {a?.addressLine1} -{" "}
                  {a?.city} - {a?.state}
                </Box>
                <ShowSellerInvoiceAddressAction billingAddressId={a.id} />
              </Box>
            )}
            choices={billingAddresses || []}
            disabled={sameAsDelivery || isLoading}
            row={false}
            validate={sameAsDelivery ? [] : [required()]}
            sx={{ ".MuiFormControlLabel-label": { flex: "1" } }}
          />
          <div>
            <CreateSellerInvoiceAddressAction
              sellerId={sellerId}
              disabled={sameAsDelivery}
            />
          </div>
        </>
      </ResourceContextProvider>
    </Box>
  );
};
