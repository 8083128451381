export const supplierPaymentStatusChoices = [
  { id: "PENDING", name: "Pendiente" },
  { id: "PAID", name: "Pagado" },
];

export const supplierPaymentTransactionTypeChoices = [
  { id: "PAYMENT_SERVICE", name: "Pasarela de pago" },
  { id: "LOGISTIC_SERVICE", name: "Logística" },
  { id: "PRODUCT_SELL", name: "Producto vendido" },
];

export const supplierPaymentTransactionReferenceChoices = [
  { id: "SUPPLIER_PAY", name: "Pago a proveedor" },
  { id: "UPON_DELIVERY", name: "Contraentrega" },
];
