import { TTicketDetail, updateTicket } from "@/api";
import { pick } from "lodash";

export const customAnswerTicket = async (
  ticketId: string,
  data: TTicketDetail
) => {
  const payload = pick(data, ["requestPickup", "reply", "status", "type"]);

  return updateTicket(ticketId, payload);
};
