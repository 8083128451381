import { useRecordContext } from "react-admin";
import { CardMedia } from "@mui/material";

export const VideoField = (props: any) => {
  const record = useRecordContext(props);

  return (
    <CardMedia
      src={`${record.video.src}`}
      component="video"
      controls
      sx={{
        width: 320,
        height: 240,
      }}
    />
  );
};

VideoField.defaultProps = {
  addLabel: true,
};
