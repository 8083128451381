import { Edit } from "react-admin";
import { BANNERS } from "../../config/resources";
import { buckets } from "../../core/buckets";
import { useAdmin } from "../../hooks/admin";
import { uploadFile } from "../../utils/file-upload";
import { getMD5Checksum } from "../../utils/md5-checksum";
import { BannerForm } from "./_form";

export const BannerEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("banner.create")) return null;

  const transform = async (data: any) => {
    const rawFileWeb = data.images.webImage?.rawFile;
    const rawFileMobile = data.images.mobileImage?.rawFile;

    if (rawFileWeb) {
      const folderWeb = await getMD5Checksum(rawFileWeb);
      const webImage = await uploadFile(
        buckets.banners,
        BANNERS,
        folderWeb,
        rawFileWeb
      );
      data.images.webImage = webImage;
    } else {
      data.images.webImage = data.images.webImage.src;
    }

    if (rawFileMobile) {
      const folderMobile = await getMD5Checksum(rawFileMobile);
      const mobileImage = await uploadFile(
        buckets.banners,
        BANNERS,
        folderMobile,
        rawFileMobile
      );
      data.images.mobileImage = mobileImage;
    } else {
      data.images.mobileImage = data.images.mobileImage.src;
    }

    return data;
  };

  return (
    <Edit mutationMode="pessimistic" redirect="show" transform={transform}>
      <BannerForm />
    </Edit>
  );
};
