import {
  ArrayField,
  AutocompleteInput,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import { PRODUCTS, SUPPLIERS } from "../../config/resources";
import { TProduct } from "@/api";
import { QuoteStatus, quoteStatusNames, statusChoices } from "./constants";
import { useAdmin } from "../../hooks/admin";

export const QuoteList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("quotes.list")) return null;

  return (
    <List
      exporter={false}
      filters={[
        <SelectInput
          source="status"
          choices={statusChoices}
          resettable
          alwaysOn
          emptyText="Todos"
        />,
        <TextInput source="quoteNumber" resettable alwaysOn />,
        <ReferenceInput
          source="supplierId"
          reference={SUPPLIERS}
          sort={{ field: "name", order: "ASC" }}
          perPage={50}
          alwaysOn
        >
          <AutocompleteInput
            label="Proveedor"
            optionText="name"
            optionValue="id"
            filterToQuery={(searchText: string) => ({ name: searchText })}
          />
        </ReferenceInput>,
      ]}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="quoteNumber" />
        <FunctionField
          source="status"
          render={(quote: any) => quoteStatusNames[quote.status as QuoteStatus]}
        />
        <NumberField source="price" />
        <ReferenceField source="supplierId" reference="suppliers">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <ArrayField source="products">
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(record: any) => (
                <ReferenceField
                  source="productId"
                  reference={PRODUCTS}
                  link="show"
                >
                  <FunctionField
                    render={(product: TProduct) => (
                      <ChipField
                        record={{
                          text: `${product.name} (${record.quantity})`,
                        }}
                        source="text"
                      />
                    )}
                  />
                </ReferenceField>
              )}
            />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
};
