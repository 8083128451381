export const userTypes = [
  { id: "SELLER", name: "Vendedor" },
  { id: "STORE", name: "Tienda" },
];

export const negotiationTypes = [
  { id: "FIXED_MARGIN", name: "Margen Fijo" },
  { id: "VARIABLE_MARGIN", name: "Margen Variable" },
  { id: "PRICE_LIST", name: "Lista de Precios" },
];

export const getNTName = (negotiationTypeCode: string) =>
  negotiationTypes.find((c) => c.id === negotiationTypeCode)?.name ??
  negotiationTypeCode ??
  "Indefinido";
