import { getSellerAvailableCredit } from "@/api";
import useSWR from "swr";
import { Labeled, NumberField, useRecordContext } from "react-admin";
import { Typography } from "@mui/material";

export const SellerAvailableCreditField = () => {
  const seller = useRecordContext();
  const { data, error } = useSWR(
    { key: "seller-credit", id: seller.id },
    ({ id }) => getSellerAvailableCredit(id + "")
  );
  return (
    <Labeled label="Crédito disponible">
      {error ? (
        <Typography sx={{ fontSize: "14px" }}>
          Error al cargar el crédito disponible.
        </Typography>
      ) : !data ? (
        <Typography sx={{ fontSize: "14px" }}>Cargando...</Typography>
      ) : (
        <NumberField
          source="availableCreditAmount"
          record={data}
          color={data.availableCreditAmount > 0 ? "green" : "red"}
        />
      )}
    </Labeled>
  );
};
