import { useEffect } from "react";
import {
  BooleanField,
  EditButton,
  ImageField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useStore,
} from "react-admin";
import { VideoField } from "../../components/VideoField";
import { audienceTypes } from "../../data/education";
import { useAdmin } from "../../hooks/admin";
import { LectureList } from "../lectures";
import { useParams } from "react-router-dom";

const CourseShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>{hasPermission("course.update") && <EditButton />}</TopToolbar>
  );
};

export const CourseShow = () => {
  const { hasPermission } = useAdmin();
  const { id } = useParams();

  const [courseId, setCourseId] = useStore("currentCourseId");

  useEffect(() => {
    if (id) setCourseId(id);
  }, [id]);

  if (!hasPermission("course.view")) return null;

  return (
    <Show actions={<CourseShowActions />}>
      <TabbedShowLayout>
        <Tab label="Principal">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          <SelectField source="audience" choices={audienceTypes} />
          <ImageField source="image.src" />
          <VideoField source="video.src" />
          <BooleanField source="status" />
        </Tab>
        <Tab label="Clases">
          <LectureList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
