import { TVoucher } from "@/api/types/voucher";
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { ADMINS, SELLERS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";
import { statusChoices } from "./constants";

const VoucherListFilters = [
  <SelectInput source="status" choices={statusChoices} alwaysOn />,
  <ReferenceInput source="sellerId" reference={SELLERS} alwaysOn>
    <AutocompleteInput
      label="Vendedor"
      filterToQuery={(searchText: string) => ({ email: searchText })}
      optionText="email"
    />
  </ReferenceInput>,
  <TextInput source="id" alwaysOn />,
  // <ISODateInput source="createdAtGte" label="Creado desde" alwaysOn />,
  // <ISODateInput source="createdAtLte" label="Creado hasta" eod alwaysOn />,
];

const SupplierActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      <FilterButton />
      {hasPermission("voucher.create") && <CreateButton />}
    </TopToolbar>
  );
};

export const VoucherList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("voucher.list")) return null;

  return (
    <List
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={20}
      filters={VoucherListFilters}
      pagination={<CustomPagination />}
      actions={<SupplierActions />}
    >
      <Datagrid
        rowClick={hasPermission("voucher.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <DateField source="createdAt" showTime />
        <TextField source="id" />
        <NumberField source="amount" />
        <FunctionField
          source="status"
          render={(record: TVoucher) =>
            statusChoices.find((c) => c.id === record.status)?.name
          }
        />
        <TextField source="reference" />
        <ReferenceField
          source="sellerId"
          sortable={false}
          reference={SELLERS}
          link={hasPermission("seller.view") ? "show" : false}
          emptyText="-"
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          source="userId"
          sortable={false}
          reference={ADMINS}
          link={hasPermission("admin.view") ? "show" : false}
          emptyText="-"
        >
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
