import { TRestListData, TSeller, TSellerFile, getSeeriApi } from "@/api";
import { validationStatuses } from "@/data/seller";
import { formatStatusColor } from "@/hooks/purchase";
import { Box, Chip, Link as MaterialLink } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  RecordContextProvider,
  SimpleShowLayout,
  useRecordContext,
  Link,
  TextField,
} from "react-admin";
import useSWR from "swr";

export const SellerDocuments = () => {
  const seller = useRecordContext<TSeller>();

  const { data: result } = useSWR(
    () => [seller.id, "seller-documents"],
    ([id]) =>
      getSeeriApi()
        .get<TRestListData<TSellerFile>>("/api/sellers/files", {
          params: {
            search: `sellerId:${id}`,
            sort: "createdAt,desc",
            size: 200,
          },
        })
        .then((res) => res.data)
  );

  const documentsRecord = { documents: result?.content ?? [] };

  const sellerId = seller.id;
  const linkFilter = JSON.stringify({
    sellerId,
  });

  return (
    <RecordContextProvider value={documentsRecord}>
      <SimpleShowLayout>
        <ArrayField label="Documentos" source="documents">
          <Datagrid bulkActionButtons={false} rowClick={undefined}>
            <DateField source="createdAt" showTime />
            <TextField source="fileType" label="Tipo de documento" />
            <FunctionField
              label="Enlace al documento"
              source="fileUrl"
              render={(record: any) => (
                <MaterialLink href={record.fileUrl} target="_blank">
                  Ver documento
                </MaterialLink>
              )}
            />
            <FunctionField
              source="validationStatus"
              label="Estado de validación"
              render={(record: any) => (
                <Chip
                  size="small"
                  variant="outlined"
                  color={formatStatusColor(record.validationStatus)}
                  sx={{ width: "fit-content" }}
                  label={
                    validationStatuses.find(
                      (s) => s.code === record.validationStatus
                    )?.name
                  }
                />
              )}
            />

            <DateField
              source="validatedAt"
              label="Validado el"
              showTime
              emptyText="-"
            />
          </Datagrid>
        </ArrayField>
        <Box>
          <Link
            to={`/documents?filter=${linkFilter}&order=ASC&page=1&perPage=20&sort=id`}
          >
            Visualizar en el módulo de documentos
          </Link>
        </Box>
      </SimpleShowLayout>
    </RecordContextProvider>
  );
};
