import { es } from "date-fns/locale";
import format from "date-fns/format";
import styles from "./HorizontalTimeline.module.css";
import { ReactNode } from "react";
import { fixApiDateStr } from "@/utils/parse-date";

type TTimelineEvent = {
  date: string;
  status?: string;
  title: string | ReactNode;
  description: string | ReactNode;
};

type THorizontalTimeline = {
  events: TTimelineEvent[];
  title: string;
  sortEvents?: boolean;
  sortDirection?: "asc" | "dsc";
};

const HorizontalTimeline = ({
  events,
  title,
  sortEvents = true,
  sortDirection = "asc",
}: THorizontalTimeline) => {
  let sortedEvents = events;

  if (sortEvents) {
    const eventsWithDate = events
      .filter((e) => e.date)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    const eventsWithoutDate = events.filter((e) => !e.date);
    sortedEvents = [...eventsWithoutDate, ...eventsWithDate];
  }

  if (sortDirection === "dsc") {
    sortedEvents = [...sortedEvents].reverse();
  }

  const formatDate = (dateString: string) => {
    return format(
      new Date(fixApiDateStr(dateString)),
      "d 'de' MMMM 'de' yyyy HH:mm",
      { locale: es }
    );
  };

  return (
    <>
      <p className={styles.title}>{title}</p>
      <div className={styles.horizontalTimeline}>
        {sortedEvents.map((event, index) => (
          <div className={styles.timelineEvent} key={index}>
            <div className={styles.line}>
              <div className={styles.eventContent}>
                {event.status && (
                  <h3 className={styles.status}>{event.status}</h3>
                )}
                {event.title &&
                  (typeof event.title === "string" ? (
                    <h3 className={styles.eventTitle}>{event.title}</h3>
                  ) : (
                    <>{event.title}</>
                  ))}
                {event.description &&
                  (typeof event.description === "string" ? (
                    <p className={styles.eventDescription}>
                      {event.description}
                    </p>
                  ) : (
                    <>{event.description}</>
                  ))}
                <div className={styles.eventDate}>
                  {event.date ? formatDate(event.date) : "-"}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default HorizontalTimeline;
