import { getMetadataTaxClassifications } from "@/api";
import { useEffect, useState } from "react";
import { FunctionField, Labeled, useRecordContext } from "react-admin";

export const TaxClassificationProductField = () => {
  const [taxClassification, setTaxClassification] = useState<any>();
  const record = useRecordContext();

  useEffect(() => {
    getMetadataTaxClassifications().then((data) => {
      setTaxClassification(
        data.find((t: any) => t.code === record.taxClassification)
      );
    });
  }, []);

  if (!taxClassification) return null;

  return (
    <Labeled label="Clasificación de impuesto">
      <FunctionField render={() => taxClassification.name} />
    </Labeled>
  );
};
