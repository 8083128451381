import { country } from "@/config/country";
import { getBaseURL } from "@/config/remote";
import { seeriAppDomain } from "@/constants/apps";

export const uploadFile = async (
  bucketName: string,
  resource: string,
  directory: string,
  file: File
) => {
  let filename = file.name.replace(/[^a-zA-Z0-9\.]/g, "");
  const parts = filename.split(".");
  const ext = parts.pop();
  const name = parts.join();
  const timestamp = Date.now();
  filename = `${name}-${timestamp}.${ext}`;
  file = new File([file], filename, { type: file.type });
  const baseUrl = await getBaseURL(country);
  const res = await fetch(`${baseUrl}/api/files/bucket-file`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
    body: JSON.stringify({
      bucketName,
      filePath: `${resource}/${country.code}/${directory}/${filename}`,
    }),
  });

  const { url, fields } = await res.json();

  const formData = new FormData();

  Object.entries({ ...fields, file }).forEach(([key, value]) => {
    formData.append(key, value as any);
  });

  await fetch(url, {
    method: "POST",
    body: formData,
  });

  return `${url}${fields.key}`;
};
