import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CreateTicketForm } from "./_form";

export const TicketCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("ticket.create")) return null;

  const transform = (formData: any) => ({
    reason: formData.reason,
    comment: formData.comment,
    type: formData.type,
    userId: formData.userId,
    purchaseId: formData.purchaseId,
    purchaseNumber: formData.purchaseNumber ?? "",
    refundType: formData.refundType,
    requestPickup: false,
    evidences: formData.evidenceListFiles.map((evidence: any) => ({
      src: evidence.url,
      type: "IMAGE",
    })),
    products:
      formData.reason === "ORDER_WITHOUT_ARRIVING"
        ? []
        : Object.entries(formData)
            .filter(([key, value]) => key.includes("product:") && value)
            .map(([key, quantity]: any) => ({
              id: key.replace("product:", ""),
              quantityReturned: quantity,
            })),
  });

  return (
    <Create redirect="list" transform={transform}>
      <CreateTicketForm />
    </Create>
  );
};
