import { ModalAction } from "@/components/common/ModalAction";
import { BRANDS, PRODUCTS, SUPPLIERS } from "@/config/resources";
import { Box, Stack } from "@mui/material";
import { AxiosError, getSeeriApi } from "@/api";
import { FC, useCallback } from "react";
import {
  ArrayField,
  AutocompleteInput,
  Button,
  Datagrid,
  Form,
  FunctionField,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  TextField,
  WithRecord,
  maxValue,
  minValue,
  required,
  useNotify,
} from "react-admin";
import useSWR from "swr";
import { Edit, Add, Delete } from "@mui/icons-material";
import { ProductOptionRenderer } from "@/resources/shared/product";
import { groupBy } from "lodash";
import { Swal } from "@/core/swal";
import { getLimitSourceName } from "@/constants/limits";
import { useSupplierBrands } from "@/hooks/supplier-brands";
import {
  useSupplierDiscounts,
  SupplierDiscountsContext,
} from "./supplier-discounts-context";

type Props = {
  applier: "SUPPLIER" | "BRAND" | "PRODUCT";
  supplierId: string;
};

const resources = {
  SUPPLIER: SUPPLIERS,
  BRAND: BRANDS,
  PRODUCT: PRODUCTS,
};

export const SupplierDiscounts: FC<Props> = ({ applier, supplierId }) => {
  const param = applier === "SUPPLIER" ? "referenceId" : "supplierId";

  const { data, mutate: reload } = useSWR(
    {
      supplierId,
      applier,
    },
    ({ supplierId, applier }) =>
      getSeeriApi()
        .get("/api/limits", {
          params: {
            search: `applier:${applier},${param}:${supplierId}`,
            size: 400,
          },
        })
        .then((res) => res.data)
  );

  const content = data?.content ?? [];

  const groupedLimits = Object.entries(groupBy(content, "referenceId")).map(
    ([id, limits]) => ({ id, limits })
  );

  return (
    <SupplierDiscountsContext.Provider value={{ applier, supplierId, reload }}>
      <Box>
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <SupplierDiscountAction
            referenceId={applier === "SUPPLIER" ? supplierId : ""}
            valueType={applier === "SUPPLIER" ? content[0]?.valueType : ""}
            existingReferences={content.map((limit: any) => limit.referenceId)}
          />
        </Box>

        {applier === "SUPPLIER" ? (
          <LimitsField limits={content} />
        ) : (
          <ArrayField source="groupedLimits" record={{ groupedLimits }}>
            <Datagrid
              bulkActionButtons={false}
              aria-expanded={true}
              expand={
                <Box>
                  <WithRecord
                    render={({ limits }: any) => (
                      <LimitsField limits={limits} />
                    )}
                  />
                </Box>
              }
            >
              <ReferenceField
                source="id"
                reference={resources[applier]}
                label="Referencia"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                label="Acciones"
                render={(record: any) => (
                  <Box>
                    <SupplierDiscountAction
                      referenceId={record.id}
                      valueType={record.limits[0]?.valueType}
                    />
                  </Box>
                )}
              />
            </Datagrid>
          </ArrayField>
        )}
      </Box>
    </SupplierDiscountsContext.Provider>
  );
};

const LimitsField: FC<{ limits: any[] }> = ({ limits }) => {
  return (
    <ArrayField source="limits" record={{ limits }}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          source="valueType"
          label="Tipo de valor"
          render={(limit: any) => getValueTypeName(limit.valueType)}
        />

        <NumberField source="minValue" label="Valor mínimo" />

        <NumberField source="discountPercentage" label="Descuento (%)" />

        <FunctionField
          label="Acciones"
          render={(record: any) => (
            <Stack direction="row" spacing={1}>
              <SupplierDiscountAction limit={record} />
              <SupplierDiscountDeleteButton limitId={record.id} />
            </Stack>
          )}
        />
      </Datagrid>
    </ArrayField>
  );
};

function getValueTypeName(name: string) {
  const names: Record<string, string> = {
    AMOUNT: "Monto",
    UNITS: "Unidades",
  };
  return names[name] || "Otro";
}

export const SupplierDiscountAction: FC<{
  limit?: any;
  referenceId?: string;
  valueType?: string;
  existingReferences?: string[];
}> = ({ limit, referenceId = "", valueType = "", existingReferences = [] }) => {
  const notify = useNotify();
  const { applier, supplierId, reload } = useSupplierDiscounts();

  const { data: brands = [] } =
    applier === "BRAND" ? useSupplierBrands(supplierId) : {};

  const onSubmit = useCallback(
    async (values: any, onDone: Function) => {
      try {
        const { value, valueType, percentage, referenceId } = values;
        const payload = {
          applier,
          referenceId: applier === "SUPPLIER" ? supplierId : referenceId,
          supplierId,
          type: "DISCOUNT",
          valueType,
          value,
          percentage,
        };
        if (limit) {
          await getSeeriApi().put(`/api/limits/${limit.id}`, payload);
        } else {
          await getSeeriApi().post("/api/limits", payload);
        }
        await reload();
        notify("Se agregó el límite exitosamente", { type: "success" });
        onDone();
      } catch (error) {
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo agregar", { type: "error" });
        }
      }
    },
    [applier, supplierId]
  );

  const defaultValues = limit
    ? {
        referenceId: limit.referenceId,
        value: limit.minValue,
        valueType: limit.valueType,
        percentage: limit.discountPercentage,
      }
    : { referenceId, valueType };

  return (
    <ModalAction
      buttonText={limit ? "Editar" : "Agregar"}
      buttonIcon={limit ? <Edit /> : <Add />}
      dialogTitle={
        (limit ? "Editar" : "Agregar") +
        " descuento escalonado > " +
        getLimitSourceName(applier)
      }
      dialogMaxWidth="sm"
      dialogContent={(onClose) => (
        <Form
          record={defaultValues}
          onSubmit={(values) => onSubmit(values, onClose)}
        >
          {applier === "BRAND" ? (
            <AutocompleteInput
              source="referenceId"
              label="Referencia"
              choices={brands}
              optionValue="id"
              optionText="name"
              validate={required()}
              noOptionsText="No hay resultados"
              disabled={!!referenceId || !!limit}
              getOptionDisabled={(choice) =>
                existingReferences.includes(choice.id as string)
              }
              fullWidth
            />
          ) : (
            <ReferenceInput
              source="referenceId"
              filter={applier !== "SUPPLIER" ? { supplierId } : undefined}
              reference={resources[applier]}
              sort={{ field: "name", order: "ASC" }}
              perPage={50}
            >
              <AutocompleteInput
                source="name"
                label="Referencia"
                validate={required()}
                filterToQuery={(searchText: string) => ({
                  name: searchText,
                })}
                matchSuggestion={(_: string, choice: any) => true}
                optionText={
                  applier === "PRODUCT" ? <ProductOptionRenderer /> : "name"
                }
                inputText={(p: any) => p?.name}
                noOptionsText="No hay resultados"
                optionValue="id"
                disabled={!!referenceId || !!limit}
                getOptionDisabled={(choice) =>
                  existingReferences.includes(choice.id as string)
                }
                fullWidth
              />
            </ReferenceInput>
          )}

          <SelectInput
            source="valueType"
            label="Tipo de valor"
            choices={[
              { id: "AMOUNT", name: "Monto" },
              // { id: "UNITS", name: "Unidades" },
            ]}
            validate={[required()]}
            disabled={!!valueType || !!limit}
            fullWidth
          />
          <NumberInput
            source="value"
            label="Valor"
            validate={[required()]}
            fullWidth
          />
          <NumberInput
            source="percentage"
            label="Porcentaje"
            validate={[required(), minValue(0), maxValue(100)]}
            min={0}
            max={100}
            fullWidth
          />
          <SaveButton label="Confirmar" />
        </Form>
      )}
    />
  );
};

export const SupplierDiscountDeleteButton: FC<{ limitId: string }> = ({
  limitId,
}) => {
  const notify = useNotify();
  const { reload } = useSupplierDiscounts();

  const onClick = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "¿Seguro que quieres eliminar este descuento?",
      showCancelButton: true,
      confirmButtonText: "Sí, seguro",
      cancelButtonText: "Cancelar",
    });
    if (!isConfirmed) return;

    try {
      await getSeeriApi().delete(`/api/limits/${limitId}`);
      notify(`Se eliminó el descuento exitosamente`, { type: "success" });
      reload();
    } catch (error) {
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo eliminar", { type: "error" });
      }
    }
  };

  return (
    <Box>
      <Button
        variant="outlined"
        startIcon={<Delete />}
        label={"Eliminar"}
        onClick={onClick}
      />
    </Box>
  );
};
