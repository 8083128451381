import {
  Button,
  CheckboxGroupInput,
  Form,
  useNotify,
  useRecordContext,
} from "react-admin";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { processPurchasesCSV } from "../../export/process";
import { getAllHeaders, getHeaderName } from "../../export/headers";
import { mdiDownloadBoxOutline } from "@mdi/js";
import { usePaymentMethods } from "../../../../hooks/payment-methods";

export const PurchaseExportButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [headers, setHeaders] = useState<string[]>(getAllHeaders());
  const [downloading, setDownloading] = useState(false);
  const notify = useNotify();
  const paymentMethods = usePaymentMethods();

  const download = useCallback(async () => {
    setDownloading(true);
    try {
      await processPurchasesCSV([record!], headers, paymentMethods);
      setOpen(false);
    } catch (error) {
      console.log(error);
      notify("Ocurrió un error. Por favor, inténtelo de nuevo.");
    }
    setDownloading(false);
  }, [headers]);

  if (!record) return null;

  return (
    <>
      <Tooltip title="Exportar pedido">
        <div>
          <IconButton color="primary" onClick={() => setOpen(true)}>
            <SvgIcon>
              <path d={mdiDownloadBoxOutline} />
            </SvgIcon>
          </IconButton>
        </div>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Exportar pedido</DialogTitle>
        <DialogContent>
          <HeadersInput value={headers} onChange={setHeaders} />
        </DialogContent>
        <DialogActions>
          <Button
            label="Cerrar"
            onClick={() => setOpen(false)}
            variant="outlined"
            size="medium"
            disabled={downloading}
          />
          <Button
            label="Exportar"
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            onClick={download}
            disabled={downloading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const HeadersInput: FC<{
  value: string[];
  onChange: (value: string[]) => void;
}> = ({ value, onChange }) => {
  return (
    <>
      <Typography sx={{ mt: 2 }}>
        Selecciona las columnas que deseas incluir en el exportable:
      </Typography>
      <Form record={{ headers: value }}>
        <CheckboxGroupInput
          source="headers"
          choices={getAllHeaders().map((name) => ({
            id: name,
            name: getHeaderName(name),
          }))}
          row={false}
          onChange={(v) => onChange(v)}
        />
      </Form>
    </>
  );
};
