import { Grid } from "@mui/material";
import { FC, useEffect } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { PRODUCTS } from "../../../config/resources";
import { integer } from "../../../utils/input-validators";
import { ProductOptionRenderer } from "../../shared/product";

export const KitProductsReferenceArrayInput: FC<{
  getSource: (source: string) => string;
  type: string;
}> = ({ getSource, type }) => {
  const { setValue } = useFormContext();
  const products = useWatch({ name: getSource("products") });

  const isKit = type === "KIT";

  useEffect(() => {
    if (!isKit) {
      setValue(getSource("products"), []);
    } else {
      setValue(getSource("products"), [{}]);
    }
  }, [type]);

  return isKit ? (
    <ArrayInput
      source={getSource("products")}
      label="Productos (Kit)"
      fullWidth
    >
      <SimpleFormIterator
        disableClear
        disableRemove={products?.length <= 1}
        fullWidth
      >
        <ReferenceInput
          reference={PRODUCTS}
          source="id"
          sort={{ field: "name", order: "ASC" }}
          perPage={50}
        >
          <AutocompleteInput
            label="Nombre del producto"
            matchSuggestion={() => true}
            optionText={<ProductOptionRenderer />}
            inputText={(p: any) => p?.name}
            filterToQuery={(searchText: string) => ({
              name: searchText,
              type: "PRODUCT",
            })}
            shouldRenderSuggestions={(v: string) => v?.trim().length >= 1}
            noOptionsText="No hay resultados"
            optionValue="id"
            validate={required()}
            fullWidth
          />
        </ReferenceInput>
        <NumberInput
          source="quantity"
          min={1}
          validate={[required(), number(), integer(), minValue(1)]}
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  ) : null;
};
