import {
  ArrayField,
  BooleanField,
  EditButton,
  EmailField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
} from "react-admin";
import { SimpleChipField } from "../../components/SimpleChipField";
import { ADMINROLES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";

const AdminShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>{hasPermission("admin.update") && <EditButton />}</TopToolbar>
  );
};

export const AdminShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("admin.view")) return null;

  return (
    <Show actions={<AdminShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="phonePrefix" />
        <TextField source="phone" />
        <ReferenceField
          source="roleId"
          reference={ADMINROLES}
          link={hasPermission("adminrole.view") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="status" />
        <ArrayField source="countries">
          <SingleFieldList linkType={false}>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="permissions">
          <SingleFieldList linkType={false}>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
