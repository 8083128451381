import { TranslatedFields } from "@/components/translated/TranslatedFields";
import { SELLER_BILLING_ADDRESSES } from "@/config/resources";
import { FC } from "react";
import { DateField, ShowBase, SimpleShowLayout, TextField } from "react-admin";

type Props = {
  billingAddressId: string;
};

export const SellerInvoiceAddressShow: FC<Props> = ({ billingAddressId }) => {
  return (
    <ShowBase resource={SELLER_BILLING_ADDRESSES} id={billingAddressId}>
      <SimpleShowLayout>
        <TranslatedFields
          sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
        >
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="documentType" />
          <TextField source="documentId" />
          <TextField source="verifierDigit" />
          <TextField source="email" />
          <TextField source="phonePrefix" />
          <TextField source="phone" />
          <TextField source="country" />
          <TextField source="state" />
          <TextField source="city" />
          <TextField source="addressLine1" />
          <TextField source="addressLine2" />
          <DateField source="createdAt" showTime />
        </TranslatedFields>
      </SimpleShowLayout>
    </ShowBase>
  );
};
