import { FC } from "react";
import {
  AutocompleteArrayInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { Grid } from "@mui/material";
import { permissions } from "../permissions/data";

const AdminRoleEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

type TAdminRoleForm = {
  isEdit?: boolean;
};

export const AdminRoleForm: FC<TAdminRoleForm> = ({ isEdit }) => {
  return (
    <SimpleForm mode="onBlur" toolbar={<AdminRoleEditToolbar />}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextInput source="name" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteArrayInput
            source="permissions"
            choices={permissions.map((p) => ({ id: p.id, name: p.name }))}
            optionText="name"
            fullWidth
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
