import { useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Button,
  FileField,
  FileInput,
  Form,
  ImageField,
  NumberInput,
  ReferenceInput,
  SimpleFormIterator,
  minValue,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import { buckets } from "../../../core/buckets";
import { PRODUCTS, QUOTES } from "../../../config/resources";
import { nanoid } from "nanoid";
import { uploadFile } from "../../../utils/file-upload";
import { QuoteStatus, quoteStatusNames } from "../constants";
import { AxiosError } from "axios";
import { FileCopy, Refresh } from "@mui/icons-material";
import { getRules } from "../rules";
import { ModalAction } from "../../../components/common/ModalAction";
import { Typography } from "@mui/material";
import { OptimizedImageInput } from "../../../components/elements/OptimizedImageInput";

export const QuoteQuotedAction = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const quote = useRecordContext();
  const [loading, setLoading] = useState(false);
  const [update] = useUpdate();

  if (!quote) return null;

  const setQuoted = async (values: any, onClose: () => void) => {
    setLoading(true);
    try {
      const products = values.products;
      let quoteFileUrl = values.quoteFileUrl.src;
      if (values.quoteFileUrl.rawFile) {
        quoteFileUrl = await uploadFile(
          buckets.quotes,
          QUOTES,
          nanoid(),
          values.quoteFileUrl.rawFile
        );
      }
      await update(QUOTES, {
        id: quote.id,
        data: { quoteFileUrl, products, status: QuoteStatus.enum.QUOTED },
        previousData: quote,
      });
      const msg = "La cotización fue actualizada correctamente";
      notify(msg, { type: "success" });
      refresh();
      onClose();
    } catch (error) {
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  return (
    <ModalAction
      buttonText="Cotizar"
      buttonDisabled={!getRules(quote).quoted}
      buttonIcon={<FileCopy />}
      dialogTitle="Cargar archivo de cotización"
      dialogContent={(onClose) => (
        <>
          <Form
            record={{
              products: quote.products,
              quoteFileUrl: { src: quote.quoteFileUrl },
            }}
            onSubmit={(values) => setQuoted(values, onClose)}
            id="form-quote-quoted"
          >
            <FileInput source="quoteFileUrl" validate={required()}>
              <FileField source="src" title="title" />
            </FileInput>
            <ArrayInput source="products" validate={required()}>
              <SimpleFormIterator
                fullWidth
                disableClear
                disableReordering
                disableRemove
                disableAdd
                getItemLabel={(index) => `#${index + 1}`}
                inline
                sx={{
                  ".RaSimpleFormIterator-inline > .ra-input": {
                    flex: 1,
                  },
                }}
              >
                <ReferenceInput source="productId" reference={PRODUCTS}>
                  <AutocompleteInput optionText="name" disabled />
                </ReferenceInput>
                <NumberInput
                  source="supplierPrice"
                  validate={[required(), minValue(0)]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Form>
          <Typography>
            La cotización se marcará como{" "}
            <b>{quoteStatusNames[QuoteStatus.enum.QUOTED]}</b>
          </Typography>
        </>
      )}
      dialogActions={
        <Button
          type="submit"
          variant="contained"
          size="medium"
          label="Guardar"
          form="form-quote-quoted"
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
      disableClose={loading}
    />
  );
};
