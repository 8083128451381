import {
  BooleanField,
  EditButton,
  ImageField,
  NumberField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useListParams,
  useRedirect,
  useStore,
} from "react-admin";
import { VideoField } from "../../components/VideoField";
import { useAdmin } from "../../hooks/admin";
import { COURSES, LECTURES } from "../../config/resources";
import { useEffect } from "react";
import { audienceTypes } from "../../data/education";
import { useParams } from "react-router-dom";
import { ResourceList } from "../resources";

const LectureShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>{hasPermission("lecture.update") && <EditButton />}</TopToolbar>
  );
};

export const LectureShow = () => {
  const { hasPermission } = useAdmin();
  const redirect = useRedirect();
  const { id } = useParams();

  const [lectureId, setLectureId] = useStore("currentLectureId");
  const [courseId] = useStore("currentCourseId");

  useEffect(() => {
    if (!courseId) {
      redirect("/" + COURSES);
    }
  }, [courseId]);

  useEffect(() => {
    if (id) setLectureId(id);
  }, [id]);

  if (!hasPermission("lecture.view")) return null;

  if (!courseId) return null;

  return (
    <Show
      resource={LECTURES}
      queryOptions={{
        meta: {
          dependentId: courseId,
        },
      }}
      actions={<LectureShowActions />}
    >
      <TabbedShowLayout>
        <Tab label="Principal">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          <SelectField source="audience" choices={audienceTypes} />
          <ImageField source="image.src" />
          <VideoField source="video.src" />
          <TextField source="instructorName" />
          <TextField source="instructorLastname" />
          <TextField source="instructorProfession" />
          <ImageField source="instructorPhoto.src" />
          <NumberField source="sequenceOrder" />
          <BooleanField source="status" />
        </Tab>
        <Tab label="Recursos">
          <ResourceList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
