import { Box, BoxProps } from "@mui/material";
import { get } from "lodash";
import { FC, ReactElement } from "react";
import { useRecordContext } from "react-admin";
import ModalImage from "react-modal-image";

type Props = BoxProps & { source: string; emptyContent?: ReactElement };

export const ModalImageField: FC<Props> = ({
  source,
  emptyContent,
  ...rest
}) => {
  const record = useRecordContext();

  if (!record || !source) return null;

  const url = get(record, source);

  if (!url) {
    return (
      emptyContent || <Box width={60} height={60} bgcolor="#ddd" {...rest} />
    );
  }

  return (
    <Box maxWidth={250} display="flex" {...rest}>
      <ModalImage small={[url]} large={[url]} alt="Image" />
    </Box>
  );
};
