import { getProductGroup, TProduct } from "@/api";
import { pick } from "lodash";
import * as yup from "yup";
import es from "yup-es";
import { PRODUCTS } from "../../../../config/resources";
import { dataProvider } from "../../../../providers";
import { parseProductOptionsToEntries } from "../shared/parse";
import { validateProductOptions } from "../shared/validation";

yup.setLocale(es);

export const getSchema = (fields: string[]) =>
  yup.object().shape(
    pick(
      {
        sku: yup.string().required(),
        name: yup.string().required(),
        description: yup.string().required(),
        longDescription: yup.string(),
        retailPrice: yup.number().required().positive(),
        barCode: yup.string().required(),
        height: yup.number().required().positive(),
        width: yup.number().required().positive(),
        length: yup.number().required().positive(),
        weight: yup.number().required().positive(),
        status: yup.boolean().required(),
        productOptions: yup
          .string()
          .test("product-options", async (value = "", context) => {
            let product;
            let productGroup;

            try {
              product = await getProductBySKU(context.parent.sku);
            } catch (error) {
              return context.createError({
                message: "El SKU es inválido",
              });
            }

            try {
              productGroup = await getProductGroup(product.groupProductId);
            } catch (error) {
              return context.createError({
                message: "El groupProductId es inválido",
              });
            }

            return validateProductOptions(productGroup)(value, context);
          }),
      },
      fields
    )
  );

export const serialize = async (payload: any) => {
  let { ...rest }: any = payload;

  if ("productOptions" in payload) {
    const productOptions = Object.fromEntries(
      parseProductOptionsToEntries(payload.productOptions as string)
    );
    rest = { ...rest, productOptions };
  }

  return rest;
};

async function getProductBySKU(sku: string) {
  const {
    data: [product],
  } = await dataProvider.getList<TProduct>(PRODUCTS, {
    filter: { sku },
    pagination: { page: 0, perPage: 1 },
    sort: { field: "createdAt", order: "ASC" },
  });
  return product;
}
