import md5 from "md5";

export const getMD5Checksum = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(md5(e.target?.result as string));
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
};
