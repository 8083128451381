import { Login } from "react-admin";
import { primaryAuth } from "../../core/firebase";
import { StyledFirebaseAuth } from "react-firebaseui";
import { GoogleAuthProvider } from "firebase/auth";

export const CustomLoginPage = () => (
  <Login
    style={{
      boxShadow: "inset 0px 0px 400px 110px rgb(0 0 0 / 70%)",
      padding: "1rem",
    }}
  >
    <div style={{ margin: "2rem auto" }}>
      <StyledFirebaseAuth
        uiCallback={(ui) => ui.disableAutoSignIn()}
        firebaseAuth={primaryAuth}
        uiConfig={{
          signInFlow: "popup",
          signInSuccessUrl: "/",
          signInOptions: [GoogleAuthProvider.PROVIDER_ID],
          adminRestrictedOperation: {
            status: true,
          },
        }}
      />
    </div>
  </Login>
);
