import { Box, Chip } from "@mui/material";
import { getMetadataTaxClassifications, TProduct } from "@/api";
import { useEffect, useState } from "react";
import {
  ArrayField,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  Labeled,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  UrlField,
  useRecordContext,
} from "react-admin";
import { ModalImageField } from "../../components/elements/ModalImageField";
import { SimpleChipField } from "../../components/SimpleChipField";
import {
  BRANDS,
  CATEGORIES,
  PRODUCTS,
  SUPPLIERS,
} from "../../config/resources";
import {
  productTypeChoices,
  supplierProductApproveStatus,
} from "../../data/products";
import { useAdmin } from "../../hooks/admin";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Link } from "react-router-dom";
import { ProductsField } from "./fields/products";
import { ApproveButton } from "./actions/approveButton";

const ProductGroupShowActions = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();

  return (
    <TopToolbar>
      <ApproveButton />
      {record && hasPermission("productGroup.update") && <EditButton />}
      {record && hasPermission("productGroup.delete") && (
        <DeleteWithConfirmButton />
      )}
    </TopToolbar>
  );
};

export const ProductGroupShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("productGroup.view")) return null;

  return (
    <Show actions={<ProductGroupShowActions />}>
      <TabbedShowLayout>
        <Tab label="Principal">
          <TextField source="id" />
          <TextField source="name" />
          <FunctionField
            source="approvedStatus"
            render={(group: any) => (
              <Chip
                color={
                  ["PENDING", "IN_REVIEW"].includes(group?.approvedStatus)
                    ? "error"
                    : "APPROVED" === group?.approvedStatus
                    ? "success"
                    : "warning"
                }
                label={
                  supplierProductApproveStatus.find(
                    ({ id }) => id === group.approvedStatus
                  )?.name ?? "Sin estado"
                }
                variant="outlined"
              />
            )}
          />
          <FunctionField
            source="type"
            render={(record: any) =>
              productTypeChoices.find((t) => t.id === record.type)?.name
            }
          />
          <ReferenceField
            source="supplierId"
            reference={SUPPLIERS}
            link={hasPermission("supplier.view") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="brandId"
            reference={BRANDS}
            link={hasPermission("brand.view") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="categoryId"
            reference={CATEGORIES}
            link={hasPermission("category.view") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>

          <FunctionField
            source="options"
            render={(record: any) =>
              Object.entries(record.options || {}).map(([name, values]) => (
                <Box key={name}>
                  {name}: {(values as string[]).join(", ")} <br />
                </Box>
              ))
            }
          />

          <NumberField source="taxPercentage" />

          <TaxClassificationProductField />
          <ArrayField source="taxesType">
            <SingleFieldList linkType={false}>
              <SimpleChipField />
            </SingleFieldList>
          </ArrayField>

          <BooleanField source="featured" />
          <BooleanField source="status" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Tab>

        <Tab label="Imágenes">
          <ArrayField source="images">
            <Datagrid bulkActionButtons={false}>
              <FunctionField
                label="Foto"
                render={() => <ModalImageField source="src" maxHeight={60} />}
              />
              <FunctionField
                label="Enlace"
                render={() => (
                  <UrlField source="src" label="Enlace" target="_blank" />
                )}
              />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Variantes">
          <CreateVariantButton />
          <ProductsField />
        </Tab>

        <Tab label="Categorías visibles">
          <ReferenceArrayField
            label="Categorías visibles"
            reference={CATEGORIES}
            source="visibleCategories"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Tab>

        <Tab label="Keywords">
          <ArrayField source="keyWords">
            <SingleFieldList linkType={false}>
              <SimpleChipField />
            </SingleFieldList>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const TaxClassificationProductField = () => {
  const [taxClassification, setTaxClassification] = useState<any>();
  const record = useRecordContext();

  useEffect(() => {
    getMetadataTaxClassifications().then((data) => {
      setTaxClassification(
        data.find((t: any) => t.code === record.taxClassification)
      );
    });
  }, []);

  if (!taxClassification) return null;

  return (
    <Labeled label="Clasificación de impuesto">
      <FunctionField render={() => taxClassification.name} />
    </Labeled>
  );
};

const CreateVariantButton = () => {
  const record = useRecordContext();
  return (
    <Box textAlign={"right"}>
      <Button
        component={Link}
        to={{
          pathname: `/${PRODUCTS}/create`,
          search: `?source=${JSON.stringify({ groupProductId: record.id })}`,
        }}
        label="Agregar producto"
        variant="contained"
        startIcon={<AddIcon />}
      />
    </Box>
  );
};
