import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NullableBooleanInput,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";
import { SUPPLIERS } from "@/config/resources";
import { offerTypes } from "@/data/offers";

const OffertFilter = [
  <SelectInput
    source="type"
    choices={offerTypes}
    optionValue="code"
    alwaysOn
  />,
  <NullableBooleanInput
    source="enabled"
    label="Estado"
    nullLabel="Todos"
    trueLabel="Activo"
    falseLabel="Inactivo"
    alwaysOn
  />,
  <TextInput source="name" label="Nombre" alwaysOn />,
  <ReferenceInput source="createdBy" reference={SUPPLIERS} alwaysOn>
    <AutocompleteInput
      label="Proveedor"
      filterToQuery={(searchText: string) => ({ name: searchText })}
      optionText="name"
    />
  </ReferenceInput>,
];

export const OffertList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("offert.list")) return null;

  return (
    <List
      perPage={100}
      filters={OffertFilter}
      filterDefaultValues={{ status: true }}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("offert.create")}
      sort={{ field: "createdAt", order: "desc" }}
    >
      <Datagrid
        rowClick={hasPermission("offert.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <DateField source="createdAt" />
        <BooleanField source="enabled" />
        <TextField source="name" />
        <FunctionField
          source="type"
          render={(record: any) =>
            offerTypes.find((o) => o.code === record.type)?.name
          }
        />
        <DateField source="endsAt" />
      </Datagrid>
    </List>
  );
};
