import { mdiCarCoolantLevel, mdiDelete } from "@mdi/js";
import { SvgIcon } from "@mui/material";
import { AxiosError, getSeeriApi } from "@/api";
import { FC } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import { useLimits } from "./use";

type Props = {
  applier: "CATEGORY" | "SUPPLIER" | "BRAND";
  type: "LIMIT" | "DISCOUNT";
  limitId: string;
};

export const DeleteLimitButton: FC<Props> = ({ applier, type, limitId }) => {
  const notify = useNotify();
  const { refreshLimits } = useLimits(applier, type);

  const name = type === "LIMIT" ? "límite" : "descuento escalonado";

  const onClick = async () => {
    if (!confirm("¿Estás seguro?")) return;

    try {
      await getSeeriApi().delete(`/api/limits/${limitId}`);
      notify(`Se eliminó el ${name} exitosamente`, { type: "success" });
      refreshLimits();
    } catch (error) {
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo eliminar", { type: "error" });
      }
    }
  };

  return (
    <>
      <Button
        startIcon={
          <SvgIcon>
            <path d={mdiDelete} />
          </SvgIcon>
        }
        label={"Eliminar"}
        onClick={onClick}
      />
    </>
  );
};
