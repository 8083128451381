import {
  ArrayField,
  BooleanField,
  CreateButton,
  Datagrid,
  EmailField,
  ExportButton,
  List,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { SimpleChipField } from "../../components/SimpleChipField";
import { ADMINROLES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";

const AdminFilter = [
  <TextInput source="email" alwaysOn />,
  <TextInput source="firstName" alwaysOn />,
  <TextInput source="lastName" alwaysOn />,
];

const AdminActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("admin.create") && <CreateButton />}
      {hasPermission("admin.export") && <ExportButton />}
    </TopToolbar>
  );
};

export const AdminList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("admin.list")) return null;

  return (
    <List
      perPage={20}
      filters={AdminFilter}
      pagination={<CustomPagination />}
      actions={<AdminActions />}
    >
      <Datagrid
        rowClick={hasPermission("admin.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <TextField source="firstName" />
        <TextField source="lastName" />
        <ReferenceField
          source="roleId"
          reference={ADMINROLES}
          link={hasPermission("adminrole.view") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>
        <EmailField source="email" />
        <ArrayField source="countries">
          <SingleFieldList linkType={false}>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
        <BooleanField source="status" />
      </Datagrid>
    </List>
  );
};
