import { getMetadataDocumentTypes } from "@/api";
import { FC, useEffect, useState } from "react";
import { AutocompleteInput, AutocompleteInputProps } from "react-admin";

export const DocumentTypeInput: FC<AutocompleteInputProps> = ({ ...props }) => {
  const [idTypes, setIdTypes] = useState([]);

  useEffect(() => {
    getMetadataDocumentTypes().then((documentTypes: any) => {
      setIdTypes(documentTypes);
    });
  }, []);

  return (
    <AutocompleteInput
      {...props}
      choices={idTypes}
      optionValue="code"
      optionText="name"
      disabled={!idTypes.length}
      fullWidth
    />
  );
};
