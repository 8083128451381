import {
  createPartialCourse,
  TPartialCoursePayload,
  TSource,
  updateCourse,
} from "@/api";
import { pick } from "lodash";

import { buckets } from "../../../core/buckets";
import { uploadFile } from "../../../utils/file-upload";

export const customCreateCourse = async (
  data: TPartialCoursePayload & { video: { rawFile: File } }
) => {
  const payload = pick(data, [
    "audience",
    "description",
    "image",
    "name",
    "status",
  ]);
  const newCourse = await createPartialCourse(payload);
  const url = await uploadFile(
    buckets.education,
    "Course",
    newCourse.id,
    data.video.rawFile
  );
  return updateCourse(newCourse.id, { video: url });
};

export const customEditCourse = async (
  courseId: string,
  data: TPartialCoursePayload & {
    video: { rawFile: File } & TSource;
  }
) => {
  if (data.video.rawFile) {
    const url = await uploadFile(
      buckets.education,
      "Course",
      courseId,
      data.video.rawFile
    );
    data.video.src = url;
  }

  return updateCourse(courseId, {
    ...data,
    video: data.video.src,
  });
};
