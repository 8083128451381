import { getCoinsBalanceBySellerId, TSeller } from "@/api";
import { Labeled, NumberField, TextField, useRecordContext } from "react-admin";
import useSWR from "swr";
import { useAdmin } from "../../../hooks/admin";
import { SellerCoinsTransactionAction } from "./_coins-create-transaction";
import { CoinsTransactionsList } from "./_coins-transaction-list";

export const CoinsBalance = () => {
  const seller = useRecordContext<TSeller>();

  const { data, error } = useSWR(
    () => [seller.id, "balance"],
    ([id]) => getCoinsBalanceBySellerId(id)
  );

  return (
    <Labeled label="Coins disponibles">
      {error ? (
        <>Error al cargar</>
      ) : !data ? (
        <>Cargando...</>
      ) : (
        <NumberField
          source="balance"
          record={data}
          color={data.balance > 0 ? "green" : "red"}
        />
      )}
    </Labeled>
  );
};

export const SellerCoinsTab = () => {
  const { hasPermission } = useAdmin();

  return (
    <>
      {hasPermission("seller.add.credits") && (
        <div>
          <SellerCoinsTransactionAction />
        </div>
      )}
      <div>
        <CoinsBalance />
      </div>
      <div>
        <CoinsTransactionsList />
      </div>
    </>
  );
};
