import { endOfDay } from "date-fns";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { FC } from "react";
import { DateInput, DateInputProps, DateTimeInput } from "react-admin";

type ISODateInputProps = DateInputProps & {
  eod?: boolean;
  time?: boolean;
};

export const ISODateInput: FC<ISODateInputProps> = ({
  eod,
  time,
  source,
  ...props
}) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return time ? (
    <DateTimeInput
      {...props}
      source={source}
      parse={(v: string) =>
        v
          ? eod
            ? endOfDay(zonedTimeToUtc(v, timeZone))
            : zonedTimeToUtc(v, timeZone)
          : ""
      }
      format={(v: string) =>
        v ? format(utcToZonedTime(v, timeZone), "yyyy-MM-dd'T'HH:mm") : ""
      }
      fullWidth
    />
  ) : (
    <DateInput
      {...props}
      source={source}
      parse={(v: string) =>
        v
          ? eod
            ? endOfDay(zonedTimeToUtc(v, timeZone))
            : zonedTimeToUtc(v, timeZone)
          : ""
      }
      format={(v: string) =>
        v ? format(utcToZonedTime(v, timeZone), "yyyy-MM-dd") : ""
      }
      fullWidth
    />
  );
};
