import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";

import { ADMINROLES, ADMINS } from "../config/resources";
import { isProd } from "../constants/env";

const configDev = {
  apiKey: "AIzaSyBEPsehUqoFXBQX8razE2S67yE86Ddi9Kc",
  authDomain: "seeri-dev.firebaseapp.com",
  projectId: "seeri-dev",
  storageBucket: "seeri-dev.appspot.com",
  messagingSenderId: "425297633803",
  appId: "1:425297633803:web:092af3b14f792b79d65d0e",
  measurementId: "G-84RY012EQS",
};

const configProd = {
  apiKey: "AIzaSyAIu6gSsfBFUipuK3ihd4lC5ZPKVKLrPVg",
  authDomain: "seeri-b81c0.firebaseapp.com",
  projectId: "seeri-b81c0",
  storageBucket: "seeri-b81c0.appspot.com",
  messagingSenderId: "292039451305",
  appId: "1:292039451305:web:315c1adec303eecbbdffb8",
  measurementId: "G-0SQXH7077N",
};

export const config = isProd ? configProd : configDev;

export const primaryApp = initializeApp(config);
export const primaryAuth = getAuth(primaryApp);

export const db = getFirestore(primaryApp);

export const secondaryApp = initializeApp(config, "secondary");
export const secondaryAuth = getAuth(secondaryApp);

export const adminsRef = collection(db, ADMINS);
export const adminRolesRef = collection(db, ADMINROLES);

export const emailExists = async (email: string) => {
  const list = await fetchSignInMethodsForEmail(primaryAuth, email);
  return !!list.length;
};

export const addLog = async (log: {
  data: string;
  date: Date;
  method: string;
  origin: string;
  status: number;
  url: string;
  userId?: string;
  role?: string;
}) => {
  await addDoc(collection(db, "Logs"), log);
};

const bulkSetPermissions = async (names: string[], descriptions: string[]) => {
  for (let i = 0; i < names.length; i++) {
    const name = names[i];
    const description = descriptions[i];
    await setDoc(doc(db, "Permissions", name), {
      name,
      description,
    });
    console.log("Added:", name, description);
  }
};

(window as any).bulkSetPermissions = bulkSetPermissions;
