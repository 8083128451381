import { ModalAction } from "@/components/common/ModalAction";
import Visibility from "@mui/icons-material/Visibility";
import { FC } from "react";
import { SellerInvoiceAddressShow } from "./show";

export const ShowSellerInvoiceAddressAction: FC<{
  billingAddressId: string;
}> = ({ billingAddressId }) => {
  return (
    <ModalAction
      dialogTitle="Detalles de dirección de facturación"
      buttonText="Ver"
      buttonIcon={<Visibility />}
      dialogContent={() => (
        <SellerInvoiceAddressShow billingAddressId={billingAddressId} />
      )}
      dialogContentSx={{ p: 0 }}
    />
  );
};
