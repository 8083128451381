import { Chip } from "@mui/material";
import { TListedTicket } from "@/api";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  RecordContextProvider,
  ReferenceField,
  TextField,
} from "react-admin";
import { PURCHASES } from "../../config/resources";
import { ticketStatuses } from "../../data/purchases";
import { useAdmin } from "../../hooks/admin";
import { formatStatusColor } from "../../hooks/purchase";

export const SellerTicketHistory = ({
  tickets,
}: {
  tickets: TListedTicket[];
}) => {
  return (
    <RecordContextProvider value={{ tickets: tickets }}>
      <Labeled label={"Últimos tickets"}>
        <TicketHistoryTable source="tickets" />
      </Labeled>
    </RecordContextProvider>
  );
};

type TTicketHistoryTable = {
  source: string;
};

const TicketHistoryTable = ({ source }: TTicketHistoryTable) => {
  const { hasPermission } = useAdmin();

  return (
    <ArrayField source={source}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="number" sortable={false} />
        <DateField source="createdAt" showTime />
        <ReferenceField
          source="purchaseNumber"
          reference={PURCHASES}
          link={hasPermission("purchase.view") ? "show" : false}
        >
          <TextField source="purchaseNumber" />
        </ReferenceField>
        <FunctionField
          source="status"
          render={(record: any) => (
            <Chip
              size="small"
              variant="outlined"
              color={formatStatusColor(record.status)}
              sx={{ width: "fit-content" }}
              label={ticketStatuses.find((s) => s.code === record.status)?.name}
            />
          )}
        />
      </Datagrid>
    </ArrayField>
  );
};
