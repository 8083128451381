import { FC } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { Divider, Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import {
  formatChoices,
  redirectToTypeChoices,
  statusChoices,
  TBannerType,
  typeChoices,
  typesResources,
} from "./constants";
import { ISODateInput } from "../../components/elements/ISODateInput";
import { isBefore } from "date-fns";
import { OptimizedImageInput } from "../../components/elements/OptimizedImageInput";
import { SUPPLIERS } from "@/config/resources";

export const BannerForm: FC = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();

  if (!record && !hasPermission("banner.create")) return null;
  if (record && !hasPermission("banner.update")) return null;

  const customRecord = !record
    ? undefined
    : {
        ...record,
        images: {
          webImage: { src: record.images.webImage },
          mobileImage: { src: record.images.mobileImage },
        },
      };

  return (
    <SimpleForm
      record={customRecord}
      mode="onBlur"
      defaultValues={{ typeIds: [] }}
      warnWhenUnsavedChanges
    >
      <Grid container>
        <Grid item xs={8}>
          <ReferenceInput
            source="supplierId"
            reference={SUPPLIERS}
            sort={{ field: "name", order: "ASC" }}
            perPage={100}
            alwaysOn
          >
            <AutocompleteInput
              label="Proveedor"
              optionText="name"
              filterToQuery={(searchText: string) => ({ name: searchText })}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={8}>
          <TextInput source="name" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={8}>
          <TextInput source="description" fullWidth />
        </Grid>
        <Grid item xs={8}>
          <SelectInput
            source="type"
            choices={typeChoices}
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <SelectInput
            source="format"
            choices={formatChoices}
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput source="text" fullWidth />
        </Grid>
        <TypeIdsInput />
        <Grid item xs={8}>
          <SelectInput
            source="status"
            choices={statusChoices}
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <ISODateInput source="availableSince" validate={required()} time />
        </Grid>
        <Grid item xs={8}>
          <ISODateInput
            source="availableUntil"
            validate={[
              required(),
              (availableUntil, { availableSince }) =>
                isBefore(availableUntil, availableSince)
                  ? "Debe ser posterior a `Disponible desde`"
                  : undefined,
            ]}
            time
          />
        </Grid>
        <Grid item xs={8}>
          <OptimizedImageInput
            source="images.webImage"
            width={1440}
            height={240}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <OptimizedImageInput
            source="images.mobileImage"
            width={720}
            height={240}
            validate={[required()]}
            fullWidth
          />
        </Grid>

        <Grid item xs={8}>
          <NumberInput
            source="priority"
            validate={required()}
            defaultValue={0}
            fullWidth
            helperText="Mientras más alto el número, mayor prioridad."
          />
        </Grid>

        <RedirectInputs />
      </Grid>
    </SimpleForm>
  );
};

const TypeIdsInput = () => {
  const type = useWatch({ name: "type" }) as TBannerType;

  if (!type || type === "HOME") return null;

  return (
    <Grid item xs={8}>
      <ReferenceArrayInput source="typeIds" reference={typesResources[type]}>
        <AutocompleteArrayInput
          filterToQuery={(searchText: string) => ({ name: searchText })}
          defaultValue={[]}
          optionText="name"
          fullWidth
        />
      </ReferenceArrayInput>
    </Grid>
  );
};

const RedirectInputs = () => {
  const type = useWatch({ name: "redirectTo.type" });

  return (
    <>
      <Grid item xs={8} my={2}>
        <Divider />
      </Grid>
      <Grid item xs={8}>
        <SelectInput
          source="redirectTo.type"
          choices={redirectToTypeChoices}
          validate={required()}
          fullWidth
        />
      </Grid>
      {type === "EXTERNAL" && (
        <Grid item xs={8}>
          <TextInput
            source="redirectTo.target"
            defaultValue="https://"
            helperText={
              <>
                URL de la página <b>fuera de Seeri</b> a la cual se desea
                redireccionar.
              </>
            }
            fullWidth
          />
        </Grid>
      )}
      {type === "INTERNAL" && (
        <>
          <Grid item xs={8}>
            <TextInput
              source="redirectTo.data.targetWeb"
              defaultValue={"https://seeri.store/"}
              helperText={
                <>
                  URL de la <b>página interna de la web de Seeri</b> a la cual
                  se quiere enlazar.
                </>
              }
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <TextInput
              source="redirectTo.data.targetMobile"
              validate={required()}
              defaultValue="Seeri://com.seeri.app/"
              helperText={
                "Preguntar al equipo mobile por el deep link correspondiente."
              }
              fullWidth
            />
          </Grid>
        </>
      )}
    </>
  );
};
