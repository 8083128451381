import { getProductGroup } from "@/api";
import * as yup from "yup";
import es from "yup-es";
import { generateSlug } from "../../../../utils/slug";
import { parseProductOptionsToEntries } from "../shared/parse";
import { validateProductOptions } from "../shared/validation";

yup.setLocale(es);

export const schema = yup.object().shape({
  groupProductId: yup.string().required(),
  name: yup.string().required(),
  description: yup.string().required(),
  longDescription: yup.string().required(),
  stock: yup.number().required().integer().min(0),
  retailPrice: yup.number().required().positive(),
  wholesalePrice: yup
    .number()
    .required()
    .positive()
    .when("retailPrice", (retailPrice, yup) => yup.max(retailPrice)),
  barCode: yup.string().required(),
  height: yup.number().required().positive(),
  width: yup.number().required().positive(),
  length: yup.number().required().positive(),
  weight: yup.number().required().positive(),
  supplierSku: yup.string().required(),
  supplierPrice: yup.number().required().positive(),
  locationType: yup
    .string()
    .required()
    .oneOf(["SEERI_STORAGE", "SUPPLIER_STORAGE"]),
  productOptions: yup
    .string()
    .test("product-options", async (value = "", context) => {
      let productGroup;
      try {
        productGroup = await getProductGroup(context.parent.groupProductId);
      } catch (error) {
        return context.createError({
          message: "El groupProductId es inválido",
        });
      }
      return validateProductOptions(productGroup)(value, context);
    }),
});

export const serialize = async (payload: typeof schema.__outputType) => {
  const productOptions = Object.fromEntries(
    parseProductOptionsToEntries(payload.productOptions)
  );

  return {
    ...payload,
    productOptions,
    status: false,
    marginSeeri: 100,
    type: "PRODUCT",
    images: [],
    slug: generateSlug(`${payload.name}-${payload.productOptions}`),
  };
};
