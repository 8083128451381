import { Create } from "react-admin";
import { buckets } from "../../core/buckets";
import { useAdmin } from "../../hooks/admin";
import { uploadFile } from "../../utils/file-upload";
import { getMD5Checksum } from "../../utils/md5-checksum";
import { VoucherForm } from "./_form";

export const VoucherCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("voucher.create")) return null;

  const transform = async (data: any) => {
    const rawFile = data.url?.rawFile;
    if (!rawFile) throw new Error("Archivo requerido");
    const hash = await getMD5Checksum(rawFile);
    const url = await uploadFile(buckets.purchases, "Voucher", hash, rawFile);
    return { ...data, url, hash };
  };

  return (
    <Create redirect="show" transform={transform}>
      <VoucherForm />
    </Create>
  );
};
