import { useEffect } from "react";
import { Create, useRedirect, useStore } from "react-admin";
import { LECTURES, RESOURCES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { ResourceForm } from "./_form";

export const ResourceCreate = () => {
  const { hasPermission } = useAdmin();
  const redirect = useRedirect();

  const [lectureId] = useStore("currentLectureId");

  useEffect(() => {
    if (!lectureId) {
      redirect("/" + LECTURES);
    }
  }, [lectureId]);

  if (!hasPermission("lecture.create")) return null;

  if (!lectureId) return null;

  return (
    <Create
      resource={RESOURCES}
      title={`Crear Recurso de Clase #${lectureId}`}
      redirect="show"
      mutationOptions={{
        meta: {
          dependentId: lectureId,
        },
      }}
    >
      <ResourceForm />
    </Create>
  );
};
