import { ReactNode } from "react";
import { formatCurrency } from "../utils/currency";

/**
 * @param {number} missingValue Purchase general status
 */
export const formatProductLimitText = (
  limitType: string,
  missingValue: number,
  limitValue: number
) => {
  const missingFormatted = formatCurrency(missingValue);
  const limitFormatted = formatCurrency(limitValue);
  if (missingValue > 0) {
    const logProductLimitText: { [key: string]: () => ReactNode } = {
      NONE: () => "Tus productos están listos para procesar",
      AMOUNT: () => (
        <>
          Faltan <strong>{missingFormatted}</strong> para completar la compra
          mínima.
          <br />
          El monto mínimo es <strong>{limitFormatted}</strong>
        </>
      ),
      UNITS: () => (
        <>
          Faltan {missingValue} unidades para completar la compra mínima de
          estos productos.
          <br /> La cantidad mínima es {limitValue}
        </>
      ),
      DEFAULT: () => "",
    };
    return (
      logProductLimitText[limitType]?.() || logProductLimitText["DEFAULT"]()
    );
  } else {
    return "Tus productos están listos para procesar";
  }
};
