import {
  Box,
  Dialog,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { MouseEvent, useRef, useState, ChangeEvent } from "react";
import { Button, useRefresh } from "react-admin";
import Upload from "@mui/icons-material/Upload";
import FileCopy from "@mui/icons-material/FileCopy";
import CloudUpload from "@mui/icons-material/CloudUpload";
import * as XLSX from "xlsx";
import { UpdateProcess } from "./subpurchase-update/process";
import { UpdateTemplate } from "./subpurchase-update/template";

export const PurchasesImportButton = () => {
  return (
    <>
      <PurchasesImportMenu />
    </>
  );
};

const PurchasesImportMenu = () => {
  const refresh = useRefresh();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [mode, setMode] = useState<
    "create" | "update" | "update-template" | null
  >();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rows, setRows] = useState<any[] | null>(null);
  const [headers, setHeaders] = useState<string[] | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFileAsync = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
    const rows = XLSX.utils.sheet_to_json(worksheet);
    setHeaders(headers as string[]);
    setRows(rows);
    setDialogOpen(true);
    e.target.value = "";
  };

  const handleClose = () => {
    setDialogOpen(false);
    setRows(null);
    setHeaders(null);
    setMode(null);
    refresh();
  };

  return (
    <>
      <Button
        label="Importar"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<Upload />}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuList>
          <Box sx={{ px: 2, pb: 1, textTransform: "uppercase" }}>
            <strong>Subpedidos: Actualización</strong>
          </Box>
          <MenuItem
            onClick={() => {
              setMode("update-template");
              setDialogOpen(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <FileCopy />
            </ListItemIcon>
            <ListItemText>Descargar plantilla</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setMode("update");
              fileInput.current!.click();
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <CloudUpload />
            </ListItemIcon>
            <ListItemText>Cargar archivo</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>

      <input
        ref={fileInput}
        type="file"
        onChange={handleFileAsync}
        style={{ display: "none" }}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />

      <Dialog
        open={dialogOpen}
        onClose={(event, reason) => {
          if (reason) return;
          handleClose();
        }}
        fullWidth
        maxWidth="md"
      >
        {mode === "update" && headers && rows && (
          <UpdateProcess headers={headers} rows={rows} onEnd={handleClose} />
        )}

        {mode === "update-template" && <UpdateTemplate onEnd={handleClose} />}
      </Dialog>
    </>
  );
};
