import { TDeliveryProvider } from "@/api";
import { pick } from "lodash";
import * as yup from "yup";
import es from "yup-es";

yup.setLocale(es);

export const getSchema = (
  fields: string[],
  deliveryProviders: TDeliveryProvider[]
) =>
  yup.object().shape(
    pick(
      {
        subPurchaseNumber: yup.string().required(),
        deliveryProvider: yup
          .string()
          .required()
          .oneOf(deliveryProviders.map((p) => p.code)),
        trackingCode: yup.string().required(),
        deliveryStatus: yup
          .string()
          .required()
          .oneOf(["PENDING", "IN_TRANSIT", "DELIVERED", "REJECTED"]),
        deliveryCost: yup.number().required().min(0),
      },
      fields
    )
  );
