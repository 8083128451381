import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text";
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  Form,
  minValue,
  NumberInput,
  required,
  TextInput,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { OptimizedImageInput } from "../../../components/elements/OptimizedImageInput";
import { SlugInput } from "../../shared/slug";
import { KitProductsReferenceArrayInput } from "./kit";
import { FC, useEffect, useState } from "react";
import { TOption } from "../../productgroups/inputs/options";
import useSWR from "swr";
import { TCategory, TProduct, getSeeriApi } from "@/api";
import { Add } from "@mui/icons-material";
import { difference } from "lodash";
import { useFormContext } from "react-hook-form";
import { ModalAction } from "@/components/common/ModalAction";
import { CATEGORIES } from "@/config/resources";

export const uniqueOptionCombination = (products: any[] = [], target?: any) => {
  const serialize = (p: any) =>
    Object.entries(p?.productOptions ?? {})
      .map(([k, v]) => `${k}: ${v}`)
      .join(" + ");

  const mapping = products.map(serialize);

  if (target) {
    const combination = serialize(target);
    return mapping.includes(combination)
      ? "Esta combinación de opciones ya existe: " + combination
      : undefined;
  } else {
    const duplicates = mapping.filter(
      (item, index) => mapping.indexOf(item) != index
    );

    return duplicates.length
      ? "Hay opciones duplicadas: " + duplicates.join(" | ")
      : undefined;
  }
};

type ProductGroupDeps = {
  options: TOption[];
  type: string;
  supplierId: string;
  categoryId: string;
};

export const ProductInputs: FC<{
  getSource?: (v: string) => string;
  groupDeps: ProductGroupDeps;
}> = ({ getSource = (v) => v, groupDeps }) => {
  const product = useRecordContext<TProduct>();
  const [newOptions, setNewOptions] = useState<TOption[]>([]);
  const allOptions = [...groupDeps.options, ...newOptions];
  const { setValue } = useFormContext();

  const { data: category } = useGetOne<TCategory>(
    CATEGORIES,
    { id: groupDeps.categoryId ?? product?.categoryId },
    { enabled: Boolean(groupDeps.categoryId || product?.categoryId) }
  );

  const optionsToBeDeleted = Object.keys(product.productOptions).filter(
    (key) => !allOptions.map((o) => o.name).includes(key)
  );

  useEffect(() => {
    setValue("optionsToBeDeleted", optionsToBeDeleted);
  }, [optionsToBeDeleted]);

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          {allOptions.map((option) => (
            <Grid key={option.name} item xs={12} md={6} lg={4}>
              <ProductOptionInput
                source={getSource("productOptions." + option.name)}
                option={option}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: "1rem" }}>
        <AddProductOptionButton
          currentOptionNames={allOptions.map((o) => o.name)}
          onDone={(name: string) =>
            setNewOptions((prev) => [...prev, { name, values: [] }])
          }
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: "1rem" }}>
        {optionsToBeDeleted.length > 0 && (
          <Alert variant="filled" severity="warning">
            <AlertTitle>
              Las siguientes opciones ya no existen en el grupo y se eliminarán
              al guardar el formulario
            </AlertTitle>
            <TableContainer component={Paper}>
              <Table sx={{ maxWidth: 400 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {optionsToBeDeleted.map((o) => (
                    <TableRow key={o}>
                      <TableCell>{o}</TableCell>
                      <TableCell>{product.productOptions[o]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Alert>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          source={getSource("name")}
          validate={required()}
          helperText="Nombre asignado por el proveedor"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SlugInput
          source={getSource("slug")}
          stringSource={getSource("name")}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextInput
          source={getSource("description")}
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput source={getSource("barCode")} fullWidth />
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            "& .ProseMirror": {
              minHeight: 100,
            },
          }}
        >
          <RichTextInput
            fullWidth
            source={getSource("longDescription")}
            toolbar={<RichTextInputToolbar size="large" />}
          />
        </Box>
      </Grid>

      <Grid item xs={6} md={3}>
        <NumberInput
          source={getSource("height")}
          defaultValue={20}
          helperText="Si tienes el valor real, ponlo."
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source={getSource("width")}
          defaultValue={20}
          helperText="Si tienes el valor real, ponlo."
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source={getSource("length")}
          defaultValue={20}
          helperText="Si tienes el valor real, ponlo."
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source={getSource("weight")}
          defaultValue={1}
          helperText="Si tienes el valor real, ponlo."
          validate={required()}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} my={2} />
      <Grid item xs={6} md={3}>
        <NumberInput
          source={getSource("retailPrice")}
          validate={[minValue(1, "Debe ser mayor a 0")]}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <BooleanInput
          source={getSource("partialUnitsAllowed")}
          helperText="Habilita la cantidad decimal al crear pedidos."
          defaultValue={false}
          validate={required()}
        />
      </Grid>

      <Grid item xs={12} my={2} />

      <Grid item xs={12}>
        <KitProductsReferenceArrayInput
          getSource={getSource}
          type={groupDeps.type}
        />
      </Grid>

      <Grid item xs={12}>
        <OptimizedImageInput
          source={getSource("images")}
          multiple
          width={800}
          height={800}
          fullWidth
          defaultValue={[]}
        />
      </Grid>

      <Grid item xs={12}>
        <Box
          display="flex"
          width={"full"}
          minHeight={0}
          flexWrap={"wrap"}
          justifyContent="space-evenly"
        >
          <BooleanInput
            disabled={["PENDING", "IN_REVIEW"].includes(
              product?.approvedStatus
            )}
            source={getSource("featured")}
            defaultValue={false}
          />
          <BooleanInput
            disabled={
              ["PENDING", "IN_REVIEW"].includes(product?.approvedStatus) ||
              // Deshabilitado si el producto está apagado y su categoría también.
              (!product ? false : !product.status && !category?.status)
            }
            source={getSource("status")}
            defaultValue={false}
            helperText={
              <>
                El estado no se puede cambiar si el producto está pendiente o en
                revisión.
                <br />
                El producto no se puede prender si su categoría está apagada.
              </>
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const ProductOptionInput: FC<{ source: string; option: TOption }> = ({
  source,
  option,
}) => {
  const { unregister } = useFormContext();
  const [newValues, setNewValues] = useState<string[]>([]);

  const { data: options, isLoading } = useSWR<Record<string, string[]>>(
    "options",
    () =>
      getSeeriApi()
        .get("/api/products/group/options")
        .then((res) => res.data)
  );

  const optionValues = difference(options?.[option.name] ?? [], option.values);

  const allValues = [...option.values, ...optionValues, ...newValues];

  useEffect(() => {
    return () => {
      unregister(source);
    };
  }, []);

  return (
    <AutocompleteInput
      source={source}
      label={option.name}
      choices={allValues.map((s) => ({ id: s, name: s }))}
      onCreate={(filter = "") => {
        const newChoice = {
          id: filter,
          name: filter,
        };
        setNewValues((prev) => [...prev, filter]);
        return newChoice;
      }}
      isLoading={isLoading}
      validate={required()}
      fullWidth
    />
  );
};

const AddProductOptionButton: FC<{
  currentOptionNames: string[];
  onDone: Function;
}> = ({ onDone, currentOptionNames }) => {
  const { data: options = [] } = useSWR<Record<string, string[]>>(
    "options",
    () =>
      getSeeriApi()
        .get("/api/products/group/options")
        .then((res) => res.data)
  );

  const optionNames = difference(Object.keys(options), currentOptionNames);

  const [newOptions, setNewOptions] = useState<string[]>([]);

  return (
    <>
      <ModalAction
        buttonText="Agregar opción"
        buttonIcon={<Add />}
        dialogTitle="Nueva opción"
        dialogMaxWidth="xs"
        dialogContent={(onClose) => (
          <Form
            onSubmit={(values) => {
              onDone(values.newOptionName);
              onClose();
            }}
          >
            <AutocompleteInput
              source="newOptionName"
              label="Nombre"
              choices={[...optionNames, ...newOptions].map((name) => ({
                id: name,
                name,
              }))}
              onCreate={(filter = "") => {
                const newChoice = {
                  id: filter,
                  name: filter,
                };
                setNewOptions((prev) => [...prev, filter]);
                return newChoice;
              }}
              validate={[
                (value) =>
                  currentOptionNames.includes(value)
                    ? "Ya se está usando esta opción"
                    : undefined,
              ]}
            />
            <Button
              type="submit"
              label="Guardar"
              variant="contained"
              size="medium"
            />
          </Form>
        )}
      />
    </>
  );
};
