import {
  ChipField,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  Labeled,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { ModalImageField } from "../../components/elements/ModalImageField";
import { useAdmin } from "../../hooks/admin";
import {
  redirectToTypeChoices,
  statusChoices,
  TBannerType,
  typeChoices,
  typesResources,
} from "./constants";
import { SUPPLIERS } from "@/config/resources";

const BannerShowActions = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();

  return (
    <TopToolbar>
      {record && hasPermission("banner.update") && <EditButton />}
      {record && hasPermission("banner.delete") && <DeleteWithConfirmButton />}
    </TopToolbar>
  );
};

export const BannerShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("banner.view")) return null;

  return (
    <Show actions={<BannerShowActions />}>
      <SimpleShowLayout>
        <ReferenceField source="supplierId" reference={SUPPLIERS}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" />
        <TextField source="format" />
        <TextField source="text" />
        <TextField source="name" />
        <TextField source="description" />
        <FunctionField
          source="type"
          render={(record: any) =>
            typeChoices.find((c) => c.id === record.type)?.name
          }
        />
        <TypeIdsField />
        <DateField source="availableSince" showTime />
        <DateField source="availableUntil" showTime />
        <ModalImageField source="images.webImage" />
        <ModalImageField source="images.mobileImage" />
        <NumberField source="priority" />
        <FunctionField
          source="redirectTo.type"
          render={(record: any) =>
            redirectToTypeChoices.find((c) => c.id === record.redirectTo?.type)
              ?.name
          }
        />
        <TextField source="redirectTo.target" />
        <FunctionField
          source="status"
          render={(record: any) =>
            statusChoices.find((c) => c.id === record.status)?.name
          }
        />
      </SimpleShowLayout>
    </Show>
  );
};

const TypeIdsField = () => {
  const record = useRecordContext();

  if (!record) return null;

  const type = record.type as TBannerType;

  if (type === "HOME") return null;

  const filter =
    record.type === "SUB_CATEGORIES" ? { parentCategoryId: null } : {};

  return (
    <Labeled>
      <ReferenceArrayField
        source="typeIds"
        reference={typesResources[type]}
        filter={filter}
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Labeled>
  );
};
