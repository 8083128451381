import { Button, useNotify, useRefresh, useRecordContext } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useCallback, useState } from "react";
import { AxiosError, getSubpurchasePaymentLink, TSubPurchase } from "@/api";
import { LinkSharp } from "@mui/icons-material";

export const SubpurchasePaymentLinkButton = () => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const subpurchase = useRecordContext<TSubPurchase>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = useCallback(async () => {
    try {
      await getSubpurchasePaymentLink(subpurchase.id);
      notify("Se generó el enlace de pago.", { type: "success" });
      refresh();
      setOpen(false);
    } catch (error) {
      console.log({ error });
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo generar el enlace de pago", { type: "error" });
      }
      return;
    } finally {
      setOpen(false);
    }
  }, [subpurchase]);

  return (
    <>
      <Tooltip title="Generar enlace de pago">
        <div>
          <IconButton
            color="primary"
            onClick={handleClickOpen}
            disabled={
              subpurchase.paymentMethod === "UPON_DELIVERY" ||
              Boolean(subpurchase.paymentLink) ||
              subpurchase.paymentStatus === "PAID"
            }
          >
            <LinkSharp />
          </IconButton>
        </div>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>Generar enlace de pago</DialogTitle>
        <DialogActions>
          <Button
            label="Confirmar"
            variant="contained"
            onClick={onConfirm}
            size="medium"
          />
          <Button label="Cancelar" onClick={handleClose} size="medium" />
        </DialogActions>
      </Dialog>
    </>
  );
};
