export const productTypeChoices = [
  { id: "PRODUCT", name: "Producto" },
  { id: "KIT", name: "Kit" },
  { id: "GIFT", name: "Regalo" },
];

export const productLocationTypeChoices = [
  { id: "SUPPLIER_STORAGE", name: "Almacén del proveedor" },
  { id: "SEERI_STORAGE", name: "Almacén de Seeri" },
];

export const supplierProductApproveStatus = [
  { id: "IN_REVIEW", name: "En revisión" },
  { id: "APPROVED", name: "Aprobado" },
  { id: "PENDING", name: "Pendiente" },
];
