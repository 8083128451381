import {
  AxiosError,
  TProductSupplier,
  TSupplier,
  getProductsV2,
  getSeeriApi,
} from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import { useAdmin } from "@/hooks/admin";
import { Alert, AlertTitle, Box, LinearProgress } from "@mui/material";
import { format } from "date-fns";
import { useRef, useState } from "react";
import { Button, useNotify, useRecordContext, useRefresh } from "react-admin";
import * as XLSX from "xlsx";
import { ZodIssue, z } from "zod";

const ExcelRow = z.object({
  SKU: z.string(),
  Marca: z.string(),
  Nombre: z.string(),
  PVSP: z.number(),
  Stock: z.number().optional(),
  Estado: z.enum(["Habilitado", "Deshabilitado"]),
});

const ExcelRowNotPriceList = ExcelRow.extend({
  Precio: z.number().optional(),
});
// .refine((arg) => (arg.Precio ? arg.PVSP >= arg.Precio : true), {
//   message: "No puede ser mayor que el PVSP",
//   path: ["Precio"],
// });

const ExcelRowPriceList = ExcelRow.extend({
  "Precio de compra a distribuidor": z.number(),
  "Precio al por mayor": z.number().optional(),
}).refine(
  (arg) =>
    arg["Precio al por mayor"]
      ? arg["Precio al por mayor"] >= arg["Precio de compra a distribuidor"]
      : true,
  {
    message: "No puede ser menor que el Precio de compra a distribuidor",
    path: ["Precio al por mayor"],
  }
);
// .refine(
//   (arg) =>
//     arg["Precio al por mayor"]
//       ? arg.PVSP >= arg["Precio al por mayor"]
//       : true,
//   {
//     message: "No puede ser mayor que el PVSP",
//     path: ["Precio al por mayor"],
//   }
// );

type ExcelRow = z.infer<typeof ExcelRow>;
type ExcelRowNotPriceList = z.infer<typeof ExcelRowNotPriceList>;
type ExcelRowPriceList = z.infer<typeof ExcelRowPriceList>;

enum EValidationStatus {
  PENDING = "Pendiente",
  VALIDATING = "Validando... 🕝",
  SUCCESS = "Éxito ✅",
  ERROR = "Error ❌",
}

enum EUploadStatus {
  PENDING = "Pendiente",
  IN_PROGRESS = "En progreso... 🕝",
  DONE = "Finalizado ✅",
}

type UploadError = { row: number; message: string };

export const SupplierProductsBulkUpdateAction = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { admin } = useAdmin();
  const supplier = useRecordContext<TSupplier>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [validation, setValidation] = useState(EValidationStatus.PENDING);
  const [validationErrors, setValidationErrors] = useState<ZodIssue[]>([]);
  const [uploadStatus, setUploadStatus] = useState(EUploadStatus.PENDING);
  const [uploadErrors, setUploadErrors] = useState<UploadError[]>([]);
  const [productRows, setProductRows] = useState<ExcelRow[] | null>(null);
  const [progress, setProgress] = useState(0);

  const isPriceList = supplier.negotiationType === "PRICE_LIST";

  const clear = () => {
    setUploadErrors([]);
    setProductRows(null);
    setValidation(EValidationStatus.PENDING);
    setValidationErrors([]);
    setUploadStatus(EUploadStatus.PENDING);
    setProgress(0);
  };

  return (
    <ModalAction
      disableClose={uploadStatus === EUploadStatus.IN_PROGRESS}
      buttonDisabled={supplier.productsAdministration}
      buttonText="Actualización masiva"
      dialogTitle="Actualización masiva"
      dialogContent={
        <div>
          {isPriceList && (
            <Alert
              severity="info"
              sx={{ padding: "6px 10px", margin: "8px 0" }}
            >
              Por tener tipo de negociación <b>Lista de precios</b>, las reglas
              son especiales. Están resaltadas de azul.
            </Alert>
          )}
          <ol>
            <li>
              <p style={{ margin: "8px 0" }}>
                <b>Descarga</b> la plantilla de actualización, esta contiene
                todos los productos del proveedor.
              </p>
              <ul
                style={{
                  paddingLeft: "12px",
                  margin: "8px 0",
                  fontSize: "14px",
                }}
              >
                <li>
                  El <i>SKU</i> interno sirve para identificar el producto a
                  actualizar.
                </li>
                <li>
                  La <i>Marca</i> solo sirve de guía. Se ignora durante la
                  carga, no se debería tocar.
                </li>
                <li>
                  El <i>Nombre</i> solo sirve de guía. Se ignora durante la
                  carga, no se debería tocar.
                </li>
                <li>
                  El <i>PVSP</i> (Precio de Venta Sugerido al Público) solo
                  sirve de guía. Se ignora durante la carga, no se debería
                  tocar.
                </li>
                <li>
                  El <i>Stock</i> es opcional y viene precargado con el actual.
                  Si no se desea actualizar un stock, se debe limpiar su celda.
                </li>
                {isPriceList ? (
                  <>
                    <li
                      style={{ color: "#014361", backgroundColor: "#e5f6fd" }}
                    >
                      El <i>Precio de compra a distribuidor</i> viene precargado
                      y es requerido.
                    </li>
                    <li
                      style={{ color: "#014361", backgroundColor: "#e5f6fd" }}
                    >
                      El <i>Precio al por mayor</i> viene vacío y es opcional.
                      Si se llena, debe ser <b>mayor</b> que el{" "}
                      <i>Precio de compra a distribuidor</i>.
                    </li>
                  </>
                ) : (
                  <li>
                    El <i>Precio</i> es opcional y viene precargado con el
                    actual. Si no se desea actualizar un precio, se debe limpiar
                    su celda.
                  </li>
                )}
                <li>
                  El <i>Estado</i> es requerido, puede ser <i>Habilitado</i> o{" "}
                  <i>Deshabilitado</i>.
                </li>
              </ul>
              <div>
                <Button
                  label="Descargar plantilla"
                  variant="outlined"
                  size="medium"
                  onClick={() =>
                    downloadTemplate(supplier).catch((error) =>
                      notify(error.message)
                    )
                  }
                />
              </div>
            </li>
            <li>
              <p style={{ margin: "8px 0" }}>
                <b>Carga la plantilla actualizada</b>. Se tomará solo la primera
                página del archivo excel.
              </p>
              <div>
                <Button
                  label="Cargar plantilla actualizada"
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    clear();
                    fileInputRef.current!.value = "";
                    fileInputRef.current!.click();
                  }}
                  disabled={uploadStatus !== EUploadStatus.PENDING}
                />
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx"
                  onChange={async (event) => {
                    if (!event.target.files) return;
                    const file = event.target.files[0];
                    try {
                      const rows = await loadTemplate(file);
                      setValidation(EValidationStatus.VALIDATING);
                      const Schema = isPriceList
                        ? ExcelRowPriceList
                        : ExcelRowNotPriceList;
                      const result = Schema.array().safeParse(rows);
                      if (result.success) {
                        setValidation(EValidationStatus.SUCCESS);
                        setProductRows(result.data);
                      } else {
                        setValidation(EValidationStatus.ERROR);
                        setValidationErrors(result.error.issues);
                      }
                    } catch (error) {
                      if (error instanceof Error)
                        notify(error.message, { type: "error" });
                    }
                  }}
                  hidden
                />
              </div>
            </li>
            <li>
              <p style={{ margin: "8px 0" }}>
                <b>Validación</b>. Se verifica que se estén usando campos
                numéricos donde corresponden.
              </p>
              <Box marginBottom={"8px"}>
                Estado: <b>{validation}</b>
              </Box>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                {validationErrors.map((error, i) => (
                  <Alert key={i} severity="warning">
                    <AlertTitle>Fila {+error.path[0] + 2}</AlertTitle>
                    {error.path[1]}: {error.message}
                  </Alert>
                ))}
              </Box>
            </li>
            <li>
              <p style={{ margin: "8px 0" }}>
                <b>Carga masiva hacia el servidor</b>.
              </p>
              {isPriceList && (
                <ul
                  style={{
                    margin: "8px 0",
                    paddingLeft: "12px",
                    color: "#014361",
                    fontSize: "14px",
                  }}
                >
                  <li style={{ backgroundColor: "#e5f6fd" }}>
                    Si el <i>Precio de compra a distribuidor</i> no cambia y el{" "}
                    <i>Precio al por mayor</i> viene vacío, no se actualiza
                    niguno de los dos.
                  </li>
                  <li style={{ backgroundColor: "#e5f6fd" }}>
                    Si el <i>Precio de compra a distribuidor</i> cambia y el{" "}
                    <i>Precio al por mayor</i> viene vacío, se calcula el precio
                    al por mayor según el <i>Porcentaje de incremento</i> y se
                    actualizan ambos.
                  </li>
                  <li style={{ backgroundColor: "#e5f6fd" }}>
                    Si el <i>Precio de compra a distribuidor</i> no cambia y el{" "}
                    <i>Precio al por mayor</i> viene lleno, se actualiza el
                    precio al por mayor.
                  </li>
                  <li style={{ backgroundColor: "#e5f6fd" }}>
                    Si el <i>Precio de compra a distribuidor</i> cambia y el{" "}
                    <i>Precio al por mayor</i> viene lleno, se actualizan ambos.
                  </li>
                </ul>
              )}
              <Box marginBottom={"8px"}>
                Estado: <b>{uploadStatus}</b>
              </Box>
              <div>
                {uploadStatus === EUploadStatus.PENDING && (
                  <Button
                    label="Iniciar actualización masiva"
                    variant="contained"
                    size="medium"
                    onClick={async () => {
                      setUploadStatus(EUploadStatus.IN_PROGRESS);
                      for (const row of productRows!) {
                        const index = productRows!.indexOf(row);
                        const addError = (field: string, error: unknown) => {
                          const message =
                            field +
                            ": " +
                            ((error instanceof AxiosError &&
                              error.response?.data.message) ||
                              (error instanceof Error && error.message) ||
                              "Ocurrió un error");
                          setUploadErrors((prev) => [
                            ...prev,
                            { row: index + 2, message },
                          ]);
                        };

                        try {
                          const [errorPrice, errorStock] =
                            await updateSupplierProduct(row, supplier, admin);
                          if (errorPrice) {
                            addError("Price", errorPrice);
                          }
                          if (errorStock) {
                            addError("Stock", errorStock);
                          }
                        } catch (error) {
                          addError("Producto", error);
                        }
                        setProgress((index + 1 / productRows!.length) * 100);
                      }
                      refresh();
                      setUploadStatus(EUploadStatus.DONE);
                    }}
                    disabled={validation !== EValidationStatus.SUCCESS}
                  />
                )}
                {uploadStatus === EUploadStatus.IN_PROGRESS && (
                  <LinearProgress variant="determinate" value={progress} />
                )}
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  {uploadErrors.map((error, i) => (
                    <Alert key={i} severity="error">
                      <AlertTitle>Fila {error.row}</AlertTitle>
                      {error.message}
                    </Alert>
                  ))}
                </Box>
                {uploadStatus === EUploadStatus.DONE && (
                  <Button
                    label="Limpiar"
                    variant="text"
                    onClick={clear}
                    sx={{ marginTop: "8px" }}
                  />
                )}
              </div>
            </li>
          </ol>
        </div>
      }
    />
  );
};

async function downloadTemplate(supplier: TSupplier) {
  const { content: products } = await getProductsV2({
    search: `supplierId:${supplier.id}`,
    size: 9999,
  });
  const isPriceList = supplier.negotiationType === "PRICE_LIST";

  const productRows = products.map((p) => {
    const productSupplier = p.suppliers.find(
      (ps) => ps.supplierId === supplier.id
    );

    if (!productSupplier) {
      throw new Error("No se encontró al proveedor en la variante.");
    }

    if (isPriceList) {
      const row: ExcelRowPriceList = {
        SKU: p.sku,
        Marca: p.brand.name,
        Nombre: p.name,
        PVSP: p.retailPrice ?? 0,
        Stock: productSupplier.availableStock,
        "Precio de compra a distribuidor": productSupplier.supplierPrice,
        "Precio al por mayor": productSupplier.wholesalePrice,
        Estado: productSupplier.status ? "Habilitado" : "Deshabilitado",
      };
      return row;
    } else {
      const row: ExcelRowNotPriceList = {
        SKU: p.sku,
        Marca: p.brand.name,
        Nombre: p.name,
        PVSP: p.retailPrice ?? 0,
        Stock: productSupplier.availableStock,
        Precio: productSupplier.wholesalePrice,
        Estado: productSupplier.status ? "Habilitado" : "Deshabilitado",
      };
      return row;
    }
  });

  const worksheet = XLSX.utils.json_to_sheet(productRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    `Productos de ${supplier.name}`.slice(0, 31)
  );
  const timestamp = format(new Date(), "yyyy-MM-dd HH-mm");
  XLSX.writeFile(workbook, `Productos de ${supplier.name} ${timestamp}.xlsx`);
}

async function loadTemplate(file: File) {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  return XLSX.utils.sheet_to_json(sheet) as ExcelRow[];
}

async function updateSupplierProduct(
  productRow: ExcelRow,
  supplier: TSupplier,
  admin: any
) {
  const search = `supplierId:${supplier.id},sku:${productRow.SKU}`;
  const result = await getProductsV2({ search, size: 1 });
  const product = result.content[0];

  if (!product)
    throw new Error(
      "No se encontró el producto con nombre " + productRow.Nombre
    );

  const productSupplier = product.suppliers.find(
    (ps) => ps.supplierId === supplier.id
  );

  if (!productSupplier)
    throw new Error("No se encontró el proveedor en la variante ");

  const updateProductSupplier = async () => {
    const isPriceList = supplier.negotiationType === "PRICE_LIST";
    let payload: Partial<TProductSupplier> = {};

    if (isPriceList) {
      const row = productRow as ExcelRowPriceList;
      const supplierPrice = row["Precio de compra a distribuidor"];
      const wholesalePrice = row["Precio al por mayor"];
      const supplierPriceHasChanged =
        supplierPrice !== productSupplier.supplierPrice;

      // Casos:
      // 1. Si el supplier price no cambia y precio al por mayor viene vacío, no actualizo el precio
      // 2. Si el supplier price cambia y precio al por mayor viene vacío, calculo el wholesalePrice y actualizo ambos
      // 3. Si el supplier price no cambia y el precio a por mayor viene, actualizo el wholesalePrice
      // 4. Si el supplier price cambia y el precio al por mayor viene, se actualizan ambos

      if (supplierPriceHasChanged && !wholesalePrice) {
        // 2
        payload.supplierPrice = supplierPrice;
        payload.wholesalePrice =
          supplierPrice /
          (1 - (supplier.priceListIncreasePercentage ?? 0) / 100);
      } else if (!supplierPriceHasChanged && wholesalePrice) {
        //3
        payload.wholesalePrice = wholesalePrice;
      } else if (supplierPriceHasChanged && wholesalePrice) {
        // 4
        payload.supplierPrice = supplierPrice;
        payload.wholesalePrice = wholesalePrice;
      }
    } else {
      const row = productRow as ExcelRowNotPriceList;
      const wholesalePrice = row.Precio;
      if (wholesalePrice !== productSupplier.wholesalePrice) {
        payload.wholesalePrice = wholesalePrice;
      }
    }

    // if (
    //   payload.wholesalePrice &&
    //   product.retailPrice &&
    //   product.retailPrice < payload.wholesalePrice
    // ) {
    //   throw new Error("El Precio no puede ser menor al PVSP");
    // }

    const newStatus = productRow.Estado === "Habilitado";
    if (newStatus !== productSupplier.status) {
      payload.status = newStatus;
    }

    if (Object.keys(payload).length) {
      return getSeeriApi().put(
        "/api/products/product-supplier/" + productSupplier.id,
        payload
      );
    }
  };

  const updateStock = async () => {
    if (typeof productRow.Stock !== "number") return;
    const provisioningType =
      productRow.Stock > productSupplier.availableStock ? "destiny" : "source";
    const provisioningAmount = Math.abs(
      productRow.Stock - productSupplier.availableStock
    );

    if (provisioningAmount !== 0) {
      return getSeeriApi().post(
        `/api/products/product-supplier/${productSupplier.id}/movements`,
        {
          amount: provisioningAmount,
          [provisioningType]: "AVAILABLE",
          type: "PROVISIONING",
          createdBy: admin.email,
        }
      );
    }
  };

  let errorProduct;
  let errorStock;

  // Se cambió de secuencial a paralelo por un asunto en backend.
  await updateProductSupplier().catch((error) => (errorProduct = error));
  await updateStock().catch((error) => (errorStock = error));

  return [errorProduct, errorStock];
}
