import {
  DialogActions,
  Alert,
  AlertTitle,
  List,
  ListItem,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { AxiosError } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { Button, LinearProgress, useDataProvider } from "react-admin";
import { PRODUCTS } from "../../../../config/resources";
import { schema, serialize } from "./schema";

type Props = {
  rows: any[];
  onEnd: () => void;
};

export const CreateProcess: FC<Props> = ({ rows, onEnd }) => {
  const dataProvider = useDataProvider();
  const [isCreating, setIsCreating] = useState(false);
  const [invalid, setInvalid] = useState<any[]>([]);
  const [valid, setValid] = useState<any[]>([]);
  const [checks, setChecks] = useState<any[]>([]);
  const [errors, setErrors] = useState<any[]>([]);
  const progress = checks.length + errors.length;
  const isDone = progress === rows.length;

  const start = useCallback(async () => {
    setChecks([]);
    setErrors([]);
    setIsCreating(true);
    for (const row of valid) {
      const index = valid.indexOf(row);
      try {
        const data = await serialize(row);
        const result = await dataProvider.create(PRODUCTS, { data });
        setChecks((old) => [...old, result.data]);
      } catch (error) {
        const message =
          error instanceof AxiosError
            ? error.response?.data?.message
            : error instanceof Error
            ? error.message
            : error;
        setErrors((old) => [...old, { row: index + 2, message }]);
      }
    }
    setIsCreating(false);
  }, [valid]);

  useEffect(() => {
    const validate = async () => {
      const valid = [];
      const invalid: any[] = [];
      for (let i = 0; i < rows.length; i++) {
        const data = rows[i];
        const row = i + 2;
        const obj = await schema
          .validate(data, { abortEarly: false, stripUnknown: true })
          .catch((error) => {
            invalid.push({ row, errors: error.errors });
          });
        if (obj) valid.push(obj);
      }
      setValid(valid);
      setInvalid(invalid);
    };
    validate();
  }, []);

  return (
    <>
      <DialogTitle>Creación masiva de productos</DialogTitle>
      <DialogContent>
        {valid.length + invalid.length < rows.length && (
          <p>Validando archivo...</p>
        )}

        {invalid.length > 0 && (
          <>
            <Alert severity="error">
              <AlertTitle>
                Se encontraron {invalid.length} filas inválidas
              </AlertTitle>
              {invalid.map((invalid, i) => (
                <div key={i}>
                  {i > 0 && <hr />}
                  <div>
                    <strong>Fila: {invalid.row}</strong>
                    <List>
                      {invalid.errors.map((error: string) => (
                        <ListItem key={error} sx={{ whiteSpace: "pre-wrap" }}>
                          {error}
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </div>
              ))}
            </Alert>
          </>
        )}

        {valid.length === rows.length && (
          <>
            <p>
              Progreso: {progress} / {rows.length}
            </p>
            <LinearProgress
              variant="determinate"
              value={Math.floor((progress * 100) / rows.length)}
            />
          </>
        )}

        {errors.length > 0 && (
          <>
            <Alert severity="error">
              <AlertTitle>Ocurrieron {errors.length} errores:</AlertTitle>
              {errors.map((error, i) => (
                <div key={i}>
                  {i > 0 && <hr />}
                  <div>
                    <strong>Fila: {error.row}</strong>
                    <div>{error.message}</div>
                  </div>
                </div>
              ))}
            </Alert>
          </>
        )}

        {isDone && <p>Se crearon {checks.length} productos correctamente</p>}

        <DialogActions>
          <Button
            onClick={onEnd}
            label={isDone ? "Cerrar" : "Cancelar"}
            color="secondary"
            variant="outlined"
            size="medium"
            disabled={isCreating}
          />
          {valid.length === rows.length && !isDone && (
            <Button
              onClick={start}
              label="Iniciar creación masiva"
              variant="contained"
              size="medium"
              disabled={isCreating}
            />
          )}
        </DialogActions>
      </DialogContent>
    </>
  );
};
