import { validateSellerData } from "@/api";
import { debounce } from "lodash";

export const PHONE_MIN_LENGTH = 10;
export const PHONE_MAX_LENGTH = 10;

/**
 * Valida si el usuario ya existe en la base de datos de sellers.
 * @param field Campo a validar
 * @param current Valor actual del campo para omitirlo en la validación
 */
export const availableSeller = (field: "phone" | "email", current: string) =>
  debounce(
    async (value: string) => {
      if (value.toString() === current) return;
      if (
        field === "phone" &&
        (value.length < PHONE_MIN_LENGTH || value.length > PHONE_MAX_LENGTH)
      )
        return;
      if (field === "email" && !value.includes("@")) return;

      const fields = {
        phone: "celular",
        email: "correo",
      };
      return validateSellerData({ [field]: value }).then(
        ({ [field]: isValid }) =>
          !isValid ? `Este ${fields[field]} ya existe (Sellers).` : undefined
      );
    },
    1000,
    { leading: true }
  );
