import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { getCoinsTransactionsBySellerId, TSeller } from "@/api";
import { ChangeEvent, useEffect, useState } from "react";
import {
  FunctionField,
  Labeled,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";
import useSWR from "swr";
import { ADMINS } from "../../../config/resources";
import { useAdmin } from "../../../hooks/admin";
import { CoinsCancelTransaction } from "./_coins-cancel-transaction";
import { transactionTypes } from "./constants";
import { formatCurrency } from "@/utils/currency";

const transactionOrientations = [
  { code: "NEGATIVE", name: "Negativo" },
  { code: "POSITIVE", name: "Positivo" },
];

export const CoinsTransactionsList = () => {
  const { hasPermission } = useAdmin();
  const seller = useRecordContext<TSeller>();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const { data } = useSWR(
    seller ? [{ sellerId: seller.id, page, size }, "coins"] : null,
    ([{ sellerId, page, size }]) =>
      getCoinsTransactionsBySellerId(sellerId, {
        page,
        size,
        sort: "createdAt,desc",
      })
  );

  const rows = data?.content ?? [];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setSize(+event.target.value);
    setPage(0);
  };

  if (!data || (rows && !rows.length)) return null;

  return (
    <Labeled label="Transacciones">
      <Paper>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="right">ID</TableCell>
                <TableCell align="right">Orientación</TableCell>
                <TableCell align="right">Monto</TableCell>
                <TableCell align="right">Razón</TableCell>
                <TableCell align="right">Tipo</TableCell>
                <TableCell align="right">Creado el</TableCell>
                <TableCell align="right">Creado por</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.number}
                  </TableCell>
                  <TableCell align="right">{row.id}</TableCell>
                  <TableCell align="right">
                    <FunctionField
                      render={() =>
                        transactionOrientations.find(
                          (s) => s.code === row.orientation
                        )?.name
                      }
                    />
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency(row.amount)}
                  </TableCell>
                  <TableCell align="right">{row.reason}</TableCell>
                  <TableCell align="right">
                    <FunctionField
                      render={() =>
                        transactionTypes.find((s) => s.code === row.type)?.name
                      }
                    />
                  </TableCell>
                  <TableCell align="right">{row.createdAt}</TableCell>
                  <TableCell align="right">
                    <ReferenceField
                      record={row}
                      source="createdBy"
                      reference={ADMINS}
                      link={hasPermission("admin.view") ? "show" : false}
                    >
                      <TextField source="email" />
                    </ReferenceField>
                  </TableCell>
                  {row.canBeReverted &&
                    hasPermission("seller.coins.revert") && (
                      <TableCell align="right">
                        <CoinsCancelTransaction purchaseId={row.id} />
                      </TableCell>
                    )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={data.totalElements}
          rowsPerPage={size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Labeled>
  );
};
