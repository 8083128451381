import { ModalAction } from "@/components/common/ModalAction";
import AddBusiness from "@mui/icons-material/AddBusiness";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TSupplier,
  getSuppliers,
  relateSupplierSeller,
  unlinkSupplierSeller,
} from "@/api";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";

export const UnlinkSupplier = ({
  sellerId,
  supplierId,
}: {
  sellerId: string;
  supplierId: string;
}) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const deleteSellerSupplier = async (onClose: () => void) => {
    try {
      await unlinkSupplierSeller(sellerId, supplierId);
      notify("El proveedor fue eliminado correctamente.", {
        type: "success",
      });
      onClose();
    } catch (e) {
      notify("Error al eliminar proveedor.", {
        type: "warning",
      });
    } finally {
      refresh();
    }
  };

  return (
    <ModalAction
      buttonIcon={<DeleteIcon />}
      buttonMode="icon"
      dialogMaxWidth="xs"
      buttonVariant="text"
      dialogTitle="Eliminar proveedor"
      dialogActions={(onClose) => (
        <Button
          label="Eliminar"
          onClick={() => {
            deleteSellerSupplier(onClose);
          }}
        />
      )}
    />
  );
};

export const RelateSupplierModal = () => {
  const { sellerId } = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const onSubmit = useCallback(
    async (values: any, onClose: Function) => {
      const { supplierId } = values;
      if (!supplierId) {
        notify("Selecciona un proveedor", {
          type: "error",
        });
        return;
      }
      try {
        await relateSupplierSeller(sellerId, supplierId);
        refresh();
        notify("El proveedor fue agregado correctamente.", {
          type: "success",
        });
        onClose();
      } catch (error: any) {
        notify("Error al agregar el proveedor.", {
          type: "warning",
        });
      }
    },
    [sellerId]
  );

  return (
    <ModalAction
      buttonText="Agregar proveedor"
      buttonIcon={<AddBusiness />}
      buttonVariant="text"
      dialogTitle="Agregar proveedor"
      dialogContent={(onClose) => (
        <>
          <SimpleForm onSubmit={(values) => onSubmit(values, onClose)}>
            <TextInput
              label="Buscar proveedor"
              source="name"
              alwaysOn
              autoComplete="off"
              validate={required()}
            />
            <SuppliersList />
          </SimpleForm>
        </>
      )}
    />
  );
};

const SuppliersList = () => {
  const supplierName = useWatch({ name: "name" });
  const { setValue } = useFormContext();
  const [suppliers, setSuppliers] = useState<TSupplier[]>([]);

  useEffect(() => {
    setValue("supplierId", "");
    if (supplierName && supplierName.length > 2) {
      getFilteredSuppliers(supplierName);
    } else {
      setSuppliers([]);
    }
  }, [supplierName]);

  const getFilteredSuppliers = async (name: string) => {
    const { content } = await getSuppliers({ search: `name:${name}` });
    setSuppliers(content);
  };

  if (!suppliers.length) {
    return null;
  }

  return (
    <RadioButtonGroupInput
      label="Seleccionar proveedor"
      source="supplierId"
      optionValue="id"
      choices={suppliers}
      validate={required()}
      optionText={(supplier) => (
        <div>
          <b>{supplier.name}</b>
          <br />
          <div style={{ fontSize: "0.75rem" }}>
            <span>
              <b>Id de empresa: </b>
              {supplier.companyId}
            </span>
            <br />
            <span>
              <b>Correo: </b>
              {supplier.email}
            </span>
            <br />
            <span>
              <b>Teléfono: </b>
              {supplier.phone}
            </span>
          </div>
          <br />
        </div>
      )}
    />
  );
};
