import {
  TSupplierPaymentTransactionType,
  TSupplierPayment,
  TSupplierPaymentTransaction,
} from "@/api";
import { PURCHASES, SUPPLIERS, SUPPLIER_PAYMENTS } from "@/config/resources";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  Link,
  NumberField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  WithRecord,
} from "react-admin";
import { useNavigate, useParams } from "react-router";
import {
  supplierPaymentTransactionReferenceChoices,
  supplierPaymentTransactionTypeChoices,
} from "./constants";
import { Grid } from "@mui/material";
import {
  SupplierPaymentDeleteButton,
  SupplierPaymentExcelButton,
  SupplierPaymentStatusSwitch,
} from "./actions";

export const SupplierPaymentShow = () => {
  const { supplierId, paymentId } = useParams();
  const navigate = useNavigate();

  return (
    <Show
      resource={SUPPLIER_PAYMENTS}
      id={paymentId}
      actions={
        <TopToolbar>
          <SupplierPaymentExcelButton />
          <SupplierPaymentDeleteButton
            afterDelete={() => navigate(`/${SUPPLIERS}/${supplierId}/show/2`)}
          />
        </TopToolbar>
      }
    >
      <SimpleShowLayout>
        <WithRecord
          render={(payment) => (
            <Link
              to={`/${SUPPLIERS}/${payment.supplierId}/show/2`}
              variant="body2"
            >
              ← Ir a la vista de pagos de {payment.supplierName}
            </Link>
          )}
        />
        <Grid container>
          <Grid item md={6}>
            <Labeled>
              <FunctionField
                source="supplierName"
                render={(payment: TSupplierPayment) => (
                  <Link to={`/${SUPPLIERS}/${payment.supplierId}/show`}>
                    {payment.supplierName}
                  </Link>
                )}
              />
            </Labeled>
          </Grid>
          <Grid item md={6}>
            <Labeled>
              <TextField source="paymentNumber" />
            </Labeled>
          </Grid>
          <Grid item md={6}>
            <Labeled>
              <NumberField source="total" />
            </Labeled>
          </Grid>
          <Grid item md={6}>
            <Labeled>
              <DateField source="createdAt" showTime />
            </Labeled>
          </Grid>
          <Grid item md={6}>
            <Labeled>
              {/* <SelectField
                source="status"
                choices={supplierPaymentStatusChoices}
              /> */}
              <FunctionField
                source="status"
                render={() => <SupplierPaymentStatusSwitch />}
              />
            </Labeled>
          </Grid>
        </Grid>

        <FunctionField
          source="transactions"
          render={(payment: TSupplierPayment) => {
            return (
              <>
                {Object.keys(payment.transactions).map((id) => (
                  <Labeled
                    key={id}
                    label={
                      <>
                        Despacho{" "}
                        <Link to={`/${PURCHASES}/${id.split("-")[0]}/show`}>
                          {id}
                        </Link>
                      </>
                    }
                    fullWidth
                  >
                    <ArrayField key={id} source={`transactions.${id}`}>
                      <Datagrid
                        bulkActionButtons={false}
                        sx={{ marginBottom: "16px" }}
                        sort={{ field: "createdAt", order: "DESC" }}
                      >
                        <SelectField
                          source="type"
                          choices={supplierPaymentTransactionTypeChoices}
                        />
                        <FunctionField
                          source="reference"
                          render={(t: TSupplierPaymentTransaction) =>
                            t.type ===
                            TSupplierPaymentTransactionType.ProductSell ? (
                              t.data?.name
                            ) : (
                              <SelectField
                                source="reference"
                                choices={
                                  supplierPaymentTransactionReferenceChoices
                                }
                                emptyText="-"
                              />
                            )
                          }
                        />
                        <TextField label="SKU" source="data.sku" />
                        <NumberField
                          label="Precio de venta"
                          source="data.sellPrice"
                        />
                        <FunctionField
                          label="Precio de distribuidor"
                          render={(t: TSupplierPaymentTransaction) =>
                            t.type ===
                              TSupplierPaymentTransactionType.ProductSell && (
                              <NumberField
                                source="value"
                                record={{
                                  value: t.value / (t.data?.quantity ?? 1),
                                }}
                              />
                            )
                          }
                        />
                        <NumberField label="Cantidad" source="data.quantity" />

                        <FunctionField
                          source="value"
                          label="Subtotal"
                          render={(t: TSupplierPaymentTransaction) => (
                            <NumberField
                              source="value"
                              color={t.value < 0 ? "red" : undefined}
                            />
                          )}
                        />
                      </Datagrid>
                    </ArrayField>
                  </Labeled>
                ))}
              </>
            );
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
};
