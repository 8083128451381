import { FC, useMemo } from "react";
import { TPrepurchaseLimit, TUserType } from "@/api";
import { LinearProgress } from "react-admin";
import { Grid, SvgIcon, Chip, Typography } from "@mui/material";
import { formatProductLimitText } from "../hooks/prepurchase";
import { mdiCheck, mdiCircle } from "@mdi/js";
import { getLimitSourceName } from "@/constants/limits";

type Props = {
  limit: TPrepurchaseLimit;
};

export const LimitHeader: FC<Props> = ({ limit }) => {
  const completed =
    limit.limitType === "NONE" || limit.currentValue > limit.limitValue;

  return (
    <Grid container spacing={2}>
      <Grid item xs={1} color="secondary">
        {completed ? (
          <SvgIcon color="success">
            <path d={mdiCheck} />
          </SvgIcon>
        ) : (
          <SvgIcon color="warning">
            <path d={mdiCircle} />
          </SvgIcon>
        )}
      </Grid>
      <Grid item xs={5}>
        <div>
          {limit.limitSource === "SUPPLIER"
            ? limit.data.socialReason
            : limit.data.name}
        </div>
        <Chip label={getLimitSourceName(limit.limitSource)} size="small" />
      </Grid>
      <Grid item xs={6}>
        <ProgressBar limit={limit} />
      </Grid>
    </Grid>
  );
};

type ProgressBarProps = {
  limit: TPrepurchaseLimit;
};

const ProgressBar: FC<ProgressBarProps> = ({ limit }) => {
  const barPercentage = useMemo(() => {
    return limit.limitType === "NONE"
      ? 100
      : 100 * (limit.currentValue / limit.limitValue);
  }, [limit.currentValue]);

  const missingValue = useMemo(() => {
    if (limit.limitType === "NONE") {
      return 0;
    } else {
      return Math.max(limit.limitValue - limit.currentValue, 0);
    }
  }, [limit.currentValue]);

  const formatLimitText = () => {
    if (barPercentage >= 100) {
      return "Tus productos están listos para procesar";
    }
    return formatProductLimitText(
      limit.limitType,
      missingValue,
      limit.limitValue
    );
  };

  return (
    <div>
      <div>
        <LinearProgress
          color={barPercentage < 100 ? "warning" : "success"}
          variant="determinate"
          value={barPercentage}
          sx={{ width: "100%" }}
        />
      </div>
      <Typography fontSize="small">{formatLimitText()}</Typography>
    </div>
  );
};
