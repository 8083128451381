import { CancelRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  AxiosError,
  cancelPurchase,
  getPurchaseCancellationReasons,
  TPurchase,
} from "@/api";
import { useEffect, useState } from "react";
import {
  Button,
  useNotify,
  useRecordContext,
  SimpleForm,
  SelectInput,
  required,
  TextInput,
} from "react-admin";
import { useAdmin } from "../../../../hooks/admin";
import { isFinishedPurchase } from "../../shared/actions-disabled";
import { addPurchaseComment } from "@/api";

export const PurchaseCancellationAction = () => {
  const notify = useNotify();
  const { hasPermission, role, admin } = useAdmin();
  const purchase = useRecordContext<TPurchase>();

  const purchaseId = purchase?.id;

  const isAdmin = role?.name === "Admin";
  const cancelEnabled = purchase?.cancelEnabled as boolean;

  const paymentStatus = purchase?.paymentStatus;
  const deliveryStatus = purchase?.deliveryStatus;

  const hasRemission = purchase?.subPurchases?.some(
    ({ supplierStatus }: any) => supplierStatus === "REMISSION_GENERATED"
  );

  const hasSubpurchaseWithTrackingLink = purchase?.subPurchases.some(
    (sub) => sub.trackingLink
  );

  const [sending, setSending] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [reasons, setReasons] = useState<[]>([]);

  const handleSubmit = async (values: any) => {
    const { reason, actionComment: comment } = values;

    setSending(true);
    try {
      await cancelPurchase(purchaseId as string, {
        reason,
      });

      await addPurchaseComment(purchaseId as string, {
        createdBy: admin.id,
        comment,
        fullName: admin.firstName + " " + admin.lastName,
      });

      notify("El pedido se canceló satisfactoriamente");
    } catch (error) {
      console.log({ error });
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se puede realizar la cancelación del pedido", {
          type: "error",
        });
      }
    } finally {
      setSending(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    getPurchaseCancellationReasons("admin").then((data: any) => {
      setReasons(data);
    });
  }, []);

  return (
    <>
      <div>
        <Tooltip title="Cancelar pedido">
          <div>
            <IconButton
              color="primary"
              onClick={handleClickOpen}
              /*
                No se puede cancelar si:
                - No tiene el permiso purchase.cancel
                - El pedido está Realizado o Cancelado
                - Algún subpedidot tiene remisión generada
                - Algún subpedido tiene link de seguimiento
                - El atributo cancelEnabled de backend viene en false
                - Si no es administrador y el pago no está pendiente
               */
              disabled={
                hasRemission ||
                hasSubpurchaseWithTrackingLink ||
                isFinishedPurchase(purchase) ||
                !hasPermission("purchase.cancel") ||
                !cancelEnabled ||
                !(paymentStatus === "PENDING" || isAdmin)
              }
            >
              <CancelRounded />
            </IconButton>
          </div>
        </Tooltip>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>¿Desea cancelar el pedido?</DialogTitle>
        <SimpleForm onSubmit={handleSubmit} toolbar={false}>
          <section style={{ width: "100%" }}>
            <DialogContent>
              <SelectInput
                label="Razón de cancelación"
                source="reason"
                fullWidth
                choices={reasons.map((reason: string) => ({
                  id: reason,
                  name: reason,
                }))}
                validate={required()}
              />
              <TextInput
                source="actionComment"
                label="Comentario"
                validate={[required()]}
                fullWidth
                multiline
              />
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button label="Cerrar" onClick={handleClose} />
              <Button
                label="Cancelar pedido"
                disabled={sending}
                type="submit"
              />
            </DialogActions>
          </section>
        </SimpleForm>
      </Dialog>
    </>
  );
};
