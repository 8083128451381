import { FC, useState } from "react";
import { ImageField, ImageInput, ImageInputProps } from "react-admin";
import { useFormContext } from "react-hook-form";
import { optimizeImage } from "../../utils/optimize-image";
import { fileToImage } from "../../utils/file-to-image";

type Props = ImageInputProps & {
  multiple?: boolean;
  quality?: number;
} & (
    | {
        width: number;
        height: number;
      }
    | {
        width?: undefined;
        height?: undefined;
      }
  );

export const OptimizedImageInput: FC<Props> = ({
  source,
  multiple,
  width,
  height,
  quality = 95,
  ...rest
}) => {
  const { setValue } = useFormContext();
  const [optimizing, setOptimizing] = useState(false);

  return (
    <ImageInput
      helperText={
        width && height ? (
          <span>
            Imagen de{" "}
            <b>
              {width}x{height}
            </b>
            . La imagen será escalada y recortada.{" "}
            {optimizing && <b style={{ color: "green" }}>Optimizando...</b>}
          </span>
        ) : (
          ""
        )
      }
      {...rest}
      source={source}
      accept="image/png, image/jpg, image/jpeg"
      fullWidth
      multiple={multiple}
      onChange={async (files: (File | { src: string })[]) => {
        if (!Array.isArray(files)) {
          files = [files];
        }
        setOptimizing(true);
        const value = await Promise.all(
          files.map(async (file) => {
            if (file instanceof File) {
              let rawFile = file;
              const title = rawFile.name;
              const image = await fileToImage(file);
              rawFile = await optimizeImage(
                file,
                width || image.width,
                height || image.height,
                quality
              );
              return { rawFile, src: image.src, title };
            } else {
              return file;
            }
          })
        );
        setValue(source, multiple ? value : value[0]);
        setOptimizing(false);
      }}
      sx={{ position: "relative" }}
    >
      <ImageField source="src" />
    </ImageInput>
  );
};
