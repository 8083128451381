import { Divider } from "@mui/material";
import {
  ChipField,
  DateField,
  DeleteWithConfirmButton,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { ModalImageField } from "../../components/elements/ModalImageField";
import { ADMINS, PURCHASES, SELLERS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { ChangeStatusButton } from "./actions/change-status";

const VoucherShowActions = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();

  return (
    <TopToolbar>
      {hasPermission("voucher.update.status") && <ChangeStatusButton />}

      {record &&
        (record.status !== "APPROVED" ||
          hasPermission("voucher.delete.approved")) && (
          <DeleteWithConfirmButton
            mutationOptions={{ meta: { force: record.status === "APPROVED" } }}
          />
        )}
    </TopToolbar>
  );
};

export const VoucherShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("voucher.view")) return null;

  return (
    <Show actions={<VoucherShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <NumberField source="amount" />
        <TextField source="status" />
        <ReferenceField
          source="sellerId"
          reference={SELLERS}
          link={hasPermission("seller.view") ? "show" : false}
          emptyText="-"
        >
          <TextField source="email" />
        </ReferenceField>

        <Divider />

        <ReferenceArrayField source="purchases" reference={PURCHASES}>
          <SingleFieldList linkType="show">
            <ChipField source="purchaseNumber" />
          </SingleFieldList>
        </ReferenceArrayField>

        <Divider />

        <ModalImageField source="url" />
        <TextField source="reference" emptyText="-" />

        <Divider />

        <ReferenceField
          source="userId"
          reference={ADMINS}
          link={hasPermission("admin.view") ? "show" : false}
          emptyText="-"
        >
          <TextField source="email" />
        </ReferenceField>
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleShowLayout>
    </Show>
  );
};
