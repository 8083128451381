import { ModalAction } from "@/components/common/ModalAction";
import {
  ArrayField,
  Datagrid,
  DateField,
  TextField,
  useRecordContext,
} from "react-admin";
import History from "@mui/icons-material/History";
import { TSubPurchase } from "@/api";
import { useSubpurchaseTrackingCodes } from "../../hooks/subpurchase-tracking-codes";

export const SubpurchaseTrackingCodesHistoryModalAction = () => {
  const subpurchase = useRecordContext<TSubPurchase>();
  const { data: trackingCodes = [], isLoading } = useSubpurchaseTrackingCodes(
    subpurchase.id
  );

  return (
    <ModalAction
      buttonText="Historial"
      dialogTitle="Historial de seguimiento"
      buttonMode="icon"
      buttonIcon={<History />}
      dialogContent={() => (
        <ArrayField
          source="trackingCodes"
          record={{
            trackingCodes: trackingCodes.map((code, index) => ({
              ...code,
              index,
            })),
          }}
        >
          <Datagrid bulkActionButtons={false} isLoading={isLoading}>
            <DateField source="createdAt" label="Fecha de creación" showTime />
            <TextField source="code" label="Código" />
            <TextField source="deliveryProvider" label="Proveedor logístico" />
          </Datagrid>
        </ArrayField>
      )}
    />
  );
};
