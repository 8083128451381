import { AppBar, type AppBarProps } from "react-admin";
import { countries, country } from "../config/country";
import { MyUserMenu } from "./UserMenu";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Link,
} from "@mui/material";
import { type MouseEvent, useState, type FC } from "react";
import { useAdmin } from "@/hooks/admin";

export const MyAppBar: FC<AppBarProps> = (...props) => {
  const { admin } = useAdmin();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const countryMenuItems = countries
    .filter((c) => c.name !== country.name)
    .filter((c) => admin?.countries.includes(c.code));

  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title" />
      </Box>
      <Box sx={{ flexGrow: 0 }}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
          <img src={country.flag} alt="flag" width={24} />
        </IconButton>
        {countryMenuItems.length > 0 && (
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {countryMenuItems.map((c) => (
              <MenuItem key={c.hostname} onClick={handleCloseUserMenu}>
                <Link
                  href={`https://${c.hostname}`}
                  sx={{ textDecoration: "none" }}
                >
                  <img src={c.flag} alt="flag" width={24} />{" "}
                  <span>{c.name}</span>
                </Link>
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    </AppBar>
  );
};
