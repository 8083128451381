import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import {
  PRODUCTS,
  PURCHASES,
  SUPPLIERS,
  SUPPLIER_PURCHASES,
} from "../../config/resources";
import { deliveryStatusRendering, supplierStatus } from "../../data/purchases";
import { useAdmin } from "../../hooks/admin";
import { ApproveSupplierPurchase } from "./actions/approve-purchase";
import { Link as MUILink } from "@mui/material";
import { usePaymentMethods } from "../../hooks/payment-methods";
import { RejectSupplierPurchase } from "./actions/reject-purchase";
import { RetrySupplierShipping } from "./actions/retry-supplier-shipping";
import { DownloadSupplierPurchase } from "./actions/download-purchase";
import { i18nProvider } from "@/providers/i18n";

export const SupplierPurchaseShow = () => {
  const paymentMethods = usePaymentMethods();

  return (
    <Show
      resource={SUPPLIER_PURCHASES}
      title={<SupplierPurchaseShowTitle />}
      actions={<SupplierPurchaseShowActions />}
    >
      <SimpleShowLayout>
        <FunctionField
          source="supplierStatus"
          render={(record: any) =>
            supplierStatus.find((s) => s.code === record.supplierStatus)?.name
          }
        />
        <FunctionField
          source="deliveryStatus"
          render={(record: any) =>
            deliveryStatusRendering.find(
              (s) => s.code === record.deliveryStatus
            )?.name
          }
        />
        <TextField source="deliveryProvider" emptyText="-" />
        <ReferenceField source="supplierId" reference={SUPPLIERS} link={"show"}>
          <TextField source="name" sortable={false} />
        </ReferenceField>
        <FunctionField
          source="paymentMethod"
          render={(record: any) =>
            i18nProvider.translate(
              `resources.${PURCHASES}.fields.paymentMethods.${record.paymentMethod}`
            )
          }
        />
      </SimpleShowLayout>
      <FunctionField
        render={(record: any) => <CancellationReason record={record} />}
      />
      <SimpleShowLayout>
        <TextField source="priority" />
        <DateField source="createdAt" label="Fecha de creación" showTime />
        <DateField
          source="approvedAt"
          label="Fecha de aprobación"
          showTime
          emptyText="Sin fecha de aprobación"
        />
        <TextField
          source="amountBoxes"
          label="Número de cajas"
          emptyText="Sin número de cajas"
        />
      </SimpleShowLayout>
      <FunctionField
        render={(record: any) => <TrackingCode record={record} />}
      />
      <SimpleShowLayout>
        <FunctionField
          source="subPurchaseNumber"
          render={(record: any) =>
            record.purchaseId ? (
              <Link
                to={`/${PURCHASES}/${record.purchaseId}/show`}
                onClick={(e) => e.stopPropagation()}
              >
                {record.subPurchaseNumber}
              </Link>
            ) : (
              record.subPurchaseNumber
            )
          }
        />
      </SimpleShowLayout>
      <ProductsTable />
    </Show>
  );
};

const CancellationReason = ({ record }: any) => {
  if (!["REJECTED", "CANCELLED"].includes(record.supplierStatus)) {
    return null;
  }

  return (
    <SimpleShowLayout>
      <TextField
        source="reasonCancellation"
        label="Razón de cancelación"
        emptyText="-"
      />
      <TextField
        source="messageCancellation"
        label="Comentario de cancelación"
        emptyText="-"
      />
    </SimpleShowLayout>
  );
};

const TrackingCode = ({ record }: any) => {
  if (!!record?.trackingFile) {
    return (
      <SimpleShowLayout>
        <FunctionField
          source="trackingCode"
          render={(record: any) => (
            <MUILink href={record?.trackingFile} target="_blank">
              {record.trackingCode}
            </MUILink>
          )}
        />
      </SimpleShowLayout>
    );
  }
  return (
    <SimpleShowLayout>
      <FunctionField
        source="trackingCode"
        label="Número de guía"
        render={(record: any) =>
          !record?.trackingCode && record?.status === "APPROVED"
            ? "Esperando creación de guía"
            : record?.trackingCode ?? "-"
        }
      />
    </SimpleShowLayout>
  );
};

const SupplierPurchaseShowTitle = () => {
  const record = useRecordContext();

  return <>Pedido de Proveedores {record?.subPurchaseNumber}</>;
};

const SupplierPurchaseShowActions = () => {
  return (
    <TopToolbar>
      <RetrySupplierShipping />
      <RejectSupplierPurchase />
      <ApproveSupplierPurchase />
      <DownloadSupplierPurchase />
    </TopToolbar>
  );
};

const ProductsTable = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      <ArrayField source="products">
        <Datagrid bulkActionButtons={false}>
          <ReferenceField
            source="id"
            reference={PRODUCTS}
            link={hasPermission("product.view") ? "show" : false}
            label="Imagen"
          >
            <FunctionField
              label="Imagen"
              render={(record: any) =>
                record?.images?.length ? (
                  <img
                    src={record.images[0].src}
                    width={80}
                    height={80}
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <div
                    style={{ width: 80, height: 80, backgroundColor: "#ddd" }}
                  />
                )
              }
            />
          </ReferenceField>
          <NumberField source="name" textAlign="left" />
          <NumberField source="supplierSku" textAlign="left" />
          <NumberField source="quantity" textAlign="left" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};
