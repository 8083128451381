import { isInteger } from "lodash";

export const integer =
  (message = "Debe ser un número entero") =>
  (n: number) =>
    isInteger(n) || !n ? undefined : message;

export const validateImage = (value: any) => {
  if (!value || !value.src) {
    return "Es necesario subir una imagen";
  }
  return undefined;
};
