import { ADMINS } from "@/config/resources";
import { Box, Typography } from "@mui/material";
import { TProduct, getSeeriApi } from "@/api";
import {
  DateField,
  FunctionField,
  RecordContext,
  RecordContextProvider,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import useSWR from "swr";
import { productLogTypes } from "../constants";

export const ProductLogs = () => {
  const product = useRecordContext<TProduct>();
  const { data: logs, error } = useSWR(
    () => product.id,
    (productId) =>
      getSeeriApi()
        .get<any[]>(`/api/products/v2/logs/${productId}`)
        .then((res) => res.data)
  );

  if (error)
    return (
      <Box>
        <Typography>Ocurrió un error</Typography>
        <Box>{error}</Box>
      </Box>
    );
  if (!logs) return <>Cargando...</>;

  return (
    <Box>
      {logs.map((log, index) => (
        <Box key={log.id}>
          {index > 0 && <hr />}
          <RecordContextProvider value={log}>
            <SimpleShowLayout spacing={0}>
              <FunctionField
                source="logType"
                label="Tipo de cambio"
                render={(record: any) => productLogTypes[record.logType]}
              />
              <TextField source="log" label="Detalle" />
              <DateField source="createdAt" showTime label="Fecha del cambio" />
              <ReferenceField
                source="createdBy"
                reference={ADMINS}
                label="Administrador"
              >
                <TextField source="email" />
              </ReferenceField>
            </SimpleShowLayout>
          </RecordContextProvider>
        </Box>
      ))}
    </Box>
  );
};
