import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import {
  Button,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useCallback, useEffect, useState } from "react";
import { SUPPLIER_PURCHASES } from "../../../config/resources";
import {
  AxiosError,
  getPurchaseCancellationReasons,
  updateSupplierOrder,
} from "@/api";

export const RejectSupplierPurchase = () => {
  const [open, setOpen] = useState(false);
  const [reasons, setReasons] = useState<[]>([]);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const trackingCode = record?.trackingCode;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const { cancelationReason: reason, message, id: orderId } = values;

        await updateSupplierOrder(orderId, "reject", {
          message,
          reason,
        });
        notify("El pedido se ha rechazado", { type: "success" });
        refresh();
        setOpen(false);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo rechazar el pedido", { type: "error" });
        }
        setOpen(false);
      }
    },
    [trackingCode]
  );

  useEffect(() => {
    getPurchaseCancellationReasons("admin").then((data: any) => {
      setReasons(data);
    });
  }, []);

  if (
    !["PENDING", "IN_PROCESS"].includes(record?.supplierStatus?.toUpperCase())
  ) {
    return null;
  }

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <Tooltip title="Cancelar pedido">
          <div>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              size="small"
              label="Cancelar pedido"
            />
          </div>
        </Tooltip>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>Confirmación de despacho</DialogTitle>
        <DialogContent>
          <SimpleForm
            mode="onBlur"
            toolbar={<ApproveSupplierPurchaseToolbar />}
            record={record}
            resource={SUPPLIER_PURCHASES}
            onSubmit={onSubmit}
          >
            <SelectInput
              label="Razón de cancelación"
              source="cancelationReason"
              fullWidth
              choices={reasons.map((reason: string) => ({
                id: reason,
                name: reason,
              }))}
              validate={required()}
            />
            <TextInput
              source="message"
              label="Comentario"
              validate={[required()]}
              fullWidth
              multiline
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button label="Cerrar" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ApproveSupplierPurchaseToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
