import { useEffect } from "react";
import { Edit, useRedirect, useStore } from "react-admin";
import { LECTURES, RESOURCES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { ResourceForm } from "./_form";

export const ResourceEdit = () => {
  const { hasPermission } = useAdmin();
  const redirect = useRedirect();

  const [lectureId] = useStore("currentLectureId");

  useEffect(() => {
    if (!lectureId) {
      redirect("/" + LECTURES);
    }
  }, [lectureId]);

  if (!hasPermission("lecture.update")) return null;

  if (!lectureId) return null;

  return (
    <Edit
      resource={RESOURCES}
      mutationMode="pessimistic"
      redirect="show"
      queryOptions={{
        meta: {
          dependentId: lectureId,
        },
      }}
      mutationOptions={{
        meta: {
          dependentId: lectureId,
        },
      }}
    >
      <ResourceForm isEdit />
    </Edit>
  );
};
