import { ModalAction } from "@/components/common/ModalAction";
import Web from "@mui/icons-material/Web";
import { AxiosError, TSupplier } from "@/api";
import { useCallback, useState } from "react";
import { Button, Form, useNotify, useRecordContext } from "react-admin";
import { Refresh } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { country } from "@/config/country";

export const SupplierCreateSubdomainModalAction = () => {
  const supplier = useRecordContext<TSupplier>();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const onSubmit = useCallback(
    async (values: any, onClose: Function) => {
      setLoading(true);
      try {
        await axios.post(
          "https://port.api.seeri.co/update/seeri-app-subdomains",
          { name: supplier.slug },
          {
            headers: {
              "x-api-key":
                "ECKPSXVg8tnbwrUgqW3Ah1hBkoKBbw1v7tfJYe2673gSqNfM42ulsTnEawVnh6t5",
            },
          }
        );

        notify("El subdominio fue creado correctamente.", {
          type: "success",
        });

        onClose();
      } catch (error) {
        let errorMessage;

        if (error instanceof AxiosError) {
          errorMessage = error.response?.data?.message;
        }

        notify(errorMessage || "Error de servidor.", {
          type: "warning",
        });
      }

      setLoading(false);
    },
    [supplier]
  );

  const formId = "supplier-subdomain-form";

  return (
    <ModalAction
      buttonText="Subdominio"
      buttonIcon={<Web />}
      buttonVariant="text"
      dialogMaxWidth="sm"
      dialogTitle="Crear subdominio"
      dialogContent={(onClose) => (
        <Form
          id={formId}
          onSubmit={(values) => onSubmit(values, onClose)}
          record={undefined}
        >
          <Typography>Se creará la siguiente dirección web:</Typography>
          <Typography fontWeight={"bold"}>
            https://
            <Box sx={{ color: "primary.main", display: "inline" }}>
              {supplier?.slug}
            </Box>
            .seeri.store{country.code === "mx" ? "/es-MX" : ""}/catalogo
          </Typography>
        </Form>
      )}
      dialogActions={
        <Button
          label="Crear"
          type="submit"
          size="medium"
          form={formId}
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
    />
  );
};
