import { getSeeriApi } from "@/api";
import { sortBy } from "lodash";
import useSWR from "swr";

export type TSubpurchaseTrackingCode = {
  id: number;
  subPurchaseId: string;
  code: string;
  trackingLink: string;
  deliveryProvider: string;
  deliveryStatus: string;
  deliveryCost: number;
  deliveredAt: string;
  createdAt: string;
  changedAt: string;
  deletedAt: string;
};

export const useSubpurchaseTrackingCodes = (subpurchaseId: string) => {
  return useSWR<TSubpurchaseTrackingCode[]>(
    {
      url: `/api/sub-purchases/${subpurchaseId}/tracking-codes`,
    },
    ({ url }) =>
      getSeeriApi()
        .get<any[]>(url)
        .then((res) => sortBy(res.data, ["createdAt"]).reverse())
  );
};
