import { getClientsBySellerId } from "@/api";
import { FC, useEffect, useState } from "react";
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  EmailField,
  LinearProgress,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useListFilterContext,
  useRecordContext,
} from "react-admin";
import { userTypes } from "../../data/seller";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";
import { CoinsBulkButton } from "./coins/_coins-excel";
import { SUPPLIERS } from "@/config/resources";
import { ModifyOTPSellers } from "./actions/ModifyOTP";
import { Box } from "@mui/material";

const SellerFilter = [
  <ReferenceInput source="supplierId" reference={SUPPLIERS} alwaysOn>
    <AutocompleteInput
      label="Proveedor"
      optionText="name"
      filterToQuery={(searchText: string) => ({ name: searchText })}
      fullWidth
    />
  </ReferenceInput>,
  <TextInput label="Buscar" source="text" resettable alwaysOn />,
];

const SellerActions = () => {
  const { total } = useListContext();
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("seller.create") && <CreateButton />}
      {hasPermission("seller.coins.bulkcharge") && <CoinsBulkButton />}
      {hasPermission("seller.enable.otp") && <ModifyOTPSellers />}
    </TopToolbar>
  );
};

export const SellerList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("seller.list")) return null;

  return (
    <List
      perPage={5}
      filters={SellerFilter}
      pagination={<SellerListPagination />}
      hasCreate={hasPermission("seller.create")}
      actions={<SellerActions />}
    >
      <CustomDatagrid />
    </List>
  );
};

const SellerListPagination = () => {
  const { filterValues } = useListFilterContext();

  if (Object.keys(filterValues).length > 0) {
    return <CustomPagination />;
  }
  return (
    <Box padding={"16px"} textAlign={"center"}>
      <b>Usa los filtros</b> para encontrar el recurso que buscas.
    </Box>
  );
};

const CustomDatagrid = () => {
  const { hasPermission } = useAdmin();
  const { filterValues } = useListFilterContext();

  return (
    <Datagrid
      rowClick={hasPermission("seller.view") ? "show" : undefined}
      bulkActionButtons={false}
      data={Object.keys(filterValues).length > 0 ? undefined : []}
      empty={
        <Box padding={"16px"} textAlign={"center"}>
          Sin resultados.
        </Box>
      }
    >
      <EmailField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <SelectField source="userType" choices={userTypes} />
      <TextField source="kam" />
      <ReferenceField
        source="supplierId"
        reference={SUPPLIERS}
        link={hasPermission("supplier.view") ? "show" : false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  );
};
