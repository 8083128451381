import { AxiosError, getSeeriApi } from "@/api";
import { useState } from "react";
import { Button, useListContext, useNotify } from "react-admin";
import Download from "@mui/icons-material/Download";

export const PurchasesListExport = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { filter, filterValues } = useListContext();

  let { createdAtGte: from, createdAtLte: to, ...filters } = filterValues;
  if (from) from = from.replace(" ", "T");
  if (to) to = to.replace(" ", "T");

  filters = serializeFilters({ ...filter, ...filters, from, to });

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      notify("Generando reporte de pedidos...", {
        type: "info",
      });
      const response = await getSeeriApi().post(
        "/api/reports/export/purchases",
        filters,
        { responseType: "arraybuffer" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `purchases-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      notify("Reporte descargado con éxito", {
        type: "success",
      });
      link.remove();
    } catch (error) {
      console.log(error);
      const msg =
        (error instanceof AxiosError && error.response?.data?.message) ||
        error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  return (
    <Button
      label="Reporte"
      onClick={handleClick}
      startIcon={<Download />}
      disabled={loading}
    />
  );
};

function serializeFilters(filters: any) {
  const serialized = structuredClone(filters);
  if (filters.status) {
    serialized.status = serialized.status[0];
  }
  if (filters.paymentMethod) {
    serialized.paymentMethod = serialized.paymentMethod[0];
  }
  if (filters.createdAtGte) {
    delete serialized.createdAtGte;
    serialized.from = filters.createdAtGte;
  }
  if (filters.createdAtLte) {
    delete serialized.createdAtLte;
    serialized.to = filters.createdAtLte;
  }
  return serialized;
}
