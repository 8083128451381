import {
  Button,
  CheckboxGroupInput,
  Form,
  useListContext,
  useNotify,
} from "react-admin";
import DownloadIcon from "@mui/icons-material/GetApp";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { i18nProvider } from "../../../providers/i18n";
import { PURCHASES } from "../../../config/resources";
import { getAllHeaders, getHeaderName } from "./headers";
import { dataProvider } from "../../../providers";
import { processPurchasesCSV } from "./process";
import { usePaymentMethods } from "../../../hooks/payment-methods";

export const PurchasesExport = () => {
  const { total, filter, filterValues } = useListContext();
  const [open, setOpen] = useState(false);
  const [headers, setHeaders] = useState<string[]>(getAllHeaders());
  const [downloading, setDownloading] = useState(false);
  const notify = useNotify();
  const paymentMethods = usePaymentMethods();

  const download = useCallback(async () => {
    setDownloading(true);
    try {
      const purchases = await dataProvider.getList(PURCHASES, {
        filter: { ...filter, ...filterValues },
        pagination: { page: 1, perPage: 100_000 },
        sort: { field: "createdAt", order: "DESC" },
        meta: { params: { includeSubPurchases: true } },
      });
      await processPurchasesCSV(purchases.data, headers, paymentMethods);
      setOpen(false);
    } catch (error) {
      console.log(error);
      notify("Ocurrió un error. Por favor, inténtelo de nuevo.");
    }
    setDownloading(false);
  }, [headers, filterValues, total]);

  return (
    <>
      <Button
        label="Exportar"
        onClick={() => setOpen(true)}
        startIcon={<DownloadIcon />}
        disabled={total === 0}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Exportar pedidos</DialogTitle>
        <DialogContent>
          <PurchasesFilters />
          <HeadersInput value={headers} onChange={setHeaders} />
        </DialogContent>
        <DialogActions>
          <Button
            label="Cerrar"
            onClick={() => setOpen(false)}
            variant="outlined"
            size="medium"
            disabled={downloading}
          />
          <Button
            label="Exportar"
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            onClick={download}
            disabled={downloading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const PurchasesFilters = () => {
  const { filterValues } = useListContext();
  const filters = Object.entries<string>(filterValues);

  if (!filters.length) return null;

  return (
    <Alert color="info">
      Los siguientes <strong>filtros</strong> serán aplicados al archivo:
      <Table>
        <TableBody>
          {filters.map((entry) => (
            <TableRow key={entry[0]}>
              <td>
                {i18nProvider.translate(
                  `resources.${PURCHASES}.fields.${entry[0]}`
                )}
              </td>
              <td>{entry[1]}</td>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Alert>
  );
};

const HeadersInput: FC<{
  value: string[];
  onChange: (value: string[]) => void;
}> = ({ value, onChange }) => {
  return (
    <>
      <Typography sx={{ mt: 2 }}>
        Selecciona las columnas que deseas incluir en el exportable:
      </Typography>
      <Form record={{ headers: value }}>
        <CheckboxGroupInput
          source="headers"
          choices={getAllHeaders().map((name) => ({
            id: name,
            name: getHeaderName(name),
          }))}
          row={false}
          onChange={(v) => onChange(v)}
        />
      </Form>
    </>
  );
};
