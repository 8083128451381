// This validator should be used in a react-admin input validate prop

import { getSeeriApi } from "@/api";
import { debounce } from "lodash";

export const availableEmailOrPhone = (
  field: "email" | "phone",
  currentUser: { email: string; phone: string }
) =>
  debounce(
    async (value) => {
      if (value && (!currentUser || currentUser[field] !== value)) {
        const existingUser = await getSeeriApi()
          .get("/api/users/email-or-phone/" + value)
          .then((res) => res.data)
          .catch(() => null);
        if (existingUser) {
          return (
            "Ya existe un usuario con este " +
            (field === "email" ? "correo" : "teléfono")
          );
        }
      }
    },
    200,
    { leading: true }
  );
