export const parseOptions = (str: string): Record<string, string[]> =>
  str
    .split(";")
    .filter((_) => _)
    .reduce((obj, optStr) => {
      const [key, values] = optStr.split(":");
      return { ...obj, [key]: values.split(",").map((s) => s.trim()) };
    }, {});

export const parseKeywords = (str?: string) =>
  str ? str.split(",").map((s) => s.trim()) : [];

export const parseTaxesType = (code: string) => {
  return code ? [code] : [];
};

export const parseProductOptions = (str: string): Record<string, string> =>
  str
    .split(";")
    .filter((_) => _)
    .reduce((obj, optStr) => {
      const [key, value] = optStr.split(":");
      return { ...obj, [key.trim()]: value.trim() };
    }, {});

export const humanizeProductOptions = (
  opts: Record<string, string>
): string => {
  let text = "";
  for (const [option, value] of Object.entries(opts)) {
    text += `${option} ${value} `;
  }
  return text.trimEnd();
};

type TOpenFilesParams = { accept?: string; multiple?: boolean };
export const openFiles = async ({
  accept,
  multiple,
}: TOpenFilesParams = {}) => {
  return new Promise<File[]>((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    if (accept) input.accept = accept;
    if (multiple) {
      input.multiple = true;
      input.webkitdirectory = true;
    }
    input.onchange = (_) => {
      console.log(input.files);

      resolve(input.files ? [...input.files] : []);
    };
    input.click();
  });
};
