import { TPurchase, getSeeriApi } from "@/api";
import { Box, TextField, Typography } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  SelectField,
  useRecordContext,
} from "react-admin";
import useSWR from "swr";
import { PurchaseRefundAction } from "./actions/refund";
import { fixApiDateStr } from "@/utils/parse-date";

const types = {
  PURCHASE_COINS: "Pago con coins",
  PURCHASE_VOUCHER: "Pago con voucher",
  PURCHASE_MANUAL_REFUND: "Reembolso manual",
  PURCHASE_COINS_REFUND: "Reembolso con coins",
  PURCHASE_ONLINE: "Pago online",
  SUB_PURCHASE_ONLINE: "Pago de subpedido online",
  SUB_PURCHASE_DELIVERED: "Pago contraentrega subpedido",
  SUB_PURCHASE_PAYMENT: "Pago de subpedido por comprobante",
  USER_ONLINE: "Abono a deuda",
  USER_ONLINE_REVERSED: "Abono a deuda reversado",
};

export const PurchasePayments = () => {
  const purchase = useRecordContext<TPurchase>();
  const { data: payments } = useSWR([purchase.id, "payments"], () =>
    getSeeriApi()
      .get(`/api/purchases/${purchase.id}/payments`)
      .then((res) => res.data)
  );

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"} marginBottom={"6px"}>
          Pagos
        </Typography>
        <div>
          <PurchaseRefundAction />
        </div>
      </Box>
      <ArrayField source="payments" record={{ payments }}>
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            source="createdAt"
            label="Fecha"
            render={(record: any) => (
              <DateField
                source="createdAt"
                record={{
                  createdAt: fixApiDateStr(record.createdAt),
                }}
                showTime
              />
            )}
          />

          <SelectField
            source="type"
            label="Tipo"
            choices={Object.entries(types).map(([id, name]) => ({ id, name }))}
          />
          <FunctionField
            label="Monto"
            render={(payment: any) => (
              <NumberField
                source="amount"
                color={payment.amount < 0 ? "red" : ""}
              />
            )}
          />
        </Datagrid>
      </ArrayField>
    </>
  );
};
