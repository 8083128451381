import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { parseOptions } from "./inputs/options";
import { uploadImageObject } from "./utils";
import { ProductGroupForm } from "./_form";

export const ProductGroupEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("productGroup.update")) return null;

  const transform = async (data: any) => {
    data.options = parseOptions(data.options);
    data.images = await Promise.all(data.images.map(uploadImageObject));
    delete data.products;
    return data;
  };

  return (
    <Edit mutationMode="pessimistic" redirect="show" transform={transform}>
      <ProductGroupForm />
    </Edit>
  );
};
