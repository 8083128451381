import type { FC } from "react";
import {
  BooleanInput,
  email,
  FormTab,
  maxLength,
  minLength,
  number,
  required,
  SaveButton,
  SelectInput,
  TabbedForm,
  type TabbedFormProps,
  TextInput,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { ISODateInput } from "../../components/elements/ISODateInput";
import { SELLERS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { DocumentTypeInput } from "../shared/documen-type";
import { Grid, Stack } from "@mui/material";
import type { TSeller } from "@/api";
import {
  PHONE_MAX_LENGTH,
  PHONE_MIN_LENGTH,
  availableSeller,
} from "../shared/available-user";
import { KamInput } from "../shared/KamInput";
import { SellerOTP } from "./form/otp";
import { type } from "@/data/seller";
import { phonePrefixChoices } from "@/config/country";
import { SEERI_SUPPLIER_ID } from "@/constants/env";

const SellerEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const SellerForm: FC<
  Omit<TabbedFormProps, "children"> & {
    isEdit?: boolean;
    isEmbed?: boolean;
  }
> = ({ isEdit = false, isEmbed = false, onSubmit }) => {
  const { hasPermission } = useAdmin();
  const seller = useRecordContext<TSeller>();

  return (
    <TabbedForm
      resource={SELLERS}
      onSubmit={onSubmit}
      mode="onChange"
      toolbar={<SellerEditToolbar />}
      syncWithLocation={!isEmbed}
      defaultValues={{ userType: "STORE", supplierId: SEERI_SUPPLIER_ID }}
      warnWhenUnsavedChanges
    >
      {((hasPermission("seller.update.tab.principal") && isEdit) ||
        !isEdit) && (
        <FormTab label="Principal">
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <TextInput source="firstName" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="lastName" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source="email"
                validate={[
                  required(),
                  email(),
                  availableSeller("email", seller?.email),
                ]}
                parse={(v: string) => v?.toLowerCase()}
                disabled={isEdit}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                source="phonePrefix"
                choices={phonePrefixChoices}
                validate={required()}
                disabled={isEdit}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source="phone"
                validate={[
                  required(),
                  number("Solo números"),
                  minLength(PHONE_MIN_LENGTH),
                  maxLength(PHONE_MAX_LENGTH),
                  availableSeller("phone", seller?.phone),
                ]}
                disabled={isEdit}
                inputMode="tel"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <DocumentTypeInput source="documentType" validate={required()} />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="documentId" validate={required()} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <ISODateInput
                source="birthDate"
                defaultValue={"2000-01-01"}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                source="gender"
                choices={[
                  { id: "M", name: "Masculino" },
                  { id: "F", name: "Femenino" },
                ]}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="type" choices={type} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                height={"100%"}
              >
                <BooleanInput
                  source="status"
                  defaultValue={true}
                  disabled={!hasPermission("seller.update.status")}
                />
                {hasPermission("seller.form.manualInvoice") && (
                  <BooleanInput source="manualInvoice" defaultValue={false} />
                )}
                <BooleanInput source="vip" defaultValue={false} />
              </Stack>
            </Grid>
          </Grid>
        </FormTab>
      )}
      {((hasPermission("seller.update.tab.sales") && isEdit) || !isEdit) && (
        <FormTab label="Ventas">
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <TextInput source="referralCode" disabled={isEdit} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="myReferralCode" disabled fullWidth />
            </Grid>
          </Grid>
        </FormTab>
      )}
      <FormTab label="Miscelánea">
        <Grid container spacing={4}>
          <TextInput
            source="source"
            defaultValue={"react_backoffice"}
            type="hidden"
            sx={{ display: "none" }}
          />
          {((hasPermission("seller.update.kam") && isEdit) || !isEdit) && (
            <Grid item xs={4}>
              <KamInput />
            </Grid>
          )}
        </Grid>
      </FormTab>
      {!hasPermission("seller.form.otpFree") && (
        <FormTab label="OTP">
          <SellerOTP channel="whatsapp" />
        </FormTab>
      )}
    </TabbedForm>
  );
};
