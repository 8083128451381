import {
  BooleanInput,
  SaveButton,
  required,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
  number,
  DeleteWithConfirmButton,
  SelectInput,
} from "react-admin";
import { Grid } from "@mui/material";
import { CityInput, CountryInput, StateInput } from "../../shared/location";
import type { FC } from "react";
import { country, phonePrefixChoices } from "@/config/country";

const EditToolbar: FC<{ supplierId: string }> = ({ supplierId }) => {
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteWithConfirmButton
        redirect={false}
        mutationOptions={{ meta: { dependentId: supplierId } }}
      />
    </Toolbar>
  );
};

export const SupplierAddressForm: FC<{ supplierId: string }> = ({
  supplierId,
}) => {
  const record = useRecordContext();
  return (
    <SimpleForm
      toolbar={record ? <EditToolbar supplierId={supplierId} /> : undefined}
      defaultValues={{ phonePrefix: country.phoneCode }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput source="name" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={6}>
          <CountryInput source="country" validate={required()} />
        </Grid>
        <Grid item xs={6}>
          <StateInput
            source="state"
            countrySource="country"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CityInput
            source="city"
            stateSource="state"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            source="postalCode"
            validate={[number()].concat(
              country.code === "co" ? [] : [required()]
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="addressLine1" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="addressLine2" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            source="phonePrefix"
            label="Prefijo país"
            choices={phonePrefixChoices}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            source="phone"
            label="Teléfono"
            validate={[number("Solo números")]}
            inputMode="tel"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <BooleanInput source="isMain" label="Principal" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
