import { getCouponTypes } from "@/api";
import { useState, useEffect } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  NullableBooleanInput,
  ReferenceField,
  AutocompleteInput,
  TextField,
  TextInput,
  AutocompleteInputProps,
} from "react-admin";
import { ISODateInput } from "../../components/elements/ISODateInput";
import { ADMINS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";

const CouponTypes = ({ source }: AutocompleteInputProps) => {
  const [couponTypes, setCouponTypes] = useState<any>([]);

  useEffect(() => {
    getCouponTypes().then(setCouponTypes);
  }, []);

  return (
    <AutocompleteInput
      source={source}
      choices={couponTypes}
      optionValue="id"
      disabled={!couponTypes?.length}
    />
  );
};

const CouponFilter = [
  <NullableBooleanInput
    source="status"
    label="Estado"
    nullLabel="Todos"
    trueLabel="Activo"
    falseLabel="Inactivo"
    alwaysOn
  />,
  <CouponTypes source="type" alwaysOn />,
  <TextInput source="code" label="Código" alwaysOn />,
  <ISODateInput source="beginsAtGte" label="Vigente desde" alwaysOn />,
  <ISODateInput source="endsAtLte" label="Vigente hasta" eod alwaysOn />,
];

export const CouponList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("coupon.list")) return null;

  return (
    <List
      perPage={100}
      filters={CouponFilter}
      filterDefaultValues={{ status: true }}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("coupon.create")}
      sort={{ field: "createdAt", order: "desc" }}
    >
      <Datagrid
        rowClick={hasPermission("coupon.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <TextField source="code" />
        <TextField source="type" />
        <DateField source="beginsAt" showTime />
        <DateField source="endsAt" showTime />
        <FunctionField
          label="Usos / Límite"
          render={(record: any) =>
            `${record.redemptionsNumber} / ${record.data.maximumNumberUses}`
          }
        />
        <ReferenceField source="createdBy" reference={ADMINS}>
          <TextField source="email" />
        </ReferenceField>
        <DateField source="createdAt" />
        <BooleanField source="status" />
      </Datagrid>
    </List>
  );
};
