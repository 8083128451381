import {
  BooleanField,
  CreateButton,
  Datagrid,
  FilterButton,
  FunctionField,
  List,
  NullableBooleanInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { audienceTypes } from "../../data/education";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";

const courseFilters = [
  <NullableBooleanInput source="status" alwaysOn />,
  <TextInput source="name" resettable alwaysOn />,
  <SelectInput source="audience" choices={audienceTypes} resettable alwaysOn />,
];

const CourseActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      <FilterButton filters={courseFilters} />
      {hasPermission("course.create") && <CreateButton />}
    </TopToolbar>
  );
};

export const CourseList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("course.list")) return null;

  return (
    <List
      perPage={20}
      filters={courseFilters}
      // sort={{ field: "createdAt", order: "DESC" }}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("course.create")}
      actions={<CourseActions />}
    >
      <Datagrid
        rowClick={hasPermission("course.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <FunctionField
          source="image"
          label="Imagen"
          sortable={false}
          render={(record: any) =>
            record.image ? (
              <img
                src={record.image.src}
                width={80}
                height={80}
                style={{ objectFit: "contain" }}
              />
            ) : (
              <div style={{ width: 80, height: 80, backgroundColor: "#ddd" }} />
            )
          }
        />
        <TextField source="name" />
        <SelectField source="audience" choices={audienceTypes} />
        <BooleanField source="status" />
      </Datagrid>
    </List>
  );
};
