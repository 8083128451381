import { FC } from "react";
import {
  BooleanInput,
  FileField,
  FileInput,
  FormTab,
  ImageField,
  ImageInput,
  maxLength,
  minValue,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { Grid } from "@mui/material";
import { audienceTypes } from "../../data/education";

const LectureEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

type TLectureForm = {
  isEdit?: boolean;
};

export const LectureForm: FC<TLectureForm> = ({ isEdit }) => {
  return (
    <TabbedForm toolbar={<LectureEditToolbar />} warnWhenUnsavedChanges>
      <FormTab label="Principal">
        <Grid container spacing={4}>
          {isEdit && (
            <Grid item xs={4}>
              <TextInput source="id" disabled fullWidth />
            </Grid>
          )}
          <Grid item xs={4}>
            <TextInput source="name" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="description"
              validate={[required(), maxLength(150)]}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              source="audience"
              choices={audienceTypes}
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ImageInput
              source="image"
              accept="image/png, image/jpg, image/jpeg"
              defaultValue={{ src: "" }}
              maxSize={100000}
              helperText="Imagen de menos de 100KB. Si la imagen no carga es porque supera el peso de 100KB."
            >
              <ImageField
                source="src"
                title="title"
                sx={{
                  "& .RaImageField-image": {
                    width: "100%",
                  },
                }}
              />
            </ImageInput>
          </Grid>
          <Grid item xs={12}>
            <FileInput
              source="video"
              accept="video/mp4"
              maxSize={50000000}
              helperText="Video de menos de 50MB. Si el video no carga es porque supera el peso de 50MB."
              validate={required()}
            >
              <FileField source="src" title="title" target="_blank" />
            </FileInput>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="instructorName"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="instructorLastname"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="instructorProfession"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ImageInput
              source="instructorPhoto"
              accept="image/png, image/jpg, image/jpeg"
              defaultValue={{ src: "" }}
              maxSize={100000}
              helperText="Imagen de menos de 100KB. Si la imagen no carga es porque supera el peso de 100KB."
            >
              <ImageField
                source="src"
                title="title"
                sx={{
                  "& .RaImageField-image": {
                    width: "100%",
                  },
                }}
              />
            </ImageInput>
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              source="sequenceOrder"
              min={0}
              validate={[minValue(0)]}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput source="status" defaultValue={true} />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  );
};
