export const listify = (
  arr: string[],
  mode: "and" | "or" | null = "and",
  separator = ", "
) => {
  if (mode && arr.length > 1) {
    const modes = {
      and: " y ",
      or: " o ",
    };
    return arr.slice(0, -1).join(separator) + modes[mode] + arr.at(-1);
  }
  return arr.join(separator);
};
