import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { refreshSeeriApiConfig } from "../context/seeri-api";
import { adminRolesRef, adminsRef, primaryAuth } from "../core/firebase";
import { union } from "lodash";
import type { TPermissionId } from "@/resources/permissions/data";
import { country } from "@/config/country";

export let adminState: any = { admin: null, role: null };

const getAdminAndRole = async (uid: string) => {
	const docAdminRef = doc(adminsRef, uid);
	const admin =
		(await getDoc(docAdminRef).then((result) => result.data())) || null;

	let role = null;
	if (admin) {
		const docRoleRef = doc(adminRolesRef, admin.roleId);
		role = (await getDoc(docRoleRef).then((result) => result.data())) || null;
	}

	return { admin, role };
};

onAuthStateChanged(primaryAuth, async (user) => {
	if (user) {
		adminState = await getAdminAndRole(user.uid);
	} else {
		adminState = { admin: null, role: null };
	}
	await refreshSeeriApiConfig();
	window.dispatchEvent(new Event("admin"));
});

export const useAdmin = () => {
	const [admin, setAdmin] = useState(adminState.admin);
	const [role, setRole] = useState(adminState.role);

	const isAdmin = role?.name === "Admin";

	useEffect(() => {
		const setValues = () => {
			setAdmin(adminState.admin);
			setRole(adminState.role);
		};
		window.addEventListener("admin", setValues);
		return () => {
			window.removeEventListener("admin", setValues);
		};
	}, []);

	const hasPermission = (permission: TPermissionId): boolean => {
		const permissions = union(admin?.permissions, role?.permissions);
		return permissions.includes(permission) || isAdmin;
	};

	const allowedCountry = admin?.countries.includes(country.code);
	const enabled = Boolean(admin?.status);

	const shouldShowApp = enabled && allowedCountry;

	return {
		admin,
		role,
		hasPermission,
		isAdmin,
		allowedCountry,
		enabled,
		shouldShowApp,
	};
};
