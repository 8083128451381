import { SELLER_BILLING_ADDRESSES } from "@/config/resources";
import { FC } from "react";
import { CreateBase, useNotify, useRefresh } from "react-admin";
import { SellerInvoiceAddressForm } from "./_form";

type Props = {
  sellerId: string;
  onDone?: () => void;
};

export const SellerInvoiceAddressCreate: FC<Props> = ({ sellerId, onDone }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <CreateBase
      resource={SELLER_BILLING_ADDRESSES}
      mutationOptions={{
        onSuccess: () => {
          notify("Dirección creada");
          refresh();
          onDone?.();
        },
      }}
      redirect={false}
    >
      <SellerInvoiceAddressForm sellerId={sellerId} />
    </CreateBase>
  );
};
