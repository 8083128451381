import {
  number,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
} from "react-admin";
import { paymentStatus as allPaymentStatus } from "../../data/purchases";
import { useAdmin } from "../../hooks/admin";
import { CityInput, CountryInput, StateInput } from "../shared/location";
import { Grid } from "@mui/material";
import { usePaymentMethods } from "../../hooks/payment-methods";
import { country } from "@/config/country";
import { PURCHASES } from "@/config/resources";
import { i18nProvider } from "@/providers/i18n";

export const PurchasePaymentMethodInput = ({ disabled = true }) => {
  const { hasPermission } = useAdmin();
  const paymentMethods = usePaymentMethods();

  console.log("paymentMethods", paymentMethods);

  return (
    <SelectInput
      label="Método de pago"
      source="paymentMethod"
      choices={paymentMethods.map((pm) => ({
        code: pm.code,
        name: i18nProvider.translate(
          `resources.${PURCHASES}.fields.paymentMethods.${pm.code}`
        ),
      }))}
      optionValue="code"
      disabled={disabled && !hasPermission("purchase.update.paymentMethod")}
      fullWidth
    />
  );
};

export const PurchasePaymentStatusInput = ({ disabled = true }) => {
  const { hasPermission } = useAdmin();

  return (
    <SelectInput
      source="paymentStatus"
      choices={nextPaymentStatus()}
      optionValue="code"
      disabled={disabled && !hasPermission("purchase.update.paymentStatus")}
      fullWidth
    />
  );
};

type TChoice = { name: string; code: string };
const nextPaymentStatus = () => {
  const { paymentStatus } = useRecordContext();
  const currentPaymentStatus = allPaymentStatus.find(
    (status: TChoice) => status.code === paymentStatus
  );
  let choices: TChoice[] = !!currentPaymentStatus ? [currentPaymentStatus] : [];

  if (["PENDING", "REVIEW"].includes(paymentStatus)) {
    choices = [
      { name: "Pendiente", code: "PENDING" },
      { name: "Por revisar", code: "REVIEW" },
      { name: "Pagado", code: "PAID" },
    ];
  }

  return choices;
};

export const PurchaseClientLocation = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <CountryInput source="client.location.country" validate={required()} />
      </Grid>
      <Grid item xs={4}>
        <StateInput
          source="client.location.state"
          countrySource="client.location.country"
          validate={required()}
        />
      </Grid>
      <Grid item xs={4}>
        <CityInput
          source="client.location.city"
          stateSource="client.location.state"
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="client.location.addressLine1"
          validate={required()}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput source="client.location.addressLine2" />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          source="client.location.postalCode"
          validate={[number()].concat(
            country.code === "co" ? [] : [required()]
          )}
        />
      </Grid>
    </Grid>
  );
};
