import { TPaymentMethodV2, TSubPurchase } from "@/api";
import { format } from "date-fns";
import jsonexport from "jsonexport/dist";
import { map, partialRight, pick } from "lodash";
import { downloadCSV, fetchRelatedRecords as frr } from "react-admin";
import {
  BRANDS,
  CATEGORIES,
  PRODUCTS,
  SELLERS,
} from "../../../config/resources";
import { paymentStatus, status } from "../../../data/purchases";
import { dataProvider } from "../../../providers";
import { getAllHeadersNames } from "./headers";

export const processPurchasesCSV = async (
  rawPurchases: any[],
  headers: string[],
  paymentMethods: TPaymentMethodV2[]
) => {
  const purchases = structuredClone(rawPurchases);
  const fetchRelatedRecords = frr(dataProvider);

  const sellers = await fetchRelatedRecords(purchases, "sellerId", SELLERS);
  for (let id in sellers) {
    sellers[id] = pick(
      sellers[id],
      headers
        .filter((h) => h.startsWith("seller."))
        .map((h) => h.replace("seller.", ""))
    );
  }

  const purchasesProducts = purchases.map((p) => p.products).flat();

  const products = await fetchRelatedRecords(purchasesProducts, "id", PRODUCTS);
  const productsRecords = Object.values(products);

  const brands = await fetchRelatedRecords(
    purchasesProducts,
    "brandId",
    BRANDS
  );

  const categories = await fetchRelatedRecords(
    productsRecords,
    "categoryId",
    CATEGORIES
  );

  const subCategories = await fetchRelatedRecords(
    productsRecords,
    "subCategoryId",
    CATEGORIES
  );

  const purchasesForExport = purchases.map((purchase) => {
    purchase.products?.forEach((item: any) => {
      item.brandId = brands[item.brandId]?.name ?? item.brandId ?? "-";

      const product = products[item.id];
      item.categoryId = categories[product?.categoryId]?.name ?? "-";
      item.subCategoryId = subCategories[product?.subCategoryId]?.name ?? "-";

      const subPurchase = purchase.subPurchases.find(
        (sp: TSubPurchase) => sp.id === item.subPurchaseId
      );
      item.subPurchase = pick(
        subPurchase,
        headers
          .filter((h) => h.startsWith("products.subPurchase."))
          .map((h) => h.replace("products.subPurchase.", ""))
      );
      item.subPurchase.subPurchaseNumber ??= "-";
      item.subPurchase.deliveryStatus ??= "-";
      item.subPurchase.trackingCode ??= "-";
      item.subPurchase.paymentStatus ??= "-";
      item.deliveryStatus ??= "-";
      item.trackingCode ??= "-";
    });
    purchase.seller = sellers[purchase.sellerId];
    if (purchase.seller?.location) {
      purchase.seller.location.addressLine1 =
        purchase.seller.location.addressLine1?.replace(/\n/g, " ") ?? "";
      purchase.seller.location.addressLine2 =
        purchase.seller.location.addressLine2?.replace(/\n/g, " ") ?? "";
    } else {
      console.log("NO TIENE UBICACIÓN EN EL VENDEDOR");
      console.log({ purchase });
    }
    if (purchase.client?.location) {
      purchase.client.location.addressLine1 =
        purchase.client.location.addressLine1?.replace(/\n/g, " ") ?? "";
      purchase.client.location.addressLine2 =
        purchase.client.location.addressLine2?.replace(/\n/g, " ") ?? "";
    } else {
      console.log("NO TIENE UBICACIÓN EN EL CLIENTE");
      console.log({ purchase });
    }
    purchase.status = status.find((s) => s.code === purchase.status)?.name;
    purchase.paymentMethod = paymentMethods.find(
      (m) => m.code === purchase.paymentMethod
    )?.name;
    purchase.paymentStatus = paymentStatus.find(
      (s) => s.code === purchase.paymentStatus
    )?.name;

    const obj = pick(purchase, headers);
    obj.products = map(
      purchase.products,
      partialRight(
        pick,
        headers
          .filter((h) => h.startsWith("products."))
          .map((h) => h.replace("products.", ""))
      )
    );

    return obj;
  });

  jsonexport(
    purchasesForExport,
    {
      headers,
      rename: getAllHeadersNames(headers),
      fillGaps: true,
    },
    (err, csv) => {
      downloadCSV(csv, "Pedidos-" + format(new Date(), "yyyymmddhhmmss"));
    }
  );
};
