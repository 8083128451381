import { format } from "date-fns";
import jsonexport from "jsonexport/dist";
import { pick } from "lodash";
import { downloadCSV } from "react-admin";
import { BRANDS, CATEGORIES, PRODUCTS } from "../../../config/resources";
import {
  productLocationTypeChoices,
  productTypeChoices,
} from "../../../data/products";
import { i18nProvider } from "../../../providers/i18n";

const headers = [
  "id",
  "type",
  "products",
  "sku",
  "supplierSku",
  "barCode",
  "categoryId",
  "subCategoryId",
  "brandId",
  "name",
  "stock",
  "retailPrice",
  "wholesalePrice",
  "taxPercentage",
  "locationType",
  "location.postalCode",
  "location.addressLine2",
  "location.addressLine1",
  "location.state",
  "location.country",
  "location.city",
  "calification",
  "status",
  "createdAt",
  "updatedAt",
  "groupProductId",
  "productOptions",
];

export const exporter = async (products: any[], fetchRelatedRecords: any) => {
  const categories = await fetchRelatedRecords(
    products,
    "categoryId",
    CATEGORIES
  );
  const subCategories = await fetchRelatedRecords(
    products,
    "subCategoryId",
    CATEGORIES
  );
  const brands = await fetchRelatedRecords(products, "brandId", BRANDS);
  const kitProductsIds = products.map((p) => ({
    ...p,
    kitIds: p.products.map((p: any) => p.id),
  }));
  const kitProducts = await fetchRelatedRecords(
    kitProductsIds,
    "kitIds",
    PRODUCTS
  );

  const productsForExport = products.map((product) => {
    const obj = pick(product, headers);

    obj.type = productTypeChoices.find((t) => t.id === obj.type)?.name;
    obj.products = obj.products
      ?.map((p: any) => kitProducts[p.id]?.sku)
      .join(" - ");
    obj.categoryId = categories[product.categoryId]?.name;
    obj.subCategoryId = subCategories[product.subCategoryId]?.name;
    obj.brandId = brands[product.brandId]?.name;
    obj.locationType = productLocationTypeChoices.find(
      (t) => t.id === obj.locationType
    )?.name;
    obj.productOptions = !obj.productOptions
      ? "-"
      : Object.entries(obj.productOptions).reduce(
          (str, [k, v], i, arr) =>
            str + `${k}:${v}` + (i !== arr.length - 1 ? ";" : ""),
          ""
        );

    return obj;
  });

  jsonexport(
    productsForExport,
    {
      headers,
      rename: headers.map((h) =>
        i18nProvider.translate(`resources.${PRODUCTS}.fields.${h}`)
      ),
      fillGaps: true,
    },
    (err, csv) => {
      downloadCSV(csv, "productos-" + format(new Date(), "yyyymmddhhmmss"));
    }
  );
};
