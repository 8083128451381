import { FC, useState } from "react";
import {
  Datagrid,
  TextField,
  ListBase,
  Button,
  useRecordContext,
  BooleanField,
} from "react-admin";
import { SUPPLIER_ADDRESSES } from "../../../config/resources";
import { CustomPagination } from "../../shared/pagination";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Dialog, DialogContent } from "@mui/material";
import { SupplierAddressCreate } from "./create";
import { SupplierAddressEdit } from "./edit";

type Props = {
  supplierId: string;
};

const AddressDialog: FC<{ mode: "create" | "edit"; supplierId: string }> = ({
  mode,
  supplierId,
}) => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          {mode === "create" ? (
            <SupplierAddressCreate
              supplierId={supplierId}
              onDone={() => setOpen(false)}
            />
          ) : (
            <SupplierAddressEdit
              supplierId={supplierId}
              id={record.id as string}
              onDone={() => setOpen(false)}
            />
          )}
        </DialogContent>
      </Dialog>
      <Button
        label={mode === "create" ? "Crear" : "Editar"}
        startIcon={mode === "create" ? <AddIcon /> : <EditIcon />}
        onClick={() => setOpen(true)}
      />
    </>
  );
};

export const SupplierAddressList: FC<Props> = ({ supplierId }) => {
  return (
    <ListBase
      resource={SUPPLIER_ADDRESSES}
      queryOptions={{ meta: { dependentId: supplierId } }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", my: "8px" }}>
        <AddressDialog mode="create" supplierId={supplierId} />
      </Box>
      <Datagrid bulkActionButtons={false}>
        <BooleanField source="isMain" label="Principal" FalseIcon={null} />
        <TextField source="name" emptyText="-" />
        <TextField source="country" />
        <TextField source="state" />
        <TextField source="city" />
        <TextField source="addressLine1" sortable={false} />
        <TextField source="addressLine2" sortable={false} />
        <TextField source="postalCode" sortable={false} />
        <AddressDialog mode="edit" supplierId={supplierId} />
      </Datagrid>
      <CustomPagination />
    </ListBase>
  );
};
