import { AxiosError, TSeller, createCoinsTransaction } from "@/api";
import {
  Button,
  Form,
  NumberInput,
  SelectInput,
  TextInput,
  minValue,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useAdmin } from "../../../hooks/admin";
import { ModalAction } from "@/components/common/ModalAction";
import useSWRMutation from "swr/mutation";
import { FieldValues } from "react-hook-form";
import { Refresh, CreateRounded } from "@mui/icons-material";
import { transactionChoices } from "./constants";
import { mutate } from "swr";

export const SellerCoinsTransactionAction = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const seller = useRecordContext<TSeller>();
  const { admin } = useAdmin();

  const { trigger, isMutating } = useSWRMutation(
    "seller-coins-transaction",
    (_, { arg }: { arg: FieldValues }) =>
      createCoinsTransaction(seller.id, {
        type: arg.type,
        reason: arg.reason,
        orientation: "POSITIVE",
        amount: arg.amount,
        createdBy: admin.id,
      }),
    {
      onSuccess: () => {
        notify("Transacción creada satisfactoriamente", { type: "success" });
        refresh();
        // Refresh coins transactions list
        mutate((key) => Array.isArray(key) && key[1] === "coins");
      },
      onError: (error) => {
        console.error(error);
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo realizar la operación", {
            type: "error",
          });
        }
      },
    }
  );

  const formId = "seller-coins-transaction";

  return (
    <ModalAction
      buttonText="Crear Transacción"
      buttonIcon={<CreateRounded />}
      dialogTitle="Crear Transacción"
      dialogMaxWidth="sm"
      dialogContent={(close) => (
        <Form id={formId} onSubmit={(values) => trigger(values).then(close)}>
          <SelectInput
            source="type"
            label="Tipo"
            choices={transactionChoices}
            optionValue="code"
            optionText="name"
            validate={required()}
            fullWidth
          />
          <NumberInput
            source="amount"
            label="Monto"
            validate={[required(), minValue(1, "El monto debe ser positivo")]}
            fullWidth
          />
          <TextInput
            source="reason"
            label="Razón"
            validate={[required()]}
            fullWidth
          />
        </Form>
      )}
      dialogActions={
        <Button
          label="Guardar"
          type="submit"
          size="medium"
          form={formId}
          disabled={isMutating}
          startIcon={
            isMutating && (
              <Refresh className={isMutating ? "rotate-icon" : ""} />
            )
          }
        />
      }
    />
  );
};
