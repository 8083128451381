import {
  AxiosError,
  TSubPurchase,
  TSubpurchaseVoucher,
  getSeeriApi,
} from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import {
  ArrayField,
  Button,
  Confirm,
  Datagrid,
  DateField,
  FileField,
  FileInput,
  Form,
  FunctionField,
  Labeled,
  NumberField,
  NumberInput,
  SelectField,
  minValue,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Refresh } from "@mui/icons-material";
import { Box, Card, CardContent, SvgIcon, Typography } from "@mui/material";
import { mdiFileDocumentPlus } from "@mdi/js";
import useSWRMutation from "swr/mutation";
import { uploadFile } from "@/utils/file-upload";
import { buckets } from "@/core/buckets";
import { PURCHASES } from "@/config/resources";
import { FieldValues } from "react-hook-form";
import { useAdmin } from "@/hooks/admin";
import { useState } from "react";
import { fixApiDateStr } from "@/utils/parse-date";

export const SubpurchaseVoucherAction = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { hasPermission } = useAdmin();
  const subpurchase = useRecordContext<TSubPurchase>();

  const { trigger: uploadVoucher, isMutating: uploadingVoucher } =
    useSWRMutation(
      "subpurchase-voucher-upload",
      async (_, { arg }: { arg: FieldValues }) => {
        const voucherLink = await uploadFile(
          buckets.purchases,
          PURCHASES,
          "subpurchases-vouchers",
          arg.voucherFile.rawFile
        );

        const data = await getSeeriApi()
          .post<any>(`/api/sub-purchases/${subpurchase.id}/voucher`, {
            voucherLink,
            amount: arg.amount,
          })
          .then((res) => res.data);

        return data;
      },
      {
        onSuccess() {
          notify("Comprobante cargado con éxito", { type: "success" });
          refresh();
        },
        onError(err) {
          const message =
            (err instanceof AxiosError && err.response?.data?.message) ||
            (err instanceof Error && err.message);
          notify("Error: " + message, {
            type: "error",
          });
        },
      }
    );

  const canUpload =
    hasPermission("subpurchase.action.voucher") &&
    subpurchase.paymentStatus === "PENDING";

  return (
    <ModalAction
      buttonMode="icon"
      buttonIcon={
        <SvgIcon>
          <path d={mdiFileDocumentPlus} />
        </SvgIcon>
      }
      dialogMaxWidth="md"
      dialogTitle="Comprobante de pago"
      dialogContent={() => (
        <>
          <Labeled label="Comprobantes" fullWidth>
            <ArrayField source="vouchers">
              <Datagrid bulkActionButtons={false}>
                <FunctionField
                  source="createdAt"
                  render={(voucher: TSubpurchaseVoucher) => (
                    <DateField
                      source="createdAt"
                      record={{ createdAt: fixApiDateStr(voucher.createdAt) }}
                    />
                  )}
                />
                <FileField
                  source="voucherLink"
                  title="Abrir archivo"
                  target="_blank"
                  emptyText="No se ha cargado ningún archivo"
                />
                <NumberField source="amount" />
                <SelectField
                  source="status"
                  choices={[
                    { id: "REVIEW", name: "En revisión" },
                    { id: "PAID", name: "Pagado" },
                  ]}
                />
                <FunctionField
                  label="Acciones"
                  render={(voucher: TSubpurchaseVoucher) => (
                    <SubpurchaseVoucherActions
                      subpurchase={subpurchase}
                      voucher={voucher}
                    />
                  )}
                />
              </Datagrid>
            </ArrayField>
          </Labeled>

          {canUpload && (
            <Card sx={{ marginTop: "16px", backgroundColor: "#fafafa" }}>
              <CardContent>
                <Form onSubmit={(values) => uploadVoucher(values)}>
                  <Typography fontWeight={"bold"} marginBottom={"8px"}>
                    Cargar comprobante de pago
                  </Typography>
                  <FileInput
                    source="voucherFile"
                    label="Archivo"
                    accept="image/png, image/jpeg, application/pdf"
                    helperText="Formatos aceptados: .jpg, .png o .pdf"
                    fullWidth
                    validate={[
                      required(),
                      (value) => {
                        if (!value?.rawFile) return "La imagen es requerida";
                      },
                      (value) => {
                        if (value?.rawFile?.size > 1_500_000)
                          return "La imagen supera los 1.5MB";
                      },
                    ]}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                  <NumberInput
                    label="Monto"
                    source="amount"
                    validate={[required(), minValue(0)]}
                    fullWidth
                  />
                  <Button
                    label="Guardar"
                    type="submit"
                    size="medium"
                    variant="contained"
                    disabled={uploadingVoucher}
                    startIcon={
                      uploadingVoucher && (
                        <Refresh
                          className={uploadingVoucher ? "rotate-icon" : ""}
                        />
                      )
                    }
                  />
                </Form>
              </CardContent>
            </Card>
          )}
        </>
      )}
    />
  );
};

const SubpurchaseVoucherActions = ({
  subpurchase,
  voucher,
}: {
  subpurchase: TSubPurchase;
  voucher: TSubpurchaseVoucher;
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { hasPermission } = useAdmin();
  const [showConfirm, setShowConfirm] = useState(false);

  const { trigger: reviewVoucher, isMutating: reviewingVoucher } =
    useSWRMutation(
      "subpurchase-voucher-review",
      async (_, { arg: voucher }: { arg: TSubpurchaseVoucher }) =>
        getSeeriApi().post(
          `/api/sub-purchases/${subpurchase.id}/voucher/${voucher.id}/review`,
          { paymentStatus: "PAID" }
        ),
      {
        onSuccess() {
          notify("Comprobante aprobado", { type: "success" });
          refresh();
        },
        onError(err) {
          const message =
            (err instanceof AxiosError && err.response?.data?.message) ||
            (err instanceof Error && err.message);
          notify("Error: " + message, {
            type: "error",
          });
        },
      }
    );

  const { trigger: deleteVoucher, isMutating: deletingVoucher } =
    useSWRMutation(
      "subpurchase-voucher-delete",
      async (_, { arg: voucher }: { arg: TSubpurchaseVoucher }) =>
        getSeeriApi().delete(
          `/api/sub-purchases/${subpurchase.id}/voucher/${voucher.id}`
        ),
      {
        onSuccess() {
          notify("Comprobante eliminado", { type: "info" });
          refresh();
        },
        onError(err) {
          const message =
            (err instanceof AxiosError && err.response?.data?.message) ||
            (err instanceof Error && err.message);
          notify("Error: " + message, {
            type: "error",
          });
        },
      }
    );

  const canReview = hasPermission("subpurchase.action.voucher.review");

  const canDelete = hasPermission("subpurchase.action.voucher.delete");

  return (
    <Box display={"flex"} gap={"8px"}>
      {canReview && voucher.status === "REVIEW" && (
        <Button
          variant="outlined"
          label="Aprobar"
          onClick={() => reviewVoucher(voucher)}
          disabled={reviewingVoucher}
        />
      )}
      {canDelete && (
        <Button
          variant="outlined"
          label="Eliminar"
          onClick={() => setShowConfirm(true)}
          disabled={deletingVoucher}
        />
      )}
      <Confirm
        isOpen={showConfirm}
        title="Eliminar voucher"
        content="¿Seguro que desea eliminar el voucher?"
        confirm="Sí"
        confirmColor="primary"
        cancel="Cancelar"
        onConfirm={() => {
          deleteVoucher(voucher);
          setShowConfirm(false);
        }}
        onClose={() => setShowConfirm(false)}
      />
    </Box>
  );
};
