import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CategoryForm } from "./_form";

export const CategoryCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("category.create")) return null;

  return (
    <Create redirect="show">
      <CategoryForm />
    </Create>
  );
};
