import {
  getDebtSummary,
  getDebtTransactions,
  getTransactionsBySellerId,
  TDebtTransaction,
  TSeller,
} from "@/api";
import { useEffect, useState } from "react";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  FunctionField,
  Labeled,
  Loading,
  NumberField,
  RecordContextProvider,
  ReferenceField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useGetList,
  useRecordContext,
  Link,
  SimpleShowLayout,
  DeleteWithConfirmButton,
} from "react-admin";
import { PURCHASES, SUPPLIERS, VOUCHERS } from "../../config/resources";
import {
  deliveryStatus,
  linkStatus,
  paymentStatus,
  status,
} from "../../data/purchases";
import { type, userTypes } from "../../data/seller";
import { useAdmin } from "../../hooks/admin";
import { SellerCoinsTab } from "./coins/_coins-tab";
import { Box } from "@mui/material";
import { SellerAddressList } from "./addresses/list";
import { useParams } from "react-router-dom";
import { usePaymentMethods } from "../../hooks/payment-methods";
import useSWR from "swr";
import { ModalImageField } from "../../components/elements/ModalImageField";
import { AddDocumment } from "./actions/AddDocument";
import { SellerPaymentLink } from "./actions/PaymentLink";
import { SellerInvoiceAddressesList } from "./billing-addresses/list";
import { MaxCreditCapacityAction } from "./actions/max-credit-capacity";
import { SellerAvailableCreditField } from "./fields/available-credit";
import { SellerDocuments } from "./fields/documents";
import { SellerSuppliersList } from "./show/suppliers";

export const TransactionsField = () => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const record = useRecordContext();
  const customRecord = { ...record, transactions };

  const { hasPermission } = useAdmin();

  useEffect(() => {
    getTransactionsBySellerId(record.id as string).then(setTransactions);
  }, []);

  return (
    <ArrayField source="transactions" record={customRecord}>
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" showTime />
        <TextField source="type" />
        <TextField source="amount" />
        <ReferenceField
          source="reference"
          reference={PURCHASES}
          link={hasPermission("purchase.view") ? "show" : false}
        >
          <TextField source="purchaseNumber" />
        </ReferenceField>
      </Datagrid>
    </ArrayField>
  );
};

const SellerPurchasesField = () => {
  const record = useRecordContext();
  const paymentMethods = usePaymentMethods();

  const {
    data: purchases,
    isLoading,
    error,
  } = useGetList(PURCHASES, {
    pagination: { page: 1, perPage: 50 },
    sort: { field: "createdAt", order: "DESC" },
    filter: {
      sellerId: record.id,
      status: "DONE",
    },
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const customRecord = { ...record, purchases };

  return (
    <Labeled label="Pedidos">
      <>
        <Link
          to={`/${PURCHASES}?displayedFilters=%7B%7D&filter=%7B"sellerId"%3A"${record.id}"%7D&order=DESC&page=1&perPage=20&sort=createdAt`}
          style={{ marginBottom: 16, display: "block" }}
        >
          Ver todos los pedidos de {record.firstName}
        </Link>
        <ArrayField source="purchases" record={customRecord}>
          <Datagrid bulkActionButtons={false}>
            <DateField source="createdAt" showTime />
            <FunctionField
              source="purchaseNumber"
              render={(record: any) => (
                <Link to={`/${PURCHASES}/${record.id}/show`}>
                  {record.purchaseNumber}
                </Link>
              )}
            />
            <FunctionField
              source="paymentMethod"
              render={(record: any) =>
                paymentMethods.find((s) => s.code === record.paymentMethod)
                  ?.name
              }
            />
            <NumberField source="total" />
            <FunctionField
              source="status"
              render={(record: any) =>
                status.find((s) => s.code === record.status)?.name
              }
            />
            <FunctionField
              source="paymentStatus"
              render={(record: any) =>
                paymentStatus.find((s) => s.code === record.paymentStatus)?.name
              }
            />
            <FunctionField
              source="deliveryStatus"
              render={(record: any) =>
                deliveryStatus.find((s) => s.code === record.deliveryStatus)
                  ?.name
              }
            />
            <FunctionField
              source="linkStatus"
              render={(record: any) =>
                linkStatus.find((s) => s.code === record.linkStatus)?.name
              }
            />
          </Datagrid>
        </ArrayField>
      </>
    </Labeled>
  );
};

const SellerShowActions = () => {
  const { hasPermission, admin } = useAdmin();
  const seller = useRecordContext<TSeller>();

  return (
    <TopToolbar style={{ display: "flex", alignItems: "center" }}>
      {hasPermission("seller.maxCreditCapacity") && <MaxCreditCapacityAction />}
      {hasPermission("seller.addDocument") && <AddDocumment />}
      {hasPermission("seller.update") && <EditButton />}
      {hasPermission("seller.delete") && seller && (
        <DeleteWithConfirmButton
          confirmTitle={`Eliminar vendedor ${seller?.email}`}
          mutationOptions={{
            meta: { reason: `Eliminado desde backoffice por ${admin.email}` },
          }}
        />
      )}
    </TopToolbar>
  );
};

export const SellerShow = () => {
  const { hasPermission } = useAdmin();
  const { id } = useParams();

  if (!hasPermission("seller.view")) return null;

  return (
    <>
      <Show actions={<SellerShowActions />}>
        <TabbedShowLayout>
          <Tab label="Principal">
            <TextField source="id" />
            <SelectField source="userType" choices={userTypes} />
            <TextField source="firstName" />
            <TextField source="lastName" />
            {hasPermission("seller.show.email") && (
              <EmailField source="email" />
            )}
            {hasPermission("seller.show.phone") && <TextField source="phone" />}
            <TextField source="documentType" />
            <TextField source="documentId" />
            <DateField source="birthDate" />
            <TextField source="gender" />
            <NumberField source="maxCreditCapacity" />
            <SellerAvailableCreditField />
            <BooleanField source="vip" emptyText="-" />
            <BooleanField source="status" />
            <BooleanField source="manualInvoice" emptyText="-" />
            <SelectField source="type" choices={type} />
            <ReferenceField
              source="supplierId"
              reference={SUPPLIERS}
              link={hasPermission("supplier.view") ? "show" : false}
            >
              <TextField source="name" />
            </ReferenceField>
          </Tab>
          <Tab label="Direcciones">
            <SellerAddressList sellerId={id!} />
          </Tab>
          <Tab label="Facturación">
            <SellerInvoiceAddressesList sellerId={id!} />
          </Tab>
          <Tab label="Pedidos">
            <NumberField source="balanceAmount" />
            <NumberField source="creditsAmount" />
            <TextField source="referralCode" />
            <TextField source="myReferralCode" />
            <NumberField source="lastMonthSales" />
            {hasPermission("seller.show.transactions") && (
              <SellerPurchasesField />
            )}
          </Tab>
          <Tab label="Deudas">
            <SellerDebts />
          </Tab>
          <Tab label="Documentos">
            <SellerDocuments />
          </Tab>
          <Tab label="Miscelánea">
            <TextField source="source" />
            <TextField source="kam" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
          </Tab>
          {hasPermission("seller.coins.show") && (
            <Tab label="Coins">
              <SellerCoinsTab />
            </Tab>
          )}
          <Tab label="Proveedores">
            <SellerSuppliersList />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

const SellerDebts = () => {
  const { hasPermission } = useAdmin();
  const seller = useRecordContext<TSeller>();

  const { data: debt } = useSWR(
    () => [seller.id, "seller-debt"],
    ([id]) => getDebtSummary(id)
  );

  const { data: result, mutate } = useSWR(
    () => [seller.id, "seller-debt-transactions"],
    ([id]) =>
      getDebtTransactions({
        search: `sellerId:${id}`,
        sort: "createdAt,desc",
        size: 200,
      })
  );

  const debtRecord = { ...debt, transactions: result?.content ?? [] };

  return (
    <RecordContextProvider value={debtRecord}>
      <SimpleShowLayout>
        <Box display="flex" gap={2} justifyContent="space-between">
          <Box display={"flex"} alignItems="center">
            <Labeled>
              <NumberField label="Deuda" source="debt" />
            </Labeled>
            <SellerPaymentLink sellerId={seller.id} />
          </Box>
          <Labeled>
            <NumberField label="Revenue" source="revenue" />
          </Labeled>
          <Labeled>
            <NumberField
              label="Contraentrega rechazados"
              source="uponDeliveryRejected"
            />
          </Labeled>
          <Labeled>
            <BooleanField label="Bloqueado por deuda" source="blockedByDebt" />
          </Labeled>
        </Box>
        <ArrayField label="Transacciones" source="transactions">
          <Datagrid rowClick={undefined} bulkActionButtons={false}>
            <TextField source="id" />
            <DateField source="createdAt" showTime />

            <FunctionField
              source="type"
              render={(record: any) => (
                <>
                  {
                    { SUB_PURCHASE: "Subpedido", PAYMENT: "Pago" }[
                      record.type as string
                    ]
                  }
                </>
              )}
            />

            <FunctionField
              label="Referencia"
              render={(record: any) =>
                record.type === "SUB_PURCHASE" ? (
                  <Link
                    to={`/${PURCHASES}/${record.purchase?.purchaseId}/show`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {record.subPurchase?.subPurchaseNumber}
                  </Link>
                ) : record.payment?.voucher ? (
                  <Box display="flex" gap={2} alignItems="center">
                    <Link
                      to={`/${VOUCHERS}/${record.payment?.voucher?.id}/show`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Voucher {record.payment?.voucher?.id}
                    </Link>
                    <ModalImageField
                      source="payment.voucher.url"
                      maxHeight={32}
                    />
                  </Box>
                ) : record.payment?.type === "ONLINE" ? (
                  <>Pago online ({record.payment?.reference})</>
                ) : (
                  "-"
                )
              }
            />
            <FunctionField
              source="amount"
              textAlign="right"
              render={(record: any) => (
                <NumberField
                  source="amount"
                  color={record.amount >= 0 ? "green" : "red"}
                />
              )}
            />
            <FunctionField
              source="status"
              render={(record: any) =>
                ({ PENDING: "Pendiente", PAID: "Pagado" }[
                  record.status as string
                ])
              }
            />

            {hasPermission("voucher.delete.approved") && (
              <FunctionField
                render={(record: TDebtTransaction) => {
                  if (!record.payment?.voucher) return null;
                  return (
                    <DeleteWithConfirmButton
                      resource={VOUCHERS}
                      record={record.payment?.voucher}
                      mutationOptions={{
                        meta: { force: true },
                        onSuccess: () => mutate(),
                      }}
                      redirect={false}
                      confirmContent="Si elimina el voucher, se recalculará la deuda."
                    />
                  );
                }}
              />
            )}
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </RecordContextProvider>
  );
};
