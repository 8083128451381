import { AxiosError, TPurchase, getSeeriApi } from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import { OptimizedImageInput } from "@/components/elements/OptimizedImageInput";
import {
  Button,
  Form,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  required,
  useNotify,
  useRecordContext,
} from "react-admin";
import useSWRMutation from "swr/mutation";
import { Refresh, ArrowBack } from "@mui/icons-material";
import { mdiCashRefund } from "@mdi/js";
import { Icon, SvgIcon } from "@mui/material";
import { uploadFile } from "@/utils/file-upload";
import { buckets } from "@/core/buckets";
import { PURCHASES } from "@/config/resources";
import { useSWRConfig } from "swr";

export const PurchaseRefundAction = () => {
  const notify = useNotify();
  const purchase = useRecordContext<TPurchase>();
  const { mutate } = useSWRConfig();

  const { trigger, isMutating } = useSWRMutation(
    `/api/purchases/refunds/${purchase.id}`,
    async (url, { arg }: { arg: any }) => {
      const payload = structuredClone(arg);
      if (payload.image?.rawFile) {
        payload.image = await uploadFile(
          buckets.purchaseReturn,
          PURCHASES,
          "REFUNDS",
          arg.image.rawFile
        );
      }
      return getSeeriApi()
        .post(url, payload)
        .then((res) => res.data);
    },
    {
      onError(error) {
        notify(
          error instanceof AxiosError
            ? error.response?.data.message
            : "Ocurrió un error",
          { type: "error" }
        );
      },
    }
  );

  const formId = "refund-form";

  return (
    <ModalAction
      buttonIcon={
        <SvgIcon>
          <path d={mdiCashRefund} />
        </SvgIcon>
      }
      buttonMode="icon"
      dialogTitle="Generar reembolso"
      dialogMaxWidth="sm"
      dialogContent={(onClose) => (
        <Form
          id={formId}
          record={{}}
          onSubmit={(values) => {
            trigger(values, {
              onSuccess: () => {
                notify("Reembolso exitoso", { type: "info" });
                mutate([purchase.id, "payments"]);
                onClose();
              },
            });
          }}
        >
          <NumberInput
            source="amount"
            label="Monto"
            validate={required()}
            fullWidth
          />
          <SelectInput
            source="type"
            label="Tipo"
            defaultValue={"COINS"}
            choices={["COINS", "MANUAL"].map((id) => ({
              id,
              name: id.at(0) + id.slice(1).toLowerCase(),
            }))}
            validate={required()}
            fullWidth
          />
          <TextInput
            source="reason"
            label="Razón"
            validate={required()}
            multiline
            fullWidth
          />

          <FormDataConsumer>
            {({ formData }) =>
              formData.type === "MANUAL" && (
                <OptimizedImageInput
                  source="image"
                  label="Imagen"
                  validate={required()}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
        </Form>
      )}
      dialogActions={
        <Button
          label="Guardar"
          type="submit"
          size="medium"
          form={formId}
          disabled={isMutating}
          startIcon={
            isMutating && (
              <Refresh className={isMutating ? "rotate-icon" : ""} />
            )
          }
        />
      }
    />
  );
};
