import { z } from "zod";

export const QuoteStatus = z.enum([
  "PENDING",
  "QUOTED",
  "PAYMENT_IN_PROGRESS",
  "PAID",
  "DELIVERED",
]);

export type QuoteStatus = z.infer<typeof QuoteStatus>;

export const quoteStatusNames: Record<QuoteStatus, string> = {
  PENDING: "Pendiente",
  QUOTED: "Cotizado",
  PAYMENT_IN_PROGRESS: "Pago en progreso",
  PAID: "Pagado",
  DELIVERED: "Entregado",
} as const;

export const statusChoices = QuoteStatus.options.map((id) => ({
  id,
  name: quoteStatusNames[id],
}));
