import { ModalAction } from "@/components/common/ModalAction";
import { AxiosError, TSubPurchase, getSeeriApi } from "@/api";
import { FC } from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  Form,
  FormDataConsumer,
  FunctionField,
  NumberInput,
  TextField,
  minValue,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { PriceChange, Save, Delete } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Swal } from "@/core/swal";
import {
  TSubpurchaseTrackingCode,
  useSubpurchaseTrackingCodes,
} from "../../hooks/subpurchase-tracking-codes";

export const SubpurchaseTrackingCodesAction: FC = ({}) => {
  const subpurchase = useRecordContext<TSubPurchase>();
  const notify = useNotify();
  const refresh = useRefresh();

  const {
    data: trackingCodes = [],
    isLoading,
    mutate,
  } = useSubpurchaseTrackingCodes(subpurchase.id);

  return (
    <ModalAction
      dialogTitle={"Gestionar costos de envío"}
      buttonIcon={<PriceChange />}
      buttonText="Agregar distribuidor logístico"
      buttonMode={"icon"}
      dialogContent={() => (
        <ArrayField
          source="trackingCodes"
          record={{
            trackingCodes: trackingCodes.map((code, index) => ({
              ...code,
              index,
            })),
          }}
        >
          <Datagrid bulkActionButtons={false} isLoading={isLoading}>
            <DateField source="createdAt" label="Fecha de creación" showTime />
            <TextField source="code" label="Código" />
            <TextField source="deliveryProvider" label="Proveedor logístico" />

            <FunctionField
              source="deliveryCost"
              label="Costo de envío"
              render={(trackingCode: TSubpurchaseTrackingCode) => (
                <Box>
                  <Form
                    onSubmit={async (data) => {
                      try {
                        await getSeeriApi().put(
                          `/api/sub-purchases/${subpurchase.id}/tracking-code/${data.code}`,
                          { deliveryCost: data.deliveryCost }
                        );
                        await mutate();
                        notify("El costo de envío se actualizó correctamente", {
                          type: "success",
                        });
                        refresh();
                      } catch (error) {
                        console.error(error);
                        const msg =
                          error instanceof AxiosError
                            ? error.response?.data.message
                            : error + "";
                        notify(msg, { type: "error" });
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <NumberInput
                        source="deliveryCost"
                        label="Costo de envío"
                        validate={[required(), minValue(0)]}
                      />
                      <FormDataConsumer>
                        {({ formData }) => (
                          <Tooltip title="Guardar">
                            <div>
                              <IconButton
                                color="primary"
                                type="submit"
                                disabled={
                                  trackingCode.deliveryCost ===
                                  formData?.deliveryCost
                                }
                              >
                                <Save />
                              </IconButton>
                            </div>
                          </Tooltip>
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Form>
                </Box>
              )}
            />

            <FunctionField
              label="Eliminar"
              render={(trackingCode: any) => (
                <Tooltip
                  title={
                    trackingCode.index
                      ? ""
                      : "No puedes eliminar el último tracking code"
                  }
                >
                  <div>
                    <Button
                      disabled={!trackingCode.index}
                      label={"Eliminar"}
                      startIcon={<Delete />}
                      onClick={async () => {
                        const { isConfirmed } = await Swal.fire({
                          title:
                            "¿Seguro que quieres eliminar este código de seguimiento?",
                          showCancelButton: true,
                          confirmButtonText: "Sí, seguro",
                          cancelButtonText: "Cancelar",
                        });

                        if (!isConfirmed) return;

                        try {
                          await getSeeriApi().delete(
                            `/api/sub-purchases/${subpurchase.id}/tracking-code/${trackingCode.code}`
                          );
                          await mutate();
                          notify(
                            "El código de seguimiento fue eliminado correctamente.",
                            { type: "success" }
                          );
                          refresh();
                        } catch (error) {
                          console.error(error);
                          const msg =
                            error instanceof AxiosError
                              ? error.response?.data.message
                              : error + "";
                          notify(msg, { type: "error" });
                        }
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            />
          </Datagrid>
        </ArrayField>
      )}
    />
  );
};
