import {
  ADMINROLES,
  ADMINS,
  BRANDS,
  CATEGORIES,
  COUPONS,
  COURSES,
  LECTURES,
  PERMISSIONS,
  PRODUCTS,
  PURCHASES,
  RESOURCES,
  SELLER_ADDRESSES,
  SELLERS,
  SUPPLIER_ADDRESSES,
  SUPPLIERS,
  SUPPLIER_PURCHASES,
  TICKETS,
  VOUCHERS,
  BANNERS,
  PRODUCT_GROUPS,
  DOCUMENTS,
  QUOTES,
  SELLER_BILLING_ADDRESSES,
  OFFERTS,
  SUPPLIER_PAYMENTS,
  SUPPLIER_PERMISSIONS,
} from "../config/resources";

const products = {
  name: "Producto |||| Productos",
  fields: {
    approvedStatus: "Estado de aprobación",
    groupProductId: "Grupo",
    id: "ID",
    slug: "Slug",
    sku: "SKU Interno",
    barCode: "Código de barras",
    brandId: "Marca",
    categoryId: "Categoría",
    subCategoryId: "Subcategoría",
    approveStatus: "Aprobación",

    type: "Tipo",
    name: "Nombre",
    description: "Descripción",
    longDescription: "Descripción larga",
    totalStock: "Stock real",
    partialUnitsAllowed: "Unidades parciales permitidas",
    unitType: "Tipo de unidad",
    totalDebt: "Deuda de productos",
    taxClassification: "Clasificación de impuesto",
    taxPercentage: "Impuestos (%)",
    taxesType: "Tipos de impuesto",
    commissionBase: "Descuento vendedor independiente (%)",
    commissionExtra: "Descuento extra (%)",
    marginSeeri: "Margen Seeri (%)",
    products: "Productos",
    quantity: "Cantidad",
    price: "Precio",
    retailPrice: "Precio Venta Sugerido",
    suggestedDiscount: "Descuento Sugerido (%)",
    discountPercentage: "Descuento (%)",
    totalDiscountPercentage: "Descuento (%)",
    featured: "Destacado",
    status: "Estado",
    images: "Imágenes",
    "images.src": "Ruta",
    categoryAttributes: "Categoría de atributos",
    otherAttributes: "Otros atributos",

    // Location
    locationType: "Tipo de ubicación",
    location: "Ubicación",
    country: "País",
    state: "Estado",
    city: "Ciudad",
    postalCode: "Código postal",
    addressLine1: "Línea de dirección 1",
    addressLine2: "Línea de dirección 2",

    keyWords: "Palabras claves",
    recommendedProducts: "Productos recomendados",
    calification: "Calificación",
    retailDiscountPercentage: "Descuento tienda (%)",
    retailDiscountPrice: "Descuento tienda (Precio)",
    retailThresholdDiscountPercentage: "Desc tienda por meta mensual (%)",
    retailThresholdDiscountPrice: "Desc tienda por meta mensual (Precio)",
    retailFirstPurchaseDiscountPercentage: "Desc tienda 1ra compra (%)",
    retailFirstPurchaseDiscountPrice: "Desc tienda 1ra compra (Precio)",

    deliveryStatus: "Estado de envío",
    trackingCode: "Código de seguimiento",
    subTotalWithDiscount: "Subtotal con descuentos",
    discountByProfit: "Descuento base",
    discountByBenefit: "Descuento por beneficio",
    discountByProduct: "Descuento por producto",
    discountByCoupon: "Descuento por cupón",
    totalDiscount: "Descuento total",
    subtotal: "Subtotal",
    createdAt: "Creado el",
    createdBy: "Creado por",
    updatedAt: "Actualizado el",
    updatedBy: "Actualizado por",
    height: "Alto",
    width: "Ancho",
    length: "Largo",
    weight: "Peso",
    productOptions: "Opciones",

    // Product supplier
    supplierId: "Proveedor",
    stock: "Stock",
    availableStock: "Stock disponible",
    damagedStock: "Stock dañado",
    departureStock: "Stock de salida",
    reservedStock: "Stock reservado",
    disableInStockOut: "Deshabilitar en stock agotado",
    isMarketplace: "Marketplace",

    leadTime: "Tiempo de espera",
    days: "Días",

    wholesalePrice: "Precio al por mayor",
    supplierPrice: "Precio compra a distribuidor",
    supplier_price: "Precio compra a distribuidor",
    supplierSku: "SKU",
    sales: "Descuentos escalonados",
    applier: "Aplicador",
    valueType: "Tipo de valor",
    minValue: "Valor mínimo",
  },
};

export const es = {
  resources: {
    [ADMINROLES]: {
      name: "Rol de Admin |||| Roles de Admin",
      fields: {
        id: "ID",
        name: "Nombre",
        permissions: "Permisos",
        createdAt: "Creado el",
        createdBy: "Creado por",
        updatedAt: "Actualizado el",
        updatedBy: "Actualizado por",
      },
    },
    [ADMINS]: {
      name: "Administrador |||| Administradores",
      fields: {
        id: "ID",
        email: "Correo",
        phonePrefix: "Código de país",
        phone: "Teléfono",
        firstName: "Nombres",
        lastName: "Apellidos",
        roleId: "Rol",
        permissions: "Permisos específicos",
        status: "Estado",
        countries: "País(es)",
        createdAt: "Creado el",
        createdBy: "Creado por",
        updatedAt: "Actualizado el",
        updatedBy: "Actualizado por",
      },
    },
    [BRANDS]: {
      name: "Marca |||| Marcas",
      fields: {
        id: "ID",
        slug: "Slug",
        name: "Nombre",
        description: "Descripción",
        supplierId: "Proveedor",
        limitType: "Tipo de límite",
        limitValue: "Valor del límite",
        limitUsers: "Límite de usuarios",
        logo: "Logo",
        "logo.src": "Ruta de logo",
        manual: "Manual",
        "manual.src": "Ruta de manual",
        bannerDesktop: "Imagen de banner Desktop",
        "bannerDesktop.src": "Ruta de banner Desktop",
        bannerMobile: "Imagen de banner Móvil",
        "bannerMobile.src": "Ruta de banner Móvil",
        featured: "Destacado",
        sales: {
          target: "Usuario objetivo",
          minValue: "Valor mínimo",
          discountPercentage: "Porcentaje de descuento",
        },
        status: "Estado",
        createdAt: "Creado el",
        createdBy: "Creado por",
        updatedAt: "Actualizado el",
        updatedBy: "Actualizado por",
      },
    },
    [CATEGORIES]: {
      name: "Categoría |||| Categorías",
      fields: {
        id: "ID",
        slug: "Slug",
        name: "Nombre",
        code: "Código",
        description: "Descripción",
        type: "Tipo",
        value: "Valor",
        mandatory: "Mandatorio",
        parentCategoryId: "Categoría padre",
        attributes: "Atributos",
        image: "Imagen",
        "image.src": "Ruta de imagen",
        bannerDesktop: "Imagen de banner Desktop",
        "bannerDesktop.src": "Ruta de banner Desktop",
        bannerMobile: "Imagen de banner Móvil",
        "bannerMobile.src": "Ruta de banner Móvil",
        status: "Estado",
        createdAt: "Creado el",
        createdBy: "Creado por",
        updatedAt: "Actualizado el",
        updatedBy: "Actualizado por",
      },
    },
    [COUPONS]: {
      name: "Cupón |||| Cupones",
      fields: {
        id: "ID",
        code: "Código",
        status: "Estado",
        firstSale: "Primera compra",
        beginsAt: "Inicia el",
        endsAt: "Termina el",
        type: "Tipo",
        data: {
          name: "Nombre",
          sellersIds: "Vendedores",
          paymentMethods: "Métodos de pago",
          productsCategories: "Categorías de productos",
          productsSubCategories: "Subcategorías de productos",
          productsBrands: "Marcas de productos",
          productsIds: "Productos",
          maximumNumberUses: "Máximo de usos del cupón",
          minValue: "Monto mínimo de la compra",
          value: "Valor de descuento a aplicar",
          maxDiscount: "Valor máximo de descuento a aplicar",
          limitByUser: "Limites por usuario",
          productsFree: "Número de productos grátis",
          productsPaid: "Número de productos pagados",
          usesSamePurchase: "Limites por compra",
        },
        redemptionsNumber: "Número de usos",
        createdAt: "Creado el",
        createdBy: "Creado por",
        updatedAt: "Actualizado el",
      },
    },
    [COURSES]: {
      name: "Curso |||| Cursos",
      fields: {
        name: "Nombre",
        description: "Descripción",
        audience: "Audiencia",
        status: "Estado",
        image: "Imagen",
        "image.src": "Ruta de Imagen",
        video: "Video",
        "video.src": "Ruta de video",
      },
    },
    [LECTURES]: {
      name: "Clase |||| Clases",
      fields: {
        name: "Nombre",
        description: "Descripción",
        audience: "Audiencia",
        status: "Estado",
        image: "Imagen",
        "image.src": "Ruta de Imagen",
        video: "Video",
        "video.src": "Ruta de video",
        instructorName: "Nombres del instructor",
        instructorLastname: "Apellidos del instructor",
        instructorProfession: "Profesión del instructor",
        instructorPhoto: "Foto del instructor",
        "instructorPhoto.src": "Ruta de Foto del instructor",
      },
    },
    [RESOURCES]: {
      name: "Recurso |||| Recursos",
      fields: {
        name: "Nombre",
        url: "Archivo",
        "url.src": "Ruta de Archivo",
      },
    },
    [PERMISSIONS]: {
      name: "Permiso |||| Permisos",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    [SUPPLIER_PERMISSIONS]: {
      name: "Permiso de proveedor |||| Permisos de proveedor",
      fields: {
        name: "Nombre",
        description: "Descripción",
        key: "Identificador",
      },
    },
    [PRODUCTS]: products,
    [PRODUCT_GROUPS]: {
      name: "Grupo de Productos |||| Grupos de Productos",
      fields: {
        approvedStatus: "Estado de aprobación",
        id: "ID",
        name: "Nombre",
        supplierId: "Proveedor",
        brandId: "Marca",
        categoryId: "Categoría",
        subCategoryId: "Subcategoría",
        options: "Opciones",
        taxClassification: "Clasificación de impuesto",
        taxPercentage: "Impuestos (%)",
        taxesType: "Tipos de impuesto",
        visibleCategories: "Categorías visibles",
        visibleSubCategories: "Subcategorías visibles",
        keyWords: "Palabras clave",
        type: "Tipo",
        images: "Imágenes",
        featured: "Destacado",
        status: "Estado",
        createdAt: "Creado el",
        updatedAt: "Actualizado el",
        products: products.fields,
      },
    },
    [PURCHASES]: {
      name: "Pedido |||| Pedidos",
      fields: {
        id: "ID",
        purchaseNumber: "Número de pedido",
        priority: "Prioridad",
        recalculate: "Recalcular",
        sellerId: "Vendedor",
        kam: "KAM",
        status: "Estado",
        linkStatus: "Estado del enlace",
        creationInfo: "Datos de creación",
        deliveryStatus: "Estado de envío",
        deliveryProvider: "Proveedor",
        deliverOn: "Fecha para enviar",
        products: {
          id: "Producto",
          price: "Precio",
          quantity: "Cantidad",
          taxPercentage: "Impuestos (%)",
          commissionExtra: "Descuento extra (%)",
          commissionSeeri: "Descuento Seeri (%)",
          wholesalePrice: "Precio al por mayor",
          suggestedDiscount: "Descuento Sugerido (%)",
          subTotalWithDiscount: "Subtotal con descuentos",
          brandId: "Marca",
          deliveryStatus: "Estado de envío",
          trackingCode: "Código de seguimiento",
        },
        subPurchases: {
          id: "Subpedido",
          subPurchaseNumber: "Número",
          createdAt: "Creación",
          purchaseId: "ID de Pedido",
          products: "Productos",
          invoices: "Facturas",
          deliveryStatus: "Estado de envío",
          deliveryProvider: "Proveedor logístico",
          deliveryCost: "Costo de envío",
          trackingCode: "Código de seguimiento",
        },
        paymentMethods: {
          ONLINE: "En linea",
          CREDIT: "Credito",
          UPON_DELIVERY: "Contraentrega",
          SUPPLIER_PAY: "Pago a proveedor",
          IN_STORE: "En tienda",
          UPON_DELIVERY_SEERI: "Pago a comercial",
        },
        paymentProviders: {
          PLATAM: "platam",
          SUPPLIER_CREDIT: "Credito proveedor",
          WOMPI: "wompi",
          ACH: "ach",
          CHECK: "check",
          ZELLE: "zelle",
          CASH: "cash",
          COORDINADORA: "Coordinadora",
        },
        price: "Precio",
        quantity: "Cant.",
        taxPercentage: "Impuestos (%)",
        commissionBase: "Desc. base (%)",
        commissionExtra: "Desc. extra (%)",
        subTotalWithDiscount: "Total",
        commissionSeeri: "Desc. Seeri (%)",
        client: {
          id: "ID",
          documentType: "Tipo de documento",
          documentId: "Número de documento",
          displayName: "Apodo",
          firstName: "Nombres",
          lastName: "Apellidos",
          email: "Correo",
          phone: "Celular",
          status: "Estado",
          location: {
            country: "País",
            state: "Estado",
            city: "Ciudad",
            postalCode: "Código postal",
            addressLine1: "Línea de dirección 1",
            addressLine2: "Línea de dirección 2",
          },
        },
        paymentStatus: "Estado del pago",
        paymentMethod: "Método de pago",
        webCheckoutLink: "Enlace de pago",
        trackingCode: "Cód. Seguimiento",
        shipping: "Costo envío",
        retailPriceTotal: "Precio de venta sugerido al público total",
        discountByProfit: "Descuento base",
        discountByProduct: "Descuento por producto",
        discountByCoupon: "Descuento por cupón",
        totalTaxes: "Impuestos",
        totalProfit: "Ganancia total",
        totalDiscount: "Descuento total",
        discount: "Descuento total",
        subtotal: "Subtotal",
        total: "Total",
        partialMethodAmount: "Monto pagado por método parcial",
        partialPaymentMethod: "Método de pago parcial",
        pendingToPay: "Pendiente por pagar",
        state: "Estado",
        rejectionReason: "Razón de cancelación",
        purchaseTimeline: "Estado del envío (timeline)",
        vouchers: {
          voucher: "Comprobante de pago",
          "file.src": "Comprobante de pago",
          amount: "Monto",
        },
        "origin.name": "Origen",
        deliveredAt: "Entregado el",
        expiresAt: "Expira el",
        createdAt: "Creado el",
        createdBy: "Creado por",
        updatedAt: "Actualizado el",
        updatedBy: "Actualizado por",
        shippingPrice: "Costo de envío",
        voucherLink: "Enlace al archivo",
        amount: "Monto",
      },
    },
    [VOUCHERS]: {
      name: "Comprobante de pago |||| Comprobantes de pago",
      fields: {
        id: "Identificador",
        amount: "Monto",
        sellerId: "Vendedor",
        purchases: "Pedidos",
        userId: "Cargado por",
        status: "Estado",
        createdAt: "Creado el",
        updatedAt: "Actualizado el",
        reference: "Código de transacción",
      },
    },
    [SUPPLIER_PURCHASES]: {
      name: "Subpedido |||| Subpedidos",
      fields: {
        purchaseNumber: "Número de pedido",
        orderNumber: "Número de pedido",
        createdAt: "Creado el",
        status: "Estado",
        "supplier.companyId": "Id de Empresa",
        "supplier.name": "Nombre del Proveedor",
        products: "Productos",
        trackingCode: "Número de guía",
        sku: "SKU",
        name: "Nombre",
        quantity: "Cantidad",
        paymentMethod: "Método de pago",
        deliveryProvider: "Proveedor logístico",
        deliveryStatus: "Estado de envío",
        supplierStatus: "Estado de proveedor",
        subPurchaseNumber: "Número de pedido",
        supplierSku: "SKU",
        priority: "Prioridad",
      },
    },
    [TICKETS]: {
      name: "Ticket |||| Tickets",
      fields: {
        products: "Productos",
        refund: "Reembolso",
        requestPickup: "Recoger productos",
        number: "No.",
        createdAt: "Creado el",
        ticketType: "Tipo",
        refundType: "Tipo de reembolso",
        purchaseNumber: "No. Pedido",
        subPurchaseId: "Id. Subpedido",
        subPurchaseNumber: "No. Subpedido",
        status: "Estado",
        comment: "Comentario",
        reply: "Respuesta Seeri",
        userId: "Usuario",
        evidences: "Evidencias",
        type: "Tipo",
        reason: "Razón",
        history: "Historial",
      },
    },
    [DOCUMENTS]: {
      name: "Documento |||| Documentos",
      fields: {
        actions: "Acciones",
        createdAt: "Subido el",
        enabled: "Activo",
        fileUrl: "Documento",
        id: "ID",
        isValidated: "Validado",
        seller: {
          sellerId: "Vendedor",
          id: "Vendedor",
          email: "Correo",
          firstName: "Nombre",
          lastName: "Apellido",
          balanceAmount: "Balance",
          creditsAmount: "Créditos",
          documentType: "Tipo de documento",
          documentId: "No. documento",
          gender: "Género",
          myReferralCode: "Mi Cód.referido",
          phone: "Teléfono",
          phonePrefix: "Prefijo de celular",
          referralCode: "Cód.referido",
          userType: "Tipo usuario",
          status: "Estado",
          isNewSeller: "Es nuevo",
          rank: "Rank",
          birthDate: "Fecha nacimiento",
          createdAt: "Creado el",
          validated: "Validado",
        },
        updatedAt: "Actualizado el",
        validatedAt: "Validado el",
        validatedBy: "Validado por",
        validatedReason: "Comentario de revisión",
        validationStatus: "Estado de validación",
      },
    },
    [SELLERS]: {
      name: "Vendedor |||| Vendedores",
      fields: {
        id: "ID",
        userType: "Tipo de usuario",
        rank: "Ranking",
        displayName: "Apodo",
        firstName: "Nombres",
        lastName: "Apellidos",
        roleId: "Rol",
        phonePrefix: "Prefijo de celular",
        phone: "Celular",
        status: "Estado",
        vip: "VIP",
        documentType: "Tipo de documento",
        documentId: "Número de documento",
        birthDate: "Fecha de nacimiento",
        gender: "Género",
        email: "Correo",
        maxCreditCapacity: "Capacidad Máxima de Crédito",
        balanceAmount: "Balance de cuenta",
        creditsAmount: "Cantidad de créditos",
        referralCode: "Código de referencia",
        myReferralCode: "Mi código de referencia",
        lastMonthSales: "Ventas del mes pasado",
        location: {
          country: "País",
          state: "Estado",
          city: "Ciudad",
          postalCode: "Código postal",
          addressLine1: "Línea de dirección 1",
          addressLine2: "Línea de dirección 2",
          latitude: "Latitud",
          longitude: "Longitud",
          updateAt: "Actualizado el",
        },
        bankAccount: {
          bankName: "Banco",
          accountType: "Tipo de cuenta",
          accountNumber: "Número de cuenta",
          verified: "Verificada",
        },
        purchaseNumber: "Número de pedido",
        linkStatus: "Estado del enlace",
        deliveryStatus: "Estado de envío",
        deliveryProvider: "Proveedor",
        paymentStatus: "Estado del pago",
        paymentMethod: "Método de pago",
        sellerId: "Vendedor",
        amount: "Monto",
        type: "Tipo",
        kam: "KAM",
        supplierId: "Proveedor",
        reference: "Pedido",
        createdAt: "Creado el",
        createdBy: "Creado por",
        updatedAt: "Actualizado el",
        updatedBy: "Actualizado por",
      },
    },
    [SELLER_ADDRESSES]: {
      name: "Dirección |||| Direcciones",
      fields: {
        country: "País",
        state: "Estado",
        city: "Ciudad",
        addressLine1: "Dirección 1",
        addressLine2: "Dirección 2",
        postalCode: "Código Postal",
        isMain: "Principal",
      },
    },
    [SELLER_BILLING_ADDRESSES]: {
      name: "Dirección de facturación |||| Direcciones de facturación",
      fields: {
        id: "ID",
        firstName: "Nombres",
        lastName: "Apellidos",
        documentType: "Tipo de documento",
        documentId: "Número de documento",
        verifierDigit: "Dígito verificador",
        phonePrefix: "Prefijo de celular",
        phone: "Número de celular",
        email: "Correo",
        country: "País",
        state: "Estado",
        city: "Ciudad",
        addressLine1: "Línea de dirección 1",
        addressLine2: "Línea de dirección 2",
        createdAt: "Creación",
        sellerId: "Usuario",
      },
    },
    [SUPPLIER_ADDRESSES]: {
      name: "Almacén de Proveedor |||| Almacenes de Proveedor",
      fields: {
        name: "Nombre",
        country: "País",
        state: "Estado",
        city: "Ciudad",
        addressLine1: "Dirección 1",
        addressLine2: "Dirección 2",
        postalCode: "Código Postal",
      },
    },
    [SUPPLIERS]: {
      name: "Proveedor |||| Proveedores",
      fields: {
        id: "ID",
        companyId: "ID de Empresa",
        name: "Nombre del proveedor",
        publicName: "Nombre público",
        email: "Correo",
        communicationChannel: "Canal de comunicaciones",
        marginSeeri: "Margen Seeri",
        status: "Estado",
        contactName: "Nombre del contacto",
        socialReason: "Razón social",
        phonePrefix: "Prefijo de celular",
        phone: "Teléfono/Celular",
        digitalDocument: "Documento digital (RUT/RFC)",
        "digitalDocument.src": "Documento digital (RUT/RFC)",
        paymentMethodDeal: "Forma de pago negociada",
        location: {
          country: "País",
          state: "Estado",
          city: "Ciudad",
          postalCode: "Código postal",
          addressLine1: "Línea de dirección 1",
          addressLine2: "Línea de dirección 2",
        },
        sales: {
          target: "Usuario objetivo",
          minValue: "Valor mínimo",
          discountPercentage: "Porcentaje de descuento",
        },
        limitType: "Tipo de límite",
        limitValue: "Valor de límite",
        limitUsers: "Limitación a usuarios",
        negotiationType: "Tipo de negociación",
        priceListIncreasePercentage: "Porcentaje de incremento",
        supplierTakeRates: {
          discount: "Descuento (%)",
          takeRate: "Porcentaje de cobro (%)",
        },
        paymentGatewayServiceTakeRate:
          "Porcentaje por uso por servicios de pago (%)",
        logisticServiceTakeRate:
          "Porcentaje por uso de servicios logísticos (%)",
        multiSupplierProducts: "Multidistribuidor",
        // Payments
        payments: "Pagos",
        paymentNumber: "Número de pago",
        createdAt: "Creado el",
        createdBy: "Creado por",
        updatedAt: "Actualizado el",
        updatedBy: "Actualizado por",
      },
    },
    [SUPPLIER_PAYMENTS]: {
      name: "Proveedor Pago |||| Proveedor Pagos",
      fields: {
        paymentNumber: "Número de pago",
        reference: "Referencia",
        supplierId: "ID Proveedor",
        supplierName: "Proveedor",
        total: "Total",
        status: "Estado",

        transactions: "Transacciones",
        type: "Tipo",
        cutoffDate: "Fecha de corte",

        createdAt: "Creado el",
        updatedAt: "Actualizado el",
      },
    },
    [QUOTES]: {
      name: "Cotización |||| Cotizaciones",
      fields: {
        id: "ID",
        quoteNumber: "Número de cotización",
        status: "Estado",
        price: "Precio",
        supplierId: "Proveedor",
        quoteFileUrl: "Archivo de cotización",
        paymentFileUrl: "Imagen de pago",
        createdAt: "Creación",
        updatedAt: "Actualización",
        products: "Productos",
        productId: "Producto",
        quantity: "Cantidad",
        totalNeeded: "Necesitado por pedidos",
        supplierPrice: "Precio proveedor",
        purchases: "Pedidos",
        minRequired: "Mínimo requerido",
      },
    },
    [BANNERS]: {
      name: "Banner |||| Banners",
      fields: {
        id: "ID",
        name: "Nombre",
        description: "Descripción",
        type: "Tipo",
        typeIds: "Elementos de tipo",
        availableSince: "Disponible desde",
        availableUntil: "Disponible hasta",
        images: {
          webImage: "Imagen para web",
          mobileImage: "Imagen para móviles",
        },
        priority: "Prioridad",
        redirectTo: {
          type: "Tipo de enlace",
          target: "Objetivo del enlace",
          data: {
            targetWeb: "Web: Url de enlace interno",
            targetMobile: "App móvil: Deep link",
          },
        },
        status: "Estado",
      },
    },
    [OFFERTS]: {
      name: "Oferta |||| Ofertas",
      fields: {
        id: "ID",
        createdAt: "Creado el",
        createdBy: "Creado por",
        enabled: "Estado",
        endsAt: "Termina el",
        firstSale: "Primera compra",
        name: "Nombre de la oferta",
        type: "Tipo",
        updatedAt: "Actualizado el",
        maximumUses: "Uso máximo de la oferta",
        maximumUsesByUser: "Máximo de usos por cliente",
        maximumUsesByPurchase: "Máximo de usos por pedido",
        targetBrandsProducts: "Marcas especificas",
        targetProductsIds: "Productos especificos",
        targetPaymentMethods: "Métodos de pago",
        productsToPay: "Productos a comprar",
        productsToTake: "Productos de regalo",
        targetCategoriesProducts: "Categorias especificas",
        targetSubCategoriesProducts: "Subcategorias especificas",
        targetSellersIds: "Clientes especificos",
        targetSuppliersProducts: "Proveedores especificos",
        maxValueToOffer: "Valor máximo de descuento",
        value: "Descuento a aplicar",
        minValue: "Valor mínimo de la compra",
        paymentMethods: "Métodos de pago",
        gifts: "Producto de regalo",
      },
    },
  },
};
