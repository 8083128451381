import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Button,
  NumberInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { atom, useAtom, useSetAtom } from "jotai";
import { PURCHASES } from "@/config/resources";
import { useAdmin } from "@/hooks/admin";
import { useCallback } from "react";
import { AxiosError, TPurchase, updatePurchasePriority } from "@/api";
import { useParams } from "react-router-dom";
import { isFinishedPurchase } from "../../shared/actions-disabled";

const PriorityModalOpen = atom(false);

export const PriorityButton = () => {
  const setPriorityModalOpen = useSetAtom(PriorityModalOpen);
  const { hasPermission } = useAdmin();
  const record = useRecordContext<TPurchase>();

  if (!hasPermission("purchase.update.priority")) return null;

  return (
    <div>
      <Tooltip title="Cambiar prioridad">
        <div>
          <IconButton
            color="primary"
            onClick={() => setPriorityModalOpen(true)}
            disabled={isFinishedPurchase(record) || !!record?.trackingCode}
          >
            <LowPriorityIcon />
          </IconButton>
        </div>
      </Tooltip>
      <PriorityDialog />
    </div>
  );
};

export const PriorityDialog = () => {
  const [open, setOpen] = useAtom(PriorityModalOpen);
  const record = useRecordContext();
  const { id: purchaseId } = useParams();
  const refresh = useRefresh();
  const notify = useNotify();

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        let { priority } = values;
        await updatePurchasePriority(purchaseId as string, priority);
        notify("El pedido se actualizó exitosamente", { type: "success" });
        refresh();
        setOpen(false);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }

        return;
      } finally {
        setOpen(false);
      }
    },
    [purchaseId]
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>Cambiar prioridad</DialogTitle>
      <DialogContent>
        <p>
          Para cambiar la prioridad del pedido considera los siguientes puntos
        </p>
        <ul
          style={{
            fontSize: "0.8rem",
            lineHeight: "1.5rem",
            paddingLeft: "1rem",
          }}
        >
          <li>
            Si la prioridad esta entre 0 y 999, este valor no se mantiene. Se
            recalcula con base en la prioridad que calcule el back.
          </li>
          <li>
            Si se coloca inferior a 0, el pedido no recalcula la prioridad y no
            se despacha por integración.
          </li>
          <li>
            Si se coloca más de 999, se aumenta la prioridad de envio en el
            siguiente batch de la integración.
          </li>
        </ul>
        <SimpleForm
          mode="onBlur"
          record={record}
          resource={PURCHASES}
          onSubmit={onSubmit}
          toolbar={<PriorityToolbar />}
        >
          <NumberInput source="priority" fullWidth />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button label="Cerrar" onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
};

export const PriorityToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);
