import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Link,
} from "@mui/material";

import {
  List,
  Datagrid,
  DateField,
  TextField,
  required,
  FunctionField,
  ReferenceInput,
  ReferenceField,
  SelectArrayInput,
  AutocompleteInput,
  useNotify,
  SimpleForm,
  TextInput,
  SimpleShowLayout,
  Labeled,
  Button,
  SaveButton,
  maxLength,
  useRefresh,
} from "react-admin";
import { atom, useAtom, useSetAtom } from "jotai";

import { useAdmin } from "../../hooks/admin";
import { SELLERS } from "../../config/resources";
import { validationStatuses } from "../../data/seller";
import { CustomPagination } from "../shared/pagination";
import { formatStatusColor } from "../../hooks/purchase";
import { useState } from "react";
import { TSellerFile, validateSellerFile } from "@/api";

const DocumentFilter = [
  <SelectArrayInput
    source="validationStatus"
    choices={validationStatuses}
    optionValue="code"
    alwaysOn
  />,
  <ReferenceInput source="sellerId" reference={SELLERS} alwaysOn>
    <AutocompleteInput
      label="Vendedor"
      filterToQuery={(searchText: string) => ({ email: searchText })}
      optionText="email"
    />
  </ReferenceInput>,
];

const sellerValidateDocumentModalOpen = atom(false);

export const DocumentList = () => {
  const [selectedDocument, setSelectedDocument] = useState<
    TSellerFile | undefined
  >(undefined);

  const { hasPermission } = useAdmin();
  const setValidateDocumentModalOpen = useSetAtom(
    sellerValidateDocumentModalOpen
  );

  if (!hasPermission("document.list")) return null;

  return (
    <>
      <ValidateDocumentDialog document={selectedDocument} />
      <List
        perPage={20}
        filter={{ enabled: true }}
        filters={DocumentFilter}
        pagination={<CustomPagination />}
        actions={false}
      >
        <Datagrid bulkActionButtons={false}>
          <DateField source="createdAt" showTime />
          <TextField source="fileType" label="Tipo de documento" />
          <FunctionField
            source="validationStatus"
            render={(record: any) => (
              <Chip
                size="small"
                variant="outlined"
                color={formatStatusColor(record.validationStatus)}
                sx={{ width: "fit-content" }}
                label={
                  validationStatuses.find(
                    (s) => s.code === record.validationStatus
                  )?.name
                }
              />
            )}
          />
          <ReferenceField
            source="seller.id"
            sortable={false}
            reference={SELLERS}
            link={hasPermission("seller.view") ? "show" : false}
          >
            <TextField source="email" />
          </ReferenceField>
          <TextField source="seller.firstName" />
          <TextField source="seller.lastName" />
          <FunctionField
            source="fileUrl"
            render={(record: any) => (
              <Link href={record.fileUrl} target="_blank">
                Ver documento
              </Link>
            )}
          />
          <FunctionField
            source="actions"
            render={(record: any) => (
              <>
                <Button
                  variant="outlined"
                  disabled={
                    record.validationStatus !== "IN_PROCESS" &&
                    hasPermission("seller.validate.files")
                  }
                  onClick={() => {
                    setSelectedDocument(record);
                    setValidateDocumentModalOpen(true);
                  }}
                  label="Validar"
                />
              </>
            )}
          />
          <DateField source="validatedAt" showTime />
        </Datagrid>
      </List>
    </>
  );
};

export const ValidateDocumentDialog = ({
  document,
}: {
  document?: TSellerFile;
}) => {
  const { admin } = useAdmin();
  const refresh = useRefresh();

  const [open, setOpen] = useAtom(sellerValidateDocumentModalOpen);

  const notify = useNotify();
  const [reason, setReason] = useState("");
  const { hasPermission } = useAdmin();

  if (!document) return null;

  const onValidate = async (validate: boolean) => {
    try {
      await validateSellerFile({
        sellerId: document.seller.id,
        validated: validate,
        validatedBy: admin.id,
        validateReason: reason,
      });
      notify("Los documentos se validaron satisfactoriamente");
      setOpen(false);
      refresh();
    } catch (error) {
      console.log({ error });
      notify("No se pudieron validar los documentos", {
        type: "error",
      });
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>Validar documentos fiscales</DialogTitle>
      <DialogContent>
        <SimpleShowLayout record={document}>
          <Stack direction="row" spacing={2}>
            <Labeled source="seller.firstName">
              <TextField source="seller.firstName" />
            </Labeled>
            <Labeled source="seller.lastName">
              <TextField source="seller.lastName" />
            </Labeled>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Labeled source="seller.documentType">
              <TextField source="seller.documentType" />
            </Labeled>
            <Labeled source="seller.documentId">
              <TextField source="seller.documentId" />
            </Labeled>
          </Stack>

          <ReferenceField
            source="seller.id"
            label="Correo"
            sortable={false}
            reference={SELLERS}
            link={hasPermission("seller.view") ? "show" : false}
          >
            <TextField source="email" />
          </ReferenceField>
          <FunctionField
            source="fileUrl"
            render={(record: any) => (
              <Link href={record.fileUrl} target="_blank">
                Ver documento
              </Link>
            )}
          />
        </SimpleShowLayout>
        <SimpleForm
          mode="onBlur"
          record={document}
          toolbar={
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "space-evenly" }}
            >
              <SaveButton
                type="submit"
                color="error"
                variant="contained"
                label="Rechazar"
                onClick={() => onValidate(false)}
              />
              <SaveButton
                type="submit"
                color="success"
                variant="contained"
                label="Aprobar"
                onClick={() => onValidate(true)}
              />
            </Stack>
          }
        >
          <TextInput
            multiline
            source="validatedReason"
            fullWidth
            value={reason}
            validate={[required(), maxLength(150)]}
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};
