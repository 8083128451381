import {
  Show,
  Tab,
  TabbedShowLayout,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { useAdmin } from "../../../hooks/admin";
import { PurchaseCancellationAction } from "./actions/cancellation";
import {
  PurchaseClientLocationButton,
  PurchaseClientLocationDialog,
} from "./actions/client-location";
import {
  PurchasePaymentMethodButton,
  PurchasePaymentMethodDialog,
} from "./actions/payment-method";
// import {
//   PurchasePaymentStatusButton,
//   PurchasePaymentStatusDialog,
// } from "./actions/payment-status";
import {
  PurchasePaymentVoucherButton,
  PurchasePaymentVoucherDialog,
} from "./actions/payment-voucher";
import { PurchaseProductsAction } from "./actions/products/products";
import {
  CreateSubPurchaseButton,
  CreateSubPurchaseDialog,
} from "./actions/create-subpurchase";
import { PurchaseProductsRetryButton } from "./actions/retry-shipping";
import { RemissionExportButton } from "./actions/remissions/remission-download";
import { PurchaseExportButton } from "./actions/purchase-export";
import { PriorityButton } from "./actions/priority";
import { PurchaseRevive } from "./actions/revive";
import { PurchaseMainTabContent } from "./tab-main";
import { PurchaseInfoTabContent } from "./tab-info";
import { PurchaseTicketsTabContent } from "./tab-tickets";
import { PurchaseShippingButton } from "./actions/purchase-shipping";

const PurchaseShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar sx={{ flexWrap: "wrap" }}>
      <PurchaseCancellationAction />
      <PurchasePaymentVoucherButton />
      <PurchaseProductsAction />
      <CreateSubPurchaseButton />
      {/* <PurchasePaymentStatusButton /> */}
      <PurchasePaymentMethodButton />
      <PurchaseClientLocationButton />
      <PurchaseProductsRetryButton forced={true} />
      <PurchaseProductsRetryButton />
      <PriorityButton />
      <PurchaseShippingButton />
      {hasPermission("purchase.action.revive") && <PurchaseRevive />}
      <RemissionExportButton />
      <PurchaseExportButton />
    </TopToolbar>
  );
};

const PurchaseShowTitle = () => {
  const record = useRecordContext();
  return <>Pedido {record?.purchaseNumber}</>;
};

export const PurchaseShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("purchase.view")) return null;

  return (
    <>
      <PurchasePaymentVoucherDialog />
      {/* <PurchasePaymentStatusDialog /> */}
      <PurchasePaymentMethodDialog />
      <PurchaseClientLocationDialog />
      <CreateSubPurchaseDialog />
      <Show actions={<PurchaseShowActions />} title={<PurchaseShowTitle />}>
        <TabbedShowLayout sx={{ flexWrap: "wrap", marginBottom: "100px" }}>
          <Tab label="Principal">
            <PurchaseMainTabContent />
          </Tab>
          <Tab label="Información">
            <PurchaseInfoTabContent />
          </Tab>
          <Tab label="Tickets">
            <PurchaseTicketsTabContent />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};
