import { ExcelProductGroupBase, ExcelVariant } from "./schemas/excel";

type TGroupHeaders = Record<keyof ExcelProductGroupBase, string>;
export const productGroupHeaders: TGroupHeaders = {
  name: "Nombre",
  brandName: "Marca",
  supplierName: "Distribuidor",
  categoryName: "Categoría",
  subCategoryName: "Subcategoría",
  taxesType: "Tipo de impuesto",
};

type TVariantHeaders = Record<keyof ExcelVariant, string>;
export const productHeaders: TVariantHeaders = {
  productOptions: "Opciones",
  retailPrice: "Precio de Venta Sugerido",
  description: "Descripción",
  longDescription: "Descripción larga",
  image: "Imagen",
  height: "Alto",
  width: "Ancho",
  length: "Largo",
  weight: "Peso",
  barCode: "Código de barras (Proveedor)",
  locationType: "Tipo de ubicación",

  supplierSku: "SKU (Proveedor)",
  price: "Precio",
  securityStock: "Stock de seguridad",
  status: "Estado",
  marketplace: "Marketplace",
};
