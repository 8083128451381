import { TSubPurchase } from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import { RemoveRedEye } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { format } from "date-fns";

export const SubpurchaseSupplierComments = ({
  subpurchase,
}: {
  subpurchase: TSubPurchase;
}) => {
  return (
    <ModalAction
      buttonMode="icon"
      buttonIcon={<RemoveRedEye />}
      dialogTitle="Detalles"
      dialogContent={
        <Box>
          {subpurchase.supplierStatus === "REJECTED" && (
            <Alert severity="error">
              <AlertTitle>
                El subpedido fue rechazado por el proveedor.
              </AlertTitle>
              <b>Razón:</b> {subpurchase.reasonCancellation} <br />
              <b>Mensaje:</b> {subpurchase.messageCancellation}
            </Alert>
          )}
          <Typography color={"primary.main"} margin={"16px 0 8px"}>
            Comentarios:
          </Typography>
          {subpurchase.comments?.length > 0 ? (
            <List>
              {subpurchase.comments.map((comment, i) => (
                <ListItem
                  key={i}
                  divider={i < subpurchase.comments.length - 1}
                  sx={{ display: "block" }}
                >
                  <Typography>{comment.message}</Typography>

                  <Typography fontSize={"14px"}>
                    Por <b>{comment.fullName}</b>. Fecha:{" "}
                    {format(new Date(comment.createdAt), "yyyy-MM-dd hh:mm a")}
                  </Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            <Alert severity="info">Sin comentarios</Alert>
          )}
        </Box>
      }
    />
  );
};
