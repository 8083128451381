import { getMetadataTaxTypes, TProductGroup, TTaxType } from "@/api";
import { useEffect, useState } from "react";
import { required, SelectInput, useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";

const TAX_EXCLUDED = "Excluído";

export const TaxTypesProductInput = () => {
  const { setValue } = useFormContext();
  const [types, setChoices] = useState<TTaxType[]>([]);
  const productGroup = useRecordContext<TProductGroup>();

  useEffect(() => {
    getMetadataTaxTypes().then((taxesTypes) => {
      setChoices(taxesTypes);
      if (productGroup) {
        if (productGroup.taxesType.length) {
          const tax = taxesTypes.find(
            (type) => productGroup.taxesType[0] === type.code
          )!;
          setValue("taxesTypeTemp", tax.name);
        } else {
          setValue("taxesTypeTemp", TAX_EXCLUDED);
        }
      }
    });
  }, []);

  return (
    <SelectInput
      label="Tipo de impuesto"
      source="taxesTypeTemp"
      choices={types
        .map((c) => ({
          id: c.name,
          name: c.name,
        }))
        .concat({ id: TAX_EXCLUDED, name: TAX_EXCLUDED })}
      disabled={!types.length}
      validate={[required()]}
      onChange={(event) => {
        const type = types.find((t) => t.name === event.target.value);
        setValue("taxesType", type ? [type.code] : []);
      }}
      fullWidth
    />
  );
};
