import { ModalAction } from "@/components/common/ModalAction";
import IconMarketplaceOff from "@mui/icons-material/Shop2Outlined";
import IconMarketplace from "@mui/icons-material/Shop2";
import IconStatusOff from "@mui/icons-material/DisabledByDefault";
import IconStatus from "@mui/icons-material/CheckBox";
import { Box } from "@mui/material";
import { AxiosError, getSeeriApi } from "@/api";
import { useState } from "react";
import { Button, useNotify, useRecordContext, useRefresh } from "react-admin";

export const SupplierProductsMarketplaceOrStatus = ({
  field,
  mode,
}: {
  field: "status" | "isMarketplace";
  mode: "enable" | "disable";
}) => {
  const supplier = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const updateSupplierProducts = async (onClose: Function) => {
    if (loading) return;
    setLoading(true);
    try {
      await getSeeriApi().put(
        `/api/products/${supplier.id}/massive-update-status`,
        { [field]: mode === "enable" }
      );
      notify(
        "Se actualizaron todos los productos del proveedor " + supplier.name,
        { type: "success" }
      );
      refresh();
      onClose();
    } catch (error) {
      notify(
        "Error: " +
          (error instanceof AxiosError ? error.response?.data.message : error),
        { type: "error" }
      );
    }
    setLoading(false);
  };

  const meta = {
    status: {
      enable: {
        text: "Activar todos",
        icon: <IconStatus />,
      },
      disable: {
        text: "Desactivar todos",
        icon: <IconStatusOff />,
      },
    },
    isMarketplace: {
      enable: {
        text: "Activar marketplace",
        icon: <IconMarketplace />,
      },
      disable: {
        text: "Desactivar marketplace",
        icon: <IconMarketplaceOff />,
      },
    },
  } as const;

  return (
    <>
      <ModalAction
        buttonText={meta[field][mode].text}
        buttonIcon={meta[field][mode].icon}
        buttonVariant="text"
        dialogMaxWidth="xs"
        dialogTitle={meta[field][mode].text}
        dialogContent={
          <Box>
            ¿Estás seguro que deseas ejecutar masivamente:{" "}
            {meta[field][mode].text}?
          </Box>
        }
        dialogActions={(onClose) => (
          <Button
            label="Confirmar"
            onClick={() => updateSupplierProducts(onClose)}
            disabled={loading}
          />
        )}
      />
    </>
  );
};
