import { z } from "zod";
import {
  getMetadataTaxTypes,
  TBrand,
  TCategory,
  TProductSupplier,
  TSupplier,
  TTaxType,
} from "@/api";

import { generateSlug } from "@/utils/slug";
import {
  humanizeProductOptions,
  parseProductOptions,
  parseTaxesType,
} from "../utils";

import { ExcelProductGroup, ExcelVariant } from "./excel";
import {
  getBrandByName,
  getCategoryByName,
  getSupplierByName,
} from "../../utils";

type TCache = {
  taxes: Promise<TTaxType[] | null> | null;
  brands: Record<string, Promise<TBrand | null>>;
  suppliers: Record<string, Promise<TSupplier | null>>;
  categories: Record<string, Promise<TCategory | null>>;
};

const cache: TCache = {
  taxes: null,
  brands: {},
  suppliers: {},
  categories: {},
};

export const ExcelProductGroupToDTO = ExcelProductGroup.transform(
  async (arg, ctx) => {
    // Fetch
    const [brand, supplier, category, subCategory, taxes] = await Promise.all([
      (cache.brands[arg.brandName] ??= getBrandByName(arg.brandName).catch(
        () => {
          delete cache.brands[arg.brandName];
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "No se encontró una marca con ese nombre.",
            path: ["brandName"],
          });
          return null;
        }
      )),
      (cache.suppliers[arg.supplierName] ??= getSupplierByName(
        arg.supplierName
      ).catch(() => {
        delete cache.suppliers[arg.supplierName];
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "No se encontró un proveedor con ese nombre.",
          path: ["supplierName"],
        });
        return null;
      })),
      (cache.categories[arg.categoryName] ??= getCategoryByName(
        arg.categoryName
      ).catch(() => {
        delete cache.categories[arg.categoryName];
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "No se encontró una categoría con ese nombre.",
          path: ["categoryName"],
        });
        return null;
      })),
      (cache.categories[arg.subCategoryName] ??= getCategoryByName(
        arg.subCategoryName
      ).catch(() => {
        delete cache.categories[arg.subCategoryName];
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "No se encontró una subcategoría con ese nombre..",
          path: ["subCategoryName"],
        });
        return null;
      })),
      (cache.taxes ??= getMetadataTaxTypes().catch(() => {
        cache.taxes = null;
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "No se pudieron obtener los impuestos desde el servidor",
          path: ["taxesType"],
        });
        return null;
      })),
    ]);

    if (
      category &&
      subCategory &&
      subCategory.parentCategoryId !== category.id
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "La subcategoría no pertenece a la categoría padre.",
        path: ["subCategoryName"],
      });
    }

    if (taxes?.length && arg.taxesType !== "") {
      const tax = taxes.find((t) => t.code === arg.taxesType);
      if (!tax) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_enum_value,
          options: ["", ...taxes.map((t) => t.code)],
          received: arg.taxesType,
          path: ["taxesType"],
        });
      }
    }

    const isPriceList = supplier?.negotiationType === "PRICE_LIST";

    const products = await Promise.all(
      arg.products.map(async (product: ExcelVariant, index: number) => {
        const productOptions = parseProductOptions(product.productOptions);
        const name = `${arg.name} ${humanizeProductOptions(productOptions)}`;
        const slug = generateSlug(name);

        const psPayload: Partial<TProductSupplier> = {
          supplierId: supplier?.id,
          supplierSku: product.supplierSku,
          status: product.status,
          disableInStockOut: true,
          securityStock: product.securityStock,
          isMarketplace: product.marketplace,
        };

        if (isPriceList) {
          psPayload.supplierPrice = product.price;
          psPayload.wholesalePrice = +(
            product.price /
            (1 - (supplier.priceListIncreasePercentage ?? 0) / 100)
          ).toFixed(2);
        } else {
          psPayload.wholesalePrice = product.price;
        }

        return {
          name,
          slug,
          description: product.description,
          longDescription: product.longDescription,
          images: product.image ? [{ src: product.image }] : [],
          productOptions,
          height: product.height,
          width: product.width,
          length: product.length,
          weight: product.weight,
          barCode: product.barCode,
          locationType: product.locationType,
          products: [],
          featured: false,
          status: false,

          suppliers: [psPayload],
        };
      })
    );

    // Compute group options
    const options: Record<string, string[]> = {};
    for (const product of products) {
      for (const [optionName, value] of Object.entries(
        product.productOptions
      )) {
        options[optionName] ??= [];
        if (!options[optionName].includes(value)) {
          options[optionName].push(value);
        }
      }
    }

    return {
      name: arg.name,
      brandId: brand?.id,
      supplierId: supplier?.id,
      categoryId: category?.id,
      subCategoryId: subCategory?.id,
      type: "PRODUCT",
      images: [],
      visibleCategories: [category?.id],
      visibleSubCategories: [subCategory?.id],
      keyWords: [arg.name],
      taxesType: parseTaxesType(arg.taxesType),
      featured: false,
      status: false,
      options,
      products,
    };
  }
);

export type ProductGroupDTO = z.infer<typeof ExcelProductGroupToDTO>;
