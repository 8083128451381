import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button, useNotify, useCreate } from "react-admin";
import { SELLERS } from "../../../config/resources";
import { SellerForm } from "../../sellers/_form";
import { atom, useAtom } from "jotai";

export const purchaseSellerModalOpen = atom(false);

export const SellerDialog = () => {
  const [create] = useCreate();
  const notify = useNotify();

  const [open, setOpen] = useAtom(purchaseSellerModalOpen);

  const onSubmit = (values: any) => {
    create(
      SELLERS,
      { data: values },
      {
        onSuccess: () => {
          notify("El vendedor fue creado correctamente.", {
            type: "success",
          });
          setOpen(false);
        },
        onError: (error: any) => {
          notify(error?.message || "Error al crear el vendedor.", {
            type: "warning",
          });
        },
      }
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>Crear vendedor</DialogTitle>
        <DialogContent>
          <SellerForm onSubmit={onSubmit} />
        </DialogContent>
        <DialogActions>
          <Button label="Cerrar" onClick={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </div>
  );
};
