import { AxiosError, TBrand, TProduct, updateProductsByBrand } from "@/api";
import { FC, ReactNode, useState } from "react";
import {
  ArrayField,
  BooleanField,
  Button,
  Datagrid,
  EditButton,
  FileField,
  ImageField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useNotify,
  useRecordContext,
} from "react-admin";
import { SUPPLIERS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import {
  SettingsRemote,
  SettingsRemoteOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

const BulkUpdateProductsButton: FC<{
  label: string;
  data: Partial<TProduct>;
  startIcon: ReactNode;
}> = ({ label, data, startIcon }) => {
  const notify = useNotify();
  const brand = useRecordContext() as TBrand;
  const [loading, setLoading] = useState(false);

  return (
    <Button
      label={label}
      disabled={loading}
      startIcon={startIcon}
      onClick={async () => {
        setLoading(true);
        try {
          await updateProductsByBrand(brand.id, data);
          notify(
            "Se actualizaron todos los productos de la marca " + brand.name,
            { type: "success" }
          );
        } catch (error) {
          notify(
            "Error: " +
              (error instanceof AxiosError
                ? error.response?.data.message
                : error),
            { type: "error" }
          );
        }
        setLoading(false);
      }}
    />
  );
};

const BrandShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>{hasPermission("brand.update") && <EditButton />}</TopToolbar>
  );
};

export const BrandShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("brand.view")) return null;

  return (
    <Show actions={<BrandShowActions />}>
      <TabbedShowLayout>
        <Tab label="Principal">
          <TextField source="id" />
          <TextField source="slug" />
          <TextField source="name" />
          <TextField source="description" />
          <BooleanField source="featured" />
          <BooleanField source="status" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
