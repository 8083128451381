import { LocalShipping } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  AxiosError,
  updateDeliveryStatusBySupurchaseId,
  TPurchase,
  TSubPurchase,
} from "@/api";
import { useCallback, useState } from "react";
import {
  Button,
  SaveButton,
  SimpleForm,
  Toolbar,
  useGetOne,
  useNotify,
  useRefresh,
  useRecordContext,
  required,
  SelectInput,
} from "react-admin";
import { PURCHASES } from "../../../../config/resources";
import { useAdmin } from "../../../../hooks/admin";
import { isFinishedPurchase } from "../../shared/actions-disabled";
import { deliveryStatus, deliveryStatusRendering } from "@/data/purchases";

const SubPurchaseDeliveryStatusEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const SubpurchaseDeliveryStatusButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const subpurchase = useRecordContext<TSubPurchase>();
  const { data: purchase } = useGetOne<TPurchase>(PURCHASES, {
    id: subpurchase.purchaseId,
  });

  const [open, setOpen] = useState(false);
  const { hasPermission } = useAdmin();

  const deliveryStatus = subpurchase?.deliveryStatus;
  const trackingCode = subpurchase?.trackingCode;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const { deliveryStatus } = values;
        await updateDeliveryStatusBySupurchaseId(
          subpurchase.id,
          deliveryStatus
        );
        notify("El subpedido se actualizó exitosamente", { type: "success" });
        refresh();
        setOpen(false);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }
        return;
      } finally {
        setOpen(false);
      }
    },
    [deliveryStatus]
  );

  return (
    <div>
      <Tooltip title="Actualizar estado de envío de subpedido">
        <div>
          <IconButton
            color="primary"
            onClick={handleClickOpen}
            disabled={
              isFinishedPurchase(purchase!) ||
              !hasPermission("subpurchase.update.deliveryStatus") ||
              ["CANCELLED", "DELIVERED"].includes(subpurchase.deliveryStatus) ||
              !trackingCode
            }
          >
            <LocalShipping />
          </IconButton>
        </div>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>Actualizar estado de envío del subpedido</DialogTitle>
        <DialogContent>
          <SimpleForm
            mode="onBlur"
            record={subpurchase}
            resource={PURCHASES}
            toolbar={<SubPurchaseDeliveryStatusEditToolbar />}
            onSubmit={onSubmit}
          >
            <SubPurchaseDeliveryStatusInput />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button label="Cerrar" onClick={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const SubPurchaseDeliveryStatusInput = () => {
  const { hasPermission } = useAdmin();
  const subpurchase = useRecordContext<TSubPurchase>();
  const canUpdate = hasPermission("purchase.deliveryStatus.rollback");

  const choices = getSubPurchaseDeliveryStatusChoices(subpurchase, canUpdate);

  return (
    <SelectInput
      source="deliveryStatus"
      choices={choices}
      optionValue="code"
      defaultValue={"PENDING"}
      validate={required()}
      fullWidth
    />
  );
};

const getSubPurchaseDeliveryStatusChoices = (
  subpurchase: TSubPurchase,
  canUpdate: boolean
) => {
  const currentDeliveryStatus = subpurchase.deliveryStatus as string;

  const currentChoice = deliveryStatus.find(
    (p) => p.code === currentDeliveryStatus
  );

  let choices = [currentChoice];

  const deliveredChoice = () => {
    const canBeDelivered =
      subpurchase.paymentMethod !== "BANK_TRANSFER" ||
      subpurchase.paymentStatus === "PAID";
    return canBeDelivered
      ? { name: "Entregado", code: "DELIVERED" }
      : undefined;
  };

  if (
    !currentDeliveryStatus ||
    ["PENDING", "REJECTED"].includes(currentDeliveryStatus)
  ) {
    choices = [
      { name: "Pendiente", code: "PENDING" },
      { name: "En camino", code: "IN_TRANSIT" },
      { name: "Rechazado", code: "REJECTED" },
    ];
  }
  if (currentDeliveryStatus === "PREPARATION") {
    choices = [{ name: "En camino", code: "IN_TRANSIT" }, deliveredChoice()];
  } else if (currentDeliveryStatus === "SUPPLIER_PENDING") {
    choices = deliveryStatusRendering.filter((s) =>
      ["PENDING", "IN_TRANSIT", "REJECTED"].includes(s.code)
    );
  } else if (
    currentDeliveryStatus === "IN_TRANSIT" ||
    currentDeliveryStatus === "IN_PROCESS"
  ) {
    choices = [
      { name: "En camino", code: "IN_TRANSIT" },
      deliveredChoice(),
      { name: "Rechazado", code: "REJECTED" },
    ];
  } else if (currentDeliveryStatus === "FAILED_TO_SEND" && canUpdate) {
    choices = [
      { name: "Fallo al enviar", code: "FAILED_TO_SEND" },
      { name: "En camino", code: "IN_TRANSIT" },
      { name: "Rechazado", code: "REJECTED" },
    ];
  }

  return choices.filter((_) => _);
};
