import { AxiosInstance } from "@/api";
import { addLog, primaryAuth } from "../core/firebase";
import { getBaseURL } from "../config/remote";
import { configureSeeriApi } from "../core/seeri-api";
import { adminState } from "../hooks/admin";
import { country } from "@/config/country";

export const refreshSeeriApiConfig = async () => {
  const baseURL = await getBaseURL(country);
  configureSeeriApi({
    baseURL,
  });
};

export const setSeeriApiInterceptors = async (instance: AxiosInstance) => {
  const userId = adminState.admin?.id;
  const role = adminState.role;

  if (!userId) {
    return instance;
  }

  instance.interceptors.request.use(
    async function (config) {
      const accessToken = await primaryAuth.currentUser?.getIdToken();
      if (accessToken && config.headers) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      if (
        ["put", "post", "delete"].includes(response.config.method || "") &&
        response.config.headers?.["x-log"] !== "omit"
      ) {
        const log = {
          data: response.config.data ?? "",
          date: new Date(),
          method: response.config.method!,
          origin: "backoffice",
          status: response.status,
          url: response.config.url!,
          userId,
          role: role?.name ?? "",
        };
        addLog(log);
      }

      return response;
    },
    function (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  );
  return instance;
};

export const setUserTypeApiConfig = async (userType: string) => {
  const baseURL = await getBaseURL(country);
  configureSeeriApi({
    baseURL,
    userType,
  });
};
