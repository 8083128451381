import { TSupplierPermission } from "@/api";
import { useAdmin } from "@/hooks/admin";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { FC } from "react";
import { SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";
import * as yup from "yup";

type TSupplierPermissionForm = {
  isEdit?: boolean;
};

const SupplierPermissionToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const schemaSupplierPermissionForm = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  key: yup.string().required(),
});

const supplierPermissionDefaultValues = () => ({
  name: "",
  description: "",
  key: "",
});

export const SupplierPermissionForm: FC<TSupplierPermissionForm> = ({
  isEdit,
}) => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("supplier-permission.create")) return null;

  return (
    <SimpleForm
      mode="onBlur"
      toolbar={<SupplierPermissionToolbar />}
      defaultValues={supplierPermissionDefaultValues()}
      resolver={yupResolver(schemaSupplierPermissionForm)}
      warnWhenUnsavedChanges
    >
      <Grid container spacing={4}>
        {isEdit && (
          <Grid item xs={4}>
            <TextInput source="id" disabled fullWidth />
          </Grid>
        )}
        <Grid item xs={4}>
          <TextInput source="name" fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="key" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextInput source="description" fullWidth multiline />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
