import { Identifier, RaRecord } from "react-admin";
import { ListItemText, MenuItem } from "@mui/material";
import { groupByPending } from "@/hooks/purchase";
import { formatCurrency } from "@/utils/currency";
import jsPDF from "jspdf";
import autoTable, { CellHookData, RowInput, Styles } from "jspdf-autotable";
import { TPaymentMethodV2, TPurchase, TPurchaseProduct } from "@/api";
import logo from "../logo.png";
import { usePaymentMethods } from "@/hooks/payment-methods";
import { country } from "@/config/country";
import { format } from "date-fns";

type ExportPDFMenuItem = {
  setState: (value: any) => void;
  record: RaRecord<Identifier>;
};

export const ExportPDFMenuItem: React.FC<ExportPDFMenuItem> = ({
  record,
  setState,
}) => {
  const paymentMethods = usePaymentMethods();

  return (
    <>
      <MenuItem
        onClick={() => {
          setState(null);
          exportRemission(record as TPurchase, paymentMethods);
        }}
      >
        <ListItemText>Descargar PDF</ListItemText>
      </MenuItem>
    </>
  );
};

const exportRemission = async (
  purchase: TPurchase,
  paymentMethods: TPaymentMethodV2[]
) => {
  const doc = new jsPDF();

  const { PENDING, PREPARING } = groupByPending(purchase?.products || []);

  const PRODUCTS_HEADERS = [
    { header: "ITEM", dataKey: "index" },
    { header: "CANTIDAD", dataKey: "quantity" },
    { header: "V/U", dataKey: "pvp" },
    { header: "PRODUCTO", dataKey: "name" },
    { header: "SUBTOTAL", dataKey: "subtotal" },
    { header: "DESCUENTO", dataKey: "totalDiscount" },
    { header: "TOTAL", dataKey: "finalPrice" },
  ];

  const styles: Partial<Styles> = {
    cellPadding: 2,
    cellWidth: "auto",
    fontStyle: "bold",
    fillColor: [245, 245, 245],
    lineWidth: 0.4,
    textColor: 0,
    fontSize: 8,
  };

  const whiteStyle: Partial<Styles> = {
    cellPadding: 2,
    fillColor: "#FFFFFF",
    lineWidth: 0.4,
  };

  const personalData = [
    [
      { content: "NOMBRE", colSpan: 1 },
      {
        content: purchase.client.displayName,
        colSpan: 3,
      },
    ],
    [
      { content: "IDENTIFICACION", colSpan: 1 },
      purchase.client.documentId,
      { content: "CIUDAD", colSpan: 1 },
      `${purchase.client.location.city}-${purchase.client.location.state}`,
    ],
    [
      { content: "DIRECCION", colSpan: 1 },
      purchase.client.location.addressLine1 +
        " \n" +
        (purchase.client.location.addressLine2 ?? "-"),
      { content: "TEL", colSpan: 1 },
      `${purchase.client.phonePrefix} ${purchase.client.phone}`,
    ],
    [
      "CÓDIGO POSTAL",
      purchase.client.location.postalCode || "-",

      "MÉTODO DE PAGO",
      paymentMethods.find((pm) => purchase.paymentMethod === pm.code)?.name ??
        purchase.paymentMethod,
    ],
    [
      { content: "CORREO", colSpan: 1 },
      { content: purchase.client.email, colSpan: 3 },
    ],
    [
      { content: "FECHA DE PEDIDO", colSpan: 1 },
      {
        content: format(new Date(purchase.createdAt), "yyyy/MM/dd hh:mm a"),
        colSpan: 3,
      },
    ],
  ];

  const companyData: RowInput[] = [
    [
      {
        content: "",
        styles: {
          cellWidth: 50,
          halign: "center",
        },
      },
      {
        content:
          country.code === "co"
            ? "SEERI COLOMBIA S.A.S\nNIT 901.492.363-7\nCL 70 BIS 4 41\nBogotá - Colombia"
            : "App Seeri Mexico\nRFC ASM210805LS9\nMontes Urales 424\nCiudad de México",
        styles: {
          halign: "center",
        },
      },
      {
        content: `NUMERO DE REMISION\n${purchase.purchaseNumber}`,
        styles: {
          ...styles,
          halign: "center",
          cellWidth: 50,
          cellPadding: 6,
        },
      },
    ],
  ];

  autoTable(doc, {
    body: companyData,
    startY: 20,
    styles: whiteStyle,
    theme: "grid",
    didDrawCell: (data) => {
      drawImage(0, data, doc);
    },
  });

  autoTable(doc, {
    body: personalData,
    theme: "grid",
    columnStyles: {
      0: styles,
      1: whiteStyle,
      2: styles,
      3: whiteStyle,
    },
  });

  if (PENDING.length) {
    drawTableTitle(doc, `PRODUCTOS PENDIENTES`, styles);
    doc.setFontSize(12);
    autoTable(doc, {
      body: await generateProductTable(PENDING),
      theme: "grid",
      columns: PRODUCTS_HEADERS,
      styles: { ...whiteStyle, fontSize: 8 },
      headStyles: { ...styles, halign: "center" },
    });
  }

  if (PREPARING.length) {
    drawTableTitle(doc, `PRODUCTOS EN PREPARACION`, styles);
    autoTable(doc, {
      body: await generateProductTable(PREPARING),
      theme: "grid",
      columns: PRODUCTS_HEADERS,
      styles: { ...whiteStyle, fontSize: 8 },
      headStyles: { ...styles, halign: "center" },
    });
  }

  for (const subpurchase of purchase.subPurchases) {
    const products = await generateProductTable(subpurchase.products);
    drawTableTitle(doc, `SUBPEDIDO #${subpurchase.subPurchaseNumber}`, styles);
    autoTable(doc, {
      body: products,
      theme: "grid",
      columns: PRODUCTS_HEADERS,
      styles: { ...whiteStyle, fontSize: 8 },
      headStyles: { ...styles, halign: "center" },
    });
  }
  renderSummary(
    doc,
    { ...styles, fontSize: 8 },
    { ...whiteStyle, fontSize: 8 },
    purchase
  );

  doc.save(`orden_${purchase.purchaseNumber}.pdf`);
};

const generateProductTable = (products: TPurchaseProduct[]) => {
  return Promise.all(
    products.map(async (product: any, index: number) => {
      const subtotal = (product.wholesalePrice || 0) * (product?.quantity || 0);

      const finalPrice =
        product?.couponType === "DISCOUNT_PERCENTAGE"
          ? (product.subTotalWithDiscount || 0) -
            (product.discountByCoupon || 0)
          : product.subTotalWithDiscount || 0;

      const totalDiscount = subtotal - finalPrice;

      return {
        index: index + 1,
        name: product?.name || "-",
        quantity: product?.quantity || 0,
        pvp: formatCurrency(product?.wholesalePrice),
        subtotal: formatCurrency(subtotal),
        totalDiscount: formatCurrency(totalDiscount),
        finalPrice: formatCurrency(finalPrice),
      };
    })
  );
};

const drawTableTitle = (doc: jsPDF, title: string, styles: Partial<Styles>) => {
  const data = [{ content: title }];

  autoTable(doc, {
    body: data,
    theme: "grid",
    columnStyles: {
      0: styles,
    },
  });
};

const renderSummary = (
  doc: jsPDF,
  headStyles: Partial<Styles>,
  bodyStyles: Partial<Styles>,
  purchase: TPurchase
) => {
  const tableWidth = 80;
  const startX = doc.internal.pageSize.width - tableWidth - 14;

  autoTable(doc, {
    body: [
      [{ content: "TOTAL", colSpan: 2, styles: { halign: "center" } }],
      ["SUBTOTAL", formatCurrency(purchase!.subTotalWithProductDiscounts)],
      ["ENVIO", formatCurrency(purchase!.shipping)],
      ["TOTAL DESCUENTOS", formatCurrency(purchase!.totalDiscount)],
      ["TOTAL", formatCurrency(purchase!.total + purchase!.shipping)],
    ],
    theme: "grid",
    tableWidth: tableWidth,
    margin: { left: startX },
    headStyles: { ...headStyles, halign: "right" },
    columnStyles: {
      0: { ...headStyles, halign: "right" },
      1: { ...bodyStyles, halign: "right" },
    },
  });
};

const drawImage = (index: number, data: CellHookData, doc: jsPDF) => {
  if (data.column.index === index && data.cell.section === "body") {
    const img = new Image();
    img.src = logo;
    doc.addImage(img, data.cell.x + 10, data.cell.y + 6, 28, 6);
  }
};
