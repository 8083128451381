import {
  TSupplier,
  TSupplierPayment,
  TSupplierPaymentTransaction,
  TSupplierPaymentTransactionType,
} from "@/api";
import {
  SUPPLIERS,
  SUPPLIER_PAYMENTS,
  SUPPLIER_TRANSACTIONS,
} from "@/config/resources";
import { IconButton, Stack, SvgIcon, Tooltip } from "@mui/material";
import {
  Datagrid,
  DateField,
  FunctionField,
  ListBase,
  NumberField,
  SelectField,
  TextField,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { mdiEye } from "@mdi/js";
import {
  GenerateSupplierPaymentAction,
  SupplierPaymentDeleteButton,
  SupplierPaymentExcelButton,
  SupplierPaymentStatusSwitch,
} from "./payments/actions";
import { CustomPagination } from "@/resources/shared/pagination";
import {
  supplierPaymentTransactionReferenceChoices,
  supplierPaymentTransactionTypeChoices,
} from "./payments/constants";

export const SupplierPaymentsTabContent = () => {
  return (
    <>
      <SupplierPayments />
      <SupplierPendingTransactions />
    </>
  );
};

const SupplierPayments = () => {
  const supplier = useRecordContext<TSupplier>();

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <h3>Pagos</h3>
        <div>
          <Stack direction={"row"} spacing={"16px"}>
            <GenerateSupplierPaymentAction />
          </Stack>
        </div>
      </Stack>

      <ListBase
        resource={SUPPLIER_PAYMENTS}
        queryOptions={{ meta: { dependentId: supplier.id } }}
        perPage={5}
        exporter={false}
        disableSyncWithLocation
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="paymentNumber" sortable={false} />
          <NumberField source="total" />
          <FunctionField
            source="status"
            render={() => <SupplierPaymentStatusSwitch />}
          />
          <DateField source="createdAt" sortable={false} showTime />
          <DateField source="cutoffDate" sortable={false} />
          <FunctionField
            label="Acciones"
            render={() => (
              <Stack direction={"row"} spacing={"8px"}>
                <SupplierPaymentDetailsButton />
                <SupplierPaymentExcelButton />
                <SupplierPaymentDeleteButton />
              </Stack>
            )}
          />
        </Datagrid>
        <CustomPagination />
      </ListBase>
    </>
  );
};

const SupplierPaymentDetailsButton = () => {
  const payment = useRecordContext<TSupplierPayment>();
  return (
    <Tooltip title="Ver detalles">
      <div style={{ display: "inline-block" }}>
        <IconButton
          color="primary"
          component={Link}
          to={`/${SUPPLIERS}/${payment.supplierId}/show/2/${SUPPLIER_PAYMENTS}/${payment.id}`}
        >
          <SvgIcon>
            <path d={mdiEye} />
          </SvgIcon>
        </IconButton>
      </div>
    </Tooltip>
  );
};

const SupplierPendingTransactions = () => {
  const supplier = useRecordContext<TSupplier>();
  return (
    <>
      <h3>Transacciones pendientes</h3>
      <ListBase
        resource={SUPPLIER_TRANSACTIONS}
        queryOptions={{ meta: { dependentId: supplier.id } }}
        perPage={10}
        exporter={false}
        disableSyncWithLocation
      >
        <Datagrid bulkActionButtons={false}>
          <SelectField
            source="type"
            choices={supplierPaymentTransactionTypeChoices}
          />
          <FunctionField
            source="reference"
            render={(t: TSupplierPaymentTransaction) =>
              t.type === TSupplierPaymentTransactionType.ProductSell ? (
                t.data?.name
              ) : (
                <SelectField
                  source="reference"
                  choices={supplierPaymentTransactionReferenceChoices}
                  emptyText="-"
                />
              )
            }
          />
          <TextField label="SKU" source="data.sku" />
          <NumberField label="Precio de venta" source="data.sellPrice" />
          <FunctionField
            label="Precio de distribuidor"
            render={(t: TSupplierPaymentTransaction) =>
              t.type === TSupplierPaymentTransactionType.ProductSell && (
                <NumberField
                  source="value"
                  record={{
                    value: t.value / (t.data?.quantity ?? 1),
                  }}
                />
              )
            }
          />
          <NumberField label="Cantidad" source="data.quantity" />

          <FunctionField
            source="value"
            label="Subtotal"
            render={(t: TSupplierPaymentTransaction) => (
              <NumberField
                source="value"
                color={t.value < 0 ? "red" : undefined}
              />
            )}
          />
          <DateField source="createdAt" sortable={false} showTime />
        </Datagrid>
        <CustomPagination />
      </ListBase>
    </>
  );
};
