import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { atom, useAtom } from "jotai";
import {
  getTicketReasons,
  getPurchaseById,
  AxiosError,
  TPurchaseProduct,
  getTicketFormByReason,
  getRefundTypes,
} from "@/api";
import { useNotify } from "react-admin";

import { buckets } from "../../../core/buckets";
import { uploadFile } from "../../../utils/file-upload";

type EditValueProps = {
  name: string;
  value?: number | undefined;
  min?: number;
  max: number;
  result?: number;
};

type TStateAtom = {
  reasonList: { id: string; name: string }[];
  productList: TPurchaseProduct[];
};

const purchaseData = atom<TStateAtom>({
  reasonList: [],
  productList: [],
});
export const hasReasonsAtom = atom(
  (get) => !!get(purchaseData).reasonList.length
);

export const useCreateTicketsForm = () => {
  const MAX_COUNT_EVIDENCE_FILES = 5;
  const {
    getValues,
    setValue,
    reset,
    formState,
    watch,
    setError,
    clearErrors,
  } = useFormContext();
  const [hasReasons] = useAtom(hasReasonsAtom);
  const [{ reasonList, productList }, setPurchaseData] = useAtom(purchaseData);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const [refundTypeList, setRefundTypeList] = useState<
    { id: string; name: string }[]
  >([]);

  const [evidenceListFiles, reason, purchaseId] = watch([
    "evidenceListFiles",
    "reason",
    "purchaseId",
  ]);

  const searchPurchaseReasons = async () => {
    try {
      const reasons = await getTicketReasons(purchaseId);
      const purchaseData = await getPurchaseById(purchaseId);

      if (purchaseData.status === "CANCELLED") {
        throw new Error("El pedido ha sido cancelado");
      }

      const reasonList = reasons.filter((reason) => reason.enabled);

      if (!reasonList.length) {
        throw new Error("No hay razones de devolución para este pedido");
      }

      setPurchaseData({
        reasonList: reasonList.map((reason) => ({
          id: reason.code,
          name: reason.name,
        })),
        productList: purchaseData.products,
      });

      setValue("purchaseNumber", purchaseData.purchaseNumber);
      setValue("userId", purchaseData.sellerId);
      setValue("evidenceListFiles", []);
    } catch (error: AxiosError | any) {
      const message =
        error.response?.data?.message ??
        error.message ??
        "No se ha podido consultar el pedido";
      notify(message, {
        type: "error",
      });
    }
  };

  const setValueProduct = (props: EditValueProps) => {
    const { name, value, min = 0, max, result } = props;

    if (value === undefined && result === undefined) return;

    const newValue = value ? +value : getValues()[name] + (result ?? 0);

    if (newValue < min || newValue > max) return;

    setValue(name, newValue);
  };

  const resetForm = () => {
    reset();
    setPurchaseData({
      reasonList: [] as { id: string; name: string }[],
      productList: [] as TPurchaseProduct[],
    });
  };

  const showProducts = !!productList.length;
  const showQuestions = !!productList.length || !!reason;
  const isAvailableToEvidence = !["INCOMPLETE_ORDER"].includes(reason);
  const evidencieFileCount = evidenceListFiles?.length ?? 0;
  const isDisabledFileInput =
    evidencieFileCount >= MAX_COUNT_EVIDENCE_FILES || isLoading;

  const getPlaceholderFile = () => {
    if (isLoading) {
      return "Cargando...";
    }

    if (
      !!evidenceListFiles?.length &&
      evidencieFileCount < MAX_COUNT_EVIDENCE_FILES
    ) {
      return `${
        evidenceListFiles?.length ?? 0
      } de ${MAX_COUNT_EVIDENCE_FILES} imágenes permitidas`;
    }

    return;
  };

  const onUploadEvidence = async (file: File) => {
    if (evidenceListFiles.length >= MAX_COUNT_EVIDENCE_FILES) {
      notify(
        `Solo puedes agregar máximo ${MAX_COUNT_EVIDENCE_FILES} imágenes`,
        {
          type: "error",
        }
      );
      return;
    }

    if (evidenceListFiles.some((item: any) => item.file.name === file.name)) {
      return;
    }

    try {
      setIsLoading(true);
      const fileUrl = await uploadFile(
        buckets.purchaseReturn,
        "sellers",
        `${purchaseId}/files`,
        file
      );

      setValue("evidenceListFiles", [
        ...evidenceListFiles,
        { file, url: fileUrl },
      ]);
    } catch (error) {
      console.trace(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveImage = (file: File) => {
    setValue(
      "evidenceListFiles",
      evidenceListFiles.filter(
        (evidence: any) => evidence.file.name !== file.name
      )
    );
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      resetForm();
    }
  }, [formState.isSubmitSuccessful]);

  useEffect(() => {
    if (formState.isSubmitting) {
      const listProducts = Object.entries(getValues()).filter(([key]) =>
        key.includes("product:")
      );

      const hasValidProducts =
        listProducts.some(([_, value]) => value > 0) ||
        ["ORDER_WITHOUT_ARRIVING"].includes(reason);

      if (!hasValidProducts) {
        setError("products", { message: "Debe seleccionar", type: "min" });
      } else {
        clearErrors("products");
      }
    }
  }, [formState.isSubmitting]);

  useEffect(() => {
    if (reason && purchaseId) {
      getTicketFormByReason(reason, purchaseId)
        .then(({ form }) => {
          const [products] = form.fields.filter(
            ({ field }) => field === "products"
          );

          setPurchaseData((prev) => ({
            ...prev,
            productList: (products?.choices ?? [])
              .filter(({ quantity }) => (quantity ?? 0) > 0)
              .map(
                (product) =>
                  ({
                    id: product.id,
                    name: product.name,
                    quantity: product.quantity,
                  } as any)
              ),
          }));
        })
        .catch(console.error);
    }
  }, [reason, purchaseId]);

  useEffect(() => resetForm(), []);

  useEffect(() => {
    getRefundTypes(purchaseId)
      .then((data) =>
        setRefundTypeList(
          data.map((data) => ({ id: data.value, name: data.name }))
        )
      )
      .catch(console.error);
  }, [purchaseId]);

  return {
    showProducts,
    showQuestions,
    isDisabledFileInput,
    hasReasons,
    reasonList,
    productList,
    purchaseId,
    isAvailableToEvidence,
    errors: formState.errors,
    refundTypeList,
    onRemoveImage,
    onUploadEvidence,
    getPlaceholderFile,
    resetForm,
    setValueProduct,
    searchPurchaseReasons,
  };
};
