import { TRestListData } from "@/api";
import { AxiosError } from "axios";
import { chunk } from "lodash";
import { DataProvider } from "react-admin";
import {
  BANNERS,
  BRANDS,
  CATEGORIES,
  COURSES,
  LECTURES,
  PRODUCTS,
  RESOURCES,
  SELLERS,
  SUPPLIERS,
} from "../../config/resources";
import { adminState } from "../../hooks/admin";
import { getBase64 } from "../../utils/file-base64";
import { deleteUserOnFirebaseAuth } from "../extensions/auth";
import { rest } from "./rest";
import { country } from "@/config/country";

const GET_LIST_LIMIT_CHUNKS = 200;

export const seeriDataProvider: DataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    let dependentId = "";
    if (params.meta?.dependentId) {
      dependentId = params.meta.dependentId as string;
    }

    const query: any = { ...params.meta?.params };

    const querySearch = { ...params.filter };
    query.search = serializeFilter(querySearch);

    if (!query.search) delete query.search;

    if (params.sort) {
      query.sort = `${field},${order.toLowerCase()}`;
    }

    if (perPage >= GET_LIST_LIMIT_CHUNKS) {
      let contentData: any[] = [];
      let totalElements = 0;
      let totalPages = 0;

      query.size = GET_LIST_LIMIT_CHUNKS;
      query.page = 0;
      do {
        const data = await rest.getList[resource](query, dependentId, params);
        contentData.push(...data.content);
        totalElements = data.totalElements;
        totalPages = data.totalPages;
        query.page++;
      } while (query.page <= totalPages - 1);

      return {
        data: contentData,
        total: totalElements,
      };
    } else {
      query.page = `${page - 1}`;
      query.size = `${perPage}`;

      return rest.getList[resource](query, dependentId, params).then(
        (data: TRestListData<any>) => ({
          data: data.content,
          total: data.totalElements,
        })
      );
    }
  },

  getOne: async (resource, params) => {
    let dependentId = "";

    if (params.meta?.dependentId) {
      dependentId = params.meta.dependentId as string;
    }

    return rest.getOne[resource](params.id, dependentId, params).then(
      (data: any) => ({
        data: { ...data, id: params.id },
      })
    );
  },

  getMany: async (resource, params) => {
    let dependentId = "";

    if (rest.getMany[resource]) {
      const chunks = chunk(params.ids, 500);
      const data = [];
      for (const chunk of chunks) {
        const result = await rest.getMany[resource](chunk as string[]);
        data.push(...result);
      }
      return { data };
    } else {
      if (params.meta?.dependentId) {
        dependentId = params.meta.dependentId as string;
      }
      // Replace with new endpoint to filter by ids
      const data = (
        await Promise.all(
          params.ids.map((id) =>
            rest.getOne[resource](id as string, dependentId, params)
              .then((data: any) => ({
                ...data,
                id,
              }))
              .catch((error) => {
                console.log({ error });
              })
          )
        )
      ).filter((_) => _);

      return { data };
    }
  },

  getManyReference: async (resource, params) => {
    let data: any;
    let total = 0;

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };

    return { data, total };
  },

  create: async (resource, params) => {
    let dependentId = "";
    if (params.meta?.dependentId) {
      dependentId = params.meta.dependentId as string;
    }

    params.data.createdAt = new Date();
    await formatFiles(resource, params.data);

    return rest.create[resource](params.data, dependentId)
      .then((data: any) => ({
        data: { ...params.data, ...data, id: data.id },
      }))
      .catch(async (error) => {
        console.log({ error });
        if (resource === SELLERS || resource === SUPPLIERS) {
          try {
            await seeriDataProvider.getOne(resource, {
              id: params.data.id,
            });
          } catch (error) {
            deleteUserOnFirebaseAuth();
          }
        }
        if (error instanceof AxiosError) {
          const date =
            error.response?.data?.timestamp ?? new Date().toISOString();
          throw new Error(
            `[${date}] Error de servidor: ${
              error.response?.data?.message ?? error.message
            }`
          );
        }
        throw error;
      });
  },

  update: async (resource, params) => {
    let dependentId = "";

    if (params.meta?.dependentId) {
      dependentId = params.meta.dependentId as string;
    }

    params.data.updatedAt = new Date();
    params.data.updatedBy = adminState.admin?.id;
    await formatFiles(resource, params.data);

    return rest.update[resource](
      params.id as string,
      params.data as any,
      dependentId
    )
      .then((data: any) => ({ data }))
      .catch((error: any) => {
        console.log({ error });
        if (error instanceof AxiosError) {
          const date =
            error.response?.data?.timestamp ?? new Date().toISOString();
          throw new Error(
            `[${date}] Error de servidor: ${
              error.response?.data?.message ?? error.message
            }`
          );
        }
        throw error;
      });
  },

  updateMany: async (resource, params) => {
    let data: any;

    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    return { data };
  },

  delete: async (resource, params) => {
    let dependentId = "";

    if (params.meta?.dependentId) {
      dependentId = params.meta.dependentId as string;
    }

    return rest.delete[resource](params.id as string, dependentId, params.meta)
      .then((data: any) => ({ data }))
      .catch((error: any) => {
        console.log({ error });
        if (error instanceof AxiosError) {
          throw new Error(
            "Error de servidor: " +
              (error.response?.data?.message ?? error.message)
          );
        }
        throw error;
      });
  },

  deleteMany: async (resource, params) => {
    let data: MutationRecordType[] = [];
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return { data };
  },
};

async function formatFiles(resource: string, data: any) {
  if (resource === PRODUCTS && data.images) {
    for (const img of data.images) {
      if (img.rawFile) {
        img.src = await getBase64(img.rawFile);
        img.rawFile = undefined;
      }
    }
  }
  if (resource === SUPPLIERS) {
    await formatDataFile(data, ["digitalDocument"]);
  }
  if (resource === BRANDS) {
    await formatDataFile(data, [
      "logo",
      "manual",
      "bannerDesktop",
      "bannerMobile",
    ]);
  }

  if (resource === CATEGORIES) {
    await formatDataFile(data, ["image", "bannerDesktop", "bannerMobile"]);
  }

  if (resource === COURSES) {
    await formatDataFile(data, ["image"]);
  }

  if (resource === LECTURES) {
    await formatDataFile(data, ["image", "instructorPhoto"]);
  }

  if (resource === RESOURCES) {
    if (data.url?.rawFile) {
      data.url = await getBase64(data.url.rawFile);
    }
  }
}

async function formatDataFile(data: any, attributes: string[]) {
  for (const attribute of attributes) {
    if (data[attribute]) {
      const value = data[attribute];
      if (value.length > 0) {
      } else {
        if (value.rawFile) {
          value.src = await getBase64(value.rawFile);
          delete value[attribute];
          delete value.rawFile;
        } else if (!value.src) {
          data[attribute] = null;
        }
      }
    } else {
      data[attribute] = null;
    }
  }
}

function serializeFilter(filter: any) {
  const keys = Object.keys(filter);
  const search = [];

  for (const key of keys) {
    let value = filter[key];

    if (Array.isArray(value)) {
      value = value.join(";") + ";";
    }

    if (key.endsWith("Gte")) {
      search.push(`${key.slice(0, -3)}>${value}`);
    } else if (key.endsWith("Lte")) {
      search.push(`${key.slice(0, -3)}<${value}`);
    } else {
      search.push(`${key}:${value}`);
    }
  }

  return search.join(",");
}
