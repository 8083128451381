import {
  DialogActions,
  Alert,
  AlertTitle,
  List,
  ListItem,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {
  AxiosError,
  updateDeliveryStatusBySupurchaseId,
  updateSubpurchaseDeliveryCost,
  updateTrackingCodeBySupurchaseId,
} from "@/api";
import { FC, useCallback, useEffect, useState } from "react";
import { Button, LinearProgress } from "react-admin";
import { useDeliveryProviders } from "../../../../hooks/purchase";
import { getSchema } from "./schema";

type Props = {
  headers: string[];
  rows: any[];
  onEnd: () => void;
};

export const UpdateProcess: FC<Props> = ({ headers, rows, onEnd }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [invalid, setInvalid] = useState<any[]>([]);
  const [valid, setValid] = useState<any[]>([]);
  const [checks, setChecks] = useState<any[]>([]);
  const [errors, setErrors] = useState<any[]>([]);
  const progress = checks.length + errors.length;
  const isDone = progress === valid.length;
  const deliveryProviders = useDeliveryProviders();

  const start = useCallback(async () => {
    setChecks([]);
    setErrors([]);
    setIsUpdating(true);
    for (const row of valid) {
      const index = valid.indexOf(row);
      const { subPurchaseNumber, ...data } = row;

      try {
        switch (headers[1]) {
          case "trackingCode":
            await updateTrackingCodeBySupurchaseId(
              subPurchaseNumber,
              data.trackingCode,
              data.deliveryProvider
            );
            break;
          case "deliveryStatus":
            await updateDeliveryStatusBySupurchaseId(
              subPurchaseNumber,
              data.deliveryStatus
            );
            break;
          case "deliveryCost":
            await updateSubpurchaseDeliveryCost(
              subPurchaseNumber,
              data.deliveryCost
            );
            break;
          default:
            throw new Error("Campo de actualización no implementado");
        }
        setChecks((old) => [...old, row]);
      } catch (error) {
        const message =
          error instanceof AxiosError
            ? error.response?.data?.message
            : error instanceof Error
            ? error.message
            : error;
        setErrors((old) => [...old, { row: index + 2, message }]);
      }
    }
    setIsUpdating(false);
  }, [valid]);

  useEffect(() => {
    const validate = async () => {
      const valid = [];
      const invalid: any[] = [];
      const schema = getSchema(headers, deliveryProviders);
      for (let i = 0; i < rows.length; i++) {
        const data = rows[i];
        const row = i + 2;
        const obj = await schema
          .validate(data, { abortEarly: false })
          .catch((error) => {
            invalid.push({ row, errors: error.errors });
          });
        if (obj) valid.push(obj);
      }
      setValid(valid);
      setInvalid(invalid);
    };
    validate();
  }, [headers, deliveryProviders]);

  return (
    <>
      <DialogTitle>
        Actualización masiva de <strong>subpedidos</strong>
      </DialogTitle>
      <DialogContent>
        {invalid.length > 0 && (
          <>
            <Alert severity="error">
              <AlertTitle>
                Se encontraron {invalid.length} filas inválidas
              </AlertTitle>
              {invalid.map((invalid, i) => (
                <div key={i}>
                  {i > 0 && <hr />}
                  <div>
                    <strong>Fila: {invalid.row}</strong>
                    <List>
                      {invalid.errors.map((error: string) => (
                        <ListItem>{error}</ListItem>
                      ))}
                    </List>
                  </div>
                </div>
              ))}
            </Alert>
          </>
        )}

        {invalid.length === 0 && (
          <>
            <p>
              Progreso: {progress} / {rows.length}
            </p>
            <LinearProgress
              variant="determinate"
              value={Math.floor((progress * 100) / rows.length)}
            />
          </>
        )}

        {errors.length > 0 && (
          <>
            <Alert severity="error">
              <AlertTitle>Ocurrieron {errors.length} errores:</AlertTitle>
              {errors.map((error, i) => (
                <div key={i}>
                  {i > 0 && <hr />}
                  <div>
                    <strong>Fila: {error.row}</strong>
                    <div>{error.message}</div>
                  </div>
                </div>
              ))}
            </Alert>
          </>
        )}

        {isDone && valid.length > 0 && (
          <p>Se actualizaron {checks.length} subpedidos correctamente</p>
        )}

        <DialogActions>
          <Button
            onClick={onEnd}
            label={isDone ? "Cerrar" : "Cancelar"}
            color="secondary"
            variant="outlined"
            size="medium"
            disabled={isUpdating}
          />
          {invalid.length === 0 && !isDone && (
            <Button
              onClick={start}
              label="Iniciar actualización masiva"
              variant="contained"
              size="medium"
              disabled={isUpdating}
            />
          )}
        </DialogActions>
      </DialogContent>
    </>
  );
};
