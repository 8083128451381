import { dataUrlToFile } from "./dataurl-file";
import { cover } from "./image-fit";

export function optimizeImage(
  imageFile: File,
  finalWidth: number,
  finalHeight: number,
  quality: number
): Promise<File> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      const img = document.createElement("img");
      img.src = reader.result as string;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = finalWidth;
        canvas.height = finalHeight;
        const context = canvas.getContext("2d")!;
        const { offsetX, offsetY, width, height } = cover(
          finalWidth,
          finalHeight,
          img.width,
          img.height
        );
        context.drawImage(img, offsetX, offsetY, width, height);
        const dataUrl = canvas.toDataURL("image/png", quality);
        const newFile = dataUrlToFile(dataUrl, Date.now() + ".png")!;
        resolve(newFile);
      };
    };
  });
}
