import { LocationOn } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  AxiosError,
  getPurchasePaymentVouchers,
  type TPurchase,
  updatePurchaseClientLocation,
} from "@/api";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  SaveButton,
  SimpleForm,
  Toolbar,
  useGetOne,
  useNotify,
  useRefresh,
  useRecordContext,
  TextInput,
  required,
} from "react-admin";
import { useParams } from "react-router-dom";
import { atom, useAtom } from "jotai";
import { useSetAtom } from "jotai";
import { isFinishedPurchase } from "../../shared/actions-disabled";
import { addPurchaseComment } from "@/api";
import { PURCHASES } from "@/config/resources";
import { PurchaseClientLocation } from "../../_inputs";
import { useAdmin } from "@/hooks/admin";
import type { FieldValues } from "react-hook-form";

const PurchaseClientLocationEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const purchaseClientLocationModalOpen = atom(false);

export const PurchaseClientLocationDialog = () => {
  const { id: purchaseId } = useParams();
  const { data } = useGetOne(PURCHASES, { id: purchaseId });
  const { admin } = useAdmin();

  const [open, setOpen] = useAtom(purchaseClientLocationModalOpen);

  const refresh = useRefresh();

  const notify = useNotify();
  const onSubmit = useCallback(
    async (values: FieldValues) => {
      try {
        const { client, actionComment: comment } = values;
        const { location } = client;
        await updatePurchaseClientLocation(purchaseId as string, {
          ...location,
        });
        await addPurchaseComment(purchaseId as string, {
          createdBy: admin.id,
          comment,
          fullName: `${admin.firstName} ${admin.lastName}`,
        });
        notify("El pedido se actualizó exitosamente", { type: "success" });
        refresh();
        setOpen(false);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }
        return;
      } finally {
        setOpen(false);
      }
    },
    [purchaseId, admin, notify, refresh, setOpen]
  );

  if (!data) return null;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>Actualizar ubicación del cliente del pedido</DialogTitle>
      <DialogContent>
        <SimpleForm
          mode="onBlur"
          record={data}
          resource={PURCHASES}
          toolbar={<PurchaseClientLocationEditToolbar />}
          onSubmit={onSubmit}
        >
          <PurchaseClientLocation />
          <TextInput
            source="actionComment"
            label="Comentario"
            validate={[required()]}
            fullWidth
            multiline
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button label="Cerrar" onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
};

export const PurchaseClientLocationButton = () => {
  const { hasPermission, role } = useAdmin();
  const [hasVouchers, setVouchers] = useState(false);
  const [loading, setLoading] = useState(false);
  const purchase = useRecordContext<TPurchase>();

  const isAdmin = role?.name === "Admin";

  const paymentMethod = purchase?.paymentMethod;
  const paymentLink = purchase?.paymentLink;
  const trackingCode = purchase?.trackingCode;
  const purchaseId = purchase?.id;
  const deliveryStatus = purchase?.deliveryStatus;

  const setPurchaseClientLocationModalOpen = useSetAtom(
    purchaseClientLocationModalOpen
  );

  useEffect(() => {
    const getVouchers = async () => {
      setLoading(true);
      const purchaseVouchers = await getPurchasePaymentVouchers(purchaseId);
      setVouchers(!!purchaseVouchers.length);
      setLoading(false);
    };
    if (purchaseId) {
      getVouchers();
    }
  }, [purchaseId]);

  const canChangeLocation =
    hasPermission("purchase.update.clientLocation") &&
    !isFinishedPurchase(purchase) &&
    purchase.products.some((p) => !p.subPurchaseId);

  return (
    <div>
      <Tooltip title="Actualizar ubicación del cliente">
        <div>
          <IconButton
            color="primary"
            onClick={() => setPurchaseClientLocationModalOpen(true)}
            disabled={!canChangeLocation || loading}
          >
            <LocationOn />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};
