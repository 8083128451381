import { useEffect, useState } from "react";
import { mdiCash, mdiHandCoinOutline } from "@mdi/js";
import {
  Card,
  CardContent,
  Chip,
  Grid,
  Stack,
  SvgIcon,
  Link,
} from "@mui/material";
import { TTicketDetail, TTicketEvidence, getRefundTypes } from "@/api";
import {
  BooleanField,
  DateField,
  EditButton,
  FunctionField,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  Button,
  RecordContextProvider,
} from "react-admin";
import { SellerTicketHistory } from "../../components/tickets/SellerTicketHistory";
import { PURCHASES, SELLERS } from "../../config/resources";
import { ticketRefundTypes, ticketStatuses } from "../../data/purchases";
import { useAdmin } from "../../hooks/admin";
import { formatStatusColor } from "../../hooks/purchase";
import { useTicketHistory } from "../../hooks/tickets";
import { ModalEditRefundType } from "./_form";
import HorizontalTimeline from "../shared/timeline/HorizontalTimeline";
import { ReusableProductsTable } from "../purchases/show/_reusable-products-table";

const TicketShowActions = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();

  return (
    <TopToolbar>
      {hasPermission("ticket.update") && (
        <EditButton
          variant="contained"
          size="small"
          label="Responder"
          disabled={record?.status !== "PENDING"}
        />
      )}
    </TopToolbar>
  );
};

export const TicketShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("ticket.view")) return null;

  return (
    <Show title={<TicketShowTitle />} actions={<TicketShowActions />}>
      <SimpleShowLayout>
        <TicketContent />
      </SimpleShowLayout>
    </Show>
  );
};

const TicketContent = () => {
  const { hasPermission } = useAdmin();
  const [isOpen, setIsOpen] = useState(false);
  const record = useRecordContext();

  const { userTickets } = useTicketHistory(record.userId);
  const [refundTypeList, setRefundTypeList] = useState<
    { id: string; name: string }[]
  >([]);

  useEffect(() => {
    getRefundTypes(record.purchaseId).then((data) =>
      setRefundTypeList(
        data.map((data) => ({ id: data.value, name: data.name }))
      )
    );
  }, [record.purchaseId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ width: "100%" }}>
          <CardContent>
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "1rem" }}>
              <Labeled source="number" hidden>
                <TextField source="number" />
              </Labeled>
              <Labeled source="createdAt">
                <DateField source="createdAt" showTime />
              </Labeled>
              <Labeled source="purchaseNumber">
                <ReferenceField
                  source="purchaseId"
                  reference={PURCHASES}
                  link={hasPermission("purchase.view") ? "show" : false}
                >
                  <TextField source="purchaseNumber" />
                </ReferenceField>
              </Labeled>
              <Labeled source="id">
                <TextField source="id" />
              </Labeled>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardContent>
            <SimpleShowLayout>
              <FunctionField
                source="status"
                render={(record: any) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    color={formatStatusColor(record.status)}
                    sx={{ width: "fit-content" }}
                    label={
                      ticketStatuses.find((s) => s.code === record.status)?.name
                    }
                  />
                )}
              />
              {record.type && (
                <FunctionField
                  source="type"
                  sortable={false}
                  render={(record: any) => record.type.name}
                />
              )}
              {record.reason && (
                <FunctionField
                  source="reason"
                  sortable={false}
                  render={(record: any) => record.reason?.name}
                />
              )}
              {record.refundType && (
                <div>
                  <FunctionField
                    source="refundType"
                    sortable={false}
                    render={(record: any) => (
                      <Chip
                        size="small"
                        icon={
                          <SvgIcon>
                            <path
                              d={
                                record.refundType === "MANUAL"
                                  ? mdiCash
                                  : mdiHandCoinOutline
                              }
                            />
                          </SvgIcon>
                        }
                        variant="outlined"
                        sx={{ width: "fit-content" }}
                        label={
                          ticketRefundTypes.find(
                            (s) => s.code === record.refundType
                          )?.name
                        }
                      />
                    )}
                  />
                  {!!refundTypeList.length && (
                    <Button label="editar" onClick={() => setIsOpen(true)} />
                  )}
                </div>
              )}
            </SimpleShowLayout>
            <ModalEditRefundType
              isOpen={isOpen}
              handleClose={() => setIsOpen(false)}
              choiseList={refundTypeList}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardContent>
            <SimpleShowLayout>
              <ReferenceField
                source="userId"
                reference={SELLERS}
                link={hasPermission("seller.view") ? "show" : false}
              >
                <TextField source="email" />
              </ReferenceField>
              {userTickets?.content && userTickets?.content?.length > 0 && (
                <SellerTicketHistory tickets={userTickets.content} />
              )}
            </SimpleShowLayout>
          </CardContent>
        </Card>
      </Grid>

      {record?.modifications?.length > 0 && (
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <HorizontalTimeline
                title="Historial"
                sortEvents={false}
                sortDirection="dsc"
                events={([...record?.modifications] ?? []).map(
                  (modification: any, index: number) => ({
                    date: modification.createdAt,
                    status: ticketStatuses.find(
                      (s) => s.code === modification.status
                    )?.name,
                    title: (
                      <div>
                        {modification.status === "IN_SUPPLIER_REVIEW" &&
                        ![record.modifications.length, 0].includes(index) ? (
                          <>
                            <Chip
                              variant="outlined"
                              label={
                                !!record.supplierStatus ? (
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#78cb46",
                                    }}
                                  >
                                    APROBADO
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "#FD4D74",
                                    }}
                                  >
                                    RECHAZADO
                                  </p>
                                )
                              }
                              size="small"
                            />
                            <br />
                          </>
                        ) : null}
                        {!!modification?.refundType && (
                          <>
                            <span style={{ fontWeight: "bold" }}>
                              {"Tipo de reembolso: "}
                            </span>
                            {
                              ticketRefundTypes.find(
                                (s) => s.code === modification.refundType
                              )?.name
                            }
                            <br />
                          </>
                        )}
                        <span style={{ fontWeight: "bold" }}>
                          {"Comentario: "}
                        </span>
                        {modification.comment}
                      </div>
                    ),
                    description: modification?.reply ? (
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {"Respuesta: "}
                        </span>
                        {modification.reply}
                      </div>
                    ) : null,
                  })
                )}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        {record.products?.length > 0 && (
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Labeled source="products">
                <FunctionField
                  source="products"
                  render={() => <ProductsTable />}
                />
              </Labeled>
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardContent>
            <SimpleShowLayout>
              <TextField source="comment" />
              {record.evidences.length > 0 && (
                <FunctionField
                  source="evidences"
                  render={(record: any) => (
                    <Evidences evidences={record.evidences} />
                  )}
                />
              )}
            </SimpleShowLayout>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardContent>
            <Labeled source="reply">
              <TextField source="reply" />
            </Labeled>
            {record.requestPickup && (
              <Labeled source="requestPickup">
                <BooleanField source="requestPickup" />
              </Labeled>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const TicketShowTitle = () => {
  const record = useRecordContext();

  return <>Ticket {record?.number}</>;
};

const ProductsTable = () => {
  const ticket = useRecordContext<TTicketDetail>();
  const products = ticket.products;

  return (
    <RecordContextProvider value={{ products }}>
      <ReusableProductsTable
        source="products"
        key={ticket.id}
        mode="ticket"
        ticket={ticket}
      />
    </RecordContextProvider>
  );
};

export const Evidences = ({ evidences }: { evidences: TTicketEvidence[] }) => {
  return (
    <div>
      {evidences.map((evidence, key) => (
        <Link key={`evidence-${key}`} href={evidence.src} target="_blank">
          <img
            src={evidence.src}
            width={80}
            height={80}
            style={{ objectFit: "contain" }}
          />
        </Link>
      ))}
    </div>
  );
};
