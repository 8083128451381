import { AttachMoney, Refresh } from "@mui/icons-material";
import { AxiosError, bulkChargeCoins } from "@/api";
import { useRef, useState } from "react";
import { Button, useNotify } from "react-admin";
import { useAdmin } from "../../../hooks/admin";

export const CoinsBulkButton = () => {
  const { admin } = useAdmin();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const notify = useNotify();

  if (!admin) return null;

  const uploadFile = async (file?: File) => {
    if (!file) return;
    setUploading(true);
    try {
      await bulkChargeCoins(file, admin.id);
      notify(
        "Se cargó el documento con éxito. Los cambios se verán reflejados progresivamente."
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else if (error.response?.status === 400) {
          notify("Error al cargar. Por favor, revisa tu documento.", {
            type: "error",
          });
        } else if (error.response?.status === 500) {
          notify("Error de servidor. Vuelva a intentarlo en unos minutos.", {
            type: "error",
          });
        } else {
          notify(error.message, { type: "error" });
        }
      } else {
        notify("Ocurrió un error de conexión con el servidor", {
          type: "error",
        });
      }
    }
    setUploading(false);
  };

  return (
    <>
      <Button
        onClick={() => fileInputRef.current?.click()}
        label="Coins"
        disabled={uploading}
      >
        {uploading ? (
          <Refresh className={uploading ? "rotate-icon" : ""} />
        ) : (
          <AttachMoney />
        )}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => uploadFile(e.target.files?.item(0) ?? undefined)}
        style={{ display: "none" }}
      />
    </>
  );
};
