import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { SupplierPermissionForm } from "./_form";

export const SupplierPermissionEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("supplier-permission.create")) return null;

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SupplierPermissionForm isEdit />
    </Edit>
  );
};
