import {
  Button,
  Form,
  NumberInput,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import { Refresh, CreditScore } from "@mui/icons-material";
import { useState } from "react";
import { ModalAction } from "@/components/common/ModalAction";
import { AxiosError } from "axios";
import { SELLERS } from "@/config/resources";

export const MaxCreditCapacityAction = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const seller = useRecordContext();
  const [update] = useUpdate();
  const [loading, setLoading] = useState(false);

  if (!seller) return null;

  const updateMaxCreditCapacity = async (values: any, onClose: () => void) => {
    setLoading(true);
    try {
      await update(SELLERS, { id: seller.id, data: values });
      const msg = "El vendedor fue actualizado correctamente.";
      notify(msg, { type: "success" });
      refresh();
      onClose();
    } catch (error) {
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  const formId = "form-seller-max-credit-capacity";

  return (
    <ModalAction
      buttonMode="icon"
      buttonIcon={<CreditScore />}
      dialogMaxWidth="sm"
      dialogTitle="Cambiar Capacidad Máxima de Crédito"
      dialogContent={(onClose) => (
        <>
          <Form
            record={{ maxCreditCapacity: seller.maxCreditCapacity ?? 0 }}
            onSubmit={(values) => updateMaxCreditCapacity(values, onClose)}
            id={formId}
          >
            <NumberInput
              source="maxCreditCapacity"
              validate={[required()]}
              fullWidth
            />
          </Form>
        </>
      )}
      dialogActions={
        <Button
          type="submit"
          variant="contained"
          size="medium"
          label="Guardar"
          form={formId}
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      }
      disableClose={loading}
    />
  );
};
