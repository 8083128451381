import { getCouponTypes } from "@/api";
import { useEffect, useState } from "react";
import {
  ArrayField,
  BooleanField,
  ChipField,
  DateField,
  EditButton,
  FunctionField,
  NumberField,
  RecordContextProvider,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { SimpleChipField } from "../../components/SimpleChipField";
import { BRANDS, CATEGORIES, PRODUCTS, SELLERS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { usePaymentMethods } from "../../hooks/payment-methods";

const CouponShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>{hasPermission("coupon.update") && <EditButton />}</TopToolbar>
  );
};

export const CouponShow = (props: any) => {
  const [couponTypes, setCouponTypes] = useState<any>([]);
  const { hasPermission } = useAdmin();
  const record = useRecordContext();

  useEffect(() => {
    getCouponTypes().then(setCouponTypes);
  }, []);

  if (!hasPermission("coupon.view")) return null;

  return (
    <>
      <Show actions={<CouponShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="code" />
          <BooleanField source="status" />
          <DateField source="beginsAt" showTime />
          <DateField source="endsAt" showTime />
          {couponTypes?.length && (
            <FunctionField
              source="type"
              render={(record: any) =>
                couponTypes.find((c: any) => c.id === record.type)?.name
              }
            />
          )}
          <BooleanField source="firstSale" />
        </SimpleShowLayout>
        <FunctionField
          render={(record: any) => <CouponData record={record} />}
        />
        <SimpleShowLayout>
          <NumberField source="redemptionsNumber" />
          <DateField source="createdAt" showTime />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const CouponData = ({ record }: any) => {
  const paymentMethods = usePaymentMethods();

  return (
    <RecordContextProvider value={record}>
      <SimpleShowLayout>
        {record?.data?.sellersIds?.length && (
          <ReferenceArrayField
            label="Vendedores"
            reference={SELLERS}
            source="data.sellersIds"
          >
            <SingleFieldList>
              <ChipField source="email" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}

        {record?.data?.paymentMethods?.length && (
          <ArrayField source="data.paymentMethods">
            <SingleFieldList linkType={false}>
              <SimpleChipField
                render={(record: any) =>
                  paymentMethods.find((p) => p.code === record)?.name
                }
              />
            </SingleFieldList>
          </ArrayField>
        )}

        {record?.data?.productsCategories?.length && (
          <ReferenceArrayField
            label="Categorías de productos"
            reference={CATEGORIES}
            source="data.productsCategories"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}

        {record?.data?.productsSubCategories?.length && (
          <ReferenceArrayField
            label="Subcategorías de productos"
            reference={CATEGORIES}
            source="data.productsSubCategories"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}

        {record?.data?.productsBrands?.length && (
          <ReferenceArrayField
            label="Marcas"
            reference={BRANDS}
            source="data.productsBrands"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}

        {record?.data?.productsIds?.length && (
          <ReferenceArrayField
            label="Productos"
            reference={PRODUCTS}
            source="data.productsIds"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}

        {record?.data?.productId && (
          <ReferenceField
            label="Producto"
            reference={PRODUCTS}
            source="data.productId"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}

        {record?.data?.maximumNumberUses && (
          <NumberField source="data.maximumNumberUses" />
        )}

        <NumberField
          source="maximumUsesByUser"
          label="Máximo número de usos por usuario"
        />

        {record?.data?.minValue && <NumberField source="data.minValue" />}

        {record?.data?.productsFree && (
          <NumberField source="data.productsFree" />
        )}

        {record?.data?.productsPaid && (
          <NumberField source="data.productsPaid" />
        )}

        {record?.data?.limitByUser && <NumberField source="data.limitByUser" />}

        {record?.data?.usesSamePurchase && (
          <NumberField source="data.usesSamePurchase" />
        )}

        {record?.data?.value && <NumberField source="data.value" />}

        {record?.data?.maxDiscount && <NumberField source="data.maxDiscount" />}
      </SimpleShowLayout>
    </RecordContextProvider>
  );
};
