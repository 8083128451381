import { AxiosError, TPurchase, getSeeriApi } from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import { useState } from "react";
import { Button, useNotify, useRecordContext, useRefresh } from "react-admin";
import { Refresh } from "@mui/icons-material";
import { Typography } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

export const PurchaseRevive = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const purchase = useRecordContext<TPurchase>();
  const [loading, setLoading] = useState(false);

  const onConfirm = async (onClose: Function) => {
    if (loading) return;
    setLoading(true);
    try {
      const payload = {
        status: "PENDING",
      };
      await getSeeriApi().put(
        `/api/purchases/${purchase.id}/force-status`,
        payload
      );
      const msg = "El estado del pedido fue actualizado correctamente.";
      notify(msg, { type: "success" });
      refresh();
      onClose();
    } catch (error) {
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  const isEnded = ["REJECTED", "CANCELLED", "DONE"].includes(purchase?.status);

  return (
    <ModalAction
      buttonDisabled={!isEnded}
      buttonMode="icon"
      buttonIcon={<RotateLeftIcon />}
      dialogTitle="Revivir pedido"
      dialogMaxWidth="sm"
      dialogContent={
        <Typography>
          El pedido pasará a estado pendiente. ¿Seguro que desea revivir el
          pedido?
        </Typography>
      }
      dialogActions={(onClose) => (
        <Button
          onClick={() => onConfirm(onClose)}
          variant="contained"
          size="medium"
          label="Confirmar"
          disabled={loading}
          startIcon={
            loading && <Refresh className={loading ? "rotate-icon" : ""} />
          }
        />
      )}
    />
  );
};
