import { AttachMoney } from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  AxiosError,
  getPurchasePaymentVouchers,
  type TPurchase,
  updatePurchasePaymentMethod,
} from "@/api";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  SaveButton,
  SimpleForm,
  Toolbar,
  useGetOne,
  useNotify,
  useRefresh,
  useRecordContext,
  TextInput,
  required,
} from "react-admin";
import { disableButtonAction } from "./create-subpurchase";
import { PurchasePaymentMethodInput } from "../../_inputs";
import { PURCHASES } from "../../../../config/resources";
import { atom, useAtom, useAtomValue } from "jotai";
import { useAdmin } from "../../../../hooks/admin";
import { useParams } from "react-router-dom";
import { useSetAtom } from "jotai";
import { isFinishedPurchase } from "../../shared/actions-disabled";
import { addPurchaseComment } from "@/api";
import type { FieldValues } from "react-hook-form";

const PurchasePaymentMethodEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const purchasePaymentMethodModalOpen = atom(false);

export const PurchasePaymentMethodDialog = () => {
  const { id: purchaseId } = useParams();
  const { data } = useGetOne(PURCHASES, { id: purchaseId });
  const { admin } = useAdmin();

  const [open, setOpen] = useAtom(purchasePaymentMethodModalOpen);

  const refresh = useRefresh();

  const notify = useNotify();
  const onSubmit = useCallback(
    async (values: FieldValues) => {
      const { paymentMethod, actionComment: comment } = values;
      try {
        await updatePurchasePaymentMethod(purchaseId as string, {
          paymentMethod,
        });
        await addPurchaseComment(purchaseId as string, {
          createdBy: admin.id,
          comment,
          fullName: `${admin.firstName} ${admin.lastName}`,
        });
        notify("El pedido se actualizó exitosamente", { type: "success" });
        refresh();
        setOpen(false);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }

        return;
      } finally {
        setOpen(false);
      }
    },
    [purchaseId, admin, notify, refresh, setOpen]
  );

  if (!data) return null;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>Actualizar método de pago del pedido</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          Solo se cambiará el método de pago de los productos pendientes
        </Alert>
        <SimpleForm
          mode="onBlur"
          record={data}
          resource={PURCHASES}
          toolbar={<PurchasePaymentMethodEditToolbar />}
          onSubmit={onSubmit}
        >
          <PurchasePaymentMethodInput disabled={false} />
          <TextInput
            source="actionComment"
            label="Comentario"
            validate={[required()]}
            fullWidth
            multiline
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button label="Cerrar" onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
};

export const PurchasePaymentMethodButton = () => {
  const disabledButton = useAtomValue(disableButtonAction);
  const [hasVouchers, setVouchers] = useState(false);
  const [loading, setLoading] = useState(false);
  const { hasPermission } = useAdmin();
  const record = useRecordContext<TPurchase>();

  const purchaseId = record?.id;
  const paid = record?.paymentStatus === "PAID";
  const paymentMethod = record?.paymentMethod;

  const setPurchasePaymentMethodModalOpen = useSetAtom(
    purchasePaymentMethodModalOpen
  );

  useEffect(() => {
    const getVouchers = async () => {
      setLoading(true);
      const purchaseVouchers = await getPurchasePaymentVouchers(purchaseId);
      setVouchers(!!purchaseVouchers.length);
      setLoading(false);
    };
    if (purchaseId) {
      getVouchers();
    }
  }, [purchaseId]);

  return (
    <div>
      <Tooltip title="Actualizar método de pago">
        <div>
          <IconButton
            color="primary"
            onClick={() => setPurchasePaymentMethodModalOpen(true)}
            disabled={
              isFinishedPurchase(record) ||
              !record.products.some((p) => !p.subPurchaseId) ||
              disabledButton ||
              !hasPermission("purchase.update.paymentMethod") ||
              paid ||
              loading ||
              (["UPON_DELIVERY_SEERI", "BANK_TRANSFER"].includes(
                paymentMethod
              ) &&
                hasVouchers)
            }
          >
            <AttachMoney />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};
