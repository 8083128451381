import {
  Alert,
  Card,
  CardContent,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { Button, useStore } from "react-admin";
import { TQuote } from "./types";
import { useFormContext } from "react-hook-form";
import { MY_QUOTES } from "./constants";
import { Delete } from "@mui/icons-material";
import { TimeZoneDate } from "../../../components/TimezoneDate";
import { Save } from "@mui/icons-material";
import { Swal } from "../../../core/swal";

export const QuoterMenu = () => {
  const [quotes, setQuotes] = useStore<TQuote[]>(MY_QUOTES, []);
  const { setValue } = useFormContext();

  const loadQuote = (quote: TQuote) => {
    setValue("userType", quote.userType);
    setValue("couponCode", quote.couponCode);
    setValue("products", quote.products);
    setValue("firstSale", quote.firstSale);
    setValue("forVip", quote.forVip);
  };

  const deleteQuote = async (quote: TQuote) => {
    const { isConfirmed } = await Swal.fire({
      title: (
        <>
          ¿Seguro que deseas borrar <b>{quote.name}?</b>
        </>
      ),
      showCancelButton: true,
    });
    if (isConfirmed) {
      setQuotes(quotes.filter((q) => q !== quote));
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          position: { md: "absolute" },
          inset: 0,
        }}
      >
        <Typography sx={{ mb: 2, fontWeight: "bold" }}>
          Cotizaciones en este dispositivo
        </Typography>

        {quotes.length ? (
          <>
            <Typography sx={{ fontSize: "13px", marginBottom: 1 }}>
              * Clic para cargar la información en el formulario.
            </Typography>
            <MenuList
              sx={{
                overflowY: "auto",
                flex: 1,
                maxHeight: { xs: "250px", md: "none" },
              }}
            >
              {quotes.map((quote) => (
                <MenuItem key={quote.name} onClick={() => loadQuote(quote)}>
                  <ListItemText>
                    <Typography sx={{ whiteSpace: "break-spaces" }}>
                      {quote.name}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      <TimeZoneDate date={quote.date} />
                    </Typography>
                  </ListItemText>
                  <IconButton
                    title="Eliminar"
                    color="error"
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteQuote(quote);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </MenuItem>
              ))}
            </MenuList>
          </>
        ) : (
          <Alert severity="info">No hay ninguna cotización guardada</Alert>
        )}
      </CardContent>
    </Card>
  );
};

export const SaveQuoteButton = () => {
  const [quotes, setQuotes] = useStore<TQuote[]>(MY_QUOTES, []);
  const {
    formState: { isValid },
    getValues,
  } = useFormContext();

  const save = async () => {
    const { value: name } = await Swal.fire({
      title: "Ingresa el nombre de la cotización",
      input: "text",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      inputValidator: (value) => {
        if (!value) return "El nombre es requerido";
        if (quotes.some((q) => q.name === value)) {
          return "Este nombre ya existe";
        }
        return null;
      },
    });
    if (name) {
      const newQuote = { name, date: new Date(), ...getValues() } as TQuote;
      setQuotes([...quotes, newQuote]);
    }
  };

  return (
    <Button
      label="Guardar"
      onClick={save}
      variant="contained"
      size="medium"
      disabled={!isValid}
      color="secondary"
      startIcon={<Save />}
      sx={{ ml: "auto" }}
    />
  );
};
