import { FC } from "react";
import {
  AutocompleteInput,
  ImageField,
  ImageInput,
  Labeled,
  NumberField,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
} from "react-admin";
import { SELLERS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import useSWR from "swr";
import { getDebt } from "@/api";

const MAX_IMG_SIZE_KB = 500;

export const VoucherForm: FC = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("voucher.create")) return null;

  return (
    <SimpleForm mode="onBlur" warnWhenUnsavedChanges>
      <Grid container>
        <Grid item xs={8}>
          <ReferenceInput source="sellerId" reference={SELLERS}>
            <AutocompleteInput
              label="Correo del vendedor"
              filterToQuery={(searchText: string) => ({ email: searchText })}
              optionText="email"
              validate={[required()]}
              fullWidth
            />
          </ReferenceInput>
          <SellerDebtField />
        </Grid>

        <Grid item xs={8} mb={4}>
          <NumberInput source="amount" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={8}>
          <ImageInput
            source="url"
            label="Imagen"
            accept="image/png, image/jpg, image/jpeg"
            maxSize={MAX_IMG_SIZE_KB * 1000}
            helperText={`Imagen de menos de ${MAX_IMG_SIZE_KB}KB. Si la imagen no carga es porque supera el peso de indicado.`}
            validate={[required()]}
            fullWidth
          >
            <ImageField source="src" />
          </ImageInput>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const SellerDebtField = () => {
  const sellerId = useWatch({ name: "sellerId" });
  const { data } = useSWR(sellerId ? [sellerId, "getDebt"] : null, ([id]) =>
    getDebt(id)
  );

  if (!data) return null;

  return (
    <Labeled
      label={
        data?.debt < 0 ? "Deuda del vendedor" : "Saldo a favor del vendedor"
      }
    >
      <NumberField source="debt" record={data} />
    </Labeled>
  );
};
