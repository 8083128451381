import {
  createAddress,
  createBanner,
  createBrand,
  createCategory,
  createCoupon,
  createProductGroup,
  createProductV2,
  createPurchase,
  createResource,
  createSeller,
  createSupplierAddress,
  createSupplierWithoutId,
  createTicket,
  createVoucher,
  deleteAddress,
  deleteBanner,
  deleteCourse,
  deleteLecture,
  deleteProductGroup,
  deleteProductV2,
  deleteResource,
  deleteSupplierAddressbyId,
  deleteVoucher,
  getAddressListBySellerId,
  getAllSupplierOrders,
  getBanner,
  getBanners,
  getBrandById,
  getBrands,
  getCategories,
  getCategory,
  getCouponById,
  getCoupons,
  getCourse,
  getCourses,
  getLecture,
  getLectures,
  getProductGroup,
  getProductGroups,
  getProductsV2,
  getProductV2,
  getPurchaseById,
  getPurchases,
  getResource,
  getResources,
  getSeeriApi,
  getSellerById,
  getSellers,
  getSellersByFiles,
  getSellersbyIds,
  getSupplierAddressbyId,
  getSupplierAddresses,
  getSupplierById,
  getSupplierOrderById,
  getSuppliers,
  getTicketById,
  getTickets,
  getVoucher,
  getVouchers,
  TPurchase,
  TRestListParams,
  TSellerAddress,
  TSupplierAddress,
  updateAddress,
  updateBanner,
  updateBrand,
  updateCategory,
  deleteCategory,
  updateCoupon,
  updateProductGroup,
  updateProductV2,
  updateResource,
  updateSeller,
  updateSupplier,
  updateSupplierAddress,
  getGlobalOffers,
  getGlobalOfferById,
  createGlobalOffer,
  updateGlobalOffer,
  deleteSeller,
  getSupplierPayment,
  getSupplierPayments,
  getSupplierPendingTransactions,
  getProductsByIdsV2,
} from "@/api";
import {
  BRANDS,
  CATEGORIES,
  COUPONS,
  COURSES,
  LECTURES,
  PRODUCTS,
  PURCHASES,
  RESOURCES,
  SELLER_ADDRESSES,
  SELLERS,
  SUPPLIER_ADDRESSES,
  SUPPLIER_PURCHASES,
  SUPPLIERS,
  TICKETS,
  VOUCHERS,
  BANNERS,
  PRODUCT_GROUPS,
  DOCUMENTS,
  QUOTES,
  SELLER_BILLING_ADDRESSES,
  OFFERTS,
  SUPPLIER_PAYMENTS,
  SUPPLIER_TRANSACTIONS,
  SUPPLIER_PERMISSIONS,
} from "../../config/resources";
import { customCreateCourse, customEditCourse } from "./customs/course";
import { customAnswerTicket } from "./customs/ticket";
import { customCreateLecture, customEditLecture } from "./customs/lecture";
import {
  createSupplierPermission,
  deleteSupplierPermission,
  getSupplierPermissionById,
  getSupplierPermissions,
  updateSupplierPermission,
} from "@/api/resources/suppliers/permissions";
import { GetListParams } from "react-admin";
import { fixApiDateStr } from "@/utils/parse-date";

export const rest: Record<
  string,
  Record<string, (...args: any) => Promise<any>>
> = {
  getList: {
    [PRODUCTS]: async (query, _, { meta, filter }: GetListParams) => {
      if (meta?.mode === "search") {
        return getSeeriApi()
          .post(
            "/api/products/v2/search",
            {
              text: filter.name,
              city: meta?.city,
              ...filter,
            },
            {
              params: {
                marketplace: filter.marketplace,
                ...query,
              },
            }
          )
          .then((res) => {
            res.data.content = res.data.content.filter((_: any) => _);
            return res.data;
          });
      } else {
        return getProductsV2(query);
      }
    },
    [PRODUCT_GROUPS]: getProductGroups,
    [COUPONS]: getCoupons,
    [SELLERS]: getSellers,
    [SELLER_ADDRESSES]: (params: TRestListParams, sellerId: string) =>
      getAddressListBySellerId(sellerId, params),
    [SUPPLIERS]: getSuppliers,
    [SUPPLIER_TRANSACTIONS]: (params: TRestListParams, supplierId: string) =>
      getSupplierPendingTransactions(supplierId, params),
    [SUPPLIER_PAYMENTS]: (params: TRestListParams, supplierId: string) =>
      getSupplierPayments(supplierId, params),
    [SUPPLIER_ADDRESSES]: (params: TRestListParams, supplierId: string) =>
      getSupplierAddresses(supplierId, params),
    [SELLER_BILLING_ADDRESSES]: (params: TRestListParams) =>
      getSeeriApi()
        .get("/api/sellers/invoice-third-party", { params })
        .then((res) => res.data),
    [BRANDS]: getBrands,
    [CATEGORIES]: getCategories,
    [PURCHASES]: (params: TRestListParams) =>
      getPurchases(params).then((list) => {
        list.content = list.content.map((purchase) => {
          purchase.createdAt = fixApiDateStr(purchase.createdAt);
          return purchase;
        });
        return list;
      }),
    [SUPPLIER_PURCHASES]: (params: TRestListParams) =>
      getAllSupplierOrders(params).then((list) => {
        list.content = list.content.map((purchase) => {
          purchase.createdAt = fixApiDateStr(purchase.createdAt);
          purchase.approvedAt = fixApiDateStr(purchase.approvedAt);
          return purchase;
        });
        return list;
      }),
    [COURSES]: getCourses,
    [LECTURES]: getLectures,
    [RESOURCES]: getResources,
    [TICKETS]: getTickets,
    [DOCUMENTS]: getSellersByFiles,
    [VOUCHERS]: getVouchers,
    [BANNERS]: getBanners,
    [QUOTES]: (params: TRestListParams) =>
      getSeeriApi()
        .get("/api/quotes", {
          params: {
            ...params,
            page: params.page ? params.page + 1 : 1,
          },
        })
        .then(({ data }) => ({
          content: data.data,
          totalElements: data.total,
        })),
    [OFFERTS]: getGlobalOffers,
    [SUPPLIER_PERMISSIONS]: getSupplierPermissions,
  },
  getOne: {
    [PRODUCTS]: getProductV2,
    [PRODUCT_GROUPS]: getProductGroup,
    [COUPONS]: getCouponById,
    [SELLERS]: getSellerById,
    [SELLER_ADDRESSES]: (id: string, sellerId: string) =>
      getAddressListBySellerId(sellerId).then((list) =>
        list.content.find((a) => a.id === id)
      ),
    [SUPPLIERS]: getSupplierById,
    [SUPPLIER_PAYMENTS]: getSupplierPayment,
    [SUPPLIER_ADDRESSES]: (id: string, supplierId: string) =>
      getSupplierAddressbyId(supplierId, id),
    [SELLER_BILLING_ADDRESSES]: (id: string) =>
      getSeeriApi()
        .get("/api/sellers/invoice-third-party/" + id)
        .then((res) => res.data),
    [BRANDS]: getBrandById,
    [CATEGORIES]: getCategory,
    [PURCHASES]: (id: string) =>
      getPurchaseById(id).then((purchase) => {
        purchase.createdAt = fixApiDateStr(purchase.createdAt);
        return purchase;
      }),
    [SUPPLIER_PURCHASES]: (id: string) =>
      getSupplierOrderById(id).then((purchase) => {
        purchase.createdAt = fixApiDateStr(purchase.createdAt);
        purchase.approvedAt = fixApiDateStr(purchase.approvedAt);
        return purchase;
      }),

    [COURSES]: getCourse,
    [LECTURES]: getLecture,
    [RESOURCES]: getResource,
    [TICKETS]: getTicketById,
    [VOUCHERS]: (id: string) => getVoucher(+id),
    [BANNERS]: getBanner,
    [QUOTES]: (id: string) =>
      getSeeriApi()
        .get("/api/quotes/" + id)
        .then((res) => res.data),
    [OFFERTS]: getGlobalOfferById,
    [SUPPLIER_PERMISSIONS]: getSupplierPermissionById,
  },
  getMany: {
    [PRODUCTS]: getProductsByIdsV2,
    [SELLERS]: getSellersbyIds,
  },
  create: {
    [PRODUCTS]: createProductV2,
    [PRODUCT_GROUPS]: createProductGroup,
    [COUPONS]: createCoupon,
    [SELLERS]: createSeller,
    [SELLER_ADDRESSES]: (data: TSellerAddress, sellerId: string) =>
      createAddress(sellerId, data),
    [SUPPLIERS]: createSupplierWithoutId,
    [SUPPLIER_ADDRESSES]: (data: TSupplierAddress, supplierId: string) =>
      createSupplierAddress(supplierId, data),
    [SELLER_BILLING_ADDRESSES]: (data: any) =>
      getSeeriApi()
        .post("/api/sellers/invoice-third-party", data)
        .then((res) => res.data),
    [BRANDS]: createBrand,
    [CATEGORIES]: createCategory,
    [PURCHASES]: (
      purchase: Partial<
        TPurchase & { ownShipping: boolean; shippingPrice: number }
      >
    ) => {
      if (!purchase?.ownShipping) {
        purchase.shipping =
          (purchase?.shippingPrice ?? -1) >= 0 ? purchase.shippingPrice : 0;
      }

      return createPurchase(purchase).then((p) => getPurchaseById(p.id));
    },
    [COURSES]: customCreateCourse,
    [LECTURES]: customCreateLecture,
    [RESOURCES]: createResource,
    [VOUCHERS]: createVoucher,
    [BANNERS]: createBanner,
    [TICKETS]: createTicket,
    [QUOTES]: (data: any) =>
      getSeeriApi()
        .post("/api/quotes", data)
        .then((res) => res.data),
    [OFFERTS]: createGlobalOffer,
    [SUPPLIER_PERMISSIONS]: createSupplierPermission,
  },
  update: {
    [PRODUCTS]: updateProductV2,
    [PRODUCT_GROUPS]: updateProductGroup,
    [COUPONS]: updateCoupon,
    [SELLERS]: updateSeller,
    [SELLER_ADDRESSES]: (id: string, data: TSellerAddress, sellerId: string) =>
      updateAddress(sellerId, id, data),
    [SUPPLIERS]: updateSupplier,
    [SUPPLIER_ADDRESSES]: (
      id: string,
      data: TSupplierAddress,
      supplierId: string
    ) => updateSupplierAddress(supplierId, id, data),
    [BRANDS]: updateBrand,
    [CATEGORIES]: updateCategory,
    [COURSES]: customEditCourse,
    [LECTURES]: customEditLecture,
    [RESOURCES]: updateResource,
    [TICKETS]: customAnswerTicket,
    [BANNERS]: updateBanner,
    [QUOTES]: (id: string, data: any) =>
      getSeeriApi()
        .put("/api/quotes/" + id, data)
        .then((res) => res.data),
    [OFFERTS]: updateGlobalOffer,
    [SUPPLIER_PERMISSIONS]: updateSupplierPermission,
  },
  delete: {
    [PRODUCTS]: deleteProductV2,
    [PRODUCT_GROUPS]: deleteProductGroup,
    [COURSES]: deleteCourse,
    [LECTURES]: deleteLecture,
    [RESOURCES]: deleteResource,
    [SELLERS]: (id: string, _, meta) => {
      return deleteSeller(id, meta?.reason);
    },
    [SELLER_ADDRESSES]: (id: string, sellerId: string) =>
      deleteAddress(sellerId, id),
    [SELLER_BILLING_ADDRESSES]: (id: string) =>
      getSeeriApi().delete("/api/sellers/invoice-third-party/" + id),
    [SUPPLIER_ADDRESSES]: (id: string, supplierId: string) =>
      deleteSupplierAddressbyId(supplierId, id),
    [VOUCHERS]: (id: string, _, meta) => {
      if (meta?.force) {
        console.log("FORCED");
        return getSeeriApi().delete(`/api/debt/vouchers/${id}/force`);
      }
      return deleteVoucher(+id);
    },
    [BANNERS]: deleteBanner,
    [QUOTES]: (id: string) => getSeeriApi().delete("/api/quotes/" + id),
    [CATEGORIES]: deleteCategory,
    [SUPPLIER_PERMISSIONS]: deleteSupplierPermission,
  },
};
