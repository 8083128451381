import {
  Datagrid,
  ExportButton,
  List,
  Pagination,
  TextField,
  TopToolbar,
  useListContext,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";

const PermissionActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("permission.export") && <ExportButton />}
    </TopToolbar>
  );
};

export const PermissionList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("permission.list")) return null;

  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      perPage={100}
      pagination={false}
      actions={<PermissionActions />}
    >
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <TextField source="name" />
        <TextField source="description" />
      </Datagrid>
    </List>
  );
};
