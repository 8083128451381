import { AxiosRequestConfig } from "axios";
import { getInstance } from "../../core/axios-gateway";
import { TRestListData, TRestListParams } from "../../types/rest";
import { TSupplierPermission } from "../../types/supplier";

const BASE_PATH = "/api/suppliers/permissions";

export const getSupplierPermissions = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TSupplierPermission>>(BASE_PATH, { params })
    .then((res) => res.data);
};

export const getSupplierPermissionById = async (
  permissionId: string,
  config?: AxiosRequestConfig
) => {
  return getInstance()
    .get<TSupplierPermission>(`${BASE_PATH}/${permissionId}`, config)
    .then((res) => res.data);
};

export const createSupplierPermission = async (
  data: Partial<TSupplierPermission>
) => {
  return getInstance()
    .post<TSupplierPermission>(BASE_PATH, data)
    .then((res) => res.data);
};

export const updateSupplierPermission = async (
  permissionId: string,
  data: Partial<TSupplierPermission>
) => {
  return getInstance()
    .put<TSupplierPermission>(`${BASE_PATH}/${permissionId}`, data)
    .then((res) => res.data);
};

export const deleteSupplierPermission = async (permissionId: string) => {
  return getInstance()
    .delete(`${BASE_PATH}/${permissionId}`)
    .then((res) => res.data);
};
