import {
  disableButtonAction,
  DISABLED_TIMEOUT,
} from "../actions/create-subpurchase";
import { AxiosError, retryPurchaseShipping, TPurchase } from "@/api";
import { useNotify, useRefresh, useRecordContext } from "react-admin";
import { IconButton, Tooltip } from "@mui/material";
import { useAdmin } from "@/hooks/admin";
import { Refresh } from "@mui/icons-material";
import { useState } from "react";
import { useAtom } from "jotai";
import { isFinishedPurchase } from "../../shared/actions-disabled";

export const PurchaseProductsRetryButton = ({
  forced = false,
}: {
  forced?: boolean;
}) => {
  const { hasPermission } = useAdmin();

  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<TPurchase>();

  const [retrying, setRetrying] = useState(false);
  const [disabledButton, setDisabledButton] = useAtom(disableButtonAction);

  if (!hasPermission(forced ? "purchase.retry.forced" : "purchase.retry"))
    return null;

  return (
    <div>
      <Tooltip
        title={"Reintentar envíos" + (forced ? " (forzado - con deuda)" : "")}
      >
        <div>
          <IconButton
            color={forced ? "success" : "primary"}
            disabled={
              isFinishedPurchase(record) ||
              record?.priority === 0 ||
              disabledButton ||
              retrying ||
              !(
                (["ADDI", "ONLINE", "APLAZO"].includes(record?.paymentMethod) &&
                  record?.paymentStatus === "PAID") ||
                [
                  "UPON_DELIVERY",
                  "UPON_DELIVERY_SEERI",
                  "BANK_TRANSFER",
                  "CREDIT",
                  "SUPPLIER_PAY",
                ].includes(record?.paymentMethod)
              )
            }
            onClick={async () => {
              setRetrying(true);
              try {
                await retryPurchaseShipping(record?.id as string, forced);
                notify("Esperando asignación de guías", {
                  type: "success",
                });
                refresh();
                setDisabledButton(true);
                setTimeout(() => {
                  setDisabledButton(false);
                  refresh();
                }, DISABLED_TIMEOUT);
              } catch (error) {
                if (error instanceof AxiosError) {
                  notify(error.response?.data?.message ?? error.message, {
                    type: "error",
                  });
                } else {
                  notify("No se pudo reintentar el envío", {
                    type: "error",
                  });
                }
              } finally {
                setRetrying(false);
              }
            }}
          >
            <Refresh />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};
