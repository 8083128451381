import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { AdminForm } from "./_form";

export const AdminEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("admin.update")) return null;

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <AdminForm isEdit />
    </Edit>
  );
};
