import {
  ArrayField,
  DateField,
  EditButton,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { SimpleChipField } from "@/components/SimpleChipField";

const AdminRoleShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("adminrole.update") && <EditButton />}
    </TopToolbar>
  );
};

export const AdminRoleShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("adminrole.view")) return null;

  return (
    <Show actions={<AdminRoleShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <ArrayField source="permissions">
          <SingleFieldList linkType={false}>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleShowLayout>
    </Show>
  );
};
