import {
  TSupplier,
  TSupplierPayment,
  deleteSupplierPayment,
  generateSupplierPayment,
  generateSupplierPaymentAndDownloadExcel,
  getSupplierPaymentExcel,
  updateSupplierPaymentStatus,
} from "@/api";
import { ModalAction } from "@/components/common/ModalAction";
import { Swal } from "@/core/swal";
import { mdiDelete, mdiFileExcelOutline } from "@mdi/js";
import { IconButton, SvgIcon, Switch, Tooltip } from "@mui/material";
import {
  BooleanInput,
  Button,
  DateInput,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import useSWRMutation from "swr/mutation";
import { Refresh } from "@mui/icons-material";
import { FieldValues } from "react-hook-form";

export const SupplierPaymentExcelButton = () => {
  const payment = useRecordContext<TSupplierPayment>();

  const { trigger, isMutating } = useSWRMutation(
    "get-supplier-payment-excel",
    (_, { arg }: { arg: number }) => getSupplierPaymentExcel(arg)
  );

  return (
    <Tooltip title="Descargar Excel">
      <div style={{ display: "inline-block" }}>
        <IconButton
          color="primary"
          onClick={() => trigger(payment.id)}
          disabled={isMutating}
        >
          <SvgIcon>
            <path d={mdiFileExcelOutline} />
          </SvgIcon>
        </IconButton>
      </div>
    </Tooltip>
  );
};

export const SupplierPaymentDeleteButton = ({
  afterDelete,
}: {
  afterDelete?: Function;
}) => {
  const payment = useRecordContext<TSupplierPayment>();

  const notify = useNotify();
  const refresh = useRefresh();

  const { trigger: deletePayment, isMutating: deletingPayment } =
    useSWRMutation(
      "delete-supplier-payment",
      () => deleteSupplierPayment(payment.id),
      {
        onSuccess: () => {
          notify("Se eliminó el pago y se liberaron las transacciones", {
            type: "info",
          });
          afterDelete?.();
          refresh();
        },
      }
    );

  return (
    <Tooltip title="Eliminar">
      <div style={{ display: "inline-block" }}>
        <IconButton
          color="primary"
          onClick={async () => {
            const result = await Swal.fire({
              title: "¿Estás seguro?",
              text: "El pago se eliminará y las transacciones se liberarán.",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Sí, eliminar",
              cancelButtonText: "Cancelar",
            });
            if (result.isConfirmed) {
              deletePayment();
            }
          }}
          disabled={deletingPayment}
        >
          <SvgIcon>
            <path d={mdiDelete} />
          </SvgIcon>
        </IconButton>
      </div>
    </Tooltip>
  );
};

export const SupplierPaymentStatusSwitch = () => {
  const refresh = useRefresh();
  const payment = useRecordContext<TSupplierPayment>();

  const { trigger, isMutating } = useSWRMutation(
    "update-payment-status-" + payment.id,
    (_, { arg }: { arg: "PENDING" | "PAID" }) =>
      updateSupplierPaymentStatus(payment.id, arg),
    {
      onSuccess: () => {
        refresh();
      },
    }
  );

  return (
    <>
      <span>Pendiente</span>
      <Switch
        checked={payment.status === "PAID"}
        onChange={(_, checked) => trigger(checked ? "PAID" : "PENDING")}
        disabled={isMutating}
      />
      <span
        style={{
          color: payment.status === "PAID" ? "green" : "black",
        }}
      >
        Pagado
      </span>
    </>
  );
};

export const GenerateSupplierPaymentAction = () => {
  const supplier = useRecordContext<TSupplier>();
  const notify = useNotify();
  const refresh = useRefresh();

  const { trigger, isMutating } = useSWRMutation(
    "generate-payment-" + supplier.id,
    (_, { arg }: { arg: FieldValues }) => {
      const generate = arg.download
        ? generateSupplierPaymentAndDownloadExcel
        : generateSupplierPayment;
      const [y, m, d] = arg.cutoffDate.split("-").map(Number);
      return generate(supplier.id, new Date(y, m - 1, d).toISOString());
    }
  );

  const formId = "supplier-generate-payment";

  return (
    <ModalAction
      buttonText="Generar pago"
      dialogTitle="Generar pago"
      dialogMaxWidth="sm"
      dialogContent={(onClose) => (
        <Form
          id={formId}
          record={undefined}
          onSubmit={(values) =>
            trigger(values, {
              onSuccess: () => {
                notify("Generación exitosa", { type: "success" });
                refresh();
                onClose();
              },
              onError: (error) => {
                console.error(error);
                notify("Generación fallida", { type: "error" });
              },
            })
          }
        >
          <DateInput source="cutoffDate" label="Fecha de corte" fullWidth />
          <BooleanInput source="download" label="Descarga Excel" />
        </Form>
      )}
      dialogActions={
        <Button
          type="submit"
          variant="contained"
          size="medium"
          label="Confirmar"
          form={formId}
          disabled={isMutating}
          startIcon={
            isMutating && (
              <Refresh className={isMutating ? "rotate-icon" : ""} />
            )
          }
        />
      }
    />
  );
};
