import { FC } from "react";
import {
  BooleanInput,
  maxLength,
  regex,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CategoryAutocompleteInput } from "../shared/category";
import { SlugInput } from "../shared/slug";
import { Grid } from "@mui/material";
import { OptimizedImageInput } from "../../components/elements/OptimizedImageInput";

const CategoryEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const CategoryForm: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const { hasPermission } = useAdmin();

  return (
    <SimpleForm
      mode="onBlur"
      toolbar={<CategoryEditToolbar />}
      warnWhenUnsavedChanges
    >
      <Grid container spacing={4}>
        {!isEdit && (
          <Grid item xs={12} md={12}>
            <CategoryAutocompleteInput
              source="parentCategoryId"
              maxDeepLevel={3}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <TextInput
            source="name"
            validate={required()}
            disabled={!hasPermission("category.update.name") && isEdit}
            fullWidth
          />
        </Grid>
        {!isEdit && (
          <Grid item xs={12} md={4}>
            <SlugInput source="slug" stringSource="name" />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <TextInput
            source="code"
            disabled={isEdit}
            validate={
              !isEdit
                ? [
                    required(),
                    regex(
                      /^[A-Z0-9]{3}$/,
                      "Al menos 3 caracteres en mayúscula"
                    ),
                  ]
                : undefined
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextInput
            source="description"
            validate={[required(), maxLength(150)]}
            fullWidth
          />
        </Grid>
        {((hasPermission("category.update.image") && isEdit) || !isEdit) && (
          <Grid item xs={12}>
            <OptimizedImageInput
              source="image"
              width={200}
              height={200}
              validate={required()}
            />
          </Grid>
        )}
        {((hasPermission("category.update.bannerDesktop") && isEdit) ||
          !isEdit) && (
          <Grid item xs={12} md={6}>
            <OptimizedImageInput
              source="bannerDesktop"
              width={1200}
              height={300}
            />
          </Grid>
        )}
        {((hasPermission("category.update.bannerMobile") && isEdit) ||
          !isEdit) && (
          <Grid item xs={12} md={6}>
            <OptimizedImageInput
              source="bannerMobile"
              width={600}
              height={300}
            />
          </Grid>
        )}
        {(hasPermission("category.update.status") || !isEdit) && (
          <Grid item xs={12}>
            <BooleanInput
              source="status"
              defaultValue={true}
              helperText="Al apagar una categoría, se apagarán también los todos productos de esta y todas las categorías hijas."
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </SimpleForm>
  );
};
