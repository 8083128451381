import { useEffect } from "react";
import {
  CreateButton,
  Datagrid,
  FilterButton,
  FunctionField,
  List,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  useRedirect,
  useStore,
} from "react-admin";
import { LECTURES, RESOURCES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";
import { Link } from "@mui/material";

const resourceFilters = [<TextInput source="name" resettable alwaysOn />];

const ResourceActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      <FilterButton filters={resourceFilters} />
      {hasPermission("resource.create") && (
        <CreateButton resource={RESOURCES} />
      )}
    </TopToolbar>
  );
};

export const ResourceList = () => {
  const { hasPermission } = useAdmin();
  const redirect = useRedirect();
  const lecture = useRecordContext();

  const [lectureId] = useStore("currentLectureId");

  useEffect(() => {
    if (!lectureId) {
      redirect("/" + LECTURES);
    }
  }, [lectureId]);

  if (!hasPermission("resource.list")) return null;

  if (!lectureId) return null;

  return (
    <List
      title={
        lecture
          ? " - Lista de Recursos"
          : `Lista de Recursos de Clase #${lectureId}`
      }
      resource={RESOURCES}
      queryOptions={{ meta: { dependentId: lectureId } }}
      filters={resourceFilters}
      // sort={{ field: "createdAt", order: "DESC" }}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("resource.create")}
      actions={<ResourceActions />}
    >
      <Datagrid
        resource={RESOURCES}
        rowClick={hasPermission("resource.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <TextField source="name" />
        <FunctionField
          source="url"
          render={(record: any) => (
            <Link href={record.url} target="_blank">
              {record.url}
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};
