// Para la tabla
export const transactionTypes = [
  { code: "REVERTED", name: "Reversión" },
  { code: "COUPON", name: "Cupón" },
  { code: "MARKETING", name: "Marketing" },
  { code: "COMPENSATION", name: "Compensación" },
];

// Para el formulario
export const transactionChoices = [
  { code: "COUPON", name: "Cupón" },
  { code: "MARKETING", name: "Marketing" },
  { code: "COMPENSATION", name: "Compensación" },
];
