import {
  BooleanField,
  CreateButton,
  Datagrid,
  EmailField,
  ExportButton,
  FunctionField,
  List,
  NullableBooleanInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";
import { TSupplier } from "@/api";
import { getNTName } from "./constants";
import { ModalImageField } from "@/components/elements/ModalImageField";

const SupplierFilter = [
  <NullableBooleanInput source="status" alwaysOn />,
  <TextInput source="name" alwaysOn />,
  <TextInput source="email" alwaysOn />,
  <TextInput source="socialReason" alwaysOn />,
  <TextInput source="contactName" />,
];

const SupplierActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {/* <FilterButton /> */}
      {hasPermission("supplier.create") && <CreateButton />}
      {hasPermission("supplier.export") && <ExportButton />}
    </TopToolbar>
  );
};

export const SupplierList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("supplier.list")) return null;

  return (
    <List
      sort={{ field: "createdAt", order: "ASC" }}
      perPage={20}
      filters={SupplierFilter}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("supplier.create")}
      actions={<SupplierActions />}
    >
      <Datagrid
        rowClick={hasPermission("supplier.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <FunctionField
          label="Logo"
          render={() => (
            <ModalImageField source="logo" maxHeight={80} maxWidth={80} />
          )}
        />
        <TextField source="companyId" />
        <TextField source="name" />
        <EmailField source="email" />
        <TextField source="contactName" />
        <TextField source="socialReason" />
        <TextField source="phone" />
        <FunctionField
          source="negotiationType"
          render={(supplier: TSupplier) => getNTName(supplier.negotiationType)}
        />
        <BooleanField source="status" />
      </Datagrid>
    </List>
  );
};
