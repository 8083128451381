import { setSeeriApi } from "@/api";
import { isProd } from "../constants/env";
import packageJson from "../../package.json";
import { setSeeriApiInterceptors } from "../context/seeri-api";
import { AxiosRequestConfig } from "axios";
import { country } from "@/config/country";

type SeeriApiParams = {
  baseURL: string;
  userType?: string;
};

const configDev = {
  apiKey: "AIzaSyAXIpYruBPO5YLoGD4ShUn1RsbDletlJcA",
};

const configProd = {
  apiKey: "AIzaSyCeq4IEkSGoU-WJWLlgZlweMS2cSNIiB6c",
};

const configEnv = isProd ? configProd : configDev;

export const configureSeeriApi = ({ baseURL, userType }: SeeriApiParams) => {
  const config: AxiosRequestConfig = {
    baseURL,
    headers: {
      "x-api-key": configEnv.apiKey,
      "x-origin-name": "backoffice",
      "x-user-country": country.code,
      "x-origin-platform-version": packageJson.version,
      "x-origin-agent-version": "unknown",
      "x-origin-agent-name": "backoffice",
      "Accept-Language": country.locale
    },
  };

  config.headers!["x-origin-language"] = country.locale;

  if (userType) {
    config.headers!["x-user-type"] = userType;
  }

  const instance = setSeeriApi(config);
  setSeeriApiInterceptors(instance);

  return instance;
};
