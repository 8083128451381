import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { AdminRoleForm } from "./_form";

export const AdminRoleCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("adminrole.create")) return null;

  return (
    <Create redirect="show">
      <AdminRoleForm />
    </Create>
  );
};
