import { Create } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { BrandForm } from "./_form";

export const BrandCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("brand.create")) return null;

  return (
    <Create
      redirect="show"
      transform={(data: any) => {
        data.name = data.name.trim();
        return data;
      }}
    >
      <BrandForm />
    </Create>
  );
};
