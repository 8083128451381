export const offerTypes = [
  { name: "Paga X lleva Y", code: "PAY_X_TAKE_Y" },
  {
    name: "Porcentaje de descuento acumulativo",
    code: "DISCOUNT_PERCENTAGE_CUMULATIVE",
  },
  {
    name: "Porcentaje de descuento no acumulativo",
    code: "DISCOUNT_PERCENTAGE_NON_CUMULATIVE",
  },
];
