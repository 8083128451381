import { AxiosError, getSeeriApi } from "@/api";
import { useState } from "react";
import { Button, useListContext, useNotify } from "react-admin";
import Download from "@mui/icons-material/Download";

export const ProductsListExport = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { filter, filterValues } = useListContext();

  const filters = { ...filter, ...filterValues };

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      notify("Generando reporte de productos...", {
        type: "info",
      });
      const response = await getSeeriApi().post(
        "/api/reports/export/products",
        filters
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `products-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      notify("Reporte descargado con éxito", {
        type: "success",
      });
      link.remove();
    } catch (error) {
      console.log(error);
      const msg =
        (error instanceof AxiosError && error.response?.data?.message) ||
        error + "";
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  return (
    <Button
      label="Reporte"
      onClick={handleClick}
      startIcon={<Download />}
      disabled={loading}
    />
  );
};
