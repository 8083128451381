import { ModalAction } from "@/components/common/ModalAction";
import { Box } from "@mui/material";
import { AxiosError, type TSupplier, getSeeriApi } from "@/api";
import { useState } from "react";
import {
  Button,
  Form,
  PasswordInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Refresh, Password } from "@mui/icons-material";
import type { FieldValues } from "react-hook-form";

export const SupplierUpdatePasswordAction = () => {
  const supplier = useRecordContext<TSupplier>();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: FieldValues, onClose: () => void) => {
    if (loading) return;
    setLoading(true);

    try {
      await getSeeriApi().put(`/api/users/${supplier.id}`, {
        password: values.secret,
      });
      refresh();
      notify("Contraseña actualizada");
      onClose();
    } catch (error) {
      console.log(error);
      const msg =
        error instanceof AxiosError ? error.response?.data.message : error;
      notify(msg, { type: "error" });
    }
    setLoading(false);
  };

  const formId = "supplier-update-password";

  return (
    <>
      <ModalAction
        buttonText="Contraseña"
        buttonIcon={<Password />}
        buttonVariant="text"
        dialogMaxWidth="xs"
        dialogTitle="Actualizar contraseña"
        dialogContent={(onClose) => (
          <Form
            id={formId}
            record={undefined}
            onSubmit={(values) => onSubmit(values, onClose)}
          >
            <PasswordInput label="Nueva contraseña" source="secret" />
          </Form>
        )}
        dialogActions={(onClose) => (
          <Box>
            <Button
              label="Guardar"
              type="submit"
              size="medium"
              form={formId}
              disabled={loading}
              startIcon={
                loading && <Refresh className={loading ? "rotate-icon" : ""} />
              }
            />
          </Box>
        )}
      />
    </>
  );
};
