import { SUPPLIERS } from "@/config/resources";
import { useAdmin } from "@/hooks/admin";
import { Box } from "@mui/material";
import {
  Datagrid,
  EmailField,
  FunctionField,
  RecordContextProvider,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";
import { RelateSupplierModal, UnlinkSupplier } from "./relate-supplier";

export const SellerSuppliersList = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();
  const suppliers = record?.suppliers?.map(({ supplierId }: any) => supplierId);
  const sellerId = record.id!;

  return (
    <RecordContextProvider value={{ suppliers, sellerId }}>
      <ReferenceArrayField source="suppliers" reference={SUPPLIERS}>
        {hasPermission("supplier.create") && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", my: "8px" }}>
            <RelateSupplierModal />
          </Box>
        )}
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <ReferenceField
            source="id"
            label="Id de la empresa"
            reference={SUPPLIERS}
            link={hasPermission("supplier.view") ? "show" : false}
          >
            <TextField source="companyId" />
          </ReferenceField>
          <ReferenceField
            source="id"
            label="Nombre"
            reference={SUPPLIERS}
            link={hasPermission("supplier.view") ? "show" : false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="contactName" />
          <EmailField source="email" />
          <TextField source="phone" />
          <TextField source="socialReason" />
          <FunctionField
            label="Acciones"
            render={(record: any) => (
              <UnlinkSupplier
                sellerId={sellerId as string}
                supplierId={record.id as string}
              />
            )}
          />
        </Datagrid>
      </ReferenceArrayField>
    </RecordContextProvider>
  );
};
