import {
  TPurchase,
  TPurchaseProduct,
  getDebtSummary,
  postPurchasePaymentLink,
} from "@/api";
import { Pricing } from "@/components/purchase/Pricing";
import { PricingByStatus } from "@/components/purchase/PricingByStatus";
import { PURCHASES, SELLERS } from "@/config/resources";
import {
  Card,
  CardContent,
  Chip,
  Grid,
  Link,
  Stack,
  SvgIcon,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import {
  DateField,
  FunctionField,
  Labeled,
  NumberField,
  RecordContextProvider,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";
import useSWR from "swr";
import { PurchaseComments } from "./_comments";
import {
  mdiCashRefund,
  mdiCreditCardOutline,
  mdiLockReset,
  mdiPackageVariantClosed,
  mdiRestart,
  mdiTruckOutline,
} from "@mdi/js";
import { useAdmin } from "@/hooks/admin";
import { formatStatusColor, groupByPending } from "@/hooks/purchase";
import {
  deliveryStatusRendering,
  paymentStatus,
  status,
} from "@/data/purchases";
import { usePaymentMethods } from "@/hooks/payment-methods";
import { PurchaseTimeline } from "./_timeline";
import { useState } from "react";
import { ReusableProductsTable } from "./_reusable-products-table";
import { PurchaseSubpurchases } from "./_subpurchases";
import { PurchasePayments } from "./_payments";
import { i18nProvider } from "@/providers/i18n";

export const PurchaseMainTabContent = () => {
  const { hasPermission } = useAdmin();

  return (
    <>
      <Grid container spacing={2} wrap="wrap">
        <Grid item xs={12} md={7}>
          <Card variant="outlined">
            <CardContent>
              <Stack direction="row" sx={{ flexWrap: "wrap", gap: "1rem" }}>
                <Labeled source="purchaseNumber">
                  <TextField source="purchaseNumber" />
                </Labeled>
                <Labeled source="priority">
                  <TextField source="priority" />
                </Labeled>
                <Labeled source="createdAt">
                  <DateField source="createdAt" showTime />
                </Labeled>

                <Labeled source="origin.name">
                  <TextField source="origin.name" />
                </Labeled>
                <Labeled source="sellerId">
                  <ReferenceField
                    source="sellerId"
                    reference={SELLERS}
                    link={hasPermission("seller.view") ? "show" : false}
                  >
                    <TextField source="email" />
                  </ReferenceField>
                </Labeled>
                <DebtField />
                <Labeled source="id">
                  <TextField source="id" />
                </Labeled>
                <Labeled>
                  <DateField source="deliverOn" emptyText="-" />
                </Labeled>
              </Stack>
            </CardContent>
          </Card>
          <br />
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Stack direction="row" sx={{ flexWrap: "wrap", gap: "1rem" }}>
                <Labeled source="status">
                  <FunctionField
                    source="status"
                    render={(record: any) => (
                      <Chip
                        size="small"
                        icon={
                          <SvgIcon>
                            <path d={mdiPackageVariantClosed} />
                          </SvgIcon>
                        }
                        variant="outlined"
                        color={formatStatusColor(record.status)}
                        sx={{ width: "fit-content" }}
                        label={
                          status.find((s) => s.code === record.status)?.name
                        }
                      />
                    )}
                  />
                </Labeled>
                <Labeled source="deliveryStatus">
                  <FunctionField
                    source="deliveryStatus"
                    render={(record: any) => (
                      <Chip
                        size="small"
                        icon={
                          <SvgIcon>
                            <path d={mdiTruckOutline} />
                          </SvgIcon>
                        }
                        variant="outlined"
                        color={formatStatusColor(record.deliveryStatus)}
                        sx={{ width: "fit-content" }}
                        label={
                          deliveryStatusRendering.find(
                            (s) => s.code === record.deliveryStatus
                          )?.name
                        }
                      />
                    )}
                  />
                </Labeled>
                <Labeled source="paymentStatus">
                  <FunctionField
                    source="paymentStatus"
                    render={(record: any) => (
                      <Chip
                        size="small"
                        icon={
                          <SvgIcon>
                            <path d={mdiCreditCardOutline} />
                          </SvgIcon>
                        }
                        variant="outlined"
                        color={formatStatusColor(record.paymentStatus)}
                        sx={{ width: "fit-content" }}
                        label={
                          paymentStatus.find(
                            (s) => s.code === record.paymentStatus
                          )?.name
                        }
                      />
                    )}
                  />
                </Labeled>
                <Labeled source="paymentMethod">
                  <FunctionField
                    source="paymentMethod"
                    render={(record: any) =>
                      i18nProvider.translate(
                        `resources.${PURCHASES}.fields.paymentMethods.${record.paymentMethod}`
                      )
                    }
                  />
                </Labeled>
                <Labeled source="webCheckoutLink">
                  <FunctionField
                    source="webCheckoutLink"
                    render={(record: any) =>
                      !["UPON_DELIVERY"].includes(record.paymentMethod) ? (
                        <>
                          {record.paymentLink && (
                            <Link
                              href={
                                record.paymentLink
                                  ? record.paymentLink
                                  : record.webCheckoutLink
                              }
                              target="_blank"
                            >
                              Abrir enlace
                            </Link>
                          )}
                          {!record.paymentLink && (
                            <div
                              onClick={() => {
                                postPurchasePaymentLink(record.id);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <SvgIcon>
                                <path d={mdiRestart} />
                              </SvgIcon>
                            </div>
                          )}
                        </>
                      ) : (
                        "No aplica"
                      )
                    }
                  />
                </Labeled>
              </Stack>
              <PurchaseRejectionReasonField />
            </CardContent>
          </Card>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardContent>
                  <PurchaseComments />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card variant="outlined">
                <CardContent>
                  <FunctionField
                    label="Resumen de compra"
                    render={(record: any) => (
                      <PricingByStatus purchase={record} />
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card variant="outlined" sx={{ width: "100%", height: "100%" }}>
            <CardContent style={{ maxHeight: "320px", overflowY: "scroll" }}>
              <PurchaseTimeline />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProductsTabs />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <PurchasePayments />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <FunctionField
                label="Resumen de compra"
                render={(record: any) => <Pricing purchase={record} />}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const DebtField = () => {
  const purchase = useRecordContext<TPurchase>();
  const { data: summary } = useSWR(
    () => [purchase.sellerId, "seller-debt"],
    ([id]) => getDebtSummary(id)
  );

  if (!summary) return null;

  const debt = summary.debt;
  const label = debt < 0 ? "Deuda" : "Saldo a favor";
  const color = debt < 0 ? "red" : "green";

  return (
    <Labeled label={label}>
      <Typography color={color}>
        <NumberField record={{ debt }} source="debt" fontWeight="bold" />
      </Typography>
    </Labeled>
  );
};

const PurchaseRejectionReasonField = () => {
  const record = useRecordContext();

  if (record?.deliveryStatus !== "REJECTED") return null;

  return (
    <Labeled label="Motivo de rechazo">
      <TextField source="rejectionReason" />
    </Labeled>
  );
};

const ProductsTabs = () => {
  const purchase = useRecordContext<any>();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Productos" />
        <Tab
          label="Productos eliminados"
          disabled={purchase?.productsDeleted?.length === 0}
        />
      </Tabs>
      {value === 0 && <Products />}
      {value === 1 && (
        <ReusableProductsTable source="productsDeleted" mode="deleted" />
      )}
    </>
  );
};

const Products = () => {
  const purchase = useRecordContext<any>();
  const pendingProducts = groupByPending(purchase.products);
  return (
    <Stack spacing={2} my={2}>
      {pendingProducts["PENDING"].length > 0 && (
        <PendingProductsTable
          products={pendingProducts["PENDING"]}
          label="Productos pendientes"
        />
      )}

      <PurchaseSubpurchases />
    </Stack>
  );
};

const PendingProductsTable = ({
  products,
  label,
}: {
  products: TPurchaseProduct[];
  label: string;
}) => {
  return (
    <>
      <RecordContextProvider value={{ products: products }}>
        <Labeled label={label}>
          <ReusableProductsTable source="products" mode="pending" />
        </Labeled>
      </RecordContextProvider>
    </>
  );
};
