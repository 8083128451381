import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  ArrayInput,
  BooleanInput,
  Button,
  ReferenceField,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  AxiosError,
  getSeeriApi,
  getSubpurchaseByTrackingCode,
  TPurchase,
} from "@/api";
import { PURCHASES, SUPPLIERS } from "../../../../config/resources";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { Splitscreen } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { get } from "lodash";
import { useDeliveryProviders } from "../../../../hooks/purchase";
import { isFinishedPurchase } from "../../shared/actions-disabled";
import { useAdmin } from "../../../../hooks/admin";
import { addPurchaseComment } from "@/api";
import { useSupplierById } from "@/hooks/supplier";

const createSubPurchaseModalOpen = atom(false);
export const disableButtonAction = atom(false);
export const DISABLED_TIMEOUT = 7000;

export const CreateSubPurchaseButton = () => {
  const record = useRecordContext<TPurchase>();
  const setCreateSubPurchaseModalOpen = useSetAtom(createSubPurchaseModalOpen);
  const disabledButton = useAtomValue(disableButtonAction);

  return (
    <div>
      <Tooltip title="Crear sub pedido">
        <div>
          <IconButton
            color="primary"
            onClick={() => setCreateSubPurchaseModalOpen(true)}
            disabled={
              isFinishedPurchase(record) ||
              !validateProductsAvailables(record)?.length ||
              disabledButton
            }
          >
            <Splitscreen />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

export const CreateSubPurchaseDialog = () => {
  const { id = "" } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
  const { data } = useGetOne(PURCHASES, { id });
  const [open, setOpen] = useAtom(createSubPurchaseModalOpen);
  const productsAvailables = validateProductsAvailables(data);
  const setDisabledButton = useSetAtom(disableButtonAction);
  const deliveryProviders = useDeliveryProviders();
  const { admin } = useAdmin();

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        let {
          products,
          trackingCode,
          deliveryProvider,
          actionComment: comment,
          supplierId,
        } = values;
        products = products?.filter(
          (product: any) => product?.createSubPurchase
        );
        if (!products?.length) {
          notify("Selecciona al menos un producto", { type: "error" });
          return;
        }
        products = products.map((p: any) => ({
          id: p.id,
          quantity: p.quantity,
        }));

        const { content = [] } = await getSubpurchaseByTrackingCode(
          trackingCode
        );
        const mappedContent = content.filter(
          (order) => order.trackingCode === trackingCode
        );
        if (mappedContent?.length) {
          notify(
            "El código de seguimiento ya está siendo usado en el subpedido " +
              mappedContent[0].subPurchaseNumber,
            { type: "error" }
          );
          return;
        }

        await getSeeriApi().post(`/api/purchases/${id}/sub-purchases`, {
          purchaseId: id,
          products,
          trackingCode,
          deliveryProvider,
          supplierId,
        });

        await addPurchaseComment(id as string, {
          createdBy: admin.id,
          comment,
          fullName: admin.firstName + " " + admin.lastName,
        });

        notify("El subpedido se creo exitosamente", { type: "success" });
        refresh();
        setOpen(false);
        setDisabledButton(true);
        setTimeout(() => {
          setDisabledButton(false);
          refresh();
        }, DISABLED_TIMEOUT);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }
        setOpen(false);
      }
    },
    [id]
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle>
        Selecciona los productos que deseas agregar al nuevo sub pedido
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          record={{ products: productsAvailables }}
          resource={PURCHASES}
          onSubmit={onSubmit}
          mode="onBlur"
        >
          <SelectInput
            source="deliveryProvider"
            label="Proveedor logístico"
            fullWidth
            choices={deliveryProviders}
            validate={required()}
            optionValue="code"
          />
          <TextInput
            source="trackingCode"
            validate={required()}
            defaultValue={""}
            fullWidth
          />
          <PurchaseProductsArrayInput />
          <TextInput
            source="actionComment"
            label="Comentario"
            validate={[required()]}
            fullWidth
            multiline
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button label="Cerrar" onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
};

const PurchaseProductsArrayInput = () => {
  const supplierId = useWatch({ name: "supplierId" });

  return (
    <>
      <ReferenceField reference={SUPPLIERS} source="name">
        <TextField source="name" />
      </ReferenceField>
      {!!supplierId && <SupplierField id={supplierId} />}
      <ArrayInput source="products">
        <SimpleFormIterator
          disableClear
          disableRemove={true}
          disableReordering
          disableAdd={true}
        >
          <PurchaseProductsFields />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

type TSupplierField = {
  id: string;
};
const SupplierField = ({ id }: TSupplierField) => {
  const supplier = useSupplierById(id);

  if (!supplier) {
    return null;
  }

  return (
    <>
      <p>Solo puedes seleccionar productos de {supplier?.name ?? "-"}</p>
    </>
  );
};

const PurchaseProductsFields = (props: any) => {
  const products = useWatch({ name: "products" });
  const supplierId = useWatch({ name: "supplierId" });
  const fieldValue = useWatch({ name: `${props.source}.createSubPurchase` });

  const currentProduct = get({ products }, props.source);
  const supplier = useSupplierById(currentProduct.supplierId);
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!supplierId && fieldValue) {
      setValue("supplierId", currentProduct?.supplierId ?? "");
    }
    if (!products?.some(({ createSubPurchase }: any) => !!createSubPurchase)) {
      setValue("supplierId", "");
    }
  }, [fieldValue]);

  return (
    <>
      <BooleanInput
        source={`${props.source}.createSubPurchase`}
        fullWidth
        disabled={supplierId && supplierId !== currentProduct?.supplierId}
        label={
          <div style={{ display: "flex", padding: "1rem 1rem 0 1rem" }}>
            {currentProduct?.images?.length ? (
              <img
                src={currentProduct?.images[0]?.src}
                width={50}
                height={50}
              />
            ) : (
              <div style={{ width: 50, height: 50, backgroundColor: "#ddd" }} />
            )}
            <span style={{ padding: "0.3rem 0 1rem 1rem" }}>
              {currentProduct?.name}
              <br />
              <span style={{ fontSize: "0.8rem" }}>
                Proveedor: {supplier?.name}
              </span>
            </span>
          </div>
        }
      />
    </>
  );
};

const validateProductsAvailables = (purchase: any) => {
  let products = purchase?.products || [];

  purchase?.subPurchases?.forEach(
    ({ products: subPurchaseProducts = [] }: any) => {
      products = products.map((product: any) => {
        if (!product?.isLocked) {
          const isInSubPurchase = subPurchaseProducts.find(
            (subPurchaseProduct: any) => subPurchaseProduct?.id === product?.id
          );

          return { ...product, isLocked: !!isInSubPurchase };
        }

        return product;
      });
    }
  );

  return products?.filter((product: any) => !product?.isLocked) || [];
};
