import {
  AutocompleteInput,
  BooleanField,
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  NullableBooleanInput,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { SUPPLIERS } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";

const BrandFilter = [
  <NullableBooleanInput source="status" alwaysOn />,
  <TextInput source="name" alwaysOn />,
  <ReferenceInput source="supplierId" reference={SUPPLIERS} alwaysOn>
    <AutocompleteInput
      label="Proveedor"
      optionText="name"
      filterToQuery={(searchText: string) => ({ name: searchText })}
    />
  </ReferenceInput>,
];

const BrandActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {hasPermission("brand.create") && <CreateButton />}
      {hasPermission("brand.export") && <ExportButton />}
    </TopToolbar>
  );
};

export const BrandList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("brand.list")) return null;

  return (
    <List
      filters={BrandFilter}
      sort={{ field: "name", order: "ASC" }}
      perPage={20}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("brand.create")}
      actions={<BrandActions />}
    >
      <Datagrid
        rowClick={hasPermission("brand.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <TextField source="name" />
        <ReferenceField
          source="supplierId"
          reference={SUPPLIERS}
          link={hasPermission("supplier.view") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="featured" />
        <BooleanField source="status" />
      </Datagrid>
    </List>
  );
};
