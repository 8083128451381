import { useEffect } from "react";
import { Edit, useRedirect, useStore } from "react-admin";
import { COURSES, LECTURES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { LectureForm } from "./_form";

export const LectureEdit = () => {
  const { hasPermission } = useAdmin();
  const redirect = useRedirect();

  const [courseId] = useStore("currentCourseId");

  useEffect(() => {
    if (!courseId) {
      redirect("/" + COURSES);
    }
  }, [courseId]);

  if (!hasPermission("lecture.update")) return null;

  if (!courseId) return null;

  return (
    <Edit
      resource={LECTURES}
      mutationMode="pessimistic"
      redirect="show"
      queryOptions={{
        meta: {
          dependentId: courseId,
        },
      }}
      mutationOptions={{
        meta: {
          dependentId: courseId,
        },
      }}
    >
      <LectureForm isEdit />
    </Edit>
  );
};
