import { Grid, IconButton } from "@mui/material";
import { AxiosError, TProductGroup } from "@/api";
import { FC } from "react";
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  Labeled,
  List,
  NullableBooleanInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useNotify,
  useRecordContext,
  useUpdate,
} from "react-admin";
import { ModalImageField } from "../../components/elements/ModalImageField";
import {
  BRANDS,
  CATEGORIES,
  PRODUCT_GROUPS,
  SUPPLIERS,
} from "../../config/resources";
import {
  productTypeChoices,
  supplierProductApproveStatus,
} from "../../data/products";
import { useAdmin } from "../../hooks/admin";
import { CategoryAutocompleteInput } from "../shared/category";
import { CustomPagination } from "../shared/pagination";
import { Refresh, ToggleOn, ToggleOffOutlined } from "@mui/icons-material";
import { ProductGroupsImportButton } from "./import/dialog";
import { ProductsField } from "./fields/products";

const ProductGroupListFilters = [
  <SelectInput
    source="approvedStatus"
    choices={supplierProductApproveStatus}
    optionValue="id"
    alwaysOn
  />,
  <NullableBooleanInput
    source="status"
    trueLabel="Activo"
    falseLabel="Inactivo"
    alwaysOn
  />,
  <SelectInput
    source="type"
    choices={productTypeChoices}
    optionValue="id"
    alwaysOn
  />,
  <CategoryAutocompleteInput source="categoryId" label="Categoría" alwaysOn />,
  <ReferenceInput
    source="supplierId"
    reference={SUPPLIERS}
    sort={{ field: "name", order: "ASC" }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      optionText="name"
      filterToQuery={(searchText: string) => ({ name: searchText })}
    />
  </ReferenceInput>,
  <ReferenceInput
    source="brandId"
    reference={BRANDS}
    sort={{ field: "name", order: "ASC" }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      optionText="name"
      filterToQuery={(searchText: string) => ({ name: searchText })}
    />
  </ReferenceInput>,
  <TextInput source="name" alwaysOn />,
];

const ProductGroupListActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      <FilterButton />
      {hasPermission("productGroup.create") && <CreateButton />}
      <ProductGroupsImportButton />
    </TopToolbar>
  );
};

export const ProductGroupList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("productGroup.list")) return null;

  return (
    <List
      perPage={20}
      sort={{ field: "name", order: "ASC" }}
      filters={ProductGroupListFilters}
      pagination={<CustomPagination />}
      actions={<ProductGroupListActions />}
    >
      <Datagrid
        rowClick={hasPermission("productGroup.view") ? "show" : undefined}
        bulkActionButtons={false}
        expand={
          <Grid container maxWidth="80%" margin="0 auto">
            <Grid item xs={12}>
              <Labeled label="Productos" fullWidth>
                <ProductsField />
              </Labeled>
            </Grid>
          </Grid>
        }
      >
        <FunctionField
          label="Foto"
          render={() => (
            <ModalImageField source="images.0.src" maxHeight={60} />
          )}
        />
        <TextField source="name" />
        <ReferenceField
          source="supplierId"
          reference={SUPPLIERS}
          link={hasPermission("supplier.view") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="brandId"
          reference={BRANDS}
          link={hasPermission("brand.view") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="categoryId"
          reference={CATEGORIES}
          link={hasPermission("category.view") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="type"
          render={(record: any) =>
            productTypeChoices.find((t) => t.id === record.type)?.name
          }
        />
        <DateField source="createdAt" showTime />
        <FunctionField
          source="approvedStatus"
          render={(group: any) =>
            supplierProductApproveStatus.find(
              ({ id }) => id === group.approvedStatus
            )?.name ?? "Sin estado"
          }
        />
        <FunctionField
          source="status"
          render={(group: any) =>
            hasPermission("product.update.status") &&
            !["PENDING", "IN_REVIEW"].includes(group.approvedStatus) ? (
              <ProductGroupStatusToggle source="status" />
            ) : (
              <BooleanField source="status" />
            )
          }
        />
      </Datagrid>
    </List>
  );
};

const ProductGroupStatusToggle: FC<{ source?: string }> = () => {
  const productGroup = useRecordContext<TProductGroup>();
  const notify = useNotify();

  const canToggle =
    productGroup.status || productGroup.products.some((p) => p.status);

  const [update, { isLoading }] = useUpdate(
    PRODUCT_GROUPS,
    {
      id: productGroup?.id,
      data: { ...productGroup, status: !productGroup.status },
      previousData: productGroup,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );

  return (
    <IconButton
      color="primary"
      title={productGroup.status ? "Desactivar" : "Activar"}
      onClick={(e) => {
        e.stopPropagation();
        update();
      }}
      disabled={isLoading || !canToggle}
    >
      {isLoading ? (
        <Refresh className={isLoading ? "rotate-icon" : ""} />
      ) : productGroup.status ? (
        <ToggleOn fontSize="large" />
      ) : (
        <ToggleOffOutlined fontSize="large" />
      )}
    </IconButton>
  );
};
