import {
  CreateButton,
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  ImageField,
  List,
  NumberField,
  ReferenceField,
  SelectInput,
  TextField,
  TopToolbar,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";
import { statusChoices, typeChoices } from "./constants";
import { SUPPLIERS } from "@/config/resources";

const BannerListFilters = [
  <SelectInput source="type" choices={typeChoices} alwaysOn />,
  <SelectInput source="status" choices={statusChoices} alwaysOn />,
];

const BannerListActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      <FilterButton />
      {hasPermission("banner.create") && <CreateButton />}
    </TopToolbar>
  );
};

export const BannerList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("banner.list")) return null;

  return (
    <List
      perPage={20}
      sort={{ field: "name", order: "ASC" }}
      filters={BannerListFilters}
      pagination={<CustomPagination />}
      actions={<BannerListActions />}
    >
      <Datagrid
        rowClick={hasPermission("banner.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <ImageField
          source="images.webImage"
          sx={{
            "&>img.RaImageField-image": {
              width: 120,
              height: 60,
              backgroundColor: "#efefef",
            },
          }}
        />
        <ReferenceField source="supplierId" reference={SUPPLIERS}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <FunctionField
          source="type"
          render={(record: any) =>
            typeChoices.find((c) => c.id === record.type)?.name
          }
        />
        <DateField source="availableSince" showTime />
        <DateField source="availableUntil" showTime />
        <NumberField source="priority" />
        <FunctionField
          source="status"
          render={(record: any) =>
            statusChoices.find((c) => c.id === record.status)?.name
          }
        />
      </Datagrid>
    </List>
  );
};
