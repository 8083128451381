import { BrowserRouter } from "react-router-dom";
import {
  ShowChart,
  MultilineChart,
  TagOutlined,
  SchoolOutlined,
  HeadsetMicOutlined,
  PhotoAlbumOutlined,
  FilePresent,
  Inventory,
} from "@mui/icons-material";
import {
  mdiPackageVariantClosed,
  mdiFormatListBulletedSquare,
  mdiAccountStarOutline,
  mdiAccountHeartOutline,
  mdiTicketPercentOutline,
  mdiAccountDetailsOutline,
  mdiAccountSwitchOutline,
  mdiShieldAccountOutline,
  mdiShieldAccountVariantOutline,
  mdiReceiptTextOutline,
  mdiGift,
} from "@mdi/js";
import { type FC, type ReactNode, useEffect, useState } from "react";
import {
  Admin,
  CustomRoutes,
  Layout,
  type LayoutProps,
  LoadingPage,
  Resource,
} from "react-admin";
import { Route } from "react-router";
import { MyAppBar } from "./components/AppBar";
import { CustomLoginPage } from "./components/auth/CustomLoginPage";
import { ProfileEdit } from "./components/Profile";
import { SideMenu } from "./components/SideMenu";
import { Version } from "./components/Version";
import {
  ADMINROLES,
  ADMINS,
  BRANDS,
  CATEGORIES,
  COUPONS,
  COURSES,
  LECTURES,
  PERMISSIONS,
  PRODUCTS,
  PURCHASES,
  TICKETS,
  RESOURCES,
  SELLERS,
  SUPPLIERS,
  SUPPLIER_PURCHASES,
  VOUCHERS,
  BANNERS,
  PRODUCT_GROUPS,
  DOCUMENTS,
  QUOTES,
  OFFERTS,
  SUPPLIER_PERMISSIONS,
} from "./config/resources";
import { refreshSeeriApiConfig } from "./context/seeri-api";
import { authProvider, dataProvider } from "./providers";
import { i18nProvider } from "./providers/i18n";
import {
  AdminRoleCreate,
  AdminRoleEdit,
  AdminRoleList,
  AdminRoleShow,
} from "./resources/adminroles";
import {
  AdminCreate,
  AdminEdit,
  AdminList,
  AdminShow,
} from "./resources/admins";
import {
  BrandCreate,
  BrandEdit,
  BrandList,
  BrandShow,
} from "./resources/brands";
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "./resources/categories";
import {
  CouponCreate,
  CouponEdit,
  CouponList,
  CouponShow,
} from "./resources/coupons";
import {
  CourseCreate,
  CourseEdit,
  CourseList,
  CourseShow,
} from "./resources/courses";
import {
  LectureCreate,
  LectureEdit,
  LectureList,
  LectureShow,
} from "./resources/lectures";
import { PermissionList, PermissionShow } from "./resources/permissions";
import {
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow,
} from "./resources/products";
import {
  PurchaseCreate,
  PurchaseList,
  PurchaseShow,
} from "./resources/purchases";
import { TicketEdit, TicketList, TicketShow } from "./resources/tickets";
import { Quoter } from "./resources/purchases/quoter";
import {
  ResourceCreate,
  ResourceEdit,
  ResourceList,
  ResourceShow,
} from "./resources/resources";
import {
  SellerCreate,
  SellerEdit,
  SellerList,
  SellerShow,
} from "./resources/sellers";
import { SupplierPurchaseShow } from "./resources/supplierpurchases";
import { SupplierPurchaseList } from "./resources/supplierpurchases/list";
import {
  SupplierCreate,
  SupplierEdit,
  SupplierList,
  SupplierShow,
} from "./resources/suppliers";
import { darkTheme, myTheme } from "./theme";
import { Box, SvgIcon } from "@mui/material";
import { VoucherCreate, VoucherList, VoucherShow } from "./resources/vouchers";
import {
  BannerCreate,
  BannerEdit,
  BannerList,
  BannerShow,
} from "./resources/banners";
import {
  ProductGroupCreate,
  ProductGroupEdit,
  ProductGroupList,
  ProductGroupShow,
} from "./resources/productgroups";
import { DocumentList } from "./resources/documents";
import { TicketCreate } from "./resources/tickets/post";
import { QuoteList } from "./resources/quotes/list";
import { QuoteEdit } from "./resources/quotes/edit";
import { QuoteShow } from "./resources/quotes/show";
import { QuoteCreate } from "./resources/quotes/create";
import { OffertList } from "./resources/offerts/list";
import { OffertShow } from "./resources/offerts/show";
import { OffertCreate } from "./resources/offerts/post";
import { OffertEdit } from "./resources/offerts/put";
import { SupplierPaymentShow } from "./resources/suppliers/show/payments/show";
import { SupplierPermissionList } from "./resources/supplier-permissions/list";
import { SupplierPermissionShow } from "./resources/supplier-permissions/show";
import { SupplierPermissionCreate } from "./resources/supplier-permissions/post";
import { SupplierPermissionEdit } from "./resources/supplier-permissions/edit";
import { useAdmin } from "./hooks/admin";

const AppLayout: FC<LayoutProps> = ({ children, ...props }) => {
  return (
    <Layout {...props} menu={SideMenu} appBar={MyAppBar}>
      <ValidatedChildren>{children}</ValidatedChildren>
    </Layout>
  );
};

const ValidatedChildren: FC<{ children: ReactNode }> = ({ children }) => {
  const { admin, allowedCountry, enabled } = useAdmin();

  if (!admin) {
    return <LoadingPage />;
  }

  if (!enabled) {
    return (
      <Box>
        <p>Usuario deshabilitado</p>
      </Box>
    );
  }

  if (!allowedCountry) {
    return (
      <Box>
        <p>País no permitido</p>
      </Box>
    );
  }

  return children;
};

function App() {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    refreshSeeriApiConfig()
      .then(() => setReady(true))
      .catch(() => setError("Error de configuración remota."));
  }, []);

  if (error) return <>{error}</>;
  if (!ready) return <>Cargando...</>;

  return (
    <>
      <BrowserRouter>
        <Admin
          loginPage={CustomLoginPage}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          theme={myTheme}
          lightTheme={myTheme}
          darkTheme={darkTheme}
          layout={AppLayout}
          disableTelemetry
          requireAuth
        >
          <CustomRoutes>
            <Route
              key="mi-perfil"
              path="/mi-perfil"
              element={<ProfileEdit />}
            />
            <Route
              key="purchases-quoter"
              path={`/${PURCHASES}/quoter`}
              element={<Quoter />}
            />
          </CustomRoutes>
          <Resource
            name={PURCHASES}
            list={PurchaseList}
            show={PurchaseShow}
            icon={ShowChart}
            create={PurchaseCreate}
          />
          <Resource
            name={SUPPLIER_PURCHASES}
            list={SupplierPurchaseList}
            show={SupplierPurchaseShow}
            icon={MultilineChart}
          />
          <Resource
            name={VOUCHERS}
            list={VoucherList}
            create={VoucherCreate}
            show={VoucherShow}
            icon={() => (
              <SvgIcon>
                <path d={mdiReceiptTextOutline} />
              </SvgIcon>
            )}
          />
          <Resource
            name={PRODUCTS}
            list={ProductList}
            show={ProductShow}
            edit={ProductEdit}
            create={ProductCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiPackageVariantClosed} />
              </SvgIcon>
            )}
          />
          <Resource
            name={PRODUCT_GROUPS}
            list={ProductGroupList}
            show={ProductGroupShow}
            edit={ProductGroupEdit}
            create={ProductGroupCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiPackageVariantClosed} />
              </SvgIcon>
            )}
          />
          <Resource
            name={CATEGORIES}
            list={CategoryList}
            show={CategoryShow}
            edit={CategoryEdit}
            create={CategoryCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiFormatListBulletedSquare} />
              </SvgIcon>
            )}
          />
          <Resource
            name={SELLERS}
            list={SellerList}
            show={SellerShow}
            edit={SellerEdit}
            create={SellerCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiAccountStarOutline} />
              </SvgIcon>
            )}
          />
          <Resource
            name={BRANDS}
            list={BrandList}
            show={BrandShow}
            edit={BrandEdit}
            create={BrandCreate}
            icon={TagOutlined}
          />
          <Resource
            name={SUPPLIERS}
            list={SupplierList}
            show={SupplierShow}
            edit={SupplierEdit}
            create={SupplierCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiAccountHeartOutline} />
              </SvgIcon>
            )}
          >
            <Route
              path=":supplierId/show/2/suppliers-payments/:paymentId"
              element={<SupplierPaymentShow />}
            />
          </Resource>
          <Resource
            name={COUPONS}
            list={CouponList}
            show={CouponShow}
            edit={CouponEdit}
            create={CouponCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiTicketPercentOutline} />
              </SvgIcon>
            )}
          />
          <Resource
            name={OFFERTS}
            list={OffertList}
            show={OffertShow}
            edit={OffertEdit}
            create={OffertCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiGift} />
              </SvgIcon>
            )}
          />
          <Resource
            name={COURSES}
            list={CourseList}
            show={CourseShow}
            edit={CourseEdit}
            create={CourseCreate}
            icon={SchoolOutlined}
          />
          <Resource
            name={LECTURES}
            list={LectureList}
            show={LectureShow}
            edit={LectureEdit}
            create={LectureCreate}
          />
          <Resource
            name={RESOURCES}
            list={ResourceList}
            show={ResourceShow}
            edit={ResourceEdit}
            create={ResourceCreate}
          />
          <Resource
            name={ADMINS}
            list={AdminList}
            show={AdminShow}
            edit={AdminEdit}
            create={AdminCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiAccountDetailsOutline} />
              </SvgIcon>
            )}
          />
          <Resource
            name={ADMINROLES}
            list={AdminRoleList}
            show={AdminRoleShow}
            edit={AdminRoleEdit}
            create={AdminRoleCreate}
            icon={() => (
              <SvgIcon>
                <path d={mdiAccountSwitchOutline} />
              </SvgIcon>
            )}
          />
          <Resource
            name={PERMISSIONS}
            list={PermissionList}
            show={PermissionShow}
            icon={() => (
              <SvgIcon>
                <path d={mdiShieldAccountOutline} />
              </SvgIcon>
            )}
          />
          <Resource
            name={SUPPLIER_PERMISSIONS}
            list={SupplierPermissionList}
            show={SupplierPermissionShow}
            create={SupplierPermissionCreate}
            edit={SupplierPermissionEdit}
            icon={() => (
              <SvgIcon>
                <path d={mdiShieldAccountVariantOutline} />
              </SvgIcon>
            )}
          />
          <Resource
            name={TICKETS}
            list={TicketList}
            edit={TicketEdit}
            create={TicketCreate}
            show={TicketShow}
            icon={HeadsetMicOutlined}
          />
          <Resource name={DOCUMENTS} list={DocumentList} icon={FilePresent} />
          <Resource
            name={BANNERS}
            list={BannerList}
            show={BannerShow}
            create={BannerCreate}
            edit={BannerEdit}
            icon={PhotoAlbumOutlined}
          />
          <Resource
            name={QUOTES}
            list={QuoteList}
            show={QuoteShow}
            create={QuoteCreate}
            edit={QuoteEdit}
            icon={Inventory}
          />
        </Admin>
      </BrowserRouter>
      <Version />
    </>
  );
}

export default App;
