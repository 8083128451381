import { getSeeriApi } from "@/api";
import useSWR from "swr";

export const useSubscription = (supplierId?: string) => {
  const { data: resources } = useSubscriptionResources();

  const features = resources?.features ?? [];
  const packages = resources?.packages ?? [];
  const addons = resources?.addons ?? [];

  const { data: subscription, mutate: refreshSubscription } = useSWR(
    supplierId ? `/api/subscriptions/${supplierId}` : null,
    (url) =>
      getSeeriApi()
        .get<ISubscription>(url)
        .then((res) => res.data)
  );

  const { data: subscriptionValidations, mutate: refreshValidations } = useSWR(
    `/api/subscriptions/${supplierId}/validations`,
    (url) =>
      getSeeriApi()
        .get<ISubscriptionValidations>(url)
        .then((res) => res.data)
  );

  const currentPackage = subscription?.currentPackage;

  const hasFeature = (featCode: string) =>
    currentPackage?.features[featCode]?.has;

  const basicPack = resources?.packages.find(
    (p) => p.purchasable && !p.monthly
  );

  const isBasicFeature = (featCode: string) =>
    basicPack?.features[featCode]?.has;

  const reloadSubscription = async () => {
    return Promise.all([refreshSubscription(), refreshValidations()]);
  };

  return {
    subscription,
    subscriptionValidations,
    currentPackage,
    features,
    packages,
    addons,
    hasFeature,
    isBasicFeature,
    reloadSubscription,
  };
};

export const useSubscriptionResources = () => {
  return useSWR(`/api/subscriptions/packages`, (url) =>
    getSeeriApi()
      .get<ISubscriptionResources>(url)
      .then((res) => res.data)
  );
};

export interface ISubscriptionResources {
  features: IFeature[];
  packages: IPackage[];
  addons: IAddon[];
}

export interface IFeature {
  code: string;
  type: "BOOLEAN" | "NUMERIC";
  name: string;
  description: string;
}

export interface IPackage {
  id: string;
  countryCode: number;
  currency: string;
  code: string;
  name: string;
  description: string;
  image: null;

  monthly: number;
  biyearly: number;
  biyearlyDiscount: number;
  yearly: number;
  yearlyDiscount: number;

  visible: boolean;
  purchasable: boolean;
  addonnable: boolean;

  packageTrial: string;
  notAvailableWhen: string;

  features: IFeatures;
}

export interface IFeatures {
  SELLS: IFeatureDetails;
  PRODUCTS: IFeatureDetails;
  EMAIL: IFeatureDetails;
  USERS: IFeatureDetails;
  MAX_USERS: IFeatureDetails;
  PURCHASES: IFeatureDetails;
  SUB_PURCHASES: IFeatureDetails;
  [key: string]: IFeatureDetails;
}

export interface IFeatureDetails {
  has: boolean;
  unlimited: boolean | null;
  value: number | null;
}

export interface IAddon {
  id: string;
  currency: string;
  countryCode: string;
  monthly: number;
  yearly: number;
  yearlyDiscount: number;
  biyearly: number;
  biyearlyDiscount: number;
  code: string;
  name: string;
  visible: boolean;
  purchasable: boolean;
  addonnable: boolean;
  features: null;
}

export interface ISubscription {
  currentPackage: IPackage;
  start: string;
  finish: string;
}

export interface ISubscriptionValidations {
  [key: string]:
    | {
        create: boolean;
        current: number;
        maximum: number;
      }
    | undefined;
}
