import { Settings } from "@mui/icons-material";
import { Logout, MenuItemLink, UserMenu } from "react-admin";

const ProfileMenu = () => {
  return (
    <MenuItemLink
      to="/mi-perfil"
      primaryText="Mi perfil"
      leftIcon={<Settings />}
    />
  );
};

export const MyUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ProfileMenu />
    <Logout />
  </UserMenu>
);
