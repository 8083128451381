import { SELLER_BILLING_ADDRESSES } from "@/config/resources";
import { CustomPagination } from "@/resources/shared/pagination";
import { Box } from "@mui/material";
import { FC } from "react";
import {
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  FunctionField,
  ListBase,
  TextField,
} from "react-admin";
import { CreateSellerInvoiceAddressAction } from "./create-modal";
import { ShowSellerInvoiceAddressAction } from "./show-modal";

type Props = {
  sellerId: string;
};

export const SellerInvoiceAddressesList: FC<Props> = ({ sellerId }) => {
  return (
    <ListBase resource={SELLER_BILLING_ADDRESSES} filter={{ sellerId }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", my: "8px" }}>
        <CreateSellerInvoiceAddressAction sellerId={sellerId} />
      </Box>
      <Datagrid bulkActionButtons={false}>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="documentType" />
        <TextField source="documentId" />
        <TextField source="phonePrefix" />
        <TextField source="phone" />
        <TextField source="email" />
        <TextField source="country" />
        <TextField source="state" />
        <TextField source="city" />
        <TextField source="addressLine1" />
        <DateField source="createdAt" showTime />
        <FunctionField
          label="Acciones"
          render={(record: any) => (
            <Box sx={{ display: "flex", gap: "0.25rem" }}>
              <ShowSellerInvoiceAddressAction billingAddressId={record.id} />
              <DeleteWithConfirmButton
                redirect={false}
                resource={SELLER_BILLING_ADDRESSES}
                id={record.id}
              />
            </Box>
          )}
        />
      </Datagrid>
      <CustomPagination />
    </ListBase>
  );
};
