import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { OfferForm } from "./_form";

export const OffertEdit = () => {
  const { hasPermission, admin } = useAdmin();

  if (!hasPermission("offert.update")) return null;

  const transform = (values: any) => {
    const data = {
      ...values,
      targetSuppliersProducts:
        values.type === "PAY_X_TAKE_Y"
          ? [values.createdBy]
          : values.targetSuppliersProducts,
      createdBy: values.type === "PAY_X_TAKE_Y" ? values.createdBy : admin.id,
    };
    return data;
  };

  return (
    <Edit mutationMode="pessimistic" redirect="show" transform={transform}>
      <OfferForm isEdit />
    </Edit>
  );
};
