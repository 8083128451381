import {
  ArrayField,
  ChipField,
  Datagrid,
  FunctionField,
  List,
  SingleFieldList,
  TextField,
} from "react-admin";
import { useAdmin } from "@/hooks/admin";
import { CustomPagination } from "@/resources/shared/pagination";

export const AdminRoleList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("adminrole.list")) return null;

  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      perPage={20}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("adminrole.create")}
    >
      <Datagrid
        rowClick={hasPermission("adminrole.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <TextField source="name" />
        <ArrayField source="permissions">
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(permission: string) => (
                <ChipField source="permission" record={{ permission }} />
              )}
            />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
};
