import { Box, Chip, ChipProps } from "@mui/material";
import { RaRecord, useRecordContext } from "react-admin";

export const SimpleChipField = ({
  render,
  ...chipProps
}: ChipProps & { render?: (record?: RaRecord) => any }) => {
  const record = useRecordContext();

  return record ? (
    <Box sx={{ display: "inline-block", padding: "4px 2px" }}>
      <Chip label={render ? render(record) : record} {...chipProps} />
    </Box>
  ) : null;
};

SimpleChipField.defaultProps = {
  addLabel: true,
};
