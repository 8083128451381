import { Button, useRefresh } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Alert,
  AlertTitle,
  Typography,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Upload, Download, Check, Refresh } from "@mui/icons-material";
import { downloadTemplate } from "./template/download";
import { openFiles } from "./utils";
import { readTemplate } from "./template/read";
import { ExcelProductGroup } from "./schemas/excel";
import { z, ZodIssue } from "zod";
import { groupBy, sortBy } from "lodash";
import {
  ExcelProductGroupToDTO,
  ProductGroupDTO,
} from "./schemas/excel-to-dto";
import { AxiosError, createProductGroup } from "@/api";
import { productGroupHeaders, productHeaders } from "./headers";
import { buckets } from "../../../core/buckets";
import { PRODUCT_GROUPS } from "../../../config/resources";
import { nanoid } from "nanoid";
import { uploadFile } from "../../../utils/file-upload";
import { optimizeImage } from "../../../utils/optimize-image";

export const ProductGroupsImportButton = () => {
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const [rawGroups, setRawGroups] = useState<ExcelProductGroup[] | null>(null);
  const [images, setImages] = useState<Record<string, File>>({});
  const [data, setData] = useState<ProductGroupDTO[] | null>(null);

  const [issues, setIssues] = useState<ZodIssue[]>([]);
  const [errors, setErrors] = useState<
    { message: string; received: ProductGroupDTO }[]
  >([]);

  const [validating, setValidating] = useState(false);

  const [uploaded, setUploaded] = useState(0);
  const [uploading, setUploading] = useState(false);
  const isFinished = uploaded + errors.length === data?.length;

  const sortedIssues = sortBy(issues, (issue) => issue.path.join("."));
  const groupedIssues = groupBy(sortedIssues, (issue) =>
    issue.path.slice(0, -1).join(".")
  );

  const reset = () => {
    setRawGroups(null);
    setImages({});
    setUploaded(0);
  };

  const loadTemplate = async () => {
    const [file] = await openFiles({ accept: ".xlsx" });
    if (!file) return;
    setRawGroups(null);
    const rawGroups = await readTemplate(file);
    setRawGroups(rawGroups);
  };

  const loadImages = async () => {
    setImages({});
    const files = await openFiles({
      multiple: true,
      accept: ".jpg, .png, .jpeg",
    });
    setImages(files.reduce((obj, file) => ({ ...obj, [file.name]: file }), {}));
  };

  const validate = async () => {
    if (!rawGroups) return;
    setData(null);
    setIssues([]);
    setValidating(true);

    /** Valida si se nombran imágenes en el excel pero no existen en la carpeta */
    const DTOwImages = ExcelProductGroupToDTO.transform((group, ctx) => {
      group.products.forEach((product, index) => {
        const img = product.images[0];
        if (img && !images[img.src]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `No se encontró la imagen ${img.src}`,
            path: ["products", index, "image"],
          });
        }
      });
      return group;
    });

    const result = await DTOwImages.array().safeParseAsync(rawGroups);

    if (result.success) {
      setData(result.data);
    } else {
      setIssues(result.error.errors);
    }

    setValidating(false);
  };

  /** Valida cuando la plantilla o las imágenes cambian */
  useEffect(() => {
    if (rawGroups) {
      validate();
    } else {
      setData(null);
      setIssues([]);
      setErrors([]);
    }
  }, [rawGroups, images]);

  /** Optimiza y carga las imágenes al bucket */
  const uploadAndSetImages = async (dto: ProductGroupDTO) => {
    const bucket = buckets.products;
    for (const product of dto.products) {
      if (product.images.length) {
        const rawFile = images![product.images[0].src];
        const image = await optimizeImage(rawFile, 800, 800, 95);
        const url = await uploadFile(bucket, PRODUCT_GROUPS, nanoid(), image);
        product.images[0].src = url;
      }
    }
  };

  const upload = async () => {
    setUploading(true);
    setUploaded(0);
    for (let dto of data!) {
      dto = structuredClone(dto);
      await uploadAndSetImages(dto);
      try {
        await createProductGroup(dto);
        setUploaded((prev) => prev + 1);
      } catch (error) {
        const message =
          error instanceof AxiosError
            ? error.response?.data?.message ?? error.message
            : "Error";
        setErrors((prev) => [...prev, { message, received: dto }]);
      }
    }
    setUploading(false);
    refresh();
  };

  return (
    <>
      <Button
        color="primary"
        label="Importar"
        onClick={() => setOpen(true)}
        startIcon={<Upload />}
      />

      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason) return;
          setOpen(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Importación Masiva de Grupos de Productos</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={1}
            mb={2}
          >
            <Box>
              <Tooltip title="Descargar plantilla excel (.xlsx)">
                <div>
                  <Button
                    label="Plantilla Excel"
                    onClick={downloadTemplate}
                    variant="outlined"
                    size="large"
                    startIcon={<Download />}
                  />
                </div>
              </Tooltip>
            </Box>
            <Typography>o</Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <Tooltip title="Cargar archivo excel (.xlsx)">
                <div>
                  <Button
                    label="Cargar Excel"
                    onClick={loadTemplate}
                    variant="outlined"
                    size="large"
                    startIcon={<Upload />}
                    endIcon={rawGroups ? <Check color="success" /> : null}
                    disabled={validating}
                  />
                </div>
              </Tooltip>

              <Typography>y</Typography>
              <Tooltip title="Cargar carpeta de imágenes">
                <div>
                  <Button
                    label="Cargar imágenes"
                    onClick={loadImages}
                    variant="outlined"
                    size="large"
                    startIcon={<Upload />}
                    endIcon={
                      Object.keys(images).length ? (
                        <Check color="success" />
                      ) : null
                    }
                    disabled={validating}
                  />
                </div>
              </Tooltip>
            </Box>
          </Box>

          {validating && (
            <Box textAlign="center">
              <Refresh className={validating ? "rotate-icon" : ""} />
            </Box>
          )}

          {rawGroups && !validating && !issues.length && (
            <Alert severity="info">
              <AlertTitle>Listo para cargar</AlertTitle>
              Todas las validaciones pasaron correctamente.
            </Alert>
          )}

          {!!issues.length && (
            <Box display="flex" flexDirection="column" gap={2}>
              {Object.entries(groupedIssues).map(([key, issues], i) => (
                <Alert key={i} severity="warning">
                  <AlertTitle>
                    <b>{rawGroups?.[issues[0].path[0] as number].name}</b>
                    {issues[0].path[1] === "products" &&
                      " → Variante " + ((issues[0].path[2] as number) + 1)}
                  </AlertTitle>
                  <ul style={{ paddingLeft: 20, margin: "4px 0" }}>
                    {issues.map((issue, i) => (
                      <li key={i} style={{ margin: "4px 0" }}>
                        <Typography>
                          <b>
                            {issue.path.length == 2
                              ? productGroupHeaders[
                                  issue.path.at(
                                    -1
                                  ) as keyof typeof productGroupHeaders
                                ]
                              : productHeaders[
                                  issue.path.at(
                                    -1
                                  ) as keyof typeof productHeaders
                                ]}
                          </b>
                          : {issue.message}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Alert>
              ))}
            </Box>
          )}

          {data && (
            <>
              <Typography mt={2}>
                Progreso: {uploaded} / {data.length}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={Math.floor((uploaded * 100) / data.length)}
              />
              {isFinished && (
                <Typography mt={2}>
                  <b>Carga finalizada.</b>
                </Typography>
              )}
            </>
          )}

          {!!errors.length && (
            <Box display="flex" flexDirection="column" gap={2}>
              {errors.map((error, i) => (
                <Alert key={i} severity="error">
                  <AlertTitle>
                    <b>{error.received.name}</b>
                  </AlertTitle>
                  <Typography>{error.message}</Typography>
                </Alert>
              ))}
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            label="Limpiar"
            variant="text"
            onClick={reset}
            size="medium"
            sx={{ mr: "auto" }}
            disabled={uploading}
          />
          <Button
            label="Cerrar"
            variant="text"
            onClick={() => setOpen(false)}
            size="medium"
            disabled={uploading}
          />
          {!isFinished && (
            <Button
              label="Crear productos"
              variant="contained"
              onClick={upload}
              size="medium"
              disabled={!data || uploading}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
