import {
  ArrayField,
  Datagrid,
  FunctionField,
  Labeled,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";
import { PRODUCTS } from "@/config/resources";
import { useAdmin } from "@/hooks/admin";

export const KitProductsReferenceArrayField = () => {
  const record = useRecordContext();
  const { hasPermission } = useAdmin();

  if (record?.type !== "KIT") return null;

  return (
    <Labeled label="Productos del kit">
      <ArrayField source="products">
        <Datagrid
          rowClick={hasPermission("product.view") ? "show" : undefined}
          bulkActionButtons={false}
        >
          <ReferenceField
            source="id"
            reference={PRODUCTS}
            link={hasPermission("product.view") ? "show" : false}
            label="Imagen"
          >
            <FunctionField
              source="images"
              render={(record: any) => (
                <img src={record.images[0]?.src} width={50} height={50} />
              )}
            />
          </ReferenceField>
          <ReferenceField
            source="id"
            reference={PRODUCTS}
            link={hasPermission("product.view") ? "show" : false}
            label="Nombre"
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="quantity" />
        </Datagrid>
      </ArrayField>
    </Labeled>
  );
};
