import { Edit } from "react-admin";

import { TicketForm } from "./_form";
import { useAdmin } from "../../hooks/admin";

export const TicketEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("ticket.update")) return null;

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <TicketForm />
    </Edit>
  );
};
