import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { Button } from "react-admin";
import { Close, Circle } from "@mui/icons-material";
import { createPortal } from "react-dom";

type ModalAction = {
  buttonMode?: "normal" | "icon";
  buttonText?: string;
  buttonIcon?: ReactNode;
  buttonDisabled?: boolean;
  buttonVariant?: "text" | "outlined" | "contained" | undefined;
  tooltipTitle?: ReactNode;
  dialogTitle: ReactNode;
  dialogContent?: ReactNode | ((onClose: () => void) => ReactNode);
  dialogContentSx?: SxProps<Theme>;
  dialogActions?: ReactNode | ((onClose: () => void) => ReactNode);
  dialogMaxWidth?: Breakpoint;
  disableClose?: boolean;
};

export const ModalAction: FC<ModalAction> = ({
  buttonMode = "normal",
  buttonText = "Botón",
  buttonIcon = null,
  buttonDisabled = false,
  buttonVariant = "outlined",
  dialogTitle,
  dialogContent = null,
  dialogContentSx,
  dialogActions = null,
  disableClose,
  dialogMaxWidth = "md",
  tooltipTitle,
}) => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  return (
    <>
      <Tooltip title={tooltipTitle || dialogTitle || buttonText}>
        <div style={{ display: "inline-block" }}>
          {buttonMode === "normal" && (
            <Button
              color="primary"
              variant={buttonVariant}
              label={buttonText}
              onClick={() => setOpen(true)}
              startIcon={buttonIcon ?? null}
              disabled={buttonDisabled}
            />
          )}
          {buttonMode === "icon" && (
            <IconButton
              color="primary"
              onClick={() => setOpen(true)}
              disabled={buttonDisabled}
            >
              {buttonIcon ?? <Circle />}
            </IconButton>
          )}
        </div>
      </Tooltip>

      {createPortal(
        <Dialog
          open={open}
          onClose={(_, reason) => {
            if (reason) return;
            setOpen(false);
          }}
          fullWidth
          maxWidth={dialogMaxWidth}
        >
          <DialogTitle>
            {dialogTitle}
            <IconButton
              aria-label="close"
              onClick={onClose}
              disabled={disableClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          {dialogContent && (
            <DialogContent sx={dialogContentSx} dividers>
              {typeof dialogContent === "function"
                ? dialogContent(onClose)
                : dialogContent}
            </DialogContent>
          )}
          {dialogActions && (
            <DialogActions>
              {typeof dialogActions === "function"
                ? dialogActions(onClose)
                : dialogActions}
            </DialogActions>
          )}
        </Dialog>,
        document.body
      )}
    </>
  );
};
