import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { AxiosError, TPurchase, updatePurchaseShipping } from "@/api";
import { useState } from "react";
import {
  Button,
  useNotify,
  useRecordContext,
  SimpleForm,
  required,
  NumberInput,
  TextInput,
  useRefresh,
} from "react-admin";
import { useAdmin } from "../../../../hooks/admin";
import { addPurchaseComment } from "@/api";
import { isFinishedPurchase } from "../../shared/actions-disabled";

export const PurchaseShippingButton = () => {
  const notify = useNotify();
  const { hasPermission, role, admin } = useAdmin();
  const purchase = useRecordContext<TPurchase>();
  const refresh = useRefresh();

  const purchaseId = purchase?.id;

  const isAdmin = role?.name === "Admin";
  const isAvailable = hasPermission("purchase.create.shipping") || isAdmin;

  const [sending, setSending] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values: any) => {
    const { shipping, actionComment: comment } = values;

    setSending(true);
    try {
      await updatePurchaseShipping(purchaseId as string, {
        shipping,
      });

      await addPurchaseComment(purchaseId as string, {
        createdBy: admin.id,
        comment,
        fullName: admin.firstName + " " + admin.lastName,
      });

      refresh();

      notify("El pedido se actualizó satisfactoriamente");
    } catch (error) {
      console.log({ error });
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se puede realizar la actualización del pedido", {
          type: "error",
        });
      }
    } finally {
      setSending(false);
      setOpen(false);
    }
  };

  if (!isAvailable || isFinishedPurchase(purchase)) {
    return null;
  }

  return (
    <>
      <div>
        <Tooltip title="Actualizar costo de envío">
          <div>
            <IconButton color="primary" onClick={handleClickOpen}>
              <LocalShippingIcon />
            </IconButton>
          </div>
        </Tooltip>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>Actualizar costo de envío</DialogTitle>
        <SimpleForm onSubmit={handleSubmit} toolbar={false}>
          <section style={{ width: "100%" }}>
            <DialogContent>
              <NumberInput
                source="shipping"
                validate={[required()]}
                fullWidth
                min={0}
              />
              <TextInput
                source="actionComment"
                label="Comentario"
                validate={[required()]}
                fullWidth
                multiline
              />
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button label="Cerrar" onClick={handleClose} />
              <Button
                label="Actualizar"
                disabled={sending}
                type="submit"
                variant="contained"
              />
            </DialogActions>
          </section>
        </SimpleForm>
      </Dialog>
    </>
  );
};
