import { Box } from "@mui/material";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  FunctionField,
  NumberField,
  TextField,
} from "react-admin";
import { ModalImageField } from "../../../components/elements/ModalImageField";
import { PRODUCTS } from "../../../config/resources";
import { supplierProductApproveStatus } from "@/data/products";

export const ProductsField = () => {
  return (
    <ArrayField source="products" fullWidth>
      <Datagrid
        resource={PRODUCTS}
        bulkActionButtons={false}
        rowClick={(id) => `/${PRODUCTS}/${id}/show`}
      >
        <FunctionField
          label="Foto"
          render={() => (
            <ModalImageField
              source="images.0.src"
              maxHeight={60}
              maxWidth={60}
            />
          )}
        />
        <TextField source="sku" />
        <TextField source="name" />
        <NumberField source="retailPrice" />
        <NumberField source="wholesalePrice" />
        <FunctionField
          source="productOptions"
          render={(record: any) =>
            Object.entries(record.productOptions || {}).map(([name, value]) => (
              <Box key={name}>
                <>
                  {name}: {value} <br />
                </>
              </Box>
            ))
          }
        />
        <BooleanField source="status" />
        <FunctionField
          source="approvedStatus"
          render={(group: any) =>
            supplierProductApproveStatus.find(
              ({ id }) => id === group.approvedStatus
            )?.name ?? "-"
          }
        />
      </Datagrid>
    </ArrayField>
  );
};
