import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { SellerForm } from "./_form";

export const SellerEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("seller.update")) return null;

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SellerForm isEdit />
    </Edit>
  );
};
