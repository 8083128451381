import { useEffect } from "react";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  FilterButton,
  FunctionField,
  List,
  NullableBooleanInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  useRedirect,
  useStore,
} from "react-admin";
import { COURSES, LECTURES } from "../../config/resources";
import { audienceTypes } from "../../data/education";
import { useAdmin } from "../../hooks/admin";
import { CustomPagination } from "../shared/pagination";

const lectureFilters = [
  <NullableBooleanInput source="status" alwaysOn />,
  <TextInput source="name" resettable alwaysOn />,
  <SelectInput source="audience" choices={audienceTypes} resettable alwaysOn />,
  <TextInput source="instructorName" resettable alwaysOn />,
];

const LectureActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>
      {/* <FilterButton filters={lectureFilters} /> */}
      {hasPermission("lecture.create") && <CreateButton resource={LECTURES} />}
    </TopToolbar>
  );
};

export const LectureList = () => {
  const { hasPermission } = useAdmin();
  const redirect = useRedirect();
  const course = useRecordContext();

  const [courseId] = useStore("currentCourseId");

  useEffect(() => {
    if (!courseId) {
      redirect("/" + COURSES);
    }
  }, [courseId]);

  if (!hasPermission("lecture.list")) return null;

  if (!courseId) return null;

  return (
    <List
      title={
        course ? " - Lista de Cursos" : `Lista de Clases de Curso #${courseId}`
      }
      resource={LECTURES}
      queryOptions={{ meta: { dependentId: courseId } }}
      // filters={lectureFilters}
      // sort={{ field: "createdAt", order: "DESC" }}
      pagination={<CustomPagination />}
      hasCreate={hasPermission("lecture.create")}
      actions={<LectureActions />}
    >
      <Datagrid
        resource={LECTURES}
        rowClick={hasPermission("lecture.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <FunctionField
          source="image"
          label="Imagen"
          sortable={false}
          render={(record: any) =>
            record.image ? (
              <img
                src={record.image.src}
                width={80}
                height={80}
                style={{ objectFit: "contain" }}
              />
            ) : (
              <div style={{ width: 80, height: 80, backgroundColor: "#ddd" }} />
            )
          }
        />
        <TextField source="name" />
        <TextField source="instructorName" />
        <TextField source="instructorLastname" />
        <SelectField source="audience" choices={audienceTypes} />
        <BooleanField source="status" />
      </Datagrid>
    </List>
  );
};
