import {
  BooleanInput,
  Button,
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
} from "react-admin";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AxiosError,
  createQuote,
  TPrePurchase,
  TPrepurchaseLimit,
} from "@/api";
import { PurchaseProductsArrayInput } from "../post/products";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { formatCurrency } from "../../../utils/currency";
import { PURCHASES } from "../../../config/resources";
import autoTable from "jspdf-autotable";
import { FC, useCallback } from "react";
import jsPDF from "jspdf";
import { format } from "date-fns";
import { useAdmin } from "../../../hooks/admin";
import { QuoterMenu, SaveQuoteButton } from "./manager";
import { useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";

const sources: Record<string, string> = {
  SUPPLIER: "Proveedor",
  BRAND: "Marca",
  CATEGORY: "Categoría",
  SUBCATEGORY: "Subcategoría",
};

const QuoterModalOpen = atom(false);
const QuoterModalData = atom<TPrePurchase | null>(null);

export const Quoter = () => {
  const notify = useNotify();
  const { hasPermission } = useAdmin();
  const setQuoterModalOpen = useSetAtom(QuoterModalOpen);
  const setQuoterModalData = useSetAtom(QuoterModalData);
  const onSubmitQuote = useCallback(async (values: any) => {
    try {
      const { products, couponCode, firstSale, forVip } = values;
      if (!products?.length) {
        notify("Agrega al menos un producto", { type: "error" });
        return;
      }

      const quote = await createQuote({
        userType: "STORE",
        products,
        couponCode,
        firstSale,
        forVip,
      });

      setQuoterModalData(quote);
      setQuoterModalOpen(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo crear la cotización", { type: "error" });
      }
      return;
    }
  }, []);

  if (!hasPermission("purchase.quoter")) {
    return null;
  }

  return (
    <>
      <Create resource={PURCHASES} title="Cotizador">
        <SimpleForm
          warnWhenUnsavedChanges
          toolbar={<QuoterToolBar />}
          defaultValues={{
            userType: "",
            couponCode: "",
            products: [{ id: "", quantity: 1 }],
          }}
          onSubmit={onSubmitQuote}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} xl={3} order={{ md: 2 }}>
              <QuoterMenu />
            </Grid>
            <Grid item xs={12} md={8} xl={9} order={{ md: 1 }}>
              <TextInput
                source="couponCode"
                label="Cupón"
                fullWidth
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
              <PurchaseProductsArrayInput />
              <BooleanInput
                label="Primera venta"
                source="firstSale"
                defaultValue={false}
              />
              <BooleanInput
                label="Para VIP"
                source="forVip"
                defaultValue={false}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
      <QuoterDialog />
    </>
  );
};

const GoToCreatePurchaseButton = () => {
  const {
    formState: { isValid },
  } = useFormContext();
  const products = useWatch({ name: "products" });
  const couponCode = useWatch({ name: "couponCode" });

  return (
    <Button
      component={Link}
      to={{
        pathname: `/${PURCHASES}/create`,
        search: `?source=${JSON.stringify({ products, couponCode })}`,
      }}
      label="Ir a crear pedido"
      variant="outlined"
      startIcon={<NavigateNext />}
      disabled={!isValid}
      size="medium"
    />
  );
};

const QuoterToolBar = () => {
  return (
    <Toolbar sx={{ gap: 2 }}>
      <SaveButton label="Ver cotización" alwaysEnable />
      <GoToCreatePurchaseButton />
      <SaveQuoteButton />
    </Toolbar>
  );
};

const QuoterDialog = () => {
  const quote = useAtomValue(QuoterModalData);
  const [open, setOpen] = useAtom(QuoterModalOpen);

  if (!quote) return null;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogTitle>Cotización</DialogTitle>
      <DialogContent>
        {quote.productLimits.map((limit, index) => (
          <QuoteTableLimit limit={limit} index={index} key={index} />
        ))}
        <QuoteTableTotal quote={quote} />
      </DialogContent>
      <DialogActions style={{ margin: "1rem" }}>
        <Button label="Cerrar" onClick={() => setOpen(false)} />
        <Button
          style={{ marginLeft: "1rem" }}
          label="Descargar PDF"
          onClick={() => createPDF(quote)}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

type TQuoteTableLimit = {
  limit: TPrepurchaseLimit;
  index: number;
};
const QuoteTableLimit: FC<TQuoteTableLimit> = ({ limit, index }) => {
  return (
    <>
      {index > 0 && <Box my={2} borderTop={"1px dotted #aaa"} />}
      <Typography>
        <strong>{sources[limit.limitSource]}</strong>: {limit.data.name}
      </Typography>
      {limit.limitValue > limit.currentValue && (
        <Typography color="red" fontSize="13px">
          Faltan {formatCurrency(limit.limitValue - limit.currentValue)} para
          completar la compra mínima.
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="brand"
          id={`table-limit-${index}`}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Cantidad</TableCell>
              <TableCell align="center">Precio unit.</TableCell>
              <TableCell align="left">Producto</TableCell>
              <TableCell align="center">Total</TableCell>
              <TableCell align="center">Descuento</TableCell>
              <TableCell align="center">Total a Pagar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {limit.products.map((item) => (
              <TableRow
                key={`limit-${index}-product-${item.id}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{item.quantity}</TableCell>
                <TableCell align="center">
                  {formatCurrency(item.wholesalePrice)}
                </TableCell>
                <TableCell align="left">{item.name}</TableCell>
                <TableCell align="center">
                  {formatCurrency(item.subtotal)}
                </TableCell>
                <TableCell align="center">
                  {formatCurrency(item.totalDiscount)}
                </TableCell>
                <TableCell align="center">
                  {formatCurrency(item.subTotalWithDiscount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

type TQuoteTableTotal = { quote: TPrePurchase };
const QuoteTableTotal = ({ quote }: TQuoteTableTotal) => {
  return (
    <>
      <p style={{ marginTop: "2rem" }}>Totales</p>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="brand"
          id="quoter-total"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Totales</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="right">Subtotal</TableCell>
              <TableCell align="left" width={15}>
                {formatCurrency(quote?.subTotalWithDiscounts)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Total</TableCell>
              <TableCell align="left" width={15}>
                {formatCurrency(quote?.subtotal)}
              </TableCell>
            </TableRow>
            {quote?.discountByCoupon > 0 && (
              <TableRow>
                <TableCell align="right">Descuento por Cupón</TableCell>
                <TableCell align="left" width={15}>
                  {formatCurrency(quote?.discountByCoupon)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="right">Total Descuento</TableCell>
              <TableCell align="left" width={15}>
                {formatCurrency(quote?.totalDiscount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Envio</TableCell>
              <TableCell align="left" width={15}>
                {formatCurrency(quote?.shipping)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Total a Pagar</TableCell>
              <TableCell align="left" width={15}>
                {formatCurrency(quote?.total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const createPDF = (quote: TPrePurchase) => {
  const doc = new jsPDF("landscape");

  quote.productLimits.forEach((limit, index) => {
    const heading = [`${sources[limit.limitSource]}: ${limit.data.name}`];
    let body = [heading];
    if (limit.limitValue > limit.currentValue) {
      body = [
        [""],
        heading,
        [
          `Faltan ${formatCurrency(
            limit.limitValue - limit.currentValue
          )} para completar la compra mínima.`,
        ],
      ];
    }
    autoTable(doc, { body });
    autoTable(doc, { html: `#table-limit-${index}` });
  });

  autoTable(doc, {
    html: "#quoter-total",
    columnStyles: {
      0: { halign: "right" },
      1: { cellWidth: 40 },
    },
  });

  doc.save(`cotizacion_${format(new Date(), "dd-MM-yyyy")}.pdf`);
};
