import { Edit } from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { CategoryForm } from "./_form";

export const CategoryEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("category.update")) return null;

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <CategoryForm isEdit />
    </Edit>
  );
};
