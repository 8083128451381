import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Button, CheckboxGroupInput, Form } from "react-admin";
import { PRODUCTS } from "../../../../config/resources";
import { i18nProvider } from "../../../../providers/i18n";
import * as XLSX from "xlsx";

type Props = {
  onEnd: () => void;
};

export const UpdateTemplate: FC<Props> = ({ onEnd }) => {
  const [headers, setHeaders] = useState<string[]>([]);

  const download = useCallback(() => {
    const row = ["sku"].concat(getHeaders().filter((h) => headers.includes(h)));
    const worksheet = XLSX.utils.aoa_to_sheet([row]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Productos");
    worksheet["!cols"] = row.map((k) => ({ wch: k.length + 4 }));
    XLSX.writeFile(workbook, "plantilla-actualizacion-productos.xlsx");
    onEnd();
  }, [headers]);

  return (
    <>
      <DialogTitle>Plantilla de actualización masiva</DialogTitle>
      <DialogContent>
        <HeadersInput value={headers} onChange={setHeaders} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onEnd}
          label="Cerrar"
          color="secondary"
          variant="outlined"
          size="medium"
        />
        <Button
          label="Descargar plantilla"
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
          onClick={download}
        />
      </DialogActions>
    </>
  );
};

const HeadersInput: FC<{
  value: string[];
  onChange: (value: string[]) => void;
}> = ({ value, onChange }) => {
  return (
    <>
      <Typography sx={{ mt: 2 }}>
        Selecciona los campos que deseas incluir en la plantilla de
        actualización:
      </Typography>
      <Form record={{ headers: value }}>
        <CheckboxGroupInput
          source="headers"
          label="Campos"
          choices={getHeaders().map((name) => ({
            id: name,
            name: getHeaderName(name),
          }))}
          row={false}
          onChange={(v) => onChange(v)}
        />
      </Form>
    </>
  );
};

const getHeaders = () => {
  return [
    "name",
    "description",
    "longDescription",
    "retailPrice",
    "barCode",
    "height",
    "width",
    "length",
    "weight",
    "status",
    "productOptions",
  ];
};

const getHeaderName = (header: string) =>
  i18nProvider.translate(`resources.${PRODUCTS}.fields.${header}`, {
    _: header,
  });
