import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { secondaryAuth } from "../../core/firebase";

export async function createUserOnFirebaseAuth(
  email: string,
  password?: string
) {
  if (!password) return;
  let auth;
  try {
    auth = await createUserWithEmailAndPassword(secondaryAuth, email, password);
  } catch (error) {
    console.log({ error });
    throw error;
  }
  return auth?.user?.uid;
}

export async function sendPasswordFirebaseAuth(email: string) {
  await sendPasswordResetEmail(secondaryAuth, email);
}

export async function deleteUserOnFirebaseAuth() {
  const user = secondaryAuth.currentUser;
  if (user) {
    await user.delete();
  }
}
