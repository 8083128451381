import { Button, useListContext } from "react-admin";
import { CleaningServicesOutlined } from "@mui/icons-material";

export const ClearFiltersButton = () => {
  const { filterValues, setFilters } = useListContext();

  if (!Object.keys(filterValues).length) return null;

  return (
    <Button
      label="Limpiar filtros"
      startIcon={<CleaningServicesOutlined />}
      onClick={() => setFilters({}, {})}
    />
  );
};
