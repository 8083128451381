import { FC } from "react";
import { CreateBase, useNotify, useRefresh } from "react-admin";
import { SUPPLIER_ADDRESSES } from "../../../config/resources";
import { SupplierAddressForm } from "./_form";

type Props = {
  supplierId: string;
  onDone: () => void;
};

export const SupplierAddressCreate: FC<Props> = ({ supplierId, onDone }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <CreateBase
      resource={SUPPLIER_ADDRESSES}
      mutationOptions={{
        meta: {
          dependentId: supplierId,
        },
        onSuccess: () => {
          notify("Dirección creada");
          refresh();
          onDone();
        },
      }}
      redirect={false}
    >
      <SupplierAddressForm supplierId={supplierId} />
    </CreateBase>
  );
};
