import { getCitiesByStateId, getStatesByCountryId } from "@/api";
import { FC, useEffect } from "react";
import { AutocompleteInput, AutocompleteInputProps } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import useSWR from "swr";
import { country } from "../../config/country";

export const CountryInput: FC<AutocompleteInputProps> = ({
  source,
  ...props
}) => {
  if (!country) return null;

  return (
    <AutocompleteInput
      {...props}
      source={source}
      choices={[
        {
          id: country.name,
          name: country.name,
        },
      ]}
      fullWidth
    />
  );
};

type StateInputProps = AutocompleteInputProps & {
  source: string;
  countrySource: string;
};

export const StateInput: FC<StateInputProps> = ({
  countrySource,
  source,
  ...props
}) => {
  const currentState = useWatch({ name: source });
  const currentCountry = useWatch({ name: countrySource });
  const { setValue } = useFormContext();

  const { data: states, isValidating } = useSWR(
    () => [country.id, "getStatesByCountryId"],
    ([id]) => getStatesByCountryId(id)
  );

  const countryStates = currentCountry
    ? states?.map((s: any) => ({
        id: s.name,
        name: s.name,
      })) || []
    : [];

  useEffect(() => {
    if (isValidating) return;
    if (!countryStates.some((s) => s.name === currentState)) {
      setValue(source, "");
    }
  }, [currentCountry, isValidating, countryStates]);

  return (
    <AutocompleteInput
      {...props}
      source={source}
      choices={countryStates}
      disabled={!currentCountry || !countryStates?.length}
      fullWidth
    />
  );
};

type CityInputProps = AutocompleteInputProps & {
  stateSource: string;
  source: string;
};

export const CityInput: FC<CityInputProps> = ({
  stateSource,
  source,
  ...props
}) => {
  const currentCity = useWatch({ name: source });
  const currentState = useWatch({ name: stateSource });
  const { setValue } = useFormContext();

  const { data: states, isValidating: loadingStates } = useSWR(
    () => [country.id, "getStatesByCountryId"],
    ([id]) => getStatesByCountryId(id)
  );

  const state = states?.find((c: any) => c.name === currentState);

  const { data: cities, isValidating: loadingCities } = useSWR(
    () => [state!.id, "getCitiesByStateId"],
    ([id]) => getCitiesByStateId(id + "")
  );

  const stateCities =
    cities?.map((c: any) => ({
      id: c.name,
      name: c.name,
    })) || [];

  useEffect(() => {
    if (loadingStates || loadingCities || !currentCity) return;
    if (!stateCities.some((s) => s.name === currentCity)) {
      setValue(source, "");
    }
  }, [currentState, loadingStates, loadingCities, stateCities]);

  return (
    <AutocompleteInput
      {...props}
      source={source}
      choices={stateCities}
      disabled={!currentState || !stateCities?.length}
      fullWidth
    />
  );
};
