import { Create } from "react-admin";
import { BANNERS } from "../../config/resources";
import { buckets } from "../../core/buckets";
import { useAdmin } from "../../hooks/admin";
import { uploadFile } from "../../utils/file-upload";
import { BannerForm } from "./_form";
import { getMD5Checksum } from "../../utils/md5-checksum";

export const BannerCreate = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("banner.create")) return null;

  const transform = async (data: any) => {
    const rawFileWeb = data.images.webImage?.rawFile;
    const rawFileMobile = data.images.mobileImage?.rawFile;
    if (!rawFileWeb || !rawFileMobile) throw new Error("Archivo requerido");
    const folderWeb = await getMD5Checksum(rawFileWeb);
    const folderMobile = await getMD5Checksum(rawFileMobile);
    const [webImage, mobileImage] = await Promise.all([
      uploadFile(buckets.banners, BANNERS, folderWeb, rawFileWeb),
      uploadFile(buckets.banners, BANNERS, folderMobile, rawFileMobile),
    ]);
    return { ...data, images: { webImage, mobileImage } };
  };

  return (
    <Create redirect="show" transform={transform}>
      <BannerForm />
    </Create>
  );
};
