import {
  TSource,
  updateLecture,
  createPartialLecture,
  TPartialLecturePayload,
} from "@/api";
import { pick } from "lodash";

import { buckets } from "../../../core/buckets";
import { uploadFile } from "../../../utils/file-upload";

export const customCreateLecture = async (
  data: TPartialLecturePayload & { video: { rawFile: File } },
  courseId: string
) => {
  const payload = pick(data, [
    "name",
    "image",
    "description",
    "audience",
    "instructorName",
    "instructorLastname",
    "instructorProfession",
    "instructorPhoto",
    "sequenceOrder",
    "status",
  ]);
  const newLecture = await createPartialLecture(payload, courseId);
  const url = await uploadFile(
    buckets.education,
    "Course",
    courseId,
    data.video.rawFile
  );
  return updateLecture(newLecture.id, { video: url }, courseId);
};

export const customEditLecture = async (
  lectureId: string,
  data: TPartialLecturePayload & {
    video: { rawFile: File } & TSource;
  },
  courseId: string
) => {
  if (data.video.rawFile) {
    const url = await uploadFile(
      buckets.education,
      "Course",
      courseId,
      data.video.rawFile
    );
    data.video.src = url;
  }

  return updateLecture(
    lectureId,
    {
      ...data,
      video: data.video.src,
    },
    courseId
  );
};
