import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Identifier,
  NumberInput,
  RaRecord,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { Box, Grid, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  BRANDS,
  CATEGORIES,
  PRODUCTS,
  SELLERS,
  SUPPLIERS,
} from "@/config/resources";
import { addDays } from "date-fns";
import { ISODateInput } from "../../components/elements/ISODateInput";
import { useFormContext, useWatch } from "react-hook-form";
import { ProductOptionRenderer } from "../shared/product";
import { TProduct } from "@/api";
import { offerTypes } from "@/data/offers";
import { usePaymentMethods } from "@/hooks/payment-methods";

const OfferEditToolbar: FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

type TOfferForm = {
  isEdit?: boolean;
  record?: any;
};

const schemaOfferForm: yup.ObjectSchema<any> = yup.object().shape({
  type: yup.string().required("El tipo es obligatorio"),
  name: yup.string().required("El nombre es obligatorio"),
  value: yup
    .string()
    .nullable()
    .when("type", {
      is: (value: string) => value !== "PAY_X_TAKE_Y",
      then: yup.string().required("El descuento a aplicar es requerido"),
    }),
  createdBy: yup
    .string()
    .nullable()
    .when("type", {
      is: (value: string) => value === "PAY_X_TAKE_Y",
      then: yup.string().required("El Proveedor es requerido"),
    }),
  endsAt: yup
    .date()
    .required("La fecha de expiracion ex obligatoria")
    .test(
      "valid-endsAt",
      "La fecha de vigencia final debe ser mayor a la de creación.",
      function (value) {
        const { createdAt } = this.parent;
        return !createdAt || !value || value > new Date(createdAt);
      }
    ),
  customerArticles: yup
    .string()
    .nullable()
    .when("type", {
      is: (value: string) => value === "PAY_X_TAKE_Y",
      then: yup
        .string()
        .required("Debe seleccionar una opción en 'Cualquier artículo de'"),
    }),
  productsToPay: yup
    .number()
    .nullable()
    .when("type", {
      is: (value: string) => value === "PAY_X_TAKE_Y",
      then: yup
        .number()
        .required("Este campo es requerido")
        .positive("Debe ingrear un número mayor a 1")
        .integer(),
    }),

  targetProductsIds: yup
    .array()
    .nullable()
    .when("customerArticles", {
      is: (value: string) => value === "SPECIFIC_PRODUCTS",
      then: yup
        .array()
        .required("Los productos específicos son requeridos")
        .min(1, "Debe seleccionar al menos un producto"),
    }),
  targetBrandsProducts: yup
    .array()
    .nullable()
    .when("customerArticles", {
      is: (value: string) => value === "SPECIFIC_BRANDS",
      then: yup
        .array()
        .required("Las marcas específicas son requeridas")
        .min(1, "Debe seleccionar al menos una marca"),
    }),
  productsToTake: yup
    .number()
    .nullable()
    .when("type", {
      is: (value: string) => value === "PAY_X_TAKE_Y",
      then: yup
        .number()
        .required("Este campo es requerido")
        .positive("Debe ingrear un número mayor a 1")
        .integer(),
    }),

  gifts: yup
    .array()
    .nullable()
    .when("type", {
      is: (value: string) => value === "PAY_X_TAKE_Y",
      then: yup
        .array()
        .of(
          yup.object().shape({
            productId: yup
              .string()
              .required("El producto del regalo es requerido")
              .transform((value, originalValue) =>
                originalValue === null ? "" : value
              ),
            invoiceIncluded: yup
              .boolean()
              .required(
                "El campo 'Necesito facturar este obsequio' es requerido"
              ),
          })
        )
        .required("Se requiere al menos un regalo")
        .min(1, "Se requiere al menos un regalo"),
    }),

  clientSegment: yup
    .string()
    .nullable()
    .when("type", {
      is: (value: string) => value === "PAY_X_TAKE_Y",
      then: yup
        .string()
        .required("Debe seleccionar una opción en 'Elegibilidad del cliente'")
        .oneOf(["ALL", "SPECIFIC_SELLERS"], "Debe seleccionar una opción"),
    }),

  targetSellersIds: yup
    .array()
    .nullable()
    .when("clientSegment", {
      is: (value: string) => value === "SPECIFIC_SELLERS",
      then: yup
        .array()
        .required("Los clientes específicos son requeridos")
        .min(1, "Debe seleccionar al menos un cliente"),
    }),
  customLimitUses: yup.boolean(),
  maximumUses: yup.number().when("customLimitUses", {
    is: true,
    then: yup
      .number()
      .required("Este campo es requerido")
      .positive("Debe ingresar un número mayor a 0")
      .integer()
      .min(1, "Debe ingresar un número mayor a 0"),
    otherwise: yup.number().nullable(),
  }),
  maximumUsesByUser: yup
    .number()
    .nullable()
    .positive("Debe ingresar un número mayor a 0")
    .integer()
    .min(1, "Debe ingresar un número mayor a 0"),
  firstSale: yup.boolean(),
});

export const OfferForm: FC<TOfferForm> = ({ isEdit }) => {
  const record = useRecordContext();

  const offerDefaultValues = () => ({
    type: isEdit ? record.type : "",
    enabled: isEdit ? record.enabled : false,
    createdBy: isEdit ? record.createdBy : "",
    createdAt: new Date(),
    updatedAt: new Date(),
    endsAt: isEdit ? record?.endsAt : addDays(new Date(), 1),
    productsToPay: isEdit ? record?.productsToPay : 1,
    customerArticles: isEdit
      ? record.targetProductsIds?.length
        ? "SPECIFIC_PRODUCTS"
        : record.targetBrandsProducts?.length
        ? "SPECIFIC_BRANDS"
        : "ANY_PRODUCTS"
      : "ANY_PRODUCTS",
    gifts: isEdit
      ? record?.gifts
      : [
          {
            productId: null,
            invoiceIncluded: false,
          },
        ],
    productsToTake: isEdit ? record?.productsToTake : 1,
    targetBrandsProducts: isEdit ? record?.targetBrandsProducts : null,
    targetProductsIds: isEdit ? record?.targetProductsIds : null,
    targetSellersIds: isEdit ? record?.targetSellersIds : null,
    clientSegment: isEdit
      ? record.targetSellersIds?.length
        ? "SPECIFIC_SELLERS"
        : "ALL"
      : "ALL",
    firstSale: isEdit ? record?.firstSale : false,
    maximumUses: isEdit ? record?.maximumUses : null,
    maximumUsesByUser: isEdit ? record?.maximumUsesByUser : null,
    customLimitUses: isEdit ? (record.maximumUses ? true : false) : false,
  });

  return (
    <SimpleForm
      mode="onBlur"
      toolbar={<OfferEditToolbar />}
      defaultValues={offerDefaultValues()}
      resolver={yupResolver(schemaOfferForm)}
      warnWhenUnsavedChanges
    >
      <OfferFormContent isEdit={isEdit} record={offerDefaultValues()} />
    </SimpleForm>
  );
};

export const OfferFormContent: FC<TOfferForm> = ({ isEdit, record }) => {
  const { setValue, watch } = useFormContext();
  const [customerArticles, setCustomerArticles] = useState(
    record.customerArticles
  );
  const [clientSegment, setClientSegment] = useState(record.clientSegment);
  const [limitUses, setLimitUses] = useState(record.customLimitUses);
  const paymentMethods = usePaymentMethods();
  const createdBy = watch("createdBy");
  const customLimitUses = watch("customLimitUses");
  const typeWatch = useWatch({ name: "type" });
  const isPayXTakeY = typeWatch === "PAY_X_TAKE_Y";
  const isDiscountNonAccumulative =
    typeWatch === "DISCOUNT_PERCENTAGE_NON_CUMULATIVE";

  const handleSelectSupplier = () => {
    setValue("targetProductsIds", null);
    setValue("targetBrandsProducts", null);
    setValue("gifts", null);
  };

  const handleSelectChange = (
    event: RaRecord<Identifier> | ChangeEvent<HTMLInputElement>
  ) => {
    setCustomerArticles(event.target.value);
    if (event.target.value === "ANY_PRODUCTS") {
      setValue("targetProductsIds", null);
      setValue("targetBrandsProducts", null);
    } else if (event.target.value === "SPECIFIC_PRODUCTS") {
      record.targetBrandsProducts = null;
      setValue("targetBrandsProducts", null);
    } else if (event.target.value === "SPECIFIC_BRANDS") {
      record.targetProductsIds = null;
      setValue("targetProductsIds", null);
    }
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setClientSegment(value);
    setValue("clientSegment", value);
    if (value === "ALL") {
      record.targetSellersIds = null;
      setValue("targetSellersIds", null);
    }
  };

  const handleLimitUsesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setLimitUses(checked);
    setValue("customLimitUses", checked);
    if (!checked) {
      setValue("maximumUses", null);
    }
  };

  return (
    <Box padding={4} sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={isEdit ? 6 : 12}>
          <SelectInput
            source="type"
            choices={offerTypes}
            optionValue="code"
            fullWidth
            disabled={isEdit}
          />
        </Grid>

        {isEdit && (
          <Grid item xs={6}>
            <TextInput source="id" disabled fullWidth />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextInput source="name" fullWidth />
        </Grid>
      </Grid>
      {isPayXTakeY && (
        <>
          <Grid item xs={12}>
            <ReferenceInput
              source="createdBy"
              reference={SUPPLIERS}
              sort={{ field: "name", order: "ASC" }}
              enableGetChoices={({ name }) => name && name.length >= 2}
            >
              <AutocompleteInput
                filterToQuery={(searchText: string) => ({ name: searchText })}
                onChange={handleSelectSupplier}
                optionValue="id"
                optionText="name"
                noOptionsText="No hay resultados"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Typography fontWeight={700}>El cliente compra</Typography>
          <Typography>
            Los clientes deben agregar la cantidad de artículos especificados a
            continuación a su carrito
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <NumberInput source="productsToPay" fullWidth />
            </Grid>
            <Grid item xs={8}>
              <SelectInput
                source="customerArticles"
                label="Cualquier artículo de"
                choices={[
                  { id: "ANY_PRODUCTS", name: "Cualquier Producto" },
                  { id: "SPECIFIC_PRODUCTS", name: "Productos específicos" },
                  { id: "SPECIFIC_BRANDS", name: "Marcas específicas" },
                ]}
                onChange={handleSelectChange}
                value={customerArticles}
                fullWidth
              />
            </Grid>
          </Grid>

          {customerArticles === "SPECIFIC_PRODUCTS" && (
            <Grid item xs={12}>
              <ReferenceArrayInput
                source="targetProductsIds"
                reference={PRODUCTS}
                filter={{ supplierId: createdBy }}
                sort={{ field: "name", order: "ASC" }}
                fullWidth
                alwaysOn
              >
                <AutocompleteArrayInput
                  label="Nombre del producto"
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  optionText={<ProductOptionRenderer />}
                  inputText={(p: TProduct) => p.name}
                  noOptionsText="No hay resultados"
                  optionValue="id"
                  disabled={!createdBy}
                  helperText={
                    !createdBy
                      ? "Seleccione un proveedor antes de agregar productos"
                      : ""
                  }
                  fullWidth
                />
              </ReferenceArrayInput>
            </Grid>
          )}

          {customerArticles === "SPECIFIC_BRANDS" && (
            <Grid item xs={12}>
              <ReferenceArrayInput
                source="targetBrandsProducts"
                reference={BRANDS}
                filter={{ supplierId: createdBy }}
                sort={{ field: "name", order: "ASC" }}
                fullWidth
                alwaysOn
              >
                <AutocompleteArrayInput
                  label="Nombre de la marca"
                  optionText="name"
                  filterToQuery={(searchText: string) => ({
                    name: searchText,
                  })}
                  noOptionsText="No hay resultados"
                  disabled={!createdBy}
                  optionValue="id"
                  helperText={
                    !createdBy
                      ? "Seleccione un proveedor antes de agregar marcas"
                      : ""
                  }
                  fullWidth
                />
              </ReferenceArrayInput>
            </Grid>
          )}

          <Typography fontWeight={700}>El cliente recibe</Typography>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <NumberInput source="productsToTake" fullWidth />
            </Grid>
          </Grid>

          <ArrayInput source="gifts" fullWidth>
            <SimpleFormIterator disableReordering disableClear fullWidth>
              <ReferenceInput
                source="productId"
                reference={PRODUCTS}
                sort={{ field: "name", order: "ASC" }}
                filter={{ supplierId: createdBy }}
                fullWidth
              >
                <AutocompleteInput
                  source="productId"
                  optionText="name"
                  label="Producto"
                  filterToQuery={(searchText: string) => ({
                    name: searchText,
                    supplierId: createdBy,
                  })}
                  disabled={!createdBy}
                  fullWidth
                />
              </ReferenceInput>
              <BooleanInput
                label="Necesito facturar este obsequio"
                source="invoiceIncluded"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </>
      )}
      <Typography fontWeight={700}>Elegibilidad del cliente</Typography>
      <Grid item xs={12}>
        <RadioButtonGroupInput
          source="clientSegment"
          label=""
          choices={[
            { id: "ALL", name: "Todos los clientes" },
            { id: "SPECIFIC_SELLERS", name: "Clientes específicos" },
          ]}
          onChange={handleRadioChange}
          value={clientSegment}
        />
      </Grid>

      {clientSegment === "SPECIFIC_SELLERS" && (
        <Grid item xs={12}>
          <ReferenceArrayInput
            source="targetSellersIds"
            reference={SELLERS}
            filter={{ ...(isPayXTakeY ? { supplierId: createdBy } : {}) }}
            fullWidth
            alwaysOn
          >
            <AutocompleteArrayInput
              optionText="email"
              filterToQuery={(searchText: string) => ({
                email: searchText,
              })}
              fullWidth
            />
          </ReferenceArrayInput>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <BooleanInput
            source="customLimitUses"
            label="Limitar el número de veces que se puede usar este descuento en
                  total."
            value={limitUses}
            onChange={handleLimitUsesChange}
          />
        </Grid>
        {customLimitUses && (
          <Grid item xs={12}>
            <NumberInput source="maximumUses" fullWidth />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={4}>
          <NumberInput source="maximumUsesByUser" fullWidth />
        </Grid>

        <Grid item xs={4}>
          <BooleanInput source="firstSale" defaultValue={false} />
        </Grid>

        {isDiscountNonAccumulative && (
          <Grid item xs={4}>
            <BooleanInput source="forVip" defaultValue={false} />
          </Grid>
        )}
      </Grid>

      {!!typeWatch && !isPayXTakeY && (
        <>
          <Typography fontWeight={700}>
            Elegibilidad de Métodos de pago
          </Typography>
          <Grid item xs={12}>
            <AutocompleteArrayInput
              source="targetPaymentMethods"
              fullWidth
              alwaysOn
              optionValue="code"
              optionText="name"
              choices={paymentMethods}
            />
          </Grid>

          <Typography fontWeight={700}>Elegibilidad de Categorias</Typography>
          <ReferenceArrayInput
            source="targetCategoriesProducts"
            reference={CATEGORIES}
            perPage={50}
          >
            <AutocompleteArrayInput
              filterToQuery={(searchText: string) => ({
                name: searchText,
              })}
              optionText="name"
              optionValue="id"
              fullWidth
            />
          </ReferenceArrayInput>

          <Typography fontWeight={700}>
            Elegibilidad de Subcategorias
          </Typography>
          <ReferenceArrayInput
            source="targetSubCategoriesProducts"
            reference={CATEGORIES}
            perPage={50}
          >
            <AutocompleteArrayInput
              filterToQuery={(searchText: string) => ({
                name: searchText,
              })}
              optionText="name"
              optionValue="id"
              fullWidth
            />
          </ReferenceArrayInput>

          <Typography fontWeight={700}>Elegibilidad de Marcas</Typography>
          <ReferenceArrayInput
            source="targetBrandsProducts"
            reference={BRANDS}
            perPage={50}
          >
            <AutocompleteArrayInput
              filterToQuery={(searchText: string) => ({
                name: searchText,
              })}
              optionText="name"
              optionValue="id"
              fullWidth
            />
          </ReferenceArrayInput>

          <Typography fontWeight={700}>Elegibilidad de Productos</Typography>
          <ReferenceArrayInput
            source="targetProductsIds"
            reference={PRODUCTS}
            perPage={50}
          >
            <AutocompleteArrayInput
              filterToQuery={(searchText: string) => ({
                name: searchText,
              })}
              optionText="name"
              optionValue="id"
              fullWidth
            />
          </ReferenceArrayInput>

          <Typography fontWeight={700}>Elegibilidad de Proveedores</Typography>
          <ReferenceArrayInput
            source="targetSuppliersProducts"
            reference={SUPPLIERS}
            perPage={50}
          >
            <AutocompleteArrayInput
              filterToQuery={(searchText: string) => ({
                name: searchText,
              })}
              optionText="name"
              optionValue="id"
              fullWidth
            />
          </ReferenceArrayInput>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <NumberInput source="minValue" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="value" fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <NumberInput source="maxValueToOffer" fullWidth />
            </Grid>
          </Grid>
        </>
      )}

      <Typography fontWeight={700}>Fechas de actividad</Typography>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <ISODateInput source="endsAt" time />
        </Grid>
        <Grid item xs={4}>
          <BooleanInput source="enabled" defaultValue={false} />
        </Grid>
      </Grid>
    </Box>
  );
};
