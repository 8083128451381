import { getSeeriApi } from "@/api";
import { useRecordContext } from "ra-core";
import useSWR from "swr";

export const useLimits = (
  applier: "CATEGORY" | "SUPPLIER" | "BRAND",
  type: "LIMIT" | "DISCOUNT"
) => {
  const record = useRecordContext();
  const { data: limits, mutate: refreshLimits } = useSWR(
    [`/api/limits/${applier.toLocaleLowerCase()}/${record.id}`, type],
    ([url, type]) =>
      getSeeriApi()
        .get<any[]>(url)
        .then((res) => res.data.filter((item) => item.type === type))
  );
  return { limits, refreshLimits };
};
