import { Box } from "@mui/system";
import {
  BooleanField,
  DateField,
  DeleteButton,
  DeleteWithConfirmButton,
  EditButton,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { ADMINS, CATEGORIES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { CreateLimitButton } from "./limits/create";
import { LimitsList } from "./limits/list";
import type { TCategory } from "@/api";
import { ModalImageField } from "../../components/elements/ModalImageField";

const CategoryShowActions = () => {
  const { hasPermission } = useAdmin();
  const category = useRecordContext<TCategory>();

  if (!category) {
    return null;
  }

  return (
    <TopToolbar>
      {hasPermission("category.update") && <EditButton />}
      {hasPermission("category.delete") && (
        <DeleteWithConfirmButton
          confirmTitle={`Eliminar categoría ${category?.name}`}
        />
      )}
    </TopToolbar>
  );
};

export const CategoryShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("category.view")) return null;

  return (
    <Show actions={<CategoryShowActions />}>
      <ShowContent />
    </Show>
  );
};

const ShowContent = () => {
  const { hasPermission } = useAdmin();
  const category = useRecordContext<TCategory>();

  return (
    <TabbedShowLayout>
      <Tab label="Principal">
        <TextField source="id" />
        <ReferenceField
          source="parentCategoryId"
          reference={CATEGORIES}
          link={hasPermission("category.view") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>

        <TextField source="slug" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="code" />
        <ModalImageField maxWidth={100} source="image.src" />

        <ModalImageField maxWidth={400} source="bannerDesktop.src" />
        <ModalImageField maxWidth={200} source="bannerMobile.src" />
        <BooleanField source="status" emptyText="-" />
      </Tab>

      {category && !category.parentCategoryId && (
        <Tab label="Descuentos escalonados">
          <CategoryDiscounts />
        </Tab>
      )}

      <Tab label="Miscelánea">
        <DateField source="createdAt" showTime />
        <ReferenceField
          source="createdBy"
          reference={ADMINS}
          link={hasPermission("admin.view") ? "show" : false}
        >
          <TextField source="email" />
        </ReferenceField>
        <DateField source="updatedAt" showTime />
        <ReferenceField
          source="updatedBy"
          reference={ADMINS}
          link={hasPermission("admin.view") ? "show" : false}
        >
          <TextField source="email" />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  );
};

const CategoryDiscounts = () => {
  return (
    <Box>
      <CreateLimitButton applier="CATEGORY" type="DISCOUNT" />
      <LimitsList applier="CATEGORY" type="DISCOUNT" />
    </Box>
  );
};
