import { CityInput, StateInput } from "@/resources/shared/location";
import { Box } from "@mui/material";
import type { FC } from "react";
import {
  SelectInput,
  SimpleForm,
  TextInput,
  maxLength,
  required,
} from "react-admin";
import { country, phonePrefixChoices } from "@/config/country";
import { DocumentTypeInput } from "@/resources/shared/documen-type";
import { SELLER_BILLING_ADDRESSES } from "@/config/resources";
import { TranslatedInputs } from "@/components/translated/TranslatedInputs";

export const SellerInvoiceAddressForm: FC<{ sellerId: string }> = ({
  sellerId,
}) => {
  return (
    <SimpleForm
      defaultValues={{ sellerId, country: country.name }}
      resource={SELLER_BILLING_ADDRESSES}
    >
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns="1fr 1fr"
        columnGap="1rem"
      >
        <TranslatedInputs>
          <TextInput source="firstName" validate={required()} />
          <TextInput source="lastName" validate={required()} />
          <DocumentTypeInput source="documentType" validate={required()} />
          <TextInput source="documentId" validate={required()} />
          <TextInput
            source="verifierDigit"
            validate={[required(), maxLength(1)]}
          />
          <TextInput source="email" validate={required()} />
          <SelectInput
            source="phonePrefix"
            choices={phonePrefixChoices}
            validate={required()}
          />
          <TextInput source="phone" validate={required()} />
          <StateInput
            source="state"
            countrySource="country"
            validate={required()}
          />
          <CityInput source="city" stateSource="state" validate={required()} />
          <TextInput source="addressLine1" validate={required()} />
          <TextInput source="addressLine2" />
        </TranslatedInputs>
      </Box>
    </SimpleForm>
  );
};
