import React, { useState } from "react";
import {
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  Grid,
  Dialog,
  SvgIcon,
  Tooltip,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";
import {
  AxiosError,
  type TSubPurchase,
  generateInvoiceBySubpurchaseIds,
  getPdfInvoice,
  joinInvoiceSubPurchase,
  getSubPurchasesInvoices,
} from "@/api";
import { ReceiptLongOutlined, Download } from "@mui/icons-material";

import { mdiReceiptTextPlusOutline } from "@mdi/js";
import { invoiceTypes } from "../../../../data/purchases";
import { useAdmin } from "../../../../hooks/admin";
import type { FieldValues } from "react-hook-form";
import useSWR from "swr";

export const SubpurchaseGenInvoiceButton = () => {
  const notify = useNotify();
  const subpurchase = useRecordContext<TSubPurchase>();
  const { hasPermission } = useAdmin();
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [queued, setQueued] = useState(false);
  const refresh = useRefresh();

  const generateInvoice = async () => {
    if (
      !confirm(
        "Se generará una factura a través de Siigo, este proceso puede tardar unos minutos"
      )
    )
      return;
    setIsLoading(true);
    try {
      await generateInvoiceBySubpurchaseIds([subpurchase.id.toString()]);
      setQueued(true);
      notify("Se ha solicitado la generación de la factura", {
        type: "success",
      });
    } catch (error) {
      const message =
        (error instanceof AxiosError && error.response?.data?.message) ||
        "Ocurrió un error";
      notify(`Error de servidor: ${message}`, { type: "error" });
    } finally {
      setShowDialog(false);
      setIsLoading(false);
    }
  };

  const handleClose = () => setShowDialog(false);

  const handleSubmit = ({
    documentName,
    total,
    shippingCost,
    coinsToPay,
  }: FieldValues) => {
    const subPurchaseId = subpurchase.id as string;
    setIsLoading(true);

    joinInvoiceSubPurchase({
      documentName,
      total,
      shippingCost,
      coinsToPay,
      subPurchaseId,
    })
      .then(() => {
        notify("Se ha agregado correctamente la factura", {
          type: "success",
        });
        setShowDialog(false);
        refresh();
      })
      .catch((err) => {
        const message =
          (err instanceof AxiosError && err.response?.data?.message) ||
          "Ocurrió un error";
        notify(`Error de servidor: ${message}`, { type: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const canGenerate =
  //   hasPermission("subpurchase.action.generateInvoice") ||
  //   (subpurchase.deliveryStatus === "DELIVERED" &&
  //     hasPermission("subpurchase.action.generateInvoiceWhenDelivered"));
  return (
    <>
      <Tooltip title="Generar factura de subpedido">
        <div>
          <IconButton
            color="primary"
            onClick={() => setShowDialog(true)}
            // disabled={Boolean(
            //   hasInvoices ||
            //     isLoading ||
            //     queued ||
            //     !canGenerate ||
            //     manualInvoice
            // )}
          >
            <SvgIcon>
              <path d={mdiReceiptTextPlusOutline} />
            </SvgIcon>
          </IconButton>
        </div>
      </Tooltip>

      <Dialog open={showDialog} onClose={handleClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>Nueva Factura</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} color="secondary">
              <Box sx={{ marginBottom: "16px" }}>
                * Generar una factura automaticamente
              </Box>
              <Button
                color="primary"
                onClick={() => generateInvoice()}
                label="Generado automático"
              >
                <SvgIcon>
                  <path d={mdiReceiptTextPlusOutline} />
                </SvgIcon>
              </Button>
            </Grid>
          </Grid>

          <Box
            sx={{
              marginTop: "16px",
              marginBottom: "16px",
              height: "1px",
              bgcolor: "gray",
            }}
          />

          <SimpleForm
            onSubmit={handleSubmit}
            toolbar={
              <Toolbar>
                <SaveButton label="Guardar" />
              </Toolbar>
            }
            sx={{ padding: 0 }}
          >
            <Box sx={{ marginBottom: "16px" }}>
              * Cargar manualmente una factura
            </Box>
            <TextInput
              source="documentName"
              label="Número de factura"
              placeholder="FV-0-00000000000"
              fullWidth
              required
            />
            <TextInput
              source="total"
              label="Valor total"
              fullWidth
              type="number"
              required
            />
            <TextInput
              source="shippingCost"
              label="Costo envío"
              fullWidth
              type="number"
              defaultValue={0}
              required={false}
            />
            <TextInput
              source="coinsToPay"
              label="Seeri coins"
              fullWidth
              type="number"
              defaultValue={0}
              required={false}
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button label="Cerrar" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export const SubpurchaseInvoicesButton = () => {
  const notify = useNotify();
  const subpurchase = useRecordContext<TSubPurchase>();
  const { hasPermission } = useAdmin();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: invoices = [] } = useSWR(
    `subPurchaseInvoices:${subpurchase.subPurchaseNumber}`,
    () => getSubPurchasesInvoices([subpurchase.subPurchaseNumber])
  );
  const hasInvoices = invoices && invoices.length;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadInvoice = async (invoiceId: string) => {
    setLoading(true);
    try {
      const response = await getPdfInvoice(invoiceId);
      const linkSource = `data:application/pdf;base64,${response.base64}`;
      const downloadLink = document.createElement("a");
      const fileName = "factura.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      notify("Factura descargada", { type: "success" });
    } catch (error) {
      const message =
        (error instanceof AxiosError && error.response?.data?.message) ||
        "Ocurrió un error";
      notify(`Error de servidor: ${message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Ver facturas">
        <div>
          <IconButton
            color="primary"
            onClick={handleClickOpen}
            disabled={!hasPermission("subpurchase.invoices") || !hasInvoices}
          >
            <ReceiptLongOutlined />
          </IconButton>
        </div>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>Subpedido No. {subpurchase.subPurchaseNumber}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            {invoices &&
              invoices.map((invoice: { id: string; type: string }) => (
                <React.Fragment key={invoice.id}>
                  <Grid item xs={6} color="secondary">
                    {invoiceTypes.find((s) => s.code === invoice.type)?.name}
                  </Grid>
                  <Grid item xs={6} color="secondary">
                    <Button
                      color="primary"
                      onClick={() => downloadInvoice(invoice.id)}
                      label="Descargar"
                      disabled={loading}
                    >
                      <Download />
                    </Button>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button label="Cerrar" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};
