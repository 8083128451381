import { Button } from "react-admin";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
} from "@mui/material";
import { useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@/core/firebase";

export const ModifyOTPSellers = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [otpPortal, setOtpPortal] = useState(false);
  const [otpWeb, setOtpWeb] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const referenceOTP = doc(db, "Properties", "OTP");

  const getOtpValues = async () => {
    try {
      setIsLoading(true);
      const docOTP = await getDoc(referenceOTP);
      if (docOTP.exists()) {
        const data = docOTP.data();
        setOtpPortal(data?.otp_suppliers_client);
        setOtpWeb(data?.otp_register);
        setDataLoaded(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setDataLoaded(false);
      }
    } catch (error) {
      setIsLoading(false);
      setDataLoaded(false);
    }
  };

  const openModal = async () => {
    setModalOpen(true);
    getOtpValues();
  };

  const handleOtp = async (key: string, value: boolean) => {
    await updateDoc(referenceOTP, {
      [key]: value,
    });
    getOtpValues();
  };

  return (
    <>
      <Button
        label="Estatus OTP"
        startIcon={<AdminPanelSettingsIcon />}
        onClick={() => openModal()}
      />
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Estatus OTP</DialogTitle>
        <DialogContent style={{ minWidth: "20rem" }}>
          <div style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            <Switch
              checked={otpPortal}
              onChange={() => handleOtp("otp_suppliers_client", !otpPortal)}
              disabled={isLoading || !dataLoaded}
            />
            OTP Portal
          </div>
          <div>
            <Switch
              checked={otpWeb}
              onChange={() => handleOtp("otp_register", !otpWeb)}
              disabled={isLoading || !dataLoaded}
            />
            OTP Web
          </div>
        </DialogContent>
        <DialogActions>
          <Button label="Cerrar" onClick={() => setModalOpen(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};
