import { emailExists } from "@/core/firebase";
import { debounce } from "lodash";

export const availableAuthEmail = (currentEmail?: string) =>
  debounce(
    async (email: string) => {
      if (currentEmail !== email && (await emailExists(email))) {
        return "Este correo ya existe (Auth)";
      }
      return undefined;
    },
    200,
    { leading: true }
  );
