import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
} from "@mui/lab";
import { Box } from "@mui/system";
import { getPurchaseModification, TPaymentMethodV2, TPurchase } from "@/api";
import { ReferenceField, TextField, useRecordContext } from "react-admin";
import useSWR from "swr";
import { TimeZoneDate } from "../../../components/TimezoneDate";
import { ADMINS } from "../../../config/resources";
import { deliveryStatus, paymentStatus, status } from "../../../data/purchases";
import { usePaymentMethods } from "../../../hooks/payment-methods";

export const PurchaseTimeline = () => {
  const purchase = useRecordContext<TPurchase>();
  const paymentMethods = usePaymentMethods();

  const { data: timeline } = useSWR(
    purchase.id ? [purchase.id, "purchase-timeline"] : null,
    ([id]) => getPurchaseModification(id)
  );

  if (!timeline) return null;

  return (
    <Timeline>
      {timeline.map((modification: any, index: number) => (
        <TimelineItem key={modification.id} sx={{ minHeight: "50px" }}>
          <TimelineOppositeContent color="text.secondary">
            <TimeZoneDate date={modification.createdAt} />
            <br />
            <ReferenceField
              record={modification}
              source="userId"
              reference={ADMINS}
            >
              <TextField source="email" />
            </ReferenceField>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="filled" color="primary" />
            {timeline.length - 1 !== index && (
              <TimelineConnector sx={{ bgcolor: "primary.main" }} />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Box fontSize={"14px"}>
              {getEventCopy(modification, paymentMethods)}
            </Box>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export const getEventCopy = (modification: any, paymentMethods: any) => {
  const logCoinTransactionType: { [key: string]: () => string } = {
    CREATE: () => "Orden creada",
    SENT_TO_DELIVERY: () => "Pedido enviado a integracion",
    PURCHASE_CANCEL_ORDER: () =>
      `Orden cancelada con razon: ${modification?.data?.reason}`,
    PURCHASE_UPDATE_CANCEL_ENABLED: () => "Orden habilitada para cancelar",
    PURCHASE_UPDATE_PRODUCT_LIST: () => "Productos actualizados",
    PAYMENT_UPDATE: () =>
      `Se actualizo el estado de pago de ${getPaymentStatusByCode(
        modification?.data?.oldPaymentStatus
      )} a ${getPaymentStatusByCode(modification?.data?.newPaymentStatus)}`,
    SUB_PURCHASE_CREATED: () =>
      `Se creo el subpedido: ${modification?.data?.subPurchaseNumber}`,
    SUB_PURCHASE_DELETED: () => "Se ha eliminado un subpedido",
    DELIVERY_STATUS_UPDATE: () =>
      `Se actualizo el estado de envio de ${getDeliveryStatusByCode(
        modification?.data?.oldDeliveryStatus
      )} a ${getDeliveryStatusByCode(modification?.data?.newDeliveryStatus)}`,
    CLIENT_LOCATION_UPDATE: () => "Direccion del cliente actualizada",
    UPDATE_PRODUCTS: () => "Productos actualizados",
    PAYMENT_METHOD_UPDATE: () =>
      `Metodo de pago actualizado de ${getPaymentMethodByCode(
        paymentMethods,
        modification?.data?.oldPaymentMethod
      )} a ${getPaymentMethodByCode(
        paymentMethods,
        modification?.data?.newPaymentMethod
      )}`,
    MANUAL_UPDATE_PRIORITY: () =>
      `Prioridad actalizada de ${modification?.data?.oldPriority} a ${modification?.data?.newPriority}`,
    UPDATE_DELIVERY_STATUS: () => "Se actualizó el estado de envío",
    MANUAL_UPDATE_STATUS: () =>
      `Estado actualizado manualmente de ${getStatusByCode(
        modification?.data?.oldStatus
      )} a ${getStatusByCode(modification?.data?.newStatus)}`,
    PURCHASE_UPDATE_KAM: () => {
      let from = modification?.data?.oldKam;
      if (from) from = "de " + from + " ";
      const to = "a " + modification?.data?.newKam;
      return `KAM actualizado ${from}${to}`;
    },
    DEFAULT: () => `Otro: ${modification?.event}`,
  };
  return (
    logCoinTransactionType[modification.event]?.() ||
    logCoinTransactionType["DEFAULT"]()
  );
};

const getStatusByCode = (text: string) => {
  return status.find((s) => s.code === text)?.name || "";
};

const getPaymentStatusByCode = (text: string) => {
  return paymentStatus.find((s) => s.code === text)?.name || "";
};

const getDeliveryStatusByCode = (text: string) => {
  return deliveryStatus.find((s) => s.code === text)?.name || "";
};

export const getPaymentMethodByCode = (
  paymentMethods: TPaymentMethodV2[],
  text: string
) => {
  return (
    paymentMethods.find((s: TPaymentMethodV2) => s.code === text)?.name || ""
  );
};
