import { IconButton, Tooltip } from "@mui/material";
import { formatCurrency } from "@/utils/currency";
import jsPDF from "jspdf";
import autoTable, { CellHookData, RowInput, Styles } from "jspdf-autotable";
import {
  TPaymentMethodV2,
  TProduct,
  TPurchase,
  TPurchaseProduct,
  TSubPurchase,
} from "@/api";
import logo from "./logo.png";
import { Download } from "@mui/icons-material";
import { usePaymentMethods } from "@/hooks/payment-methods";
import { country } from "@/config/country";
import { format } from "date-fns";

type TSubpurchaseWithClient = TSubPurchase & Pick<TPurchase, "client">;

type ExportPDFMenuItem = {
  record: TSubpurchaseWithClient;
};

export const SubpurchaseRemission: React.FC<ExportPDFMenuItem> = ({
  record,
}) => {
  const paymentMethods = usePaymentMethods();
  return (
    <>
      <Tooltip title="Descargar remisión">
        <div>
          <IconButton
            color="primary"
            onClick={() => exportRemission(record, paymentMethods)}
          >
            <Download />
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};

const exportRemission = async (
  subpurchase: TSubpurchaseWithClient,
  paymentMethods: TPaymentMethodV2[]
) => {
  const doc = new jsPDF();

  const PRODUCTS_HEADERS = [
    { header: "ITEM", dataKey: "index" },
    { header: "CANTIDAD", dataKey: "quantity" },
    { header: "PRECIO", dataKey: "wholesalePrice" },
    { header: "PRODUCTO", dataKey: "name" },
    { header: "SUBTOTAL", dataKey: "subtotal" },
    { header: "DESCUENTO", dataKey: "totalDiscount" },
    { header: "TOTAL", dataKey: "finalPrice" },
  ];

  const styles: Partial<Styles> = {
    cellPadding: 2,
    cellWidth: "auto",
    fontStyle: "bold",
    fillColor: [245, 245, 245],
    lineWidth: 0.4,
    textColor: 0,
    fontSize: 8,
  };

  const whiteStyle: Partial<Styles> = {
    cellPadding: 2,
    fillColor: "#FFFFFF",
    lineWidth: 0.4,
  };

  const personalData = [
    [
      { content: "NOMBRE", colSpan: 1 },
      {
        content: subpurchase.client.displayName,
        colSpan: 3,
      },
    ],
    [
      { content: "IDENTIFICACION", colSpan: 1 },
      subpurchase.client.documentId,
      { content: "CIUDAD", colSpan: 1 },
      `${subpurchase.client.location.city}-${subpurchase.client.location.state}`,
    ],
    [
      { content: "DIRECCION", colSpan: 1 },
      subpurchase.client.location.addressLine1 +
        " \n" +
        (subpurchase.client.location.addressLine2 ?? "-"),
      { content: "TEL", colSpan: 1 },
      `${subpurchase.client.phonePrefix} ${subpurchase.client.phone}`,
    ],
    [
      "CÓDIGO POSTAL",
      subpurchase.client.location.postalCode || "-",

      "MÉTODO DE PAGO",
      paymentMethods.find((pm) => subpurchase.paymentMethod === pm.code)
        ?.name ?? subpurchase.paymentMethod,
    ],
    [
      { content: "CORREO", colSpan: 1 },
      { content: subpurchase.client.email, colSpan: 3 },
    ],
    [
      { content: "FECHA", colSpan: 1 },
      {
        content: format(new Date(subpurchase.createdAt), "yyyy/MM/dd hh:mm a"),
        colSpan: 3,
      },
    ],
  ];

  const companyData: RowInput[] = [
    [
      {
        content: "",
        styles: {
          cellWidth: 50,
          halign: "center",
        },
      },
      {
        content:
          country.code === "co"
            ? "SEERI COLOMBIA S.A.S\nNIT 901.492.363-7\nCL 70 BIS 4 41\nBogotá - Colombia"
            : "App Seeri Mexico\nRFC ASM210805LS9\nMontes Urales 424\nCiudad de México",
        styles: {
          halign: "center",
        },
      },
      {
        content: `NUMERO DE REMISION\n${subpurchase.subPurchaseNumber}`,
        styles: {
          ...styles,
          halign: "center",
          cellWidth: 50,
          cellPadding: 6,
        },
      },
    ],
  ];

  autoTable(doc, {
    body: companyData,
    startY: 20,
    styles: whiteStyle,
    theme: "grid",
    didDrawCell: (data) => {
      drawImage(0, data, doc);
    },
  });

  autoTable(doc, {
    body: personalData,
    theme: "grid",
    columnStyles: {
      0: styles,
      1: whiteStyle,
      2: styles,
      3: whiteStyle,
    },
  });

  const products = await generateProductTable(subpurchase.products);
  autoTable(doc, {
    body: products,
    theme: "grid",
    columns: PRODUCTS_HEADERS,
    styles: { ...whiteStyle, fontSize: 8 },
    headStyles: { ...styles, halign: "center" },
  });
  renderSummary(
    doc,
    { ...styles, fontSize: 8 },
    { ...whiteStyle, fontSize: 8 },
    subpurchase
  );

  doc.save(`orden_${(subpurchase as any).subPurchaseNumber}.pdf`);
};

const generateProductTable = (products: TPurchaseProduct[]) => {
  return Promise.all(
    products.map((product, index: number) => {
      return {
        index: index + 1,
        name: product.name || "-",
        quantity: product.quantity || 0,
        wholesalePrice: formatCurrency(product.wholesalePrice),
        subtotal: formatCurrency(product.subtotal),
        totalDiscount: formatCurrency(product.totalDiscount),
        finalPrice: formatCurrency(Number(product.subTotalWithDiscount) ?? 0),
      };
    })
  );
};

const renderSummary = (
  doc: jsPDF,
  headStyles: Partial<Styles>,
  bodyStyles: Partial<Styles>,
  subPurchase: TSubpurchaseWithClient
) => {
  const tableWidth = 80;
  const startX = doc.internal.pageSize.width - tableWidth - 14;

  const subtotal: number = subPurchase.products.reduce(
    (d: number, p) => d + (p.subtotal || 0),
    0
  );

  const totalDiscount: number = subPurchase.products.reduce(
    (d: number, p) => d + p.totalDiscount,
    0
  );

  autoTable(doc, {
    body: [
      [{ content: "TOTAL", colSpan: 2, styles: { halign: "center" } }],
      ["SUBTOTAL", formatCurrency(subtotal)],
      ["ENVIO", formatCurrency(subPurchase.shipping)],
      ["TOTAL DESCUENTOS", formatCurrency(totalDiscount)],
      ["TOTAL", formatCurrency(subPurchase.total)],
    ],
    theme: "grid",
    tableWidth: tableWidth,
    margin: { left: startX },
    headStyles: { ...headStyles, halign: "right" },
    columnStyles: {
      0: { ...headStyles, halign: "right" },
      1: { ...bodyStyles, halign: "right" },
    },
  });
};

const drawImage = (index: number, data: CellHookData, doc: jsPDF) => {
  if (data.column.index === index && data.cell.section === "body") {
    const img = new Image();
    img.src = logo;
    doc.addImage(img, data.cell.x + 10, data.cell.y + 6, 28, 8);
  }
};
