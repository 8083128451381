import { fixApiDateStr } from "@/utils/parse-date";
import { formatInTimeZone } from "date-fns-tz";

export const TimeZoneDate = ({ date }: { date: string | Date }) => {
  if (typeof date === "string") {
    date = fixApiDateStr(date);
  }

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  if (!date) return null;

  const formattedDate = formatInTimeZone(
    new Date(date),
    timeZone,
    "yyyy/MM/dd HH:mm "
  );

  return <span title={"Zona horaria:" + timeZone}>{formattedDate}</span>;
};
