import { ADMINS } from "@/config/resources";
import { AutocompleteInput, useGetList } from "react-admin";

export const KamInput = () => {
  let { data, isLoading } = useGetList(ADMINS, {
    pagination: {
      page: 1,
      perPage: 1000,
    },
  });

  return (
    <AutocompleteInput
      source="kam"
      choices={data || []}
      optionValue="email"
      optionText="email"
      loading={isLoading}
    />
  );
};
