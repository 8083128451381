import { TTicketDetail } from "@/api";
import { TICKETS } from "@/config/resources";
import { ticketStatuses } from "@/data/purchases";
import { Card, CardContent, Grid } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  TextField,
} from "react-admin";

export const PurchaseTicketsTabContent = () => {
  return (
    <>
      <Grid container spacing={2} wrap="wrap">
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <ListTicketsByPurchase />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const ListTicketsByPurchase = () => (
  <ArrayField source="tickets">
    <Datagrid width="100%" rowClick={undefined} bulkActionButtons={false}>
      <FunctionField
        source="number"
        label="No."
        render={(ticket: TTicketDetail) => (
          <Link to={`/${TICKETS}/${ticket.id}/show`}>
            <TextField source="number" />
          </Link>
        )}
      />
      <DateField source="createdAt" showTime />
      <FunctionField
        source="reason"
        sortable={false}
        render={(ticket: TTicketDetail) => ticket.reason?.name}
      />
      <FunctionField
        source="type"
        sortable={false}
        render={(ticket: TTicketDetail) => ticket.type?.name}
      />
      <FunctionField
        source="status"
        render={(ticket: TTicketDetail) =>
          ticketStatuses.find((s) => s.code === ticket.status)?.name
        }
      />
    </Datagrid>
  </ArrayField>
);
