import { useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import {
  AutocompleteInput,
  Button,
  Labeled,
  RecordContextProvider,
  ReferenceInput,
  required,
  useChoicesContext,
  useGetList,
  useGetOne,
  useRefresh,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { ADMINS, SELLERS } from "../../../config/resources";
import { purchaseSellerModalOpen } from "./seller-form-dialog";
import { CreateRounded } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { SellerAvailableCreditField } from "@/resources/sellers/fields/available-credit";
import { updateSeller } from "@/api";

export const PurchaseSellerAutocomplete = () => {
  const setPurchaseSellerModalOpen = useSetAtom(purchaseSellerModalOpen);
  const sellerId = useWatch({ name: "sellerId" });

  const { data: seller, isLoading } = useGetOne(
    SELLERS,
    { id: sellerId },
    { enabled: !!sellerId }
  );

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChangeKAM = () => {
    setModalOpen(true);
  };

  const handleConfirmKAM = () => {
    setModalOpen(false);
  };

  return (
    <>
      <ReferenceInput source="sellerId" reference={SELLERS}>
        <SellerAutocompleteInput />
      </ReferenceInput>
      <Box display="flex" justifyContent="space-between">
        <Labeled label="KAM">
          <Typography
            fontSize={"14px"}
            marginBottom="12px"
            color={seller?.kam ? "green" : "black"}
          >
            <b>{isLoading ? "Cargando..." : seller?.kam || "No tiene KAM"}</b>
          </Typography>
        </Labeled>
        {!isLoading && seller && !seller?.kam && (
          <Button label="Agregar KAM" onClick={handleChangeKAM} />
        )}
        {!isLoading && seller?.kam && (
          <Button label="Cambiar KAM" onClick={handleChangeKAM} />
        )}
        {seller && (
          <RecordContextProvider value={seller}>
            <SellerAvailableCreditField />
          </RecordContextProvider>
        )}
      </Box>
      <Button
        label="Crear vendedor"
        onClick={() => setPurchaseSellerModalOpen(true)}
      >
        <CreateRounded />
      </Button>

      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
        <DialogContent>
          <KAMInputForm
            seller={seller}
            onClose={handleModalClose}
            onConfirm={handleConfirmKAM}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export const SellerAutocompleteInput = () => {
  const { selectedChoices, isFetching } = useChoicesContext();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (selectedChoices[0] && !isFetching) {
      setValue("userType", selectedChoices[0].userType);
    }
  }, [selectedChoices[0], isFetching]);

  return (
    <AutocompleteInput
      label="Correo del vendedor"
      filterToQuery={(searchText: string) => ({ email: searchText })}
      optionText="email"
      validate={[required()]}
    />
  );
};

const KAMInputForm = ({ seller, onClose, onConfirm }: any) => {
  const [selectedKAM, setSelectedKAM] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let { data } = useGetList(ADMINS, {
    pagination: {
      page: 1,
      perPage: 1000,
    },
  });

  useEffect(() => {
    setSelectedKAM(seller.kam || "");
  }, [seller.kam]);

  const refresh = useRefresh();

  const handleKAMChange = (event: string) => {
    setSelectedKAM(event);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    if (selectedKAM !== seller.kam) {
      seller.kam = selectedKAM;
      await updateSeller(seller.id, { ...seller });
      refresh();
    }
    onConfirm();
    onClose();
    setIsLoading(false);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <AutocompleteInput
        source={selectedKAM}
        choices={data || []}
        optionValue="email"
        optionText="email"
        disabled={isLoading}
        onChange={handleKAMChange}
        fullWidth
      />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button onClick={handleCancel} color="secondary" label="Cancelar" />
        {isLoading ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          <Button onClick={handleConfirm} color="primary" label="Confirmar" />
        )}
      </Box>
    </>
  );
};
