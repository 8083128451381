import { PRODUCTS, PURCHASES, SELLERS } from "../../../config/resources";
import { i18nProvider } from "../../../providers/i18n";

export const getAllHeaders = () => {
  let columns = [
    "id",
    "purchaseNumber",
    "status",
    "priority",
    "paymentMethod",
    "paymentStatus",
    "deliveryStatus",
    "trackingCode",
    "createdAt",
    "seller.userType",
    "seller.firstName",
    "seller.lastName",
    "seller.email",
    "seller.documentId",
    "seller.phone",
    "seller.myReferralCode",
    "seller.id",
    "seller.bankAccount.accountNumber",
    "seller.bankAccount.accountType",
    "seller.bankAccount.bankName",
    "seller.bankAccount.verified",
    "seller.location.city",
    "seller.location.state",
    "seller.location.country",
    "seller.location.addressLine1",
    "seller.location.addressLine2",
    "seller.kam",
    "products.id",
    "products.sku",
    "products.name",
    "products.brandId",
    "products.categoryId",
    "products.subCategoryId",
    "products.trackingCode",
    "products.deliveryStatus",
    "products.quantity",
    "products.price",
    "products.subtotal",
    "products.discountByBenefit",
    "products.discountByProduct",
    "products.discountByCoupon",
    "products.totalDiscount",
    "products.subTotalWithDiscount",
    "products.subPurchase.subPurchaseNumber",
    "products.subPurchase.paymentStatus",
    "products.subPurchase.trackingCode",
    "products.subPurchase.deliveryStatus",
    "subtotal",
    "shipping",
    "discount",
    "total",
    "client.id",
    "client.documentId",
    "client.firstName",
    "client.lastName",
    "client.location.city",
    "client.location.state",
    "client.location.addressLine1",
    "client.location.addressLine2",
    "client.phone",
    "client.email",
  ];

  return columns;
};

export const getAllHeadersNames = (headers: string[]) => {
  return headers.map(getHeaderName);
};

export const getHeaderName = (h: string) => {
  if (h.startsWith("seller.")) {
    h = h.replace("seller.", "");
    return (
      "Vendedor: " + i18nProvider.translate(`resources.${SELLERS}.fields.${h}`)
    );
  }
  if (h.startsWith("products.")) {
    if (h.startsWith("products.product")) {
      h = h.replace("products.product.", "");
      return (
        "Producto: " +
        i18nProvider.translate(`resources.${PRODUCTS}.fields.${h}`)
      );
    }
    if (h.startsWith("products.subPurchase")) {
      h = h.replace("products.subPurchase.", "");
      return (
        "Subpedido: " +
        i18nProvider.translate(
          `resources.${PURCHASES}.fields.subPurchases.${h}`
        )
      );
    } else {
      h = h.replace("products.", "");
      return (
        "Producto: " +
        i18nProvider.translate(`resources.${PRODUCTS}.fields.${h}`)
      );
    }
  }

  if (h.startsWith("client.")) {
    return (
      "Cliente: " + i18nProvider.translate(`resources.${PURCHASES}.fields.${h}`)
    );
  }
  if (h.startsWith("location.")) {
    return (
      "Envío: " + i18nProvider.translate(`resources.${PURCHASES}.fields.${h}`)
    );
  }
  return i18nProvider.translate(`resources.${PURCHASES}.fields.${h}`);
};
