import { useEffect } from "react";
import { Create, useRedirect, useStore } from "react-admin";
import { COURSES, LECTURES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { LectureForm } from "./_form";

export const LectureCreate = () => {
  const { hasPermission } = useAdmin();
  const redirect = useRedirect();

  const [courseId] = useStore("currentCourseId");

  useEffect(() => {
    if (!courseId) {
      redirect("/" + COURSES);
    }
  }, [courseId]);

  if (!hasPermission("lecture.create")) return null;

  if (!courseId) return null;

  return (
    <Create
      resource={LECTURES}
      title={`Crear Clase de Curso #${courseId}`}
      redirect="show"
      mutationOptions={{
        meta: {
          dependentId: courseId,
        },
      }}
    >
      <LectureForm />
    </Create>
  );
};
