import { Edit } from "react-admin";
import { QuoteForm } from "./_form";
import { useAdmin } from "../../hooks/admin";

export const QuoteEdit = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("quotes.edit")) return null;

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <QuoteForm isEdit={true} />
    </Edit>
  );
};
