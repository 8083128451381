import { createTheme } from "@mui/material";
import merge from "lodash/merge";
import { defaultTheme } from "react-admin";

export const myTheme = merge({}, defaultTheme, {
  components: {
    ...defaultTheme.components,
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          userSelect: "all",
        },
      },
    },
    RaListToolbar: {
      styleOverrides: {
        root: {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          alignSelf: "flex-end",
          minHeight: "auto !important",
          flexWrap: "wrap"
        },
      },
    },
    RaImageField: {
      styleOverrides: {
        root: {
          "& > img.RaImageField-image": {
            width: "auto",
            maxWidth: "100%",
          },
        },
      },
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        root: {
          ".MuiTabs-flexContainer": {
            maxWidth: "90vw",
            flexWrap: "wrap",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      light: "#012430",
      main: "#012430",
      dark: "#012430",
      contrastText: "#fff",
    },
    primary: {
      light: "#012430",
      main: "#FD4D74",
      dark: "#012430",
      contrastText: "#fff",
    },
    status: {
      danger: "#e29185",
    },
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: "visible",
      },
    },
    MuiPaper: {
      root: {
        border: "none",
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: "#01E5DC",
      },
    },
    MuiSnackbarContent: {
      root: {
        border: "none",
      },
    },
  },
} as any);

export const darkTheme = createTheme({
  ...myTheme,
  palette: { mode: "dark" },
});
