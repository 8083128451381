import { mdiFileCheck } from "@mdi/js";
import { Box, Dialog, DialogTitle, SvgIcon } from "@mui/material";
import { AxiosError, updateVoucherStatus } from "@/api";
import { useState } from "react";
import {
  Button,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { statusChoices } from "../constants";

export const ChangeStatusButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values: any) => {
    console.log({ values });
    try {
      const { status, reference } = values;
      await updateVoucherStatus(record?.id as number, {
        status,
        reference,
      });
      notify("El voucher se actualizó exitosamente", { type: "success" });
      refresh();
      setOpen(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo actualizar", { type: "error" });
      }
    }
  };

  return (
    <>
      <Button
        startIcon={
          <SvgIcon>
            <path d={mdiFileCheck} />
          </SvgIcon>
        }
        label="Aprobar o rechazar"
        onClick={() => setOpen(true)}
        disabled={record?.status !== "PENDING"}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Cambiar estado</DialogTitle>
        {open && (
          <SimpleForm
            mode="onBlur"
            onSubmit={onSubmit}
            toolbar={
              <Toolbar>
                <SaveButton />
                <Box ml={"auto"}>
                  <Button label="Cerrar" onClick={() => setOpen(false)} />
                </Box>
              </Toolbar>
            }
          >
            <SelectInput
              source="status"
              choices={statusChoices}
              validate={required()}
              fullWidth
            />
            <ReferenceInput />
          </SimpleForm>
        )}
      </Dialog>
    </>
  );
};

const ReferenceInput = () => {
  const status = useWatch({ name: "status" });
  if (status !== "APPROVED") return null;
  return <TextInput source="reference" fullWidth />;
};
