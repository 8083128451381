import {
  ArrayField,
  DateField,
  EditButton,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { useAdmin } from "../../hooks/admin";
import { Box, Grid } from "@mui/material";
import {
  ADMINS,
  BRANDS,
  CATEGORIES,
  PRODUCTS,
  SELLERS,
  SUPPLIERS,
} from "@/config/resources";
import { offerTypes } from "@/data/offers";
import { usePaymentMethods } from "@/hooks/payment-methods";
import { getPaymentMethodByCode } from "../purchases/show/_timeline";

const OffertShowActions = () => {
  const { hasPermission } = useAdmin();

  return (
    <TopToolbar>{hasPermission("offert.update") && <EditButton />}</TopToolbar>
  );
};

export const OffertShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("offert.view")) return null;

  return (
    <>
      <Show actions={<OffertShowActions />}>
        <OfferContent />
      </Show>
    </>
  );
};

const OfferContent = () => {
  const paymentMethods = usePaymentMethods();
  const record = useRecordContext();
  const type = record?.type;
  const isPayXTakeY = type === "PAY_X_TAKE_Y";

  return (
    <Box padding={2} sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CreatedByField />
                <SimpleShowLayout>
                  <TextField source="name" />
                  <FunctionField
                    source="type"
                    render={(record: any) =>
                      offerTypes.find((o) => o.code === record.type)?.name
                    }
                  />
                  <FunctionField
                    label="Estado"
                    render={(record: any) =>
                      record?.enabled ? "Activo" : "Desactivado"
                    }
                  />
                  <DateField source="createdAt" showTime />
                  <DateField source="endsAt" showTime />

                  <NumberField
                    source="maximumUses"
                    emptyText="Sin límite de uso"
                  />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={12} md={6}>
                <SimpleShowLayout>
                  <NumberField source="maximumUsesByUser" />
                  {isPayXTakeY && (
                    <NumberField source="maximumUsesByPurchase" />
                  )}
                  <NumberField source="value" />
                  <NumberField source="maxValueToOffer" />
                  <NumberField source="minValue" />
                  <ArrayField source="targetPaymentMethods">
                    <SingleFieldList linkType={false}>
                      <FunctionField
                        render={(pm: string) => (
                          <span style={{ margin: "0 0.5rem" }}>
                            {getPaymentMethodByCode(paymentMethods, pm)}
                          </span>
                        )}
                      />
                    </SingleFieldList>
                  </ArrayField>
                  <ReferenceArrayField
                    source="targetCategoriesProducts"
                    reference={CATEGORIES}
                  >
                    <SingleFieldList>
                      <Box sx={{ marginRight: "1rem" }}>
                        <TextField source="name" />
                      </Box>
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <ReferenceArrayField
                    source="targetSubCategoriesProducts"
                    reference={CATEGORIES}
                  >
                    <SingleFieldList>
                      <Box sx={{ marginRight: "1rem" }}>
                        <TextField source="name" />
                      </Box>
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <ReferenceArrayField
                    source="targetProductsIds"
                    reference={PRODUCTS}
                  >
                    <SingleFieldList>
                      <Box sx={{ marginRight: "1rem" }}>
                        <TextField source="name" />
                      </Box>
                    </SingleFieldList>
                  </ReferenceArrayField>
                  {isPayXTakeY && (
                    <ArrayField source="gifts">
                      <SingleFieldList>
                        <ReferenceField reference={PRODUCTS} source="productId">
                          <TextField source="name" />
                        </ReferenceField>
                      </SingleFieldList>
                    </ArrayField>
                  )}
                  <ReferenceArrayField
                    source="targetBrandsProducts"
                    reference={BRANDS}
                  >
                    <SingleFieldList>
                      <Box sx={{ marginRight: "1rem" }}>
                        <TextField source="name" />
                      </Box>
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <ReferenceArrayField
                    source="targetSellersIds"
                    reference={SELLERS}
                  >
                    <SingleFieldList>
                      <Box sx={{ marginRight: "1rem" }}>
                        <TextField source="email" />
                      </Box>
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <ReferenceArrayField
                    source="targetSuppliersProducts"
                    reference={SUPPLIERS}
                  >
                    <SingleFieldList>
                      <Box sx={{ marginRight: "1rem" }}>
                        <TextField source="email" />
                      </Box>
                    </SingleFieldList>
                  </ReferenceArrayField>
                  {isPayXTakeY && <NumberField source="productsToPay" />}
                  {isPayXTakeY && <NumberField source="productsToTake" />}
                </SimpleShowLayout>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const CreatedByField = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();
  return (
    <SimpleShowLayout>
      {record?.type === "PAY_X_TAKE_Y" ? (
        <ReferenceField reference={SUPPLIERS} source="createdBy">
          <TextField source="name" />
        </ReferenceField>
      ) : (
        <ReferenceField
          reference={ADMINS}
          source="createdBy"
          link={hasPermission("admin.view") ? "show" : false}
        >
          <TextField source="email" />
        </ReferenceField>
      )}
    </SimpleShowLayout>
  );
};
