import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Tooltip,
  Card,
  Link,
} from "@mui/material";
import { Receipt } from "@mui/icons-material";
import { FC, useCallback } from "react";
import {
  ArrayField,
  ArrayInput,
  Button,
  Datagrid,
  FunctionField,
  ImageField,
  ImageInput,
  minValue,
  number,
  NumberField,
  NumberInput,
  RadioButtonGroupInput,
  RecordContextProvider,
  required,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  Toolbar,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useAdmin } from "@/hooks/admin";
import { PURCHASES } from "@/config/resources";
import { useParams } from "react-router-dom";
import { atom, useAtom } from "jotai";
import { useSetAtom } from "jotai";
import {
  AxiosError,
  updatePurchasePaymentStatus,
  updatePurchasePaymentVouchersV2,
  deletePurchasePaymentVoucherV2,
  getPurchasePaymentVouchers,
  TPurchase,
} from "@/api";
import { useWatch } from "react-hook-form";

import useSWR from "swr";
import { formatCurrency } from "@/utils/currency";
import { disableButtonAction, DISABLED_TIMEOUT } from "./create-subpurchase";
import { seeriAppDomain } from "@/constants/apps";

export const useVouchers = (purchaseId?: string) => {
  const { data: purchase } = useGetOne(PURCHASES, { id: purchaseId });
  const {
    data: vouchers,
    mutate: refreshVouchers,
    error,
  } = useSWR(
    purchaseId ? [purchaseId, "getPurchasePaymentVouchers"] : null,
    ([id]) => getPurchasePaymentVouchers(id)
  );

  const vouchersAmount =
    vouchers?.reduce((amount, v) => amount + v.amount, 0) ?? 0;
  const pendingToPayByVouchers = purchase?.pendingToPay - vouchersAmount;

  return {
    vouchers,
    refreshVouchers,
    vouchersAmount,
    pendingToPayByVouchers,
    loadingVouchers: !vouchers && !error,
  };
};

const PurchasePaymentVouchersArrayField: FC<{ record: any }> = ({ record }) => {
  return (
    <RecordContextProvider>
      <Card>
        <ArrayField source="vouchers" record={record}>
          <Datagrid bulkActionButtons={false}>
            <ImageField source="file.src" label="Comprobante de pago" />
            <FunctionField
              source="file.src"
              label="Ruta de archivo"
              render={(record: any) => (
                <Link href={record.file.src} target="_blank">
                  {record.file.src}
                </Link>
              )}
            />
            <NumberField source="amount" label="Monto" />
          </Datagrid>
        </ArrayField>
      </Card>
    </RecordContextProvider>
  );
};

const maxSum = (record: any) => (vouchers: any[]) => {
  let pendingToPay = record?.pendingToPay;

  const sumAmounts =
    vouchers
      ?.map((v: any) => v.amount)
      .reduce((pValue: number, cValue: number) => pValue + cValue, 0) || 0;

  if (sumAmounts > pendingToPay * 1.01) {
    return "La suma excede el monto pendiente a pagar";
  }

  return undefined;
};

const PurchasePaymentVouchersArrayInput = () => {
  const record = useRecordContext();

  return (
    <ArrayInput source="vouchers" validate={[required(), maxSum(record)]}>
      <SimpleFormIterator disableClear>
        <ImageInput
          source="file"
          accept="image/png, image/jpg, image/jpeg"
          maxSize={5000000}
          helperText="Imagen de menos de 5MB. Si la imagen no carga es porque supera el peso de 5MB."
          format={(v: any) => {
            if (v?.rawFile) {
              const { rawFile } = v;
              const blob = rawFile.slice(0, rawFile.size, rawFile.type);
              v.rawFile = new File(
                [blob],
                Date.now() + "." + rawFile.type?.split("/")[1],
                { type: blob.type }
              );
            }
            return v;
          }}
          validate={[required()]}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <NumberInput
          source="amount"
          min={0}
          validate={[required(), number(), minValue(0)]}
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const PurchasePaymentVoucherEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const purchasePaymentVoucherModalOpen = atom(false);

const PurchasePaymentVoucherDialogContent = () => {
  const setOpen = useSetAtom(purchasePaymentVoucherModalOpen);
  const setDisabledButton = useSetAtom(disableButtonAction);
  const { role, hasPermission } = useAdmin();
  const refresh = useRefresh();
  const notify = useNotify();
  const { id } = useParams();

  let { data } = useGetOne(PURCHASES, { id });

  const { vouchers, refreshVouchers } = useVouchers(id);

  const disableActions = () => {
    setDisabledButton(true);
    setTimeout(() => {
      setDisabledButton(false);
      refresh();
    }, DISABLED_TIMEOUT);
  };

  let customRecord = data;
  if (vouchers && vouchers?.length) {
    const formatVouchers = vouchers?.map((v) => ({
      file: {
        src: v.fileUrl,
      },
      amount: v.amount,
    }));

    customRecord = { ...data, vouchers: formatVouchers };
  }

  const isValidatingVouchers =
    hasPermission("purchase.view.vouchers") &&
    customRecord?.paymentStatus === "REVIEW";
  const isPendingVouchers =
    hasPermission("purchase.update.vouchers") &&
    customRecord?.paymentStatus === "PENDING";

  const purchaseId = id;

  const onSubmitAnswer = useCallback(
    async (values: any) => {
      try {
        let { answer } = values;
        await updatePurchasePaymentStatus(purchaseId as string, answer);
        refresh();
        setOpen(false);
        disableActions();
        notify("El pedido se actualizó exitosamente", { type: "success" });
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }
        setOpen(false);
        return;
      }
    },
    [purchaseId]
  );

  const handleUploadFile = async (
    file: File,
    purchaseId: string,
    key: number
  ) => {
    const fileTimestamp = Date.now();
    const fileExt = file.name.split(".").pop();

    const newFileName = `voucher_${fileTimestamp}_${key}.${fileExt}`;

    // @todo: Replace by backend endpoint with seeri api
    const res = await fetch(
      `${seeriAppDomain}/api/upload-voucher?file=purchases/${purchaseId}/voucher/${newFileName}`
    );

    const { url, fields } = await res.json();

    const formData = new FormData();

    Object.entries({ ...fields, file }).forEach(([key, value]) => {
      formData.append(key, value as any);
    });

    await fetch(url, {
      method: "POST",
      body: formData,
    });
    const newFileUrl = `${url}${fields.key}`;
    return { file: file, url: newFileUrl, name: newFileName };
  };

  const onSubmit = useCallback(
    async (values: any) => {
      const oldVouchers = vouchers;
      try {
        const { vouchers } = values;

        const payload = await Promise.all(
          vouchers.map(async (v: any, key: number) => {
            if (v.file.rawFile) {
              const uploadedFile = await handleUploadFile(
                v.file.rawFile,
                purchaseId as string,
                key
              );
              return {
                amount: v.amount,
                url: uploadedFile.url,
              };
            } else {
              return {
                amount: v.amount,
                url: v.file.src,
              };
            }
          })
        );
        await updatePurchasePaymentVouchersV2(purchaseId as string, payload);

        const vouchersToBeDeleted = oldVouchers
          ?.filter(
            (voucher) =>
              !payload.map((pVoucher) => pVoucher.url).includes(voucher.fileUrl)
          )
          .map((v) => v.fileUrl);

        if (vouchersToBeDeleted && vouchersToBeDeleted.length > 0) {
          await deletePurchasePaymentVoucherV2(
            purchaseId as string,
            vouchersToBeDeleted
          );
        }

        refresh();
        refreshVouchers();
        setOpen(false);
        disableActions();
        notify("El pedido se actualizó exitosamente", { type: "success" });
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else {
          notify("No se pudo actualizar", { type: "error" });
        }
        setOpen(false);
        return;
      }
    },
    [customRecord, role]
  );

  if (!customRecord) return null;

  return (
    <DialogContent
      sx={{
        ".MuiPaper-root.MuiCard-root": {
          marginBottom: "1rem",
        },
      }}
    >
      {isValidatingVouchers ? (
        <>
          <PurchasePaymentVouchersArrayField record={customRecord} />
          <DialogContentText>
            Pendiente a pagar:{" "}
            <span style={{ fontWeight: "bold" }}>
              {formatCurrency(customRecord?.pendingToPay)}
            </span>
          </DialogContentText>

          <SimpleForm
            mode="onBlur"
            record={data}
            resource={PURCHASES}
            toolbar={<PurchasePaymentVoucherEditToolbar />}
            onSubmit={onSubmitAnswer}
          >
            <RadioButtonGroupInput
              label="¿Acepta o rechaza los comprobantes de pago?"
              source="answer"
              choices={[
                { id: "approve", name: "Aceptar" },
                { id: "reject", name: "Rechazar" },
              ]}
            />
          </SimpleForm>
        </>
      ) : isPendingVouchers ? (
        <SimpleForm
          mode="onBlur"
          record={customRecord}
          resource={PURCHASES}
          toolbar={<PurchasePaymentVoucherEditToolbar />}
          onSubmit={onSubmit}
        >
          <PurchasePaymentVouchersArrayInput />
          <PurchasePaymentVouchersDetails />
        </SimpleForm>
      ) : (
        <PurchasePaymentVouchersArrayField record={customRecord} />
      )}
    </DialogContent>
  );
};

export const PurchasePaymentVoucherDialog = () => {
  const [open, setOpen] = useAtom(purchasePaymentVoucherModalOpen);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle>Comprobantes de pago</DialogTitle>
      <PurchasePaymentVoucherDialogContent />
      <DialogActions>
        <Button label="Cerrar" onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
};

const PurchasePaymentVouchersDetails = () => {
  const record = useRecordContext();
  const vouchers = useWatch({ name: "vouchers" });
  let pendingToPay = record.pendingToPay;
  let exceed = false;

  if (vouchers && vouchers.length) {
    const sumAmounts = vouchers
      .map((v: any) => v.amount)
      .reduce((pValue: number, cValue: number) => pValue + cValue, 0);

    if (pendingToPay * 1.01 < sumAmounts) {
      exceed = true;
    } else {
      pendingToPay -= sumAmounts;
    }
  }

  return (
    <>
      <DialogContentText>
        Pendiente a pagar:{" "}
        <span style={{ fontWeight: "bold" }}>
          {exceed
            ? "Se ha excedido el monto"
            : pendingToPay > 0
            ? formatCurrency(pendingToPay)
            : 0}
        </span>
      </DialogContentText>
      <DialogContentText>
        Total a pagar:{" "}
        <span style={{ fontWeight: "bold" }}>
          {formatCurrency(record?.pendingToPay)}
        </span>
      </DialogContentText>
    </>
  );
};

export const PurchasePaymentVoucherButton = () => {
  const { id } = useParams();
  const record = useRecordContext<TPurchase>();
  const { vouchers, loadingVouchers } = useVouchers(id);

  const hasVouchers = vouchers?.length;

  const isValidPaymentMethod =
    record?.paymentMethod === "BANK_TRANSFER" ||
    record?.paymentMethod === "CREDIT";

  const setPurchasePaymentVoucherModalOpen = useSetAtom(
    purchasePaymentVoucherModalOpen
  );

  return (
    <div>
      <Tooltip
        title={
          vouchers?.length === 0 && !isValidPaymentMethod
            ? "¡Atención! Usa el nuevo módulo de comprobantes."
            : "Comprobantes de pago"
        }
      >
        <div>
          <IconButton
            color="primary"
            onClick={() => setPurchasePaymentVoucherModalOpen(true)}
            disabled={loadingVouchers || !(isValidPaymentMethod || hasVouchers)}
          >
            <Receipt />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};
