import { FC } from "react";
import { EditBase, useNotify, useRefresh } from "react-admin";
import { SUPPLIER_ADDRESSES } from "../../../config/resources";
import { SupplierAddressForm } from "./_form";

type Props = {
  id: string;
  supplierId: string;
  onDone: () => void;
};

export const SupplierAddressEdit: FC<Props> = ({ supplierId, id, onDone }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <EditBase
      id={id}
      resource={SUPPLIER_ADDRESSES}
      mutationMode="pessimistic"
      mutationOptions={{
        meta: {
          dependentId: supplierId,
        },
        onSuccess: () => {
          notify("Dirección actualizada");
          refresh();
          onDone();
        },
      }}
      queryOptions={{
        meta: {
          dependentId: supplierId,
        },
      }}
      redirect={false}
    >
      <SupplierAddressForm supplierId={supplierId} />
    </EditBase>
  );
};
