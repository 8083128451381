import { createContext, useContext } from "react";

export type TSupplierDiscountsContext = {
  applier: "SUPPLIER" | "BRAND" | "PRODUCT";
  supplierId: string;
  reload: () => void | Promise<void>;
};

export const SupplierDiscountsContext =
  createContext<TSupplierDiscountsContext>({} as TSupplierDiscountsContext);

export const useSupplierDiscounts = () => useContext(SupplierDiscountsContext);
