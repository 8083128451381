import { mdiCarCoolantLevel } from "@mdi/js";
import { Box, Dialog, DialogTitle, SvgIcon } from "@mui/material";
import { AxiosError, getSeeriApi } from "@/api";
import { FC, useState } from "react";
import {
  Button,
  maxValue,
  minValue,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useLimits } from "./use";

type Props = {
  type: "LIMIT" | "DISCOUNT";
  applier: "CATEGORY" | "SUPPLIER" | "BRAND";
  referenceId?: string;
  disabled?: boolean;
};

export const CreateLimitButton: FC<Props> = ({
  type,
  applier,
  referenceId,
}) => {
  const { limits, refreshLimits } = useLimits(applier, type);
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const onSubmit = async (values: any) => {
    try {
      const { value, valueType, percentage } = values;
      await getSeeriApi().post("/api/limits", {
        applier,
        referenceId: referenceId || record?.id,
        type,
        valueType,
        value,
        ...(type === "DISCOUNT" ? { percentage } : {}),
      });
      notify("Se agregó el límite exitosamente", { type: "success" });
      refreshLimits();
      setOpen(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo agregar", { type: "error" });
      }
    }
  };

  return (
    <>
      <Button
        startIcon={
          <SvgIcon>
            <path d={mdiCarCoolantLevel} />
          </SvgIcon>
        }
        label={
          "Agregar " + (type === "LIMIT" ? "Límite" : "Descuento escalonado")
        }
        onClick={() => setOpen(true)}
        disabled={type === "LIMIT" && limits?.length ? true : false}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>
          Agregar {type === "LIMIT" ? "Límite" : "Descuento escalonado"}
        </DialogTitle>
        {open && (
          <SimpleForm
            mode="onBlur"
            onSubmit={onSubmit}
            toolbar={
              <Toolbar>
                <SaveButton />
                <Box ml={"auto"}>
                  <Button label="Cerrar" onClick={() => setOpen(false)} />
                </Box>
              </Toolbar>
            }
          >
            <SelectInput
              source="valueType"
              label="Tipo de valor"
              choices={[
                { id: "AMOUNT", name: "Monto" },
                { id: "UNITS", name: "Unidades" },
              ]}
              validate={[required()]}
            />
            <NumberInput source="value" label="Valor" validate={[required()]} />
            {type === "DISCOUNT" && (
              <NumberInput
                source="percentage"
                label="Porcentaje"
                validate={[required(), minValue(0), maxValue(100)]}
                min={0}
                max={100}
              />
            )}
          </SimpleForm>
        )}
      </Dialog>
    </>
  );
};
