import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Button, Form, RadioButtonGroupInput } from "react-admin";
import { PURCHASES } from "../../../../config/resources";
import { i18nProvider } from "../../../../providers/i18n";
import * as XLSX from "xlsx";

type Props = {
  onEnd: () => void;
};

export const UpdateTemplate: FC<Props> = ({ onEnd }) => {
  const [headers, setHeaders] = useState("");

  const download = useCallback(() => {
    const row = ["subPurchaseNumber"].concat(headers.split(","));
    const worksheet = XLSX.utils.aoa_to_sheet([row]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subpedidos");
    worksheet["!cols"] = row.map((k) => ({ wch: k.length + 4 }));
    XLSX.writeFile(workbook, "plantilla-actualizacion-subpedidos.xlsx");
    onEnd();
  }, [headers]);

  return (
    <>
      <DialogTitle>
        <strong>Subpedidos:</strong> Plantilla de actualización masiva
      </DialogTitle>
      <DialogContent>
        <HeadersInput value={headers} onChange={setHeaders} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onEnd}
          label="Cerrar"
          color="secondary"
          variant="outlined"
          size="medium"
        />
        <Button
          label="Descargar plantilla"
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
          onClick={download}
          disabled={!headers}
        />
      </DialogActions>
    </>
  );
};

const HeadersInput: FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  return (
    <>
      <Typography sx={{ mt: 2 }}>
        Selecciona el campo que deseas incluir en la plantilla de actualización:
      </Typography>
      <Form record={{ header: value ?? [] }} mode="onBlur">
        <RadioButtonGroupInput
          source="header"
          label="Campos"
          choices={getHeaders().map((names) => ({
            id: names,
            name: names.split(",").map(getHeaderName).join(", "),
          }))}
          row={false}
          onChange={(v) => onChange(v.target.value)}
        />
      </Form>
    </>
  );
};

const getHeaders = () => {
  return ["trackingCode,deliveryProvider", "deliveryStatus", "deliveryCost"];
};

const getHeaderName = (header: string) =>
  i18nProvider.translate(
    `resources.${PURCHASES}.fields.subPurchases.${header}`,
    {
      _: header,
    }
  );
